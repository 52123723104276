<template>
  <transition name="fade">
    <div
      :style="{ 'background-image': 'url(' + src + ')', height }"
      class="pinpoll-image"
    />
  </transition>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: '150px',
    },
  },
};
</script>

<style>
.pinpoll-image {
  background-size: cover;
  background-position: center center;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, max-height 0.5s;
  max-height: 150px;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0px;
}
</style>
