/* ============
 * Vue-ACL
 * ============
 *
 * Access Control List 4.0.6 for VueJS 2.0
 *
 * https://github.com/leonardovilarinho/vue-acl
 * to work with Vuex
 *
 * Memory Leak detected so using own repo until pull request is approved
 */

import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import { router } from '@/plugins/vue-router';
import store from '@/store';
import bus from '@/utils/bus';
import 'regenerator-runtime/runtime'; // needed for vue-cli modern mode

Vue.use(AclInstaller);

export default new AclCreate({
  initial: store.state.account.access,
  notfound: {
    path: '/login',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    // virtual permission used to check if user is logged in
    isLoggedIn: new AclRule('AUTHENTICATED').generate(),

    // Products
    useProductTools: new AclRule('MODULE_TOOLS.VIEW').generate(),
    useProductDmp: new AclRule('MODULE_DMP.VIEW').generate(),
    useProductAnalytics: new AclRule('MODULE_ANALYTICS.VIEW').generate(),
    useProductAds: new AclRule('MODULE_ADS.VIEW').generate(),

    // Modules
    viewModuleDashboard: new AclRule('MODULE_DASHBOARD.VIEW').generate(),
    viewModuleAnalytics: new AclRule('MODULE_ANALYTICS.VIEW').generate(),
    viewModuleDMP: new AclRule('MODULE_DMP.VIEW').generate(),
    viewModuleAds: new AclRule('MODULE_ADS.VIEW').generate(),
    viewModuleAccount: new AclRule('MODULE_ACCOUNT.VIEW').generate(),
    viewModuleAdmin: new AclRule('MODULE_ADMIN.VIEW').generate(),

    'POLLS.VIEW': new AclRule('POLLS.VIEW').generate(),
    'QUIZZES.VIEW': new AclRule('QUIZZES.VIEW').generate(),
    'PERSONALITY_TESTS.VIEW': new AclRule('PERSONALITY_TESTS.VIEW').generate(),
    'ALL_ENTITIES.VIEW': new AclRule('ALL_ENTITIES.VIEW').generate(),

    // Entities
    viewRealtime: new AclRule('REALTIME.VIEW')
      .and('AUDIENCES.VIEW')
      .and('MODULE_ANALYTICS.VIEW')
      .generate(),
    viewInsights: new AclRule('INSIGHTS.VIEW')
      .and('AUDIENCES.VIEW')
      .and('MODULE_ANALYTICS.VIEW')
      .generate(),
    getAudiences: new AclRule('AUDIENCES.VIEW').generate(),
    viewAudiences: new AclRule('AUDIENCES.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    aiAudiences: new AclRule('AI_AUDIENCES.VIEW'),
    crudAudiences: new AclRule('AUDIENCES.VIEW')
      .and('AUDIENCES.CREATE')
      .and('AUDIENCES.EDIT')
      .and('AUDIENCES.DELETE')
      .and('MODULE_DMP.VIEW')
      .generate(),
    defineAudiences: new AclRule('AUDIENCES.VIEW')
      .and('AUDIENCES.CREATE')
      .and('AUDIENCES.EDIT')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewSurfBehaviour: new AclRule('SURF_BEHAVIOURS.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewPolls: new AclRule('POLLS.VIEW').and('MODULE_DMP.VIEW').generate(),
    viewForms: new AclRule('LEAD_FORMS.VIEW')
      .and('LEAD_DATA.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewTransferAudiences: new AclRule('AUDIENCE_TRANSFERS.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    crudTransferAudiences: new AclRule('AUDIENCE_TRANSFERS.VIEW')
      .and('AUDIENCE_TRANSFERS.CREATE')
      .and('AUDIENCE_TRANSFERS.EDIT')
      .and('AUDIENCE_TRANSFERS.DELETE')
      .and('PIXELS.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewTrackings: new AclRule('TRACKING_EVENTS.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    crudTrackings: new AclRule('TRACKING_EVENTS.VIEW')
      .and('TRACKING_EVENTS.CREATE')
      .and('TRACKING_EVENTS.EDIT')
      .and('TRACKING_EVENTS.DELETE')
      .and('PIXELS.VIEW')
      .and('POLLS.VIEW')
      .and('ADS.VIEW')
      .and('LEAD_FORMS.VIEW')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewPixels: new AclRule('PIXELS.VIEW').and('MODULE_DMP.VIEW').generate(),
    crudPixels: new AclRule('PIXELS.VIEW')
      .and('PIXELS.CREATE')
      .and('PIXELS.EDIT')
      .and('PIXELS.DELETE')
      .and('MODULE_DMP.VIEW')
      .generate(),
    viewAds: new AclRule('ADS.VIEW').and('MODULE_ADS.VIEW').generate(),
    crudAds: new AclRule('ADS.VIEW')
      .and('ADS.CREATE')
      .and('ADS.EDIT')
      .and('ADS.DELETE')
      .and('MODULE_ADS.VIEW')
      .generate(),
    duplicateAds: new AclRule('ADS.DUPLICATE').generate(),
    manageSettings: new AclRule('CRON_JOBS.VIEW')
      .or('USER_CACHE.DELETE')
      .or('ELASTIC_TASKS.VIEW')
      .and('MODULE_ADMIN.VIEW')
      .generate(),
    manageCronJobs: new AclRule('CRON_JOBS.VIEW')
      .and('CRON_JOBS.EDIT')
      .and('MODULE_ADMIN.VIEW')
      .generate(),
    manageCache: new AclRule('USER_CACHE.DELETE')
      .and('RETARGETING_CACHE.DELETE')
      .and('MODULE_ADMIN.VIEW')
      .generate(),
    manageElasticTasks: new AclRule('ELASTIC_TASKS.VIEW')
      .and('ELASTIC_TASKS.DELETE')
      .and('MODULE_ADMIN.VIEW')
      .generate(),
    manageChannels: new AclRule('CHANNELS.VIEW')
      .and('CHANNELS.CREATE')
      .and('CHANNELS.EDIT')
      .and('CHANNELS.DELETE')
      .and('MODULE_ACCOUNT.VIEW')
      .generate(),
    editSettings: new AclRule('MODULE_ACCOUNT.VIEW').generate(),
    viewUsers: new AclRule('ALL_ENTITIES.VIEW')
      .and('MODULE_ADMIN.VIEW')
      .generate(),
  },
  middleware: async (acl) => {
    // get user if authenticated and not having permissions yet
    if (store.state.auth.authenticated && !store.state.account.access.length) {
      await store.dispatch('account/me');
      bus.fire('user-logged-in');
    }
    acl.change(store.state.account.access);
  },
});
