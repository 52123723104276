<template>
  <div>
    <span>{{ $t('components.addImageDialog.animated.youtube.title') }}</span>
    <v-text-field
      v-model="youtube.search"
      :label="$t('components.addImageDialog.animated.youtube.search')"
      prepend-icon="mdi-youtube"
      @keydown.enter="searchPic"
      @focus="$event.target.select()"
    />
    <v-container v-if="active && items.length" fluid>
      <v-row>
        <v-col
          v-for="item in items"
          :key="item.downloadURL"
          cols="6"
          sm="4"
          md="4"
          lg="4"
          xl="4"
        >
          <v-card flat tile>
            <div
              :class="'item ' + [item.id === currentSelectedId && 'selected']"
              @click="selectFile(item)"
            >
              <iframe
                :src="youtubeBase + item.id"
                class="video"
                width="100%"
                frameborder="0"
                allowfullscreen
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" class="center-items">
          <v-btn color="primary" @click="loadMore">
            {{ $t('components.addImageDialog.more') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      youtube: {
        library: 'youtube',
        search: '',
        page: 1,
      },
      currentSelectedId: 0,
      youtubeBase: 'https://www.youtube.com/embed/',
    };
  },
  computed: {
    ...mapState('image', {
      items: (state) => state.items.youtube,
    }),
  },
  watch: {
    'youtube.search': function search() {
      this.searchPicDebounced();
    },
    active() {
      if (!this.active) {
        this.youtube.search = '';
      }
    },
  },
  methods: {
    searchPicDebounced: _.debounce(function search() {
      this.searchPic();
    }, 500),
    searchPic() {
      if (this.youtube.search) {
        this.youtube.page = 1;
        this.currentSelectedId = 0;
        this.$emit('get-items', this.youtube);
      }
    },
    loadMore() {
      this.youtube.page += 1;
      this.$emit('get-items', this.youtube);
    },
    selectFile(item) {
      this.currentSelectedId = item.id;

      // set selected file to preview
      const data = {
        imageUrl: item.downloadURL,
        isBase64: false,
        ytVideoId: item.id,
      };
      this.$store.commit('image/SELECT_ITEM', data);
    },
  },
};
</script>
<style scoped>
.item {
  cursor: pointer;
  padding: 5px;
  padding-bottom: 10px;
  background-color: white;
}
.item.selected {
  background-color: teal;
}
.center-items {
  align-items: center;
  justify-content: center;
}
</style>
