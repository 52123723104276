/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export const getInitialState = () => ({
  authenticated: false,
});

export default getInitialState;
