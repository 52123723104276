/* ============
 * State of the audience transfer module
 * ============
 *
 * The initial state of the audience transfer module.
 */

export const getInitialState = () => ({
  transfer: {
    assignedAudiences: [],
    assignedPixels: [],
  },
  filteredTransfers: {
    items: [],
    total: 0,
  },
  currentStep: 1,
  steps: [
    { step: 1, rules: true },
    { step: 2, rules: true },
    { step: 3, rules: true },
  ],
  validationErrors: {},
  form: {
    active: true,
    name: null,
    start_date: null,
    end_date: null,
    assignedAudiences: [],
    assignedPixels: [],
  },
});

export default getInitialState;
