<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title class="text-h5">
            {{ $t('components.refreshRetargeting.title') }}
          </v-card-title>
        </v-col>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                :loading="loadingRefreshDomains"
                color="primary"
                @click="refreshDomains"
              >
                {{ $t('components.refreshRetargeting.buttons.refreshDomains') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                :loading="loadingRefreshVisitors"
                color="primary"
                @click="refreshVisitors"
              >
                {{
                  $t('components.refreshRetargeting.buttons.refreshVisitors')
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loadingRefreshDomains: false,
      loadingRefreshVisitors: false,
    };
  },
  methods: {
    async refreshDomains() {
      this.loadingRefreshDomains = true;
      await this.refreshRetargeting('domains');
      this.loadingRefreshDomains = false;
    },
    async refreshVisitors() {
      this.loadingRefreshVisitors = true;
      await this.refreshRetargeting('visitors');
      this.loadingRefreshVisitors = false;
    },
    async refreshRetargeting(type) {
      let message;
      try {
        const result = await this.$store.dispatch('user/refreshRetargeting', {
          type,
        });
        if (result) {
          message = this.$t(
            'components.snackbar.text.retargetingCacheRefreshed'
          );
        }
      } catch (err) {
        message = err;
      }
      this.$bus.fire('show-snackbar', { message });
    },
  },
};
</script>

<style></style>
