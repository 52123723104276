<template>
  <layout-master />
</template>

<script>
import productDetector from '@/mixins/productDetector';
import LayoutMaster from './layouts/Master';

export default {
  components: {
    LayoutMaster,
  },
  mixins: [productDetector],
  data() {
    return {};
  },
  created() {
    this.$bus.listen('user-logged-in', async () => {
      const monitoringSettingsPromise = this.$store.dispatch(
        'monitoring/loadSettings'
      );
      await this.$acl.change(this.$store.state.account.access);
      // check if user has realtime permission in case it is forwarding to analytics
      if (
        this.currentDashboardName === 'analytics' &&
        this.$acl.check('viewRealtime')
      ) {
        await monitoringSettingsPromise;
      }
      this.$router.push({
        name: this.currentDashboardName,
        query: this.$route.query,
      });
      this.$store.dispatch('account/loadDisplaySettings');
    });
  },
  destroyed() {
    this.$bus.remove('user-logged-in');
  },
};
</script>

<style lang="scss">
body {
  // apply settings for fullscreen mode
  &.fullscreen {
    #app {
      header.v-toolbar {
        display: none;
      }
      main.v-main {
        padding: 0 !important;

        .container.main.fullscreen {
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }
}
.full-width {
  flex: 1;
}
.card-progress-container {
  height: 7px;
  margin-top: -7px;
}
.container.main {
  position: relative;
  padding: 0;
}

/* light mode adaptions */
.theme--light {
  /* General Background color of card title for all dialogs in light mode */
  .v-card__title.pp-custom-background {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
}
/* dark mode adaptions */
/* General Background color of card title for all dialogs in dark mode*/
.theme--dark {
  .v-card__title.pp-custom-background {
    background-color: #252525;
    border-color: #252525;
  }
  /* adapt color of resizable handle in dark mode */
  .vue-grid-item > .vue-resizable-handle {
    // this is an (encoded) svg with white handle color
    background-image: url('data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBvcGFjaXR5PSIuNyIgc3R5bGU9ImZpbGw6IHdoaXRlIi8+PC9zdmc+');
  }
}

.vue-grid-item.vue-grid-placeholder.vue-grid-placeholder {
  background: none;
  border: 2px dashed;
}

/* set cursor to pointer on all datatables */
.v-data-table {
  &:not(.no-pointer) {
    cursor: pointer;
  }
}
/* show pointer on item */
.pointer {
  cursor: pointer;
}

/* show link without underline and add underline and color on hover */
.v-application .link {
  display: inline;
  text-decoration: none;
  word-wrap: break-word;
  word-break: break-word;

  &:hover {
    color: #1976d2 !important;
    text-decoration: underline;
  }
}
</style>
