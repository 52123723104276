<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col class="align-center d-flex" cols="12">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.name')"
            vid="name"
            rules="required|max:100"
            class="flex-grow-1"
          >
            <v-text-field
              v-model="name"
              :counter="100"
              :label="$t('components.ads.settings.name')"
              :loading="loading"
              name="name"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-switch
            v-model="native"
            class="ml-4"
            :label="$t('components.ads.detail.native')"
          />
          <v-switch
            v-if="!native"
            v-model="rotate"
            class="ml-4"
            :label="$t('components.ads.settings.rotate')"
          />
        </v-col>
        <v-col
          v-if="!native"
          class="align-center justify-space-between"
          cols="12"
        >
          <v-subheader class="pa-0">{{
            $t('components.ads.settings.display.timing.label')
          }}</v-subheader>
          <v-btn-toggle
            v-model="display_timing"
            mandatory
            group
            class="flex-wrap"
          >
            <v-btn
              v-for="(displayTiming, key) in displayTimings"
              :key="key"
              :value="displayTiming"
            >
              <template v-if="!markedAsNew.timings.includes(displayTiming)">
                {{
                  $t('components.ads.settings.display.timing.' + displayTiming)
                }}
              </template>
              <v-badge
                color="warning"
                small
                offset-x="0.5em"
                offset-y="-0.25em"
                :content="$t('common.actions.new')"
                class="new-badge"
                v-else
              >
                {{
                  $t('components.ads.settings.display.timing.' + displayTiming)
                }}
              </v-badge>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="!native"
          class="align-center justify-space-between"
          cols="12"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.display.label')"
            vid="display"
            rules="required"
          >
            <v-select
              v-model="display"
              :hint="
                $t(`components.ads.settings.display.option.${display}.hint`)
              "
              :items="filteredDisplayOptions"
              :loading="loading"
              :label="$t('components.ads.settings.display.label')"
              persistent-hint
              :error-messages="errors"
            >
              <template slot="item" slot-scope="data">
                <template
                  v-if="!markedAsNew.positions.includes(data.item.value)"
                >
                  {{ data.item.text }}
                </template>
                <v-badge
                  color="warning"
                  offset-x="-0.25em"
                  :content="$t('common.actions.new')"
                  class="new-badge"
                  v-else
                >
                  {{ data.item.text }}
                </v-badge>
              </template>
            </v-select>
          </ValidationProvider>
        </v-col>
        <v-col
          class="align-center justify-space-between"
          cols="12"
          v-if="!delayOptionDisabled"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.delay')"
            vid="delay"
            rules="required|numeric|min_value:1"
          >
            <v-text-field
              v-model="delay"
              :label="$t('components.ads.settings.delay')"
              :suffix="$t('components.ads.settings.seconds')"
              :min="1"
              :loading="loading"
              :disabled="delayOptionDisabled"
              value="2"
              type="number"
              name="delay"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
          class="align-center justify-space-between"
          cols="12"
          v-if="display_timing === 'interstitial'"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.duration')"
            vid="duration"
            rules="required|numeric|min_value:0"
          >
            <v-text-field
              v-model="duration"
              :label="$t('components.ads.settings.duration')"
              :suffix="$t('components.ads.settings.seconds')"
              :min="0"
              :loading="loading"
              value="4"
              type="number"
              name="duration"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>

        <v-col class="align-center justify-space-between" cols="12">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.domain.label')"
            vid="domain_id"
            rules="required"
          >
            <v-autocomplete
              v-model="domain_id"
              :items="extendedDomainsOfUser"
              :label="$t('components.ads.settings.domain.label')"
              :hint="$t('components.ads.settings.domain.hint')"
              name="domain_id"
              item-text="title"
              item-value="id"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.ads.settings.deviceType.label')"
            vid="device_type"
            rules="required|oneOf:all,desktop,mobile,app"
          >
            <v-autocomplete
              v-model="device_type"
              :items="deviceTypes"
              :label="$t('components.ads.settings.deviceType.label')"
              :hint="$t('components.ads.settings.deviceType.hint')"
              name="device_type"
              item-text="text"
              item-value="value"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapState } from 'vuex';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'ad/getField',
  mutationType: 'ad/updateField',
});

export default {
  props: {
    /**
     * Current Loading indicator
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      displayOptions: Object.values(
        this.$t('components.ads.settings.display.option')
      ),
      domains: [],
      deviceTypes: [
        {
          value: 'all',
          text: this.$t('components.ads.settings.deviceType.items.all'),
        },
        {
          value: 'desktop',
          text: this.$t('components.ads.settings.deviceType.items.desktop'),
        },
        {
          value: 'mobile',
          text: this.$t('components.ads.settings.deviceType.items.mobile'),
        },
        {
          value: 'app',
          text: this.$t('components.ads.settings.deviceType.items.app'),
        },
      ],
      markedAsNew: {
        positions: ['afterQuSeparateSlide'],
        timings: ['interstitial'],
      },
      displayTimings: ['before_vote', 'interstitial', 'after_vote'],
      allowedPositionsForTiming: {
        before_vote: ['below', 'above', 'inline'],
        interstitial: ['afterQuSeparateSlide'],
        after_vote: [
          'below',
          'above',
          'inline',
          'overlay',
          'afterQuSeparateSlide',
        ],
      },
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapFields([
      'ad.name',
      'ad.delay',
      'ad.duration',
      'ad.display',
      'ad.display_timing',
      'ad.domain_id',
      'ad.device_type',
      'ad.rotate',
      'ad.native',
      'ad.isStatic',
    ]),
    filteredDisplayOptions() {
      return this.displayOptions.filter((item) =>
        this.checkPositionAllowedForTiming(item.value, this.display_timing)
      );
    },
    extendedDomainsOfUser() {
      let domains = [
        {
          id: '0',
          title: this.$t('components.ads.settings.domain.items.all'),
        },
      ];
      if (this.user) {
        domains = [...domains, ...this.user.domains];
      }
      return domains;
    },
    delayOptionDisabled() {
      return (
        this.checkPositionAllowedForTiming(this.display, 'before_vote') ||
        this.display_timing === 'interstitial'
      );
    },
  },
  methods: {
    checkPositionAllowedForTiming(position, timing) {
      return (
        position &&
        timing &&
        this.allowedPositionsForTiming[timing].includes(position)
      );
    },
  },
  watch: {
    native() {
      if (this.native) {
        this.display_timing = 'before_vote';
        this.display = 'inline';
        this.isStatic = false;
      }
    },
    display_timing() {
      if (
        !this.checkPositionAllowedForTiming(this.display, this.display_timing)
      ) {
        const allowedPositions = this.allowedPositionsForTiming[
          this.display_timing
        ];
        this.display = allowedPositions
          ? allowedPositions[allowedPositions.length - 1]
          : null;
      }
    },
  },
};
</script>

<style scoped></style>
