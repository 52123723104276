/* ============
 * Mutations for the audience transfer module
 * ============
 *
 * The mutations that are available on the
 * audience transfer module.
 */

import { updateField } from 'vuex-map-fields';
import helpers from '@/utils/helpers';
import constants, { AUDIENCE_TYPES } from '@/utils/constants';
import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  // Add the `updateField` mutation to the
  // `mutations` of the Vuex store instance.
  updateField,

  [types.SET_AUDIENCE_TRANSFER](state, data) {
    if (data.assignedAudiences) {
      data.assignedAudiences.map((audience) => {
        if (audience.filename && !audience.filename.includes('://')) {
          audience.filename = `${constants.CDN_STORAGE_AUDIENCES}/audiences/${audience.filename}`;
        }
        return audience;
      });
      data.assignedInterests = data.assignedAudiences.filter(
        (audience) => audience.type_id === AUDIENCE_TYPES.INTEREST
      );
      data.assignedProperties = data.assignedAudiences.filter(
        (audience) => audience.type_id === AUDIENCE_TYPES.PROPERTY
      );
    }
    state.transfer = data;
  },

  [types.SET_AUDIENCE_TRANSFER_TO_EDIT](state, data) {
    // format date objects (only if they are defined)
    if (data.start_date || data.end_date) {
      data.start_date =
        data.start_date && moment(data.start_date).format('YYYY-MM-DD');
      data.end_date =
        data.end_date && moment(data.end_date).format('YYYY-MM-DD');
    }

    // prepare arrays to fit to select box
    data.assignedAudiences =
      data.assignedAudiences && data.assignedAudiences.map((item) => item.id);
    data.assignedPixels = data.assignedPixels.map((item) => item.id);

    // set data to form object
    state.form = data;
  },

  [types.SET_FILTERED_AUDIENCE_TRANSFERS](state, data) {
    state.filteredTransfers.items = data.data;
    state.filteredTransfers.total = data.total;
  },

  [types.NEXT_STEP](state) {
    if (state.steps.length > state.currentStep) {
      state.currentStep += 1;
    }
  },

  [types.PREV_STEP](state) {
    if (state.currentStep > 1) {
      state.currentStep -= 1;
    }
  },

  [types.SET_STEP](state, newStep) {
    state.currentStep = newStep;
  },

  [types.RESET_FORM](state) {
    state.validationErrors = {};
    state.form = {
      active: true,
      name: null,
      start_date: null,
      end_date: null,
      domain_id: '0',
      assignedAudiences: [],
      assignedPixels: [],
    };
    state.currentStep = 1;
    // reset rules property
    state.steps.map((step) => {
      step.rules = true;
      return step;
    });
  },

  [types.SET_ERRORS](state, errors) {
    // transform object to array
    state.validationErrors = helpers.transformErrorObject(errors);

    // set rules property depending on error name (to show error in steppper header)
    const keysArray = Object.keys(state.validationErrors);
    if (
      _.intersection(keysArray, ['name', 'start_date', 'end_date', 'domain_id'])
        .length
    ) {
      state.steps[0].rules = false;
    }
    if (_.intersection(keysArray, ['assignedAudiences']).length) {
      state.steps[1].rules = false;
    }
    if (_.intersection(keysArray, ['assignedPixels']).length) {
      state.steps[2].rules = false;
    }
  },

  [types.DELETE_AUDIENCE_TRANSFER](state, id) {
    state.filteredTransfers.items = state.filteredTransfers.items.filter(
      (transfer) => transfer.id !== id
    );
  },

  [types.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
};
