/* ============
 * State of the user module
 * ============
 *
 * The initial state of the user module.
 */

export const getInitialState = () => ({
  users: [],
});

export default getInitialState;
