var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var touched = ref.touched;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.authLoginForm.textFields.email.name'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.authLoginForm.textFields.email.label'),"name":"email","autocomplete":"email","prepend-icon":"mdi-account","type":"email","required":"","error-messages":errors},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('components.authLoginForm.textFields.password.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.authLoginForm.textFields.password.label'),"append-icon":_vm.hidePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.hidePassword ? 'password' : 'text',"name":"password","autocomplete":"current-password","prepend-icon":"mdi-lock","required":"","error-messages":errors},on:{"click:append":function($event){_vm.hidePassword = !_vm.hidePassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":touched && invalid,"name":"login","color":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.authLoginForm.buttons.login'))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }