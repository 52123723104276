<template>
  <v-card>
    <v-card-text>
      <v-btn-toggle
        v-model="currentListMode"
        mandatory
        class="data-list-mode-toggler"
      >
        <v-btn v-for="listMode in listModes" :value="listMode" :key="listMode">
          {{ $t(`monitoring.data.${listMode}`) }}
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
    <slot name="append" />
  </v-card>
</template>
<script>
export default {
  props: {
    // initial data type to be listed
    listMode: {
      type: String,
      required: false,
      default: null,
    },
    // Array of available list modes to be shown in toggler menu
    listModes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      /* {String} Current data type to be listed */
      currentListMode: null,
    };
  },
  mounted() {
    this.currentListMode = this.listMode;
  },
  watch: {
    currentListMode(value) {
      this.$emit('list-mode-changed', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.data-list-mode-toggler {
  width: 100%;

  .v-btn {
    flex-grow: 1;
  }
}
</style>
