<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-menu
        ref="menu_startDate"
        v-model="menuStartDate"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            v-bind="attrs"
            :label="$t('components.dateRangeFilter.from')"
            :error="validationError"
            :error-messages="errorMessage"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          :max="new Date().toISOString().substr(0, 10)"
          :min="minStartDate"
          no-title
          scrollable
        >
          <v-spacer />
          <v-btn text color="primary" @click="menuStartDate = false">
            {{ $t('components.dateRangeFilter.cancel') }}
          </v-btn>
          <v-btn text color="primary" @click="update('startDate')">
            {{ $t('components.dateRangeFilter.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6">
      <v-menu
        ref="menu_endDate"
        v-model="menuEndDate"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            v-bind="attrs"
            :label="$t('components.dateRangeFilter.to')"
            :error="validationError"
            :error-messages="errorMessage"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          :max="maxEndDate"
          :min="minEndDate"
          no-title
          scrollable
        >
          <v-spacer />
          <v-btn text color="primary" @click="menuEndDate = false">
            {{ $t('components.dateRangeFilter.cancel') }}
          </v-btn>
          <v-btn text color="primary" @click="update('endDate')">
            {{ $t('components.dateRangeFilter.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    /* max range between start and end date */
    maxRange: {
      type: Number,
      required: false,
      default: 90,
    },
    /* max days that the start date can be selected in the past
    (i.e. 62 days from now to past are possible to select for start date) */
    maxPastDaysStart: {
      type: Number,
      required: false,
      default: 62,
    },
  },
  data() {
    return {
      startDate: this.$store.state.monitoring.filter.startDate,
      endDate: this.$store.state.monitoring.filter.endDate,
      menuStartDate: false,
      menuEndDate: false,
      /* show validation error if selected dates are invalid */
      validationError: false,
      /* the displayed error message */
      errorMessage: '',
    };
  },
  computed: {
    /**
     * Calculate min start date by subtract
     * maxRange from end date.
     *
     * @returns {string} date
     */
    minStartDate() {
      return moment(this.endDate)
        .subtract(this.maxRange, 'days')
        .format('YYYY-MM-DD');
    },
    /**
     * Calculate max end date by add maxRange
     * from start date. If calculated start is in the past
     * return now minus maxRange, else if start is in the
     * future, return now.
     *
     * @returns {string} date
     */
    maxEndDate() {
      const start = moment(this.startDate).add(this.maxRange, 'days');
      if (moment().isAfter(start)) {
        return start.format('YYYY-MM-DD');
      }
      return moment().format('YYYY-MM-DD');
    },
    /**
     * Calculate min end date based on maxPastDaysStart
     * for disabling other days in date picker
     *
     * @returns {string} date
     */
    minEndDate() {
      return moment()
        .subtract(this.maxPastDaysStart, 'days')
        .format('YYYY-MM-DD');
    },
  },

  mounted() {
    if (new Date(this.startDate) < new Date(this.minStartDate)) {
      this.startDate = this.minStartDate;
      this.update('startDate');
    }
  },

  methods: {
    update(variable) {
      this.validationError = false;
      if (this.validateDates()) {
        // close menu (and update value)
        this.$refs[`menu_${variable}`].save(this[variable]);

        if (this.$store.state.monitoring.filter[variable] === this[variable]) {
          // value did not change
          return;
        }

        // trigger updated filter flow
        this.$store.dispatch('monitoring/updateFilter', {
          prop: variable,
          value: this[variable],
        });
      } else {
        this.validationError = true;
      }
    },
    /**
     * Validate date input fields
     * Start date must be before end date
     * and range must be between max range
     *
     * @returns {bool} valid or invalid
     */
    validateDates() {
      this.resetValidation();
      if (moment(this.endDate).diff(this.startDate, 'days') > this.maxRange) {
        // range must within max range
        this.errorMessage = this.$t('components.dateRangeFilter.error.range', {
          maxRange: this.maxRange,
        });
        return false;
      }
      if (
        !moment(this.startDate).isAfter(
          moment().subtract(this.maxPastDaysStart, 'days')
        )
      ) {
        // start date is not allowed to be more than maxPastDaysStart days in the past
        this.errorMessage = this.$t('components.dateRangeFilter.error.past', {
          maxPast: this.maxPastDaysStart,
        });
        return false;
      }

      return true;
    },
    /**
     * Reset validation
     *
     * @returns {void}
     */
    resetValidation() {
      this.validationError = false;
      this.errorMessage = '';
    },
  },
};
</script>
