<template>
  <domain-page-view
    :domain-data="$store.state.monitoring.realtime.domains"
    :page-data="$store.state.monitoring.realtime.pages"
    :referrer-data="$store.state.monitoring.realtime.referrer"
    :initial-list-mode="
      cardSettings.params ? cardSettings.params.listMode : null
    "
    page-filter
    @updated="updateCardSettings"
  />
</template>

<script>
import ResizableCard from './ResizableCard';
import DomainPageView from '@/components/Monitoring/DomainPageView';

export default {
  components: {
    DomainPageView,
  },
  extends: ResizableCard,
  methods: {
    /**
     * Update listMode in card
     * @param listMode updated list mode
     * @returns {void}
     */
    updateCardSettings({ listMode }) {
      let settings = _.cloneDeep(this.cardSettings);
      settings.params = { listMode };
      this.$emit('card-changed', settings);
    },
  },
};
</script>
