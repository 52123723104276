<template>
  <div>
    <v-col cols="12">
      <div class="full-width">
        <v-card color="productColor" class="white--text" elevation-10>
          <template v-if="postData.imageUrl || model.image_data">
            <v-img
              :src="postData.imageUrl ? postData.imageUrl : model.image_data"
              height="200px"
              class="preview-image"
            >
              <v-icon dark class="delete-image" @click="clearImage">
                mdi-delete
              </v-icon>
              <dropzone-field
                :has-data="!!(postData.imageUrl || model.image_data)"
                :active="current === 'dropzone'"
                class="dropzone-field-smaller-width"
                @set-active="setCurrentActive"
              />
            </v-img>
          </template>
          <template v-else>
            <dropzone-field
              :has-data="!!(postData.imageUrl || model.image_data)"
              :active="current === 'dropzone'"
              class="dropzone-field-smaller-width"
              @set-active="setCurrentActive"
            />
          </template>
        </v-card>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="full-width">
        <v-card color="productColor" class="white--text" elevation-10>
          <v-card-text>
            <pixabay-field
              :active="current === 'pixabay'"
              @click.native="setCurrentActive('pixabay')"
              @get-items="getItems"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropzoneField from '@/components/AddImageDialog/components/DropzoneField';
import PixabayField from '@/components/AddImageDialog/components/PixabayField';

export default {
  components: { DropzoneField, PixabayField },
  data() {
    return {
      model: {
        image_data: '',
      },
      show: true,
      current: '',
    };
  },
  computed: {
    ...mapState('image', ['postData', 'items']),
    ...mapState('ad', ['ad']),
  },
  watch: {
    ad: {
      immediate: true,
      deep: true,
      handler(changedAd) {
        // only init if there is an id in the ad object
        if (changedAd.id) {
          this.initImage();
        }
      },
    },
  },
  mounted() {
    this.$bus.listen('clear-image', this.clearImage);
  },
  destroyed() {
    this.$bus.remove('clear-image', this.clearImage);
  },
  methods: {
    initImage() {
      this.model = this.ad;
    },
    setCurrentActive(field) {
      // set active field
      this.current = field;
    },
    resetFields() {
      this.$store.commit('image/RESET_FIELDS');
      this.current = '';
      this.model = {};
    },
    clearImage() {
      if (this.model && this.model.image_data) this.model.image_data = '';

      this.resetFields();
    },
    async getItems(data) {
      // get pictures and store them
      const result = await this.$store.dispatch('image/search', data);
      if (result.length === 0) {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.noResultsFound'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audience-name {
  padding: 10px;
  position: absolute;
  width: 100%;
  font-size: 3vmax !important;
  text-transform: uppercase;
  font-weight: 500;
  min-height: 1.2em;
  line-height: 3vmax;
}
.delete-image {
  float: right;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.dropzone-field-smaller-width {
  width: 95%;
}
</style>
