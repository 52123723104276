export default {
  data() {
    return {
      tabsModel: document.location.hash
        ? document.location.hash.substr(1)
        : null,
      tabResources: {
        info: [],
        audiences: ['insights.audiences'],
        devices: ['insights.devices'],
        referrer: ['insights.referrerTypes', 'insights.referrer'],
        pages: ['users.pages'],
      },
    };
  },

  mounted() {
    this.changeTabResources(null, this.tabsModel);
  },

  beforeDestroy() {
    this.changeTabResources(this.tabsModel, null);
  },

  methods: {
    tabChanged(value) {
      this.changeTabResources(this.tabsModel, value);
      this.tabsModel = value;
    },
    changeTabResources(oldTab, newTab) {
      if (oldTab) {
        this.tabResources[oldTab].forEach((resource) => {
          this.$store.dispatch('monitoring/removeActiveResource', {
            name: resource,
          });
        });
      }
      if (newTab) {
        this.tabResources[newTab].forEach((resource) => {
          this.$store.dispatch('monitoring/addActiveResource', {
            name: resource,
          });
        });
      }
    },
  },
};
