import Resource from './Resource';

class AccountResource extends Resource {
  /**
   * The constructor for the AccountResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/account');
  }

  /**
   * Load Account setting from server
   * @param {String} key Setting name
   * @returns {Promise<Object>} The result in a promise.
   */
  getSetting(key) {
    return this.submit('get', `settings/${key}`);
  }

  /**
   * Save Account setting at server
   * @param {String} key Setting name
   * @param {String} value Setting value
   * @returns {Promise<Object>} The result in a promise.
   */
  storeSetting(key, value) {
    return this.submit('post', `settings/${key}`, { value });
  }

  aiDomains() {
    return this.submit('get', 'aiDomains');
  }
}

export default AccountResource;
