import Resource from './Resource';

class ChannelResource extends Resource {
  /**
   * The constructor for the ChannelResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/channels');
  }

  /**
   * Method used to get all channels from current user
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getChannels() {
    return this.submit('get');
  }

  /**
   * Method used to get all channels from current user
   *
   * @param {Number} id Channel id
   * @returns {Promise<Object>} The result in a promise.
   */
  destroy(id) {
    return this.submit('delete', `/${id}`);
  }
}

export default ChannelResource;
