var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{class:( _obj = {
    light: _vm.light,
    'cover-column': _vm.coverColumn,
    'icon-left': _vm.iconLeft
  }, _obj[_vm.cssClass] = _vm.cssClass, _obj ),attrs:{"close-on-content-click":_vm.closeOnContentClick,"left":!_vm.right,"right":_vm.right,"nudge-bottom":_vm.bottom ? 57 : 0,"content-class":_vm.contentClass,"min-width":_vm.minWidth,"bottom":""},on:{"input":_vm.menuChanged},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:{
            'options-menu': true,
            header: _vm.inHeader,
            rect: !_vm.rounded,
            static: _vm.staticPosition,
            fill: _vm.fill,
          },attrs:{"small":_vm.small,"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_vm._t("activator",[(_vm.horizontal)?_c('v-icon',[_vm._v(" mdi-dots-horizontal ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])])],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('monitoring.dashboard.tooltips.add_remove_cards')))])])]}}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }