<template>
  <v-list>
    <v-list-item>
      <v-list-item-action @click.stop.prevent>
        <v-switch v-model="displayChannels" @change="updateDisplayChannels" />
      </v-list-item-action>
      <v-list-item-title>{{
        $t('monitoring.options.updateDisplayChannels')
      }}</v-list-item-title>
    </v-list-item>

    <v-list-item>
      <v-list-item-action @click.stop.prevent>
        <v-switch
          v-model="displayInterests"
          @change="update('displayInterests')"
        />
      </v-list-item-action>
      <v-list-item-title>{{
        $t('monitoring.options.updateDisplayInterests')
      }}</v-list-item-title>
    </v-list-item>

    <v-list-item>
      <v-list-item-action @click.stop.prevent>
        <v-switch
          v-model="displayVisitorProperties"
          @change="update('displayVisitorProperties')"
        />
      </v-list-item-action>
      <v-list-item-title>{{
        $t('monitoring.options.updateDisplayVisitorProperties')
      }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      displayChannels: this.$store.state.monitoring.settings.useChannels,
      displayAudienceGrowth: this.$store.state.monitoring.settings
        .displayAudienceGrowth,
      displayEventTracking: this.$store.state.monitoring.settings
        .displayEventTracking,
      displayInterests: this.$store.state.monitoring.settings.displayInterests,
      displayAudiences: this.$store.state.monitoring.settings.displayAudiences,
      displayVisitorProperties: this.$store.state.monitoring.settings
        .displayVisitorProperties,
    };
  },

  methods: {
    updateDisplayChannels() {
      this.$store.dispatch('monitoring/updateSettings', {
        prop: 'useChannels',
        value: this.displayChannels,
      });
      this.$store.dispatch('monitoring/purge', { prop: 'realtime.channels' });
      this.$store.dispatch('monitoring/purge', { prop: 'insights.channels' });
      if (this.$store.state.monitoring.activeView === 'realtime')
        this.$store.dispatch('monitoring/reopen', {
          sockets: ['/v1/channels'],
        });
      else if (this.$store.state.monitoring.activeView === 'overview')
        this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },

    update(prop) {
      this.$store.dispatch('monitoring/updateSettings', {
        prop,
        value: this[prop],
      });
    },
  },
};
</script>
