<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedAds.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedAds.info') }}</span>
      <v-list>
        <v-list-item v-for="ad in tracking.assignedAds" :key="ad.id">
          <v-list-item-action>
            <v-icon color="info"> mdi-picture-in-picture-top-right </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ ad.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('components.tracking.assignedAds.ctaClicks') }}:
              {{ ad.cta_clicks }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
