<template>
  <v-card class="grid-card">
    <v-card-text>
      <devices-view
        :data="$store.state.monitoring.realtime.devices.data"
        translate="monitoring.data"
      />
    </v-card-text>
    <v-card color="infoBar" class="card-bar elevation-0" dark>
      <div class="card-progress-container">
        <v-progress-linear
          v-show="!$store.state.monitoring.realtime.devices.loaded"
          :height="7"
          color="primary"
          indeterminate
        />
      </div>
      <v-card-text class="white--text">
        <v-row>
          <v-col cols="6">
            <span>Top Browser:</span>
            <span class="ml-1">{{
              $store.state.monitoring.facts.browsers.data.length
                ? $store.state.monitoring.facts.browsers.data[0].key
                : '-'
            }}</span>
          </v-col>
          <v-col cols="6">
            <span>Top OS:</span>
            <span class="ml-1">{{
              $store.state.monitoring.facts.oslist.data.length
                ? $store.state.monitoring.facts.oslist.data[0].key
                : '-'
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import DevicesView from '@/components/Monitoring/DevicesView';

export default {
  components: {
    DevicesView,
  },
};
</script>
