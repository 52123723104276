/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import { i18n } from '@/plugins/vue-i18n';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes,
});

/**
 * Get page title depending on route name
 *
 * @param {object} route - route object
 *
 * @returns {string} page title name
 */
const getPageTitle = (route) => {
  const routeProduct = route.meta.product;
  const routeName = route.name;
  const productNames = {
    analytics: 'Pinpoll Analytics',
    dmp: 'Pinpoll DMP',
    ads: 'Pinpoll Ads',
  };

  let productName = productNames[routeProduct] || 'Pinpoll';

  if (routeName) {
    productName += ` - ${i18n.t(`menu.contentHeader.${routeName}.page`)}`;
  }

  return productName;
};

router.beforeEach(async (to, from, next) => {
  // set page title received to content header
  document.title = getPageTitle(to);

  // forward the routing (to acl)
  return next();
});

Vue.router = router;

export default {
  router,
};
