var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"value":[0, 1, 2],"multiple":""}},[(_vm.showDMP)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',[_c('v-chip',{attrs:{"label":"","color":"ppDmp","dark":""}},[_vm._v(" DMP "),_c('v-tooltip',{attrs:{"right":"","max-width":"min(400px, 70%)","content-class":"mr-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"tile":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,4103051632)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.ads.targeting.info.dmp')))])])],1)],1)]),_c('v-expansion-panel-content',[_c('v-autocomplete',{attrs:{"items":_vm.userInterests,"label":_vm.$t('components.transfer.stepInventory.form.interests.label'),"hint":_vm.$t('components.transfer.stepInventory.form.interests.hint'),"loading":_vm.loadingAudiences,"name":"assignedInterests","item-text":"text","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.filename || _vm.helpers.getPlaceholderImage()}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.text)+" ")])],1)]}}],null,false,131638287),model:{value:(_vm.audience_ids),callback:function ($$v) {_vm.audience_ids=$$v},expression:"audience_ids"}}),_c('v-autocomplete',{attrs:{"items":_vm.userVisitorProperties,"label":_vm.$t(
                    'components.transfer.stepInventory.form.properties.label'
                  ),"hint":_vm.$t('components.transfer.stepInventory.form.properties.hint'),"loading":_vm.loadingAudiences,"name":"assignedProperties","item-text":"text","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.filename || _vm.helpers.getPlaceholderImage()}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.text)+" ")])],1)]}}],null,false,131638287),model:{value:(_vm.audience_ids),callback:function ($$v) {_vm.audience_ids=$$v},expression:"audience_ids"}})],1)],1):_vm._e(),(_vm.showTools)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',[_c('v-chip',{attrs:{"label":"","color":"ppTools","dark":""}},[_vm._v(" Tools "),_c('v-tooltip',{attrs:{"right":"","max-width":"min(400px, 70%)","content-class":"mr-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"tile":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,4103051632)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.ads.targeting.info.tools')))])])],1)],1)]),_c('v-expansion-panel-content',[_c('v-autocomplete',{attrs:{"items":_vm.categoryAudiences,"label":_vm.$t(
                    'components.transfer.stepInventory.form.categories.label'
                  ),"hint":_vm.$t('components.transfer.stepInventory.form.categories.hint'),"loading":_vm.loadingAudiences,"name":"assignedCategories","item-text":"text","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.filename || _vm.helpers.getPlaceholderImage()}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.text)+" ")])],1)]}}],null,false,131638287),model:{value:(_vm.category_ids),callback:function ($$v) {_vm.category_ids=$$v},expression:"category_ids"}})],1)],1):_vm._e(),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',[_c('v-chip',{attrs:{"label":"","color":"ppAds","dark":""}},[_vm._v(" Custom "),_c('v-tooltip',{attrs:{"right":"","max-width":"min(400px, 70%)","content-class":"mr-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"tile":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('components.ads.targeting.info.custom')))])])],1)],1)]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',[_vm._l((Object.entries(
                        typeof _vm.custom_targeting === 'string'
                          ? JSON.parse(_vm.custom_targeting)
                          : _vm.custom_targeting
                      )),function(ctEntry,entryIndex){return _c('div',{key:'ct-etry-' + entryIndex,staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"ml-2",attrs:{"required":"","value":ctEntry[0],"label":_vm.$t(
                            'components.ads.dialog.custom_targeting_entry.key'
                          ),"hide-details":"auto"},on:{"change":function($event){return _vm.editCustomTargeting($event, entryIndex, 0)}}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"value":ctEntry[1],"label":_vm.$t(
                            'components.ads.dialog.custom_targeting_entry.value'
                          ),"hide-details":"auto"},on:{"change":function($event){return _vm.editCustomTargeting($event, entryIndex, 1)}}}),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeCustomTargetingEntry(entryIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"productColor","dark":""},on:{"click":_vm.addCustomTargetingEntry}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],2)],1)],1)],1)],1)],1)],1),(!_vm.showDMP)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('components.ads.targeting.hintNoDMP'))+" ")])]):_vm._e()],1)],1),_c('dialog-delete',{attrs:{"show":_vm.showDeleteDialog,"model-name":_vm.$t('components.ads.dialog.custom_targeting_entry.title'),"options":{
      useQuestionWithThis: true,
      textModelName: _vm.$t(
        'components.ads.dialog.custom_targeting_entry.thisTitle'
      ),
    },"max-width":"36em"},on:{"confirmed":function($event){return _vm.deleteDialogDeferred.resolve(true)},"close-dialog":function($event){return _vm.deleteDialogDeferred.resolve(false)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }