/* ============
 * Mutation types for the audience transfer module
 * ============
 *
 * The mutation types that are available
 * on the audience transfer module.
 */

export const SET_AUDIENCE_TRANSFER = 'SET_AUDIENCE_TRANSFER';
export const SET_AUDIENCE_TRANSFER_TO_EDIT = 'SET_AUDIENCE_TRANSFER_TO_EDIT';
export const SET_FILTERED_AUDIENCE_TRANSFERS =
  'SET_FILTERED_AUDIENCE_TRANSFERS';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const SET_STEP = 'SET_STEP';
export const SET_ERRORS = 'SET_ERRORS';
export const RESET_FORM = 'RESET_FORM';
export const CREATE_AUDIENCE_TRANSFER = 'CREATE_AUDIENCE_TRANSFER';
export const UPDATE_AUDIENCE_TRANSFER = 'UPDATE_AUDIENCE_TRANSFER';
export const DELETE_AUDIENCE_TRANSFER = 'DELETE_AUDIENCE_TRANSFER';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_AUDIENCE_TRANSFER,
  SET_AUDIENCE_TRANSFER_TO_EDIT,
  SET_FILTERED_AUDIENCE_TRANSFERS,
  NEXT_STEP,
  PREV_STEP,
  SET_STEP,
  RESET_FORM,
  SET_ERRORS,
  CREATE_AUDIENCE_TRANSFER,
  UPDATE_AUDIENCE_TRANSFER,
  DELETE_AUDIENCE_TRANSFER,
  RESET_STATE,
};
