<template>
  <v-card class="mx-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="activeFilters.type"
            :items="availableFields"
            :loading="loadingAvailableFields"
            :label="
              $t(
                'components.audienceBehaviourTable.filters.availableFields.label'
              )
            "
            :hint="
              $t(
                'components.audienceBehaviourTable.filters.availableFields.hint'
              )
            "
            :menu-props="{ contentClass: 'availableFields' }"
            hide-details="auto"
            @input="getMetaTagsDebounced"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="6"
          class="d-flex align-center justify-center justify-sm-end"
        >
          <filter-button-menu
            :menus="menus"
            :initial-filters="activeFilters"
            @filters-updated="filtersUpdated"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="5">
          <v-combobox
            v-model="search"
            :label="$t('components.audienceBehaviourTable.search')"
            multiple
            autofocus
            chips
            clearable
            deletable-chips
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            @update:search-input="getMetaTagsDebouncedFromSearch"
          />
        </v-col>
        <v-col cols="12" offset-sm="1" sm="6" class="align-self-end">
          <v-slider
            v-model="slider.topResults"
            :min="1"
            :max="100"
            :label="
              $vuetify.breakpoint.xsOnly
                ? $t('components.audienceBehaviourTable.slider.topResultsXs')
                : $t('components.audienceBehaviourTable.slider.topResults')
            "
            thumb-label
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
            @click:row="rowSelected"
          >
            <template #[`item.key`]="{ item }">
              {{ helpers.cleanUpPrefix(item.key) }}
            </template>
            <template #[`item.visitors`]="{ item }">
              <div v-if="item.visitors >= 0">
                <span v-if="item.visitors === 0">
                  {{ $t('components.audienceBehaviourTable.noVisitorsFound') }}
                </span>
                <span v-else>
                  {{ item.visitors | formatCount }}
                </span>
              </div>
              <div v-else>
                <v-btn
                  :loading="loadingVisitors === `${item.key}|${item.value}`"
                  outlined
                  color="info"
                  @click.stop="getDistinctVisitors(item)"
                >
                  {{
                    $t(
                      'components.audienceBehaviourTable.buttons.getDistinctVisitors.label'
                    )
                  }}
                </v-btn>
              </div>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ item.created_at | formatDateTimeMinutes }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import FilterButtonMenu from '@/components/FilterButtonMenu';
import helpers from '@/utils/helpers';

export default {
  components: {
    FilterButtonMenu,
  },
  filters: {
    formatCount(count) {
      return numeral(count).format('0.[00] a').toUpperCase();
    },
  },
  data() {
    return {
      helpers,
      slider: {
        topResults: 10,
      },
      menus: {
        timeframe: {
          selected: true,
          selectedItemRequired: true,
          name: this.$t('components.filterButtonMenu.filters.timeframe.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.today'
              ),
              value: 'today',
              selected: true,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.yesterday'
              ),
              value: 'yesterday',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last7Days'
              ),
              value: 'last7Days',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last30Days'
              ),
              value: 'last30Days',
              selected: false,
            },
          ],
        },
        minVisitors: {
          selected: false,
          name: this.$t(
            'components.filterButtonMenu.filters.minVisitors.label'
          ),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan10k'
              ),
              value: '10000',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan100k'
              ),
              value: '100000',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan1m'
              ),
              value: '1000000',
              selected: false,
            },
          ],
        },
        mappings: {
          selected: false,
          name: this.$t('components.filterButtonMenu.filters.mappings.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.hasMappings'
              ),
              value: 'hasMappings',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.noMappings'
              ),
              value: 'noMappings',
              selected: false,
            },
          ],
        },
      },
      activeFilters: { timeframe: 'today', type: 'params.metaTags.og:title' },
      search: [],
      loading: true,
      loadingVisitors: false,
      loadingAvailableFields: false,
      options: {
        page: 1,
        sortBy: ['visitors'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      rowsPerPageItems: [5, 10, 25, 50, 100],
      headers: [
        {
          text: this.$t('components.audienceBehaviourTable.headers.key'),
          align: 'left',
          value: 'key',
        },
        {
          text: this.$t('components.audienceBehaviourTable.headers.value'),
          value: 'value',
        },
        {
          text: this.$t('components.audienceBehaviourTable.headers.visitors'),
          align: 'center',
          value: 'visitors',
        },
        {
          text: this.$t('components.audienceBehaviourTable.headers.affinity'),
          align: 'center',
          value: 'affinity_score',
        },
        {
          text: this.$t('components.audienceBehaviourTable.headers.mappings'),
          align: 'center',
          value: 'audiences',
        },
        {
          text: this.$t('components.audienceBehaviourTable.headers.createdAt'),
          align: 'right',
          value: 'created_at',
        },
      ],
    };
  },
  computed: {
    ...mapState('metatag', {
      items: (state) => state.filteredMetaTags.items,
    }),
    ...mapState('metatag', ['availableFields']),
  },
  watch: {
    'slider.topResults': function getTopResults(value) {
      if (value > 0) {
        this.getMetaTagsDebounced();
      }
    },
    search() {
      this.getMetaTags();
    },
  },
  created() {
    this.$bus.listen('data-updated', () => this.getMetaTagsDebounced());
    this.getAvailableFields();
    this.getMetaTags();
  },
  destroyed() {
    this.$bus.remove('data-updated');
  },
  methods: {
    getMetaTagsDebouncedFromSearch: _.debounce(function search(text) {
      if (text !== null) {
        this.getMetaTags(text);
      }
    }, 500),
    getMetaTagsDebounced: _.debounce(function search() {
      this.getMetaTags();
    }, 250),
    async getAvailableFields() {
      this.loadingAvailableFields = true;
      try {
        await this.$store.dispatch('metatag/getAvailableFields');
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.loadingAvailableFields = false;
    },
    async getMetaTags(searchText = null) {
      // set loading data
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      // prepare params data
      const params = {
        top: this.slider.topResults,
        search,
        filters: this.activeFilters,
      };
      try {
        await this.$store.dispatch('metatag/getMetaTags', params);
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.loading = false;
    },
    /**
     * Called when row is selected which opens the meta tag dialog
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.currentSelectedItem = `${item.key}|${item.value}`;
      this.$store.commit('metatag/SET_META_TAG', item);
      if (item.visitors < 0) {
        this.$store.dispatch('metatag/getDistinctVisitors', {
          key: item.key,
          value: item.value,
          filters: this.activeFilters,
          updateTable: true,
        });
      }
      this.$bus.fire('meta-tag-selected', {
        value: item.value,
        activeFilters: this.activeFilters,
      });
    },
    async getDistinctVisitors(item) {
      this.loadingVisitors = `${item.key}|${item.value}`;
      try {
        await this.$store.dispatch('metatag/getDistinctVisitors', {
          key: item.key,
          value: item.value,
          filters: this.activeFilters,
          updateTable: true,
        });
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.loadingVisitors = '';
    },
    showErrorMessage(error) {
      let message = this.$t('components.snackbar.text.requestTimeout');
      if (error.displayName !== 'RequestTimeout') {
        message = error.msg;
      }
      this.$bus.fire('show-snackbar', {
        message,
        options: {
          color: 'error',
          timeout: 0,
        },
      });
    },
    filtersUpdated({ activeFilters }) {
      this.options.page = 1;
      this.activeFilters = activeFilters;
      this.getMetaTags();
    },
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getMetaTags(searchText);
    },
  },
};
</script>
<style>
.availableFields {
  max-width: 500px;
}
</style>
