import Resource from './Resource';

class ElasticResource extends Resource {
  /**
   * The constructor for the ElasticResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/elastic');
  }

  /**
   * Method used to manage cron jobs
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  manageCronJob(params) {
    return this.submit('get', 'manageCronJob', params);
  }

  /**
   * Method used to get all cron jobs
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getCronJobs(params) {
    return this.submit('get', 'getCronJobs', params);
  }

  /**
   * Get All Running Tasks
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getTasks() {
    return this.submit('get', 'getTasks');
  }

  /**
   * Kill Selected Tasks
   *
   * @param {String[]} tasks list of task ids
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  killTasks(tasks) {
    return this.submit('post', 'killTasks', { tasks });
  }
}

export default new ElasticResource();
