import Loadable from '@/utils/Loadable';
import Vue from 'vue';

/**
 * This class is a collection of Loadable objects
 * so we can handle multiple cards adding the same thing to the vuex store,
 * but still have different get parameters
 */
class LoadableCollection extends Loadable {
  constructor() {
    super();
    this.owners = [];
  }

  /**
   * Adds a Loadable object to this collection
   * @param {string} ownerId id of owner
   * @param {Loadable} loadable object
   * @returns {void}
   */
  add(ownerId, loadable) {
    if (!this[ownerId]) this.owners.push(ownerId);
    Vue.set(this, ownerId, loadable);
  }

  /**
   * set loaded to false for owner
   * @param {string} ownerId id of owner
   * @returns {void}
   */
  purge(ownerId) {
    if (this[ownerId]) {
      this[ownerId].loaded = false;
    }
  }

  /**
   * set loaded to false for all owner
   * @returns {void}
   */
  purgeAll() {
    this.owners.forEach((id) => this.purge(id));
  }

  /**
   * checks if owner has data loaded
   * @param {string} ownerId id of owner
   * @returns {boolean}
   */
  isLoaded(ownerId) {
    return this[ownerId] ? this[ownerId].loaded : false;
  }

  /**
   * Returns data of owner or fallback
   * @param {string} ownerId id of owner
   * @param {any} fallback data
   * @returns {any}
   */
  getData(ownerId, fallback = null) {
    return this[ownerId] ? this[ownerId].getData(fallback) : fallback;
  }

  /**
   * Helper accessor so LoadableCollection has same prop as
   * Loadable class, to prevent code errors
   * @returns {boolean}
   */
  get loaded() {
    if (this.owners.length < 1) return false;
    return this[this.owners[0]].loaded;
  }

  /**
   * Helper accessor so LoadableCollection has same prop as
   * Loadable class, to prevent code errors
   * @returns {any}
   */
  get data() {
    if (this.owners.length < 1) return null;
    return this[this.owners[0]].data;
  }
}

export default LoadableCollection;
