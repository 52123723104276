var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px","persistent":"","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',{staticClass:"pp-custom-background py-6 text-h6"},[_vm._v(" "+_vm._s(_vm.mode === 'create' ? _vm.$t('components.pixel.pixelDialog.title.createNewPixel') : _vm.$t('components.pixel.pixelDialog.title.updatePixel'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.title'),"vid":"title","rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"label":_vm.$t('components.pixel.pixelDialog.labels.title'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.title),callback:function ($$v) {_vm.$set(_vm.pixel, "title", $$v)},expression:"pixel.title"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.general'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.pixeltype'),"vid":"pixeltypes","rules":"required|oneOf:image,google,facebook,javascript"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.pixeltypes,"label":_vm.$t('components.pixel.pixelDialog.labels.pixeltype'),"loading":_vm.loading,"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.pixel.type),callback:function ($$v) {_vm.$set(_vm.pixel, "type", $$v)},expression:"pixel.type"}})]}}],null,true)})],1)],1),(_vm.pixel.type === 'javascript')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.javascript'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.headercode'),"vid":"headercode","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.headercode'),"loading":_vm.loading,"error-messages":errors},on:{"change":_vm.addHttpsPrefix},model:{value:(_vm.pixel.externalcode),callback:function ($$v) {_vm.$set(_vm.pixel, "externalcode", $$v)},expression:"pixel.externalcode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.bodycode'),"vid":"bodycode","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.bodycode'),"rows":4,"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.staticcode),callback:function ($$v) {_vm.$set(_vm.pixel, "staticcode", $$v)},expression:"pixel.staticcode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.triggercode'),"vid":"trigger_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.triggercode'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.trigger_code),callback:function ($$v) {_vm.$set(_vm.pixel, "trigger_code", $$v)},expression:"pixel.trigger_code"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.pixel.type === 'google')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.google'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.conversionid'),"vid":"conversionid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.conversionid'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.conversion_id),callback:function ($$v) {_vm.$set(_vm.pixel, "conversion_id", $$v)},expression:"pixel.conversion_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.conversionlabel'),"vid":"conversionlabel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.conversionlabel'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.conversion_label),callback:function ($$v) {_vm.$set(_vm.pixel, "conversion_label", $$v)},expression:"pixel.conversion_label"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.conversionvalue'),"vid":"conversionvalue","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.conversionvalue'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.custom_value),callback:function ($$v) {_vm.$set(_vm.pixel, "custom_value", $$v)},expression:"pixel.custom_value"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.pixel.type === 'facebook')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.facebook'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.pixelid'),"vid":"pixelid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.pixelid'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.facebook_id),callback:function ($$v) {_vm.$set(_vm.pixel, "facebook_id", $$v)},expression:"pixel.facebook_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.customvalue'),"vid":"custom_value","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.customvalue'),"loading":_vm.loading,"error-messages":errors},model:{value:(_vm.pixel.custom_value),callback:function ($$v) {_vm.$set(_vm.pixel, "custom_value", $$v)},expression:"pixel.custom_value"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.pixel.type === 'image')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.image'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.pixel.pixelDialog.labels.imageurl'),"vid":"imageurl","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.pixel.pixelDialog.labels.imageurl'),"loading":_vm.loading,"error-messages":errors},on:{"change":_vm.addHttpsPrefix},model:{value:(_vm.pixel.source),callback:function ($$v) {_vm.$set(_vm.pixel, "source", $$v)},expression:"pixel.source"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.pixel.type === 'javascript')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.pixel.approved)?[_c('v-alert',{attrs:{"outlined":"","color":"success","icon":"mdi-check-circle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('components.pixel.pixelDialog.alert.approved')))])])]:[_c('v-alert',{attrs:{"outlined":"","color":"primary","icon":"mdi-exclamation-thick"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.alert.warning'))+" ")]),(_vm.mode === 'edit')?_c('strong',[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.alert.notApproved'))+" ")]):_vm._e()])]],2):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.pixel.id),expression:"pixel.id"}],attrs:{"text":"","color":"error"},on:{"click":_vm.deletePixel}},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.delete'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.cancel'))+" ")]),_c('v-btn',{attrs:{"loading":_vm.savingProgress,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.savePixel)}}},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelDialog.labels.save'))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }