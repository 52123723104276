var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(hoverState){return [_c('v-card',{staticClass:"grid-card",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"card-background"},[(_vm.showHistogram)?_c('line-chart',{attrs:{"data":_vm.chartData,"height":_vm.chartHeight,"padding":_vm.chartPadding,"axis":false,"grid-size":_vm.size,"datetime-format":"HH:mm","base-zero":"","timeseries":"","compare-timeseries":""}}):_vm._e()],1),_c('v-slide-x-reverse-transition',[(hoverState && hoverState.hover)?_c('v-btn',{staticClass:"closeButton",attrs:{"small":"","icon":""},on:{"click":_vm.closeCard}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-forever ")])],1):_vm._e()],1),(_vm.cardSettings.audienceId !== null)?_c('div',[_c('v-card-text',{style:(_vm.fontSize)},[_c('div',{staticClass:"number"},[_c('number',{attrs:{"data":_vm.growthToday,"no-styling":""}})],1),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.audienceGrowth.new'))+" "),_c('editable-label',{attrs:{"text":_vm.audienceText},on:{"text-changed":_vm.updateCustomAudienceText}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$store.state.monitoring.audienceGrowth.today.isLoaded(
              _vm.cardSettings.i
            )
          ),expression:"\n            $store.state.monitoring.audienceGrowth.today.isLoaded(\n              cardSettings.i\n            )\n          "}],staticClass:"progressionBar"},[_c('v-row',[_c('v-col',{staticClass:"text-md-left progress pa-1",style:({ color: _vm.getHighlightColor(_vm.progressionTodayYesterday) }),attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"progressIcon",attrs:{"color":_vm.getHighlightColor(_vm.progressionTodayYesterday)}},[_vm._v(" "+_vm._s(_vm.getProgressionIcon(_vm.progressionTodayYesterday))+" ")]),_c('span',{staticClass:"totalProgressionYesterday"},[_vm._v(_vm._s(_vm._f("percent")(_vm.progressionTodayYesterday)))])],1),_c('v-col',{staticClass:"text-md-left progress pa-1",style:({ color: _vm.getHighlightColor(_vm.progressionTodayLastWeek) }),attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"progressIcon",attrs:{"color":_vm.getHighlightColor(_vm.progressionTodayLastWeek)}},[_vm._v(" "+_vm._s(_vm.getProgressionIcon(_vm.progressionTodayLastWeek))+" ")]),_c('span',{staticClass:"totalProgressionLastWeek"},[_vm._v(_vm._s(_vm._f("percent")(_vm.progressionTodayLastWeek)))])],1)],1)],1)]),_c('div',{staticClass:"card-progress-container"},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.$store.state.monitoring.audienceGrowth.today.isLoaded(
              _vm.cardSettings.i
            )
          ),expression:"\n            !$store.state.monitoring.audienceGrowth.today.isLoaded(\n              cardSettings.i\n            )\n          "}],attrs:{"color":"primary","indeterminate":""}})],1),(_vm.cardSettings.audienceId !== null)?_c('v-card',{staticClass:"card-bar elevation-0",attrs:{"color":"infoBar","dark":""}},[_c('v-card-text',{staticClass:"white--text",staticStyle:{"padding-left":"16px"}},[_c('v-row',[_c('v-col',{staticClass:"text-md-left",attrs:{"cols":"6"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.audienceGrowth.yesterday'))+" ")]),_c('span',{staticClass:"totalCountYesterday"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.growthYesterday))+" ")])]),_c('v-col',{staticClass:"text-md-left",attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(_vm.$t('monitoring.data.audienceGrowth.lastWeek')))]),_c('span',{staticClass:"totalCountLastWeek"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.growthLastWeek))+" ")])])],1)],1)],1):_vm._e()],1):_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"pt-4 pb-2 text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('monitoring.no_data'))+" ")]),_c('v-alert',{staticClass:"mx-6 mb-5 text-body-2",attrs:{"color":"info","icon":"mdi-information","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.audienceGrowth.hint'))+" ")])],1),_c('options-menu',{attrs:{"left":"","small":"","min-width":240}},[_c('audience-growth-settings',{attrs:{"card-settings":_vm.cardSettings},on:{"updated":_vm.updateCardSettings}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }