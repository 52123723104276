import Resource from './Resource';

class FormResource extends Resource {
  /**
   * The constructor for the FormResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/forms');
  }

  /**
   * Method used to get all forms from current user
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getForms() {
    return this.submit('get', 'getForms');
  }

  /**
   * Method used to get all filtered forms
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  datatable(params) {
    return this.submit('get', 'datatable', params);
  }
}

export default FormResource;
