<template>
  <v-dialog v-model="show" max-width="900" scrollable persistent>
    <ValidationObserver
      v-slot="{ invalid, touched }"
      ref="validationObserver"
      slim
    >
      <v-card>
        <v-card-title class="pp-custom-background py-6 text-h6">
          {{ $t('components.tracking.dialog.title.createTracking') }}
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="currentStep">
            <v-stepper-header>
              <template v-for="step in steps">
                <v-stepper-step
                  :key="step.step"
                  :step="step.step"
                  :rules="[() => step.rules]"
                  editable
                >
                  {{ headline[step.step - 1] }}
                  <small v-if="step.optional">
                    {{ $t('components.tracking.dialog.steps.optional') }}
                  </small>
                </v-stepper-step>
                <v-divider
                  v-if="step.step < steps.length"
                  :key="step.headline"
                />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0">
                <step-data />
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <step-pixel />
              </v-stepper-content>
              <v-stepper-content step="3" class="pa-0">
                <step-inventory />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="warning" @click="closeDialog">
            {{ $t('components.tracking.dialog.buttons.cancel') }}
          </v-btn>
          <v-btn
            v-if="currentStep === steps.length"
            :loading="savingProgress"
            :disabled="touched && invalid"
            color="success"
            @click="save"
          >
            {{
              mode === 'create'
                ? $t('components.tracking.dialog.buttons.create')
                : $t('components.tracking.dialog.buttons.update')
            }}
          </v-btn>
          <v-btn
            v-else
            :disabled="touched && invalid"
            color="primary"
            @click="nextStep"
          >
            {{ $t('components.tracking.dialog.buttons.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import helpers from '@/utils/helpers';
import StepData from './StepData';
import StepPixel from './StepPixel';
import StepInventory from './StepInventory';

export default {
  components: {
    StepData,
    StepPixel,
    StepInventory,
  },
  data() {
    return {
      helpers,
      mode: '',
      show: false,
      savingProgress: false,
      headline: [
        this.$t('components.tracking.dialog.steps.data'),
        this.$t('components.tracking.dialog.steps.pixel'),
        this.$t('components.tracking.dialog.steps.inventory'),
      ],
      id: 0,
      type: 'Tracking',
    };
  },
  computed: {
    ...mapState('tracking', ['steps', 'validationErrors']),
    currentStep: {
      get() {
        return this.$store.state.tracking.currentStep;
      },
      set(value) {
        this.$store.commit('tracking/SET_STEP', value);
      },
    },
  },
  mounted() {
    this.$bus.listen('show-tracking-dialog', this.showDialog);
    this.$bus.listen('edit-tracking', this.loadTracking);
  },
  destroyed() {
    this.$bus.remove('show-tracking-dialog');
    this.$bus.remove('edit-tracking');
  },
  methods: {
    async loadTracking(id) {
      this.id = id;
      this.showDialog('edit');
      await this.$store.dispatch('tracking/getTrackingEdit', id);
    },
    showDialog(mode) {
      this.mode = mode;
      this.show = true;
    },
    nextStep() {
      this.$store.commit('tracking/NEXT_STEP');
    },
    prevStep() {
      this.$store.commit('tracking/PREV_STEP');
    },
    async save() {
      this.savingProgress = true;
      try {
        if (this.mode !== 'edit') {
          const id = await this.$store.dispatch('tracking/createTracking');
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.trackingCreated'),
          });
          this.$router.push({
            name: 'trackEventsDetail',
            params: { id },
          });
        } else {
          await this.$store.dispatch('tracking/updateTracking', this.id);
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.trackingUpdated'),
          });
          this.closeDialog();
        }
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.saveError'),
          options: {
            color: 'error',
            timeout: 0,
          },
        });
        this.$store.commit('tracking/SET_ERRORS', err);
        this.$refs.validationObserver.setErrors(this.validationErrors);
      }
      this.$bus.fire('tracking-updated');
      this.savingProgress = false;
    },
    resetFields() {
      this.$store.commit('tracking/RESET_FORM');
      this.$refs.validationObserver.reset();
    },
    closeDialog() {
      this.resetFields();
      this.show = false;
    },
  },
};
</script>

<style></style>
