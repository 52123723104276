<template>
  <span :class="{ editableLabel: true, editing }">
    <span v-if="!editing" class="text" @click="enableEditing">{{ value }}</span>
    <input v-else v-model="tempValue" class="input" @keyup="saveEdit" />
  </span>
</template>

<script>
export default {
  props: {
    /* Text which should be displayed */
    text: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      /* Displayed value */
      value: '',
      /* temporary value */
      tempValue: null,
      /* is in editmode or not */
      editing: false,
    };
  },
  watch: {
    text() {
      this.value = this.text;
    },
  },
  mounted() {
    if (this.text) {
      this.value = this.text;
    }
  },
  methods: {
    /**
     * Enable editing of value (show input field instead of label)
     * @returns {void}
     */
    enableEditing() {
      this.tempValue = this.value;
      this.editing = true;
    },
    /**
     * Disable editing of value (show label field instead of input)
     * @returns {void}
     */
    disableEditing() {
      this.tempValue = null;
      this.editing = false;
    },
    /**
     * Save new value
     * @param {object} event Triggered event
     * @returns {void}
     */
    saveEdit(event) {
      if (event.keyCode === 13) {
        // enter key
        this.$emit('text-changed', this.tempValue);
        this.value = this.tempValue;

        this.disableEditing();
      }
    },
  },
};
</script>
<style scoped>
.input {
  width: 100%;
}
.editableLabel .input:hover {
  cursor: pointer;
}
.editableLabel .text:hover {
  cursor: pointer;
}
.editableLabel {
  z-index: 1;
  margin-left: 5px;
}
.editableLabel.editing {
  width: 100%;
}
</style>
