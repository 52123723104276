import bus from '@/utils/bus';
import _ from 'lodash';
import store from '@/store';

/**
 * Reopen all socket connections (needed for token refresh)
 *
 * @param {VuexContext} options.VuexContext context of monitoring store
 *
 * @return {void}
 */
const reopenAllSocketConnections = _.debounce(async (options) => {
  // Note: do not use reopen, since we need to be sure that all sockets are closed before opening a new one
  await store.dispatch('monitoring/disconnect');
  await store.dispatch('monitoring/connect', options);
}, 500);

export default {
  init() {
    // Listen for the event to reopen all socket connections.
    bus.listen('reopenAllSocketConnections', reopenAllSocketConnections);
  },
};
