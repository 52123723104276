<template>
  <animate-number
    ref="number"
    :formatter="formatNumber"
    :to="data"
    :duration="duration"
    from="0"
    easing="easeOutQuad"
    mode="manual"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Number,
      required: true,
      default: 0,
    },
    duration: {
      type: Number,
      required: false,
      default: 500,
    },
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.number.reset(oldVal, newVal);
      this.$refs.number.start();
    },
  },
  mounted() {
    if (this.data !== 0) {
      // be careful it seems like you can't update value during animation
      this.$refs.number.start();
    }
  },
  methods: {
    formatNumber(num) {
      if (num < 1000) {
        return numeral(num).format('0');
      }
      return numeral(num).format('0.[00] a').toUpperCase();
    },
  },
};
</script>
