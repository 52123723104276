<template>
  <span class="dashboard-bar" v-if="currentDashboard">
    <v-menu
      v-model="dashboardMenu"
      :disabled="!availableDashboards.length"
      offset-y
      rounded
      close-on-content-click
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip :open-delay="openDelay" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              plain
              text
              small
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-icon v-if="availableDashboards.length" left>{{
                `mdi-chevron-${dashboardMenu ? 'up' : 'down'}`
              }}</v-icon>
              {{ currentDashboard ? currentDashboard.name : '' }}
            </v-btn>
          </template>
          <span>{{
            $t('monitoring.dashboard.tooltips.switch_dashboard')
          }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item v-if="!availableDashboards.length"
          >no saved dashboards</v-list-item
        >
        <v-list-item
          v-for="dashboard in availableDashboards"
          :key="dashboard.id"
          @click="setDashboard(dashboard)"
        >
          <v-list-item-title>
            {{ dashboard.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip :open-delay="openDelay" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="currentDashboard.id"
          small
          icon
          v-bind="attrs"
          v-on="on"
          @click="openEditDialog(false)"
          ><v-icon small>mdi-pencil</v-icon></v-btn
        >
      </template>
      <span>{{ $t('monitoring.dashboard.tooltips.rename_dashboard') }}</span>
    </v-tooltip>

    <v-tooltip :open-delay="openDelay" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="currentDashboard.id && availableDashboards.length > 1"
          small
          icon
          v-bind="attrs"
          v-on="on"
          @click="deleteDialog = true"
          ><v-icon small>mdi-delete</v-icon></v-btn
        >
      </template>
      <span>{{ $t('monitoring.dashboard.tooltips.delete_dashboard') }}</span>
    </v-tooltip>

    <v-tooltip :open-delay="openDelay" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon v-bind="attrs" v-on="on" @click="openEditDialog(true)"
          ><v-icon small>mdi-plus</v-icon></v-btn
        >
      </template>
      <span>{{ $t('monitoring.dashboard.tooltips.add_dashboard') }}</span>
    </v-tooltip>

    <v-dialog v-model="editDialog" width="unset">
      <v-card>
        <v-card-title v-if="createMode">{{
          $t('monitoring.dashboard.tooltips.add_dashboard')
        }}</v-card-title>
        <v-card-title v-else>{{
          $t('monitoring.dashboard.tooltips.rename_dashboard')
        }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newName"
            :placeholder="$t('monitoring.dashboard.placeholders.name')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeEditDialog(false)">{{
            $t('monitoring.buttons.cancel')
          }}</v-btn>
          <v-btn
            class="productColor--text"
            text
            @click="closeEditDialog(true)"
            >{{ $t('monitoring.buttons.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="320">
      <v-card>
        <v-card-title>{{
          $t('monitoring.dashboard.tooltips.delete_dashboard')
        }}</v-card-title>
        <v-card-text>{{
          $t('components.dialogDelete.questionWithThis', {
            model: $t('monitoring.dashboard.defaultNameThis'),
          })
        }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="productColor--text"
            @click="closeDeleteDialog(false)"
          >
            {{ $t('components.dialogDelete.no') }}
          </v-btn>
          <v-btn text @click="closeDeleteDialog(true)">
            {{ $t('components.dialogDelete.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      /* {Boolean} Flag if dashboards menu is open */
      dashboardMenu: false,
      /* {Boolean} Flag if edit dialog is open */
      editDialog: false,
      /* {Boolean} Flag if delete dialog is open */
      deleteDialog: false,
      /* {Boolean} Flag if the edit dialog is currently in creat mode */
      createMode: false,
      /* {String} Currently edited ore created name (dialog mock) */
      newName: '',
      /* {Number} Open delay (ms) for all tooltips */
      openDelay: 300,
    };
  },
  computed: {
    /**
     * Getting available dashboards in array format to be used as menu items
     * @returns {array} array of dashboard objects
     */
    availableDashboards() {
      return Object.values(this.$store.state.monitoring.realtime.dashboards);
    },
    /**
     * Currently used dashboard
     * @returns {object} current dashboard
     */
    currentDashboard() {
      return this.$store.state.monitoring.realtime.dashboards[
        this.$store.state.monitoring.settings.currentDashboardId
      ];
    },
  },
  methods: {
    /**
     * Handler for selecting a dashboard from menu
     * @param {object} dashboard object
     * @returns {void}
     */
    setDashboard(dashboard) {
      if (dashboard) {
        this.$emit('dashboard-selected', dashboard.id);
      }
    },
    /**
     * Handler for updating or saving an edited dashboard
     * @param {object} dashboard object
     * @returns {void}
     */
    saveDashboard(dashboard) {
      this.$emit('dashboard-saved', dashboard);
    },
    /**
     * Opens the edit dialog and sets dialog mode
     * @param {boolean} createMode if Create mode should be set
     * @returns {void}
     */
    openEditDialog(createMode) {
      this.newName = createMode ? '' : this.currentDashboard.name;
      this.createMode = createMode;
      this.editDialog = true;
    },
    /**
     * Handler for closing the edit dialog
     * @param {boolean} shouldBeSaved if the edited dashboard should be saved
     * @returns {void}
     */
    closeEditDialog(shouldBeSaved) {
      if (shouldBeSaved) {
        let dashboard = {
          ...(!this.createMode ? { id: this.currentDashboard.id } : null),
          ...(!this.createMode ? { grid: this.currentDashboard.grid } : null),
          name: this.newName,
        };
        this.saveDashboard(dashboard);
      }
      this.editDialog = false;
    },
    /**
     * Handler for closing the delete dialog
     * @param {boolean} isConfirmed if the dashboard deletion is confirmed
     * @returns {void}
     */
    closeDeleteDialog(isConfirmed) {
      if (isConfirmed) {
        this.$emit('dashboard-deleted', this.currentDashboard.id);
      }
      this.deleteDialog = false;
    },
  },
};
</script>
