import Resource from './Resource';

class DashboardResource extends Resource {
  /**
   * The constructor for the DashboardResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/dashboard');
  }
  /**
   * Update existing dashboard at server
   * @param {Number} id Dashboard id
   * @param {Object} dashboard dashboard object
   * @returns {Promise<Object>} The result in a promise.
   */
  updateDashboard(id, dashboard) {
    return this.submit('put', id, dashboard);
  }
  /**
   * Save new dashboard at server
   * @param {Object} dashboard Dashboard object
   * @returns {Promise<Object>} The result in a promise.
   */
  storeDashboard(dashboard) {
    return this.submit('post', '', dashboard);
  }
  /**
   * Delete the dashboard at server
   * @param {Number} dashboardId Dashboard id
   * @returns {Promise<Object>} The result in a promise.
   */
  deleteDashboard(dashboardId) {
    return this.destroy(dashboardId);
  }
}

export default DashboardResource;
