<template>
  <v-tooltip :open-delay="300" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small v-bind="attrs" v-on="on" @click="toggleFullscreen">
        <template v-if="value">
          <v-icon small class="mr-1"> mdi-fullscreen-exit </v-icon>
          {{ $t('monitoring.buttons.fullscreenExit') }}
        </template>
        <template v-else>
          <v-icon small class="mr-1"> mdi-fullscreen </v-icon>
          {{ $t('monitoring.buttons.fullscreen') }}
        </template>
      </v-btn>
    </template>
    <span>{{ $t('monitoring.dashboard.tooltips.fullscreen') }}</span>
  </v-tooltip>
</template>

<script>
import helpers from '@/utils/helpers';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Function,
      required: true,
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) this.changeFullscreenState(newValue);
    },
  },

  mounted() {
    document.addEventListener(
      helpers.fullscreenEventName(),
      this.fullScreenEvent
    );
  },
  destroyed() {
    document.removeEventListener(
      helpers.fullscreenEventName(),
      this.fullScreenEvent
    );
  },

  methods: {
    toggleFullscreen() {
      this.changeFullscreenState(!this.value);
    },
    async changeFullscreenState(fullscreenActive) {
      const fullscreenElement = this.element();
      if (fullscreenActive) {
        if (!helpers.isFullscreen()) {
          try {
            await helpers.openInFullScreen(fullscreenElement);
          } catch (e) {
            // probably will throw error since user action is required for fullscreen
          }
          this.updateFullscreenState(true, fullscreenElement);
        }
      } else {
        if (helpers.isFullscreen()) helpers.closeFullscreen();
        this.updateFullscreenState(false, fullscreenElement);
      }
    },
    updateFullscreenState(fullscreenActive, fullscreenElement) {
      if (fullscreenActive) {
        fullscreenElement.classList.add('fullscreen');
      } else {
        fullscreenElement.classList.remove('fullscreen');
      }
      this.$emit('input', fullscreenActive);
    },
    fullScreenEvent() {
      if (!helpers.isFullscreen())
        this.updateFullscreenState(false, this.element());
    },
  },
};
</script>
