<template>
  <v-card>
    <p-image
      v-if="data.length > 0 && data[0].image"
      :src="data[0].image"
      height="134px"
    />
    <expandable-number-list
      :data="data"
      :data-loaded="loaded"
      :translations="$store.state.audience.localizedAudiences"
      :value-header="$t('monitoring.data.users')"
      :key-header="$t('models.plural.property')"
      :count-field="countField"
      :max="10"
      :on-row-click="addAudienceFilter"
    />
  </v-card>
</template>

<script>
import PImage from '@/components/PImage';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import AddAudienceFilterMethod from '@/mixins/monitoring/AddAudienceFilterMethod';

export default {
  components: {
    PImage,
    ExpandableNumberList,
  },
  mixins: [AddAudienceFilterMethod],

  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    loaded: {
      type: Boolean,
      required: false,
      default: true,
    },
    countField: {
      type: String,
      required: false,
      default: 'visitors',
    },
  },

  mounted() {
    this.$store.dispatch('audience/getAudiences');
  },
};
</script>
