<template>
  <v-navigation-drawer
    v-model="showNavigationDrawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    color="navigationDrawer"
    dark
    max-height="100vh"
  >
    <v-list dense>
      <template v-for="item in menu">
        <!-- CASE: header -->
        <v-subheader
          v-if="item.type === 'header'"
          v-show="
            $acl.check(item.permissions) &&
            (item.product === currentProduct || item.product === 'all')
          "
          :key="item.name"
        >
          <v-badge
            v-if="item.new"
            color="warning"
            inline
            :content="$t('common.actions.new')"
            class="new-badge"
          >
            {{ $t('menu.headers.' + item.label) }}
          </v-badge>
          <template v-else>
            {{ $t(`menu.headers.${item.label}`) }}
          </template>
        </v-subheader>
        <!-- CASE: divider -->
        <v-divider
          v-else-if="item.type === 'divider'"
          v-show="
            $acl.check(item.permissions) &&
            (item.product === currentProduct || item.product === 'all')
          "
          :key="item.name"
        />
        <!-- CASE: item -->
        <template
          v-else-if="item.type === 'item'"
          v-show="
            $acl.check(item.permissions) &&
            (item.product === currentProduct || item.product === 'all')
          "
        >
          <!-- CASE: list group -->
          <v-list-group
            v-if="item.items"
            v-show="
              $acl.check(item.permissions) &&
              (item.product === currentProduct || item.product === 'all')
            "
            :key="item.name"
            :prepend-icon="item.icon"
            append-icon="mdi-chevron-down"
            color="navigationDrawerHighlight"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title v-t="'menu.items.' + item.label" />
              </v-list-item-content>
            </template>

            <template v-for="subItem in item.items">
              <!-- CASE nested list group -->
              <template v-if="subItem.items">
                <v-list-group
                  v-show="$acl.check(subItem.permissions)"
                  :key="subItem.name"
                  no-action
                  sub-group
                  append-icon="mdi-chevron-down"
                  color="navigationDrawerHighlight"
                >
                  <template #activator>
                    <v-list-item-title v-t="'menu.items.' + subItem.label" />
                  </template>
                  <template v-for="subGroupItem in subItem.items">
                    <v-list-item
                      v-show="$acl.check(subGroupItem.permissions)"
                      :key="subGroupItem.name"
                      :to="{ name: subGroupItem.name }"
                      ripple
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-t="'menu.items.' + subGroupItem.label"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
              <!-- CASE no nested list group -->
              <template v-else>
                <v-list-item
                  v-show="$acl.check(subItem.permissions)"
                  :key="subItem.name"
                  :to="{ name: subItem.name }"
                  ripple
                  color="navigationDrawerHighlight"
                >
                  <v-list-item-action>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-t="'menu.items.' + subItem.label" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
          <!-- CASE: list tile -->
          <v-list-item
            v-else
            v-show="
              $acl.check(item.permissions) &&
              (item.product === currentProduct || item.product === 'all')
            "
            :key="item.name"
            :to="{ name: item.name }"
            ripple
            color="navigationDrawerHighlight"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-t="'menu.items.' + item.label" />
            </v-list-item-content>
            <v-list-item-action v-if="item.subAction">
              <v-icon>{{ item.subAction }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import productDetector from '@/mixins/productDetector';
import { mapState } from 'vuex';
import { menu } from '@/utils/menu';

export default {
  mixins: [productDetector],
  data() {
    return {
      menu,
    };
  },
  computed: {
    ...mapState('account', ['displaySettings', 'bigBoardEnabled']),
    showNavigationDrawer: {
      get() {
        // if big board is enabled never show navigation drawer
        if (this.bigBoardEnabled) {
          return false;
        }
        return this.displaySettings.showNavigationDrawer;
      },
      set(show) {
        this.$store.commit('account/UPDATE_DISPLAY_SETTING', {
          prop: 'showNavigationDrawer',
          value: show,
        });
      },
    },
  },
  created() {
    this.$bus.listen('toggle-drawer', this.toggleDrawer);
    this.$bus.listen('close-drawer', this.closeDrawer);
    this.$bus.listen('open-drawer', this.openDrawer);
    this.$bus.listen('user-logged-in', this.redraw);
  },
  destroyed() {
    this.$bus.remove('toggle-drawer', this.toggleDrawer);
    this.$bus.remove('close-drawer', this.closeDrawer);
    this.$bus.remove('open-drawer', this.openDrawer);
    this.$bus.remove('user-logged-in', this.redraw);
  },
  methods: {
    toggleDrawer() {
      this.showNavigationDrawer = !this.showNavigationDrawer;
      this.$store.dispatch('account/updateDisplaySettings');
    },
    closeDrawer() {
      if (this.showNavigationDrawer) {
        this.toggleDrawer();
      }
    },
    openDrawer() {
      if (!this.showNavigationDrawer) {
        this.toggleDrawer();
      }
    },
    redraw() {
      // workaround for bug where navigationdrawer is blank after login
      window.setTimeout(() => {
        this.$forceUpdate();
      }, 1);
    },
  },
};
</script>

<style></style>
