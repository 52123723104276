/* ==================
 * Monitoring Module
 * ==================
 */
import moment from 'moment';
import helpers, { startOfDay, endOfDay } from '@/utils/helpers';
import LoadableCollection from '@/utils/LoadableCollection';
import Loadable from '@/utils/Loadable';

export const settingsLocalStoragePrefix = 'monitoring.settings.';

export const getInitialState = () => ({
  activeView: null,
  settings: helpers.generateLocalStorageFields(
    {
      histogram_compare: { type: 'string', default: 'yesterday' },
      usePinpollAudiences: { type: 'bool', default: true },
      usePageViews: { type: 'bool', default: false },
      useChannels: { type: 'bool', default: false },
      displayAudienceGrowth: { type: 'bool', default: false },
      displayEventTracking: { type: 'bool', default: false },
      displayInterests: { type: 'bool', default: true },
      displayVisitorProperties: { type: 'bool', default: false },
      displayPageTitle: { type: 'bool', default: false },
      currentDashboardId: { type: 'number', default: null },
      nicknames: {
        type: 'object',
        default: {
          events: [],
          audiences: [],
        },
      },
    },
    settingsLocalStoragePrefix
  ),
  filter: {
    startDate: moment().format(moment.HTML5_FMT.DATE),
    endDate: moment().format(moment.HTML5_FMT.DATE),
    domain: null,
    domainIsTemporary: false,
    terms: [],
  },
  isTemporaryFilterMode: false,
  views: {
    total: new Loadable(0),
    histogram: new Loadable([]),
    domains: new Loadable([]),
    pages: new Loadable([]),
  },
  users: {
    total: new Loadable(0),
    histogram: new Loadable([]),
    domains: new Loadable([]),
    pages: new Loadable([]),
  },
  realtime: {
    // for realtime view
    users: new Loadable(0),
    domains: new Loadable([]),
    pages: new Loadable([]),
    channels: new Loadable([]),
    audiences: new Loadable([]),
    devices: new Loadable([]),
    referrer: new Loadable([]),
    referrerTypes: new Loadable([]),
    histogram: new Loadable(
      helpers.defaultHistogram(startOfDay, new Date(), 'users')
    ),
    histogram_compare: new Loadable(
      helpers.defaultHistogram(startOfDay, endOfDay, 'users')
    ),
    dashboards: {},
  },
  audienceGrowth: {
    yesterday: new LoadableCollection(),
    lastWeek: new LoadableCollection(),
    today: new LoadableCollection(),
    histogram: new LoadableCollection(),
    histogramCompare: new LoadableCollection(),
  },
  eventTracking: {
    yesterday: new LoadableCollection(),
    lastWeek: new LoadableCollection(),
    today: new LoadableCollection(),
    pages: new LoadableCollection(),
    histogram: new LoadableCollection(),
    histogramCompare: new LoadableCollection(),
  },
  insights: {
    // for insights view
    audiences: new Loadable([]),
    referrer: new Loadable([]),
    devices: new Loadable([]),
    channels: new Loadable([]),
    referrerTypes: new Loadable([]),
  },
  facts: {
    // generall infos used for both insights and realtime
    browsers: new Loadable([]),
    oslist: new Loadable([]),
    referrer: new Loadable([]),
    trendingPages: new Loadable([]),
  },
  toolsStats: {
    yesterday: new Loadable(),
    lastWeek: new Loadable(),
    today: new Loadable(),
  },

  /**
   * Returns dynamic property
   *
   * @param {string} prop property to be returned
   * @returns {*}
   */
  get(prop) {
    let value = this;
    prop.split('.').forEach((subprop) => {
      value = value[subprop];
    });
    return value;
  },
});

export default getInitialState;
