var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-3"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.availableFields,"loading":_vm.loadingAvailableFields,"label":_vm.$t(
              'components.audienceBehaviourTable.filters.availableFields.label'
            ),"hint":_vm.$t(
              'components.audienceBehaviourTable.filters.availableFields.hint'
            ),"menu-props":{ contentClass: 'availableFields' },"hide-details":"auto"},on:{"input":_vm.getMetaTagsDebounced},model:{value:(_vm.activeFilters.type),callback:function ($$v) {_vm.$set(_vm.activeFilters, "type", $$v)},expression:"activeFilters.type"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('filter-button-menu',{attrs:{"menus":_vm.menus,"initial-filters":_vm.activeFilters},on:{"filters-updated":_vm.filtersUpdated}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-combobox',{attrs:{"label":_vm.$t('components.audienceBehaviourTable.search'),"multiple":"","autofocus":"","chips":"","clearable":"","deletable-chips":"","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:search-input":_vm.getMetaTagsDebouncedFromSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"align-self-end",attrs:{"cols":"12","offset-sm":"1","sm":"6"}},[_c('v-slider',{attrs:{"min":1,"max":100,"label":_vm.$vuetify.breakpoint.xsOnly
              ? _vm.$t('components.audienceBehaviourTable.slider.topResultsXs')
              : _vm.$t('components.audienceBehaviourTable.slider.topResults'),"thumb-label":""},model:{value:(_vm.slider.topResults),callback:function ($$v) {_vm.$set(_vm.slider, "topResults", $$v)},expression:"slider.topResults"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{ 'items-per-page-options': _vm.rowsPerPageItems }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.cleanUpPrefix(item.key))+" ")]}},{key:"item.visitors",fn:function(ref){
              var item = ref.item;
return [(item.visitors >= 0)?_c('div',[(item.visitors === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.audienceBehaviourTable.noVisitorsFound'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCount")(item.visitors))+" ")])]):_c('div',[_c('v-btn',{attrs:{"loading":_vm.loadingVisitors === ((item.key) + "|" + (item.value)),"outlined":"","color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.getDistinctVisitors(item)}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.audienceBehaviourTable.buttons.getDistinctVisitors.label' ))+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeMinutes")(item.created_at))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }