<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('components.transfer.stepPixel.title') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.transfer.stepPixel.form.pixels.label')"
              vid="assignedPixels"
              rules=""
            >
              <v-autocomplete
                v-model="assignedPixels"
                :items="pixels"
                :label="$t('components.transfer.stepPixel.form.pixels.label')"
                :hint="$t('components.transfer.stepPixel.form.pixels.hint')"
                :loading="loading"
                item-text="title"
                item-value="id"
                multiple
                chips
                deletable-chips
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col class="justify-space-between" cols="12">
            <v-alert
              outlined
              color="primary"
              icon="mdi-information"
              class="info-box"
            >
              {{ $t('components.transfer.stepPixel.info') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'transfer/getField',
  mutationType: 'transfer/updateField',
});
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('transfer', ['currentStep']),
    ...mapState('pixel', ['pixels']),
    ...mapFields(['form.assignedPixels']),
  },
  mounted() {
    this.getPixels();
  },
  methods: {
    async getPixels() {
      this.loading = true;
      try {
        await this.$store.dispatch('pixel/getPixels');
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>
