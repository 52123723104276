<template>
  <div :class="{ parallel }" class="audience-chooser">
    <v-tabs ref="tabs" v-model="tabsModel" grow :hide-slider="parallel">
      <v-tab href="#interests">
        {{ $t('monitoring.data.interests') }}
      </v-tab>
      <v-tab href="#properties">
        {{ $t('monitoring.data.properties') }}
      </v-tab>
    </v-tabs>
    <v-row v-if="parallel">
      <v-col cols="6" class="py-0">
        <audience-tree :value="audienceIds" :data="interestTree" />
      </v-col>
      <v-col cols="6" class="py-0">
        <audience-tree :value="audienceIds" :data="propertyTree" />
      </v-col>
    </v-row>
    <v-tabs-items v-else v-model="tabsModel">
      <v-tab-item value="interests">
        <audience-tree :value="audienceIds" :data="interestTree" />
      </v-tab-item>
      <v-tab-item value="properties">
        <audience-tree :value="audienceIds" :data="propertyTree" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { AUDIENCE_TYPES } from '@/utils/constants';
import AudienceTree from './AudienceTree';

export default {
  components: {
    AudienceTree,
  },

  props: {
    parallel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tabsModel: null,
    };
  },

  computed: {
    audiences() {
      return this.$store.state.monitoring.filter.terms.filter(
        (termfilter) => termfilter.field === 'audience'
      );
    },
    audienceIds() {
      return this.audiences.map((audience) => audience.value);
    },
    interestTree() {
      return this.$store.getters['audience/treeDataAudiencesAnalytics'].filter(
        (audience) => audience.type_id === AUDIENCE_TYPES.INTEREST
      );
    },
    propertyTree() {
      return this.$store.getters['audience/treeDataAudiencesAnalytics'].filter(
        (audience) => audience.type_id === AUDIENCE_TYPES.PROPERTY
      );
    },
  },
  watch: {
    audienceIds() {
      this.$emit('input', this.audienceIds);
      if (this.audiences.length) {
        switch (this.audiences[this.audiences.length - 1].type_id) {
          case AUDIENCE_TYPES.INTEREST:
            this.tabsModel = 'interests';
            break;
          case AUDIENCE_TYPES.PROPERTY:
            this.tabsModel = 'properties';
            break;
          default:
            console.warn('invalid audience id');
        }
      }
    },
    /**
     * Watch on parallel prop and trigger tab resize event after transition is finished
     * needed to show the slider in the correct size
     *
     * @param {boolean} value - value changed to
     *
     * @returns {void}
     */
    parallel(value) {
      if (!value) {
        window.setTimeout(() => {
          this.$refs.tabs.onResize();
        }, 550);
      }
    },
  },

  mounted() {
    this.$emit('input', this.audienceIds);
  },
};
</script>

<style lang="scss">
/* hide prev-slide arrow, since its reserving unnecessary space in tabs */
.audience-chooser {
  .v-tabs .v-slide-group__prev v-slide-group__prev--disabled,
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
// show border on the right side of audience chooser
.audience-chooser:not(.parallel) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
// apply different border color in dark mode
.theme--dark .audience-chooser:not(.parallel) {
  border-right-color: rgba(255, 255, 255, 0.12);
}
</style>
