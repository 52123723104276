<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title class="text-h5">
            {{ $t('components.refreshUsers.title') }}
          </v-card-title>
        </v-col>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="form.userId"
                :items="users"
                :loading="loading"
                :label="$t('components.refreshUsers.form.email.label')"
                :hint="$t('components.refreshUsers.form.email.hint')"
                name="user_id"
                item-text="email"
                item-value="id"
              >
                <template #item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item" />
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <v-img
                        :src="
                          data.item.image
                            ? data.item.image
                            : helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.email }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          data.item.firstname &&
                          data.item.firstname + ' ' + data.item.lastname
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                :loading="loadingSingleUser"
                :disabled="!form.userId"
                color="primary"
                @click="refreshSingleUser"
              >
                {{ $t('components.refreshUsers.buttons.refreshSingleUser') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                :loading="loadingAll"
                color="primary"
                @click="refreshAllUsers"
              >
                {{ $t('components.refreshUsers.buttons.refreshAll') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import helpers from '@/utils/helpers';

export default {
  data() {
    return {
      helpers,
      form: {},
      result: null,
      loading: false,
      loadingSingleUser: false,
      loadingAll: false,
      message: '',
    };
  },
  computed: {
    ...mapState('user', ['users']),
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.$store.dispatch('user/getUsers');
      this.loading = false;
    },
    async refreshSingleUser() {
      this.loadingSingleUser = true;
      const params = {
        userId: this.form.userId,
      };
      await this.refreshUsers(params);
      this.loadingSingleUser = false;
    },
    async refreshAllUsers() {
      this.loadingAll = true;
      const params = {
        all: true,
      };
      await this.refreshUsers(params);
      this.loadingAll = false;
    },
    async refreshUsers(params) {
      let message;
      try {
        const result = await this.$store.dispatch('user/refreshUsers', params);
        if (result) {
          message = this.$t('components.snackbar.text.userCacheRefreshed');
        }
      } catch (err) {
        message = err;
      }
      this.$bus.fire('show-snackbar', { message });
    },
  },
};
</script>

<style></style>
