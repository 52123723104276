<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedAnswers.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedAnswers.info') }}</span>
      <v-list>
        <v-list-item
          v-for="(answer, index) in tracking.assignedAnswers"
          :key="answer.id"
        >
          <v-list-item-action>
            <v-icon color="info"> mdi-check-circle-outline </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              answer.answer ||
              $t('common.answerMedia.fallback', { id: index + 1 })
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('components.tracking.assignedAnswers.votes') }}:
              {{ answer.votes }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
