var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"numberlist",class:( _obj = { 'fixed-table': _vm.fixedTable }, _obj[_vm.cssClass] = _vm.cssClass, _obj ),attrs:{"headers":_vm.headers,"items":_vm.dataRows,"item-key":_vm.keyField,"hide-default-header":"","hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"headers-length":_vm.keyColSpan + 1},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',{staticStyle:{"height":"51px"}},[_c('th',{key:props.headers[0].text,staticClass:"column text-start",attrs:{"colspan":_vm.keyColSpan}},[_c('div',{staticClass:"header-content"},[_c('span',[_vm._v(_vm._s(props.headers[0].text))]),(_vm.$slots['header-options-1'])?_c('v-icon',[_vm._v(" mdi-dots-vertical ")]):_vm._e()],1),(_vm.$slots['header-options-1'])?_c('options-menu',{attrs:{"css-class":_vm.cssClass ? _vm.cssClass + '-header-options-1' : null,"in-header":"","fill":"","bottom":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on))]}}],null,true)},[_vm._t("header-options-1")],2):_vm._e()],1),_c('th',{key:props.headers[1].text,staticClass:"column text-end"},[_vm._v(" "+_vm._s(props.headers[1].text)+" "),(_vm.$slots['header-options-2'])?_c('options-menu',{attrs:{"css-class":_vm.cssClass ? _vm.cssClass + '-header-options-1' : null,"in-header":"","bottom":"","close-on-content-click":""}},[_vm._t("header-options-2")],2):_vm._e()],1)])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ clickable: !!_vm.onRowClick },on:{"click":function($event){return _vm.rowClick(item)}}},[_c('td',{directives:[{name:"ripple",rawName:"v-ripple",value:(!!_vm.onRowClick && !_vm.isSafari),expression:"!!onRowClick && !isSafari"}],attrs:{"colspan":_vm.keyColSpan}},[(_vm.keyUrl && item[_vm.keyField] && _vm.keyUrl(item))?_c('a',{staticClass:"link textColor--text",attrs:{"href":_vm.keyUrl(item),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.translations ? _vm.translate(item) : item[_vm.keyField])+" ")]):(!item[_vm.keyField] && _vm.emptyKeyValue)?_c('span',[_vm._v(" "+_vm._s(_vm.emptyKeyValue)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.translations ? _vm.translate(item) : item[_vm.keyField])+" ")])]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple",value:(!!_vm.onRowClick && !_vm.isSafari),expression:"!!onRowClick && !isSafari"}],staticClass:"text-right text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item[_vm.countField]))+" ")])])]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.keyColSpan + 1}},[(_vm.dataLoaded === null || !_vm.loading)?_c('span',[_vm._t("no-data",[_vm._v(" "+_vm._s(_vm.$t('monitoring.no_data'))+" ")])],2):_vm._e()])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }