import ChannelResource from '@/resources/ChannelResource';
import * as types from './mutation-types';

export const getChannels = ({ commit }) =>
  new Promise((resolve, reject) => {
    new ChannelResource()
      .getChannels()
      .then((data) => {
        commit(types.SET_CHANNELS, data);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const createChannel = ({ commit }, channel) =>
  new Promise((resolve, reject) => {
    new ChannelResource()
      .create(channel)
      .then((data) => {
        commit(types.ADD_CHANNEL, data);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const deleteChannel = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new ChannelResource()
      .destroy(id)
      .then(() => {
        commit(types.REMOVE_CHANNEL, id);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export default {
  getChannels,
  createChannel,
  deleteChannel,
};
