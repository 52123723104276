<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title class="text-h5">
            {{ pluralModelName }}
          </v-card-title>
        </v-col>
        <v-card-text>
          <template v-if="audienceLoaded">
            <manage-tree-item
              v-for="children in treeData"
              :key="children.id"
              :model="children"
              :depth="0"
              :model-name="modelName"
              class="item"
              @show-delete-dialog="prepareDeleteAudience"
            />
            <add-image-dialog :model-name="modelName" />
            <dialog-name :model-name="modelName" :type="type" />
            <dialog-delete
              :show="showDeleteDialog"
              :model-name="modelName"
              :name="currentAudienceName"
              :loading="deletingProgress"
              @confirmed="deleteAudience"
              @close-dialog="setDeleteDialog(false)"
            />
          </template>
          <template v-else>
            <v-progress-circular indeterminate color="primary" />
          </template>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import AddImageDialog from '@/components/AddImageDialog/AddImageDialog';
import DialogDelete from '@/components/DialogDelete';
import ManageTreeItem from './ManageTreeItem';
import DialogName from './DialogName';

export default {
  components: {
    ManageTreeItem,
    AddImageDialog,
    DialogDelete,
    DialogName,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      audienceLoaded: false,
      parent: 0,
      showDeleteDialog: false,
      currentAudienceIdToDelete: null,
      deletingProgress: false,
      currentAudienceName: '',
    };
  },
  computed: {
    modelName() {
      if (this.type === 1) return this.$t('models.single.interest');
      if (this.type === 2) return this.$t('models.single.property');
      return 'Model';
    },
    pluralModelName() {
      if (this.type === 1) return this.$t('models.plural.interest');
      if (this.type === 2) return this.$t('models.plural.property');
      return 'Models';
    },
  },
  mounted() {
    this.getAudiences();
  },
  methods: {
    async getAudiences() {
      await this.$store.dispatch('audience/getAudiences');
      this.audienceLoaded = true;
    },
    prepareDeleteAudience({ id, name }) {
      this.currentAudienceIdToDelete = id;
      this.currentAudienceName = name;
      this.setDeleteDialog(true);
    },
    setDeleteDialog(state = true) {
      this.showDeleteDialog = state;
    },
    async deleteAudience() {
      this.deletingProgress = true;
      // post to controller
      try {
        await this.$store.dispatch(
          'audience/deleteAudience',
          this.currentAudienceIdToDelete
        );
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelDeleted', {
            model: this.modelName,
          }),
        });
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }

      // hide delete dialog and reset fields
      this.setDeleteDialog(false);
      this.deletingProgress = false;
      this.currentAudienceIdToDelete = null;
      this.currentAudienceName = '';
    },
  },
};
</script>

<style></style>
