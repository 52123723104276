<template>
  <v-data-table
    :headers="headers"
    :items="dataRows"
    :item-key="keyField"
    hide-default-header
    hide-default-footer
    disable-pagination
    :class="{ 'fixed-table': fixedTable }"
    class="transparent numberlist expandable-numberlist"
    :loading="loading"
    :headers-length="keyColSpan + 1"
    :expanded.sync="expandedItems"
  >
    <template #header="{ props }">
      <thead v-if="!hideHeaders">
        <tr>
          <th
            :key="props.headers[0].text"
            :colspan="keyColSpan"
            class="column text-start"
          >
            <span>
              {{ props.headers[0].text }}
              <slot name="header-options-1" />
            </span>
          </th>
          <th
            :key="props.headers[1].text"
            class="column text-end"
            :class="{ 'pr-6': withOptionsMenu }"
          >
            {{ props.headers[1].text }}
          </th>
        </tr>
      </thead>
    </template>
    <template #item="{ item, isExpanded }">
      <tr
        :class="{
          expandable: isExpandable(item),
          expanded: !!isExpanded,
          'with-row-click': !!onRowClick,
          clickable: isExpandable(item) || !!onRowClick,
        }"
        @click="(event) => rowClick(item, event.target, isExpanded)"
      >
        <td
          v-ripple="(isExpandable(item) || !!onRowClick) && !isSafari"
          :colspan="keyColSpan"
          :style="{ 'padding-left': 24 + level * 15 + 'px' }"
        >
          <a
            v-if="keyUrl && item[keyField] && keyUrl(item, level)"
            class="link textColor--text"
            :href="keyUrl(item, level)"
            target="_blank"
            @click.stop
          >
            {{ translations ? translate(item, level) : item[keyField] }}
          </a>
          <span v-else-if="!item[keyField] && emptyKeyValue">
            {{ emptyKeyValue }}
          </span>
          <span v-else>
            {{ translations ? translate(item, level) : item[keyField] }}
          </span>
          <span
            v-if="isExpandable(item)"
            class="icon-btn"
            @click.stop="toggleItem(item, isExpanded)"
          >
            <v-icon :size="18">
              {{
                `mdi-numeric-${
                  item[childrenField].length > 9
                    ? '9-plus'
                    : item[childrenField].length
                }-box-multiple-outline`
              }}
            </v-icon>
          </span>
          <div v-if="rowOverlay && isExpandable(item)" class="row-overlay">
            <v-btn
              depressed
              class="expand"
              @click.stop.prevent="
                (event) => {
                  toggleItem(item, isExpanded);
                  removeOverlay(event.target);
                }
              "
            >
              {{
                item.expanded
                  ? $t('monitoring.actions.collapse')
                  : $t('monitoring.actions.expand')
              }}
            </v-btn>
            <v-btn
              depressed
              class="click"
              @click.stop.prevent="
                (event) => {
                  onRowClick(item);
                  removeOverlay(event.target);
                }
              "
            >
              {{ $t('monitoring.actions.filter') }}
            </v-btn>
            <v-btn
              class="close"
              text
              icon
              dark
              @click.stop.prevent="(event) => removeOverlay(event.target)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </td>
        <td
          v-ripple="(isExpandable(item) || !!onRowClick) && !isSafari"
          class="text-right text-no-wrap"
          :class="{ 'pr-6': withOptionsMenu }"
        >
          {{ item[countField] | formatNumber }}
        </td>
      </tr>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="keyColSpan + 1">
        <expandable-number-list
          :data="item[childrenField]"
          :translations="translations"
          :key-field="keyField"
          :count-field="countField"
          :level="level + 1"
          :fixed-table="fixedTable"
          :key-url="keyUrl"
          :on-row-click="onRowClick"
          :with-options-menu="withOptionsMenu"
          hide-headers
          with-bottom-border
        />
      </td>
    </template>
    <template #[`body.append`] v-if="withBottomBorder">
      <tr />
    </template>
    <template #no-data>
      <td :colspan="keyColSpan + 1" class="text-center">
        <span v-if="dataLoaded === null || !loading">
          <slot name="no-data">
            {{ $t('monitoring.no_data') }}
          </slot>
        </span>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import NumberList from './NumberList';

export default {
  name: 'ExpandableNumberList',
  extends: NumberList,
  props: {
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    childrenField: {
      type: String,
      required: false,
      default: 'children',
    },
    // please check the css for this prop since this styling might need refactoring after upgrade to Vuetify 2.0
    rowOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSingleChildren: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Indiactor whether the header should be hidden
    hideHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Indicator whether there is an options menu on the right (so padding need to be adjusted)
    withOptionsMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Indicator whether there should be a bottom border after the last item
    withBottomBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expandedItems: [],
    };
  },
  methods: {
    /**
     * Method executed when a row is clicked
     *
     * @param {object} item - item to expand
     * @param {object} target - event target element
     * @param {bool} isExpanded - indicator whether item is currently expanded
     *
     * @returns {void}
     */
    rowClick(item, target, isExpanded) {
      if (this.onRowClick) {
        // execute rowclick overrride
        if (this.rowOverlay && this.isExpandable(item)) {
          const tr = this.findParent(target, 'TR');
          const overlay = tr.getElementsByClassName('row-overlay')[0];
          overlay.setAttribute('style', `width:${tr.offsetWidth}px`);
          tr.classList.add('overlay');
        } else {
          this.onRowClick(item);
        }
      } else {
        // expand is default functionality
        this.toggleItem(item, isExpanded);
      }
    },

    findParent(element, parent) {
      let e = element;
      while (e && e.tagName !== parent) e = e.parentElement;
      return e;
    },

    removeOverlay(target) {
      const tr = this.findParent(target, 'TR');
      tr.classList.remove('overlay');
    },

    /**
     * Method to check if the current item can be expanded by checking the amount children
     *
     * @param {object} item - item to check
     *
     * @returns {boolean} isExpandable
     */
    isExpandable(item) {
      return (
        item[this.childrenField] &&
        item[this.childrenField].length > (this.hideSingleChildren ? 1 : 0)
      );
    },

    /**
     * Method user to toggle expand item (only if its expandable)
     *
     * @param {object} currentItem - item to expand
     * @param {bool} isExpanded - indicator whether item is currently expanded
     *
     * @returns {void}
     */
    toggleItem(currentItem, isExpanded) {
      if (this.isExpandable(currentItem)) {
        // if its not expanded yet, push it to array
        if (!isExpanded) {
          this.expandedItems.push(currentItem);
        } else {
          // otherwise remove it from array
          this.expandedItems = this.expandedItems.filter(
            (item) => item.key !== currentItem.key
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/** this styling might need refactoring after Vuetify upgrade to 2.0 */
.numberlist tr > td > .row-overlay {
  background: #303030;
  background: #424242;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
  z-index: 100;
  display: none;
}
.numberlist tr.overlay > td > .row-overlay {
  display: flex;
}
.numberlist tr > td > .row-overlay .v-btn {
  flex: 1 1 auto;
}
.numberlist tr > td > .row-overlay .v-btn.close {
  flex: 0 0 auto;
}
</style>
<style>
.numberlist tr > td {
  position: relative;
}
.numberlist tr > td > * {
  vertical-align: middle;
}

/* Style .icon-btn to have a nice bubble on selection */
.expandable-numberlist tr .icon-btn {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  margin-left: -4px;
}
.expandable-numberlist tr .icon-btn .v-icon {
  position: relative;
  font-size: 18px;
  height: 100%;
  color: rgba(0, 0, 0, 0.64);
}
.theme--dark .expandable-numberlist tr .icon-btn .v-icon {
  color: rgba(255, 255, 255);
}
.expandable-numberlist tr .icon-btn .v-icon::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  opacity: 1;
  background-color: rgba(66, 66, 66, 0.15);
  transform: scale(0);
  transition: all 400ms ease;
}
.theme--dark .expandable-numberlist tr .icon-btn .v-icon::after {
  background-color: rgba(255, 255, 255, 0.1);
}
.expandable-numberlist tr.expanded .icon-btn .v-icon::after,
.expandable-numberlist tr .icon-btn:hover .v-icon::after {
  transform: scale(1);
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.expandable-numberlist tr .icon-btn:hover .v-icon::after {
  background-color: rgba(66, 66, 66, 0.25);
}
.theme--dark .expandable-numberlist tr .icon-btn:hover .v-icon::after {
  background-color: rgba(255, 255, 255, 0.25);
}
</style>
