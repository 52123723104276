import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.SET_CHANNELS](state, channels) {
    state.channels = channels;
    state.loaded = true;
  },

  [types.ADD_CHANNEL](state, channel) {
    state.channels.push(channel);
  },

  [types.REMOVE_CHANNEL](state, id) {
    state.channels = state.channels.filter((c) => c.id !== id);
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
