<template>
  <v-dialog v-model="show" max-width="600" persistent scrollable>
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('components.addImageDialog.title') }}
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <div class="full-width">
            <v-card
              color="blue-grey"
              class="white--text"
              max-width="600"
              elevation-10
            >
              <v-img
                :src="
                  postData.imageUrl ? postData.imageUrl : model.filename || ''
                "
                height="200px"
                class="preview-image"
              >
                <div class="audience-name white--text">
                  {{ model.text }}
                </div>
                <dropzone-field
                  :has-data="!!(postData.imageUrl || model.filename)"
                  :active="current === 'dropzone'"
                  @set-active="setCurrentActive"
                />
              </v-img>
              <v-card-title primary-title>
                <div class="text-h5">
                  {{ $t('components.addImageDialog.static.title') }}
                </div>
              </v-card-title>
              <v-card-text>
                <url-field
                  :active="current === 'url'"
                  @click.native="setCurrentActive('url')"
                />
                <pixabay-field
                  :active="current === 'pixabay'"
                  @click.native="setCurrentActive('pixabay')"
                  @get-items="getItems"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="full-width">
            <v-card color="light-blue" class="white--text" max-width="600">
              <v-card-title primary-title>
                <div class="text-h5">
                  {{ $t('components.addImageDialog.animated.title') }}
                </div>
              </v-card-title>
              <v-card-text>
                <giphy-field
                  :active="current === 'giphy'"
                  @click.native="setCurrentActive('giphy')"
                  @get-items="getItems"
                />
                <youtube-field
                  :active="current === 'youtube'"
                  @click.native="setCurrentActive('youtube')"
                  @get-items="getItems"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="warning" text @click="cancel">
          {{ $t('components.addImageDialog.cancel') }}
        </v-btn>
        <v-btn :disabled="!postData.imageUrl" color="success" @click="save">
          {{ $t('components.addImageDialog.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import DropzoneField from './components/DropzoneField';
import UrlField from './components/UrlField';
import PixabayField from './components/PixabayField';
import GiphyField from './components/GiphyField';
import YoutubeField from './components/YoutubeField';

export default {
  components: {
    DropzoneField,
    UrlField,
    PixabayField,
    GiphyField,
    YoutubeField,
  },
  props: {
    modelName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      model: '',
      show: false,
      current: '',
    };
  },
  computed: {
    ...mapState('image', ['postData', 'items']),
  },
  mounted() {
    this.$bus.listen('show-add-image-dialog', this.showImageDialog);
    this.$bus.listen(
      `${this.modelName}:show-add-image-dialog`,
      this.showImageDialog
    );
    this.$bus.listen('clear-data', this.resetFields);
  },
  destroyed() {
    this.$bus.remove('show-add-image-dialog');
    this.$bus.remove(`${this.modelName}:show-add-image-dialog`);
    this.$bus.remove('clear-data');
  },
  methods: {
    showImageDialog(model) {
      this.show = true;
      this.model = model;
    },
    async getItems(data) {
      // get pictures and store them
      const result = await this.$store.dispatch('image/search', data);
      if (result.length === 0) {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.noResultsFound'),
        });
      }
    },
    setCurrentActive(field) {
      // set active field
      this.current = field;
    },
    cancel() {
      this.show = false;
      this.resetFields();
    },
    async save() {
      this.show = false;

      await this.$store.dispatch('image/save', this.model.id);
      this.$bus.fire('show-snackbar', {
        message: this.$t('components.snackbar.text.imageAdded'),
      });
      this.resetFields();
    },
    resetFields() {
      this.$store.commit('image/RESET_FIELDS');
      this.current = '';
      this.model = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  transition: 0.5s ease;
  &:hover {
    opacity: 0.4;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}
.audience-name {
  padding: 10px;
  position: absolute;
  width: 100%;
  font-size: 3vmax !important;
  text-transform: uppercase;
  font-weight: 500;
  min-height: 1.2em;
  line-height: 3vmax;
}
</style>
