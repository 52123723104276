/* ============
 * Mutations for the user module
 * ============
 *
 * The mutations that are available on the
 * user module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';
import constants from '@/utils/constants';

/**
 * Set the correct image path by prefixing the cdn
 * @param {Array} users users array
 * @returns {Array} users updated user array
 */
const mapUsers = (users) =>
  users.map((user) => {
    if (user.image && !user.image.includes('://')) {
      user.image = `${constants.CDN_STORAGE}/user/${user.image}`;
    }
    return user;
  });

export default {
  [types.SET_USERS](state, users) {
    state.users = mapUsers(users);
  },
  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
