<template>
  <v-dialog
    :value="show"
    max-width="1000"
    persistent
    scrollable
    @keydown.esc="closeDetailDialog"
  >
    <v-card>
      <v-card-title class="pp-custom-background py-6 text-h6">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <assigned-rules :id="id" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="closeDetailDialog">
          {{ $t('components.audiences.dialogDetail.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AUDIENCE_TYPES } from '@/utils/constants';
import AssignedRules from './AssignedRules';

export default {
  components: {
    AssignedRules,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    id: {
      type: Number,
      required: true,
      default: null,
    },
    type: {
      type: Number,
      required: false,
      default: AUDIENCE_TYPES.INTEREST,
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogTitle() {
      const audienceType = {
        [AUDIENCE_TYPES.INTEREST]: this.$t('models.single.interest'),
        [AUDIENCE_TYPES.PROPERTY]: this.$t('models.single.property'),
      };
      return this.$t('components.audiences.dialogDetail.title', {
        audience: audienceType[this.type],
      });
    },
  },
  methods: {
    closeDetailDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style></style>
