<template>
  <v-container fluid>
    <v-card-text>
      <!-- filter -->
      <v-row>
        <v-col cols="12">
          <v-select
            class="fit"
            label="Domain"
            v-model="domain"
            :items="domains"
            item-text="title"
            @change="filterChanged"
          />
          <v-select
            class="fit ml-2"
            :label="$t('components.filterButtonMenu.filters.timeframe.label')"
            v-model="days"
            :items="timeframeValues"
            @change="filterChanged"
          />
        </v-col>
      </v-row>
      <v-divider />
      <!-- Breadcrumbs -->
      <v-row>
        <v-col cols="12" sm="7" class="order-sm-first">
          <v-breadcrumbs :items="breadcrumbs" class="text-subtitle-1 pl-0 pb-0">
            <template #item="{ item }">
              <v-breadcrumbs-item
                link
                class="pointer"
                @click="loadChildren(item)"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <!-- Audience cards -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="productColor"
        rounded
        class="mt-4"
        height="10"
      />
      <v-container fluid v-else>
        <v-row v-if="filteredAudiences.length <= 0">
          <v-col cols="12"> {{ $t('monitoring.components.no_data') }}</v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
            v-for="item in filteredAudiences"
            :key="item.id"
            cols="12"
            sm="6"
            lg="3"
            class="pa-1"
          >
            <v-card class="elevation-10">
              <v-img
                :src="
                  item.filename ? item.filename : helpers.getPlaceholderImage()
                "
                :class="{ 'is-navigatable': hasChildren(item) }"
                aspect-ratio="2.4"
                class="audience-card"
                height="100%"
                @click="loadChildren(item)"
              >
                <v-container
                  class="audience-container d-flex flex-column justify-space-between"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <span
                        :class="{
                          'max-font-size-big': $vuetify.breakpoint.mdAndDown,
                        }"
                        class="white--text text-uppercase audience-name"
                      >
                        {{ item.text }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div class="number count">
                        <span class="white--text">
                          <animated-number
                            :data="item.classifications.users"
                            :duration="2000"
                          />
                        </span>
                      </div>
                      <div>
                        <span class="white--text">
                          <template>
                            <animated-number
                              :data="item.classifications.visitors"
                              :duration="500"
                            />
                          </template>
                        </span>
                        <span class="white--text">
                          {{ $t('components.aiAudiences.visitors') }}
                        </span>
                        <span class="white--text">
                          ({{
                            item.classifications.visitors
                              | formatPercentageOf(item.classifications.users)
                          }}
                          {{ $t('components.aiAudiences.tracked') }})
                        </span>
                        <br />
                        <span class="white--text">
                          <template>
                            <animated-number
                              :data="item.classifications.views"
                              :duration="500"
                            />
                          </template>
                        </span>
                        <span class="white--text">
                          {{ $t('components.aiAudiences.page_views') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-container>
</template>
<script>
import AnimatedNumber from '../AnimatedNumber.vue';
import { mapGetters } from 'vuex';
import ClassificationsResource from '../../resources/ClassificationsResource';
import AccountResource from '../../resources/AccountResource';
import helpers from '../../utils/helpers';

export default {
  components: { AnimatedNumber },

  data() {
    return {
      helpers,
      domain: null,
      domains: [
        {
          value: null,
          title: this.$t('components.audienceOverview.all_domains'),
        },
      ],
      days: 7,
      timeframeValues: [
        {
          text: this.$t(
            'components.filterButtonMenu.filters.timeframe.last24Hours'
          ),
          value: 1,
        },
        {
          text: this.$t(
            'components.filterButtonMenu.filters.timeframe.last7Days'
          ),
          value: 7,
        },
        {
          text: this.$t(
            'components.filterButtonMenu.filters.timeframe.last30Days'
          ),
          value: 30,
        },
      ],
      filteredAudiences: [],
      AIAudiences: [],
      breadcrumbs: [
        {
          text: this.$t('components.audienceOverview.breadcrumbs.topics'),
          id: null,
          isNavigatable: true,
        },
      ],
      loading: true,
    };
  },

  async mounted() {
    await this.loadDomains();
    if (this.domains.length > 1) this.domain = this.domains[1].value;

    this.loadClassifications().then(() => (this.loading = false));
  },

  computed: {
    ...mapGetters('audience', ['aiTopics']),
  },

  methods: {
    hasChildren(item) {
      return item?.children?.length;
    },
    loadChildren(item) {
      if (!item || !item.id) {
        this.filteredAudiences = this.AIAudiences;
        this.refreshBreadcrumbs(null);
      } else if (this.hasChildren(item)) {
        this.filteredAudiences = item.children;
        this.refreshBreadcrumbs(item);
      }
    },
    refreshBreadcrumbs(item) {
      if (!item) {
        this.breadcrumbs = [this.breadcrumbs[0]];
        return;
      }
      let index = this.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.id == item.id
      );
      if (index > -1) {
        index += 1;
        this.breadcrumbs = this.breadcrumbs.slice(0, index);
      } else this.breadcrumbs.push(item);
    },
    async filterChanged() {
      this.loading = true;
      await this.loadClassifications();
      this.reaplyBreadrumbs();
      this.loading = false;
    },
    reaplyBreadrumbs() {
      for (const i in this.breadcrumbs) {
        if (this.breadcrumbs[i].id) {
          const audience = this.filteredAudiences.filter(
            (audience) => audience.id == this.breadcrumbs[i].id
          );
          if (!audience.length) {
            console.warn('breadcrumb path does not exist anymore');
            this.filteredAudiences = this.AIAudiences;
            this.breadcrumbs = [this.breadcrumbs[0]];
            return;
          }

          this.filteredAudiences = audience[0].children;
          this.breadcrumbs[i] = audience[0];
        }
      }
      this.breadcrumbs = [...this.breadcrumbs]; // force update
    },
    async loadClassifications() {
      const res = new ClassificationsResource();
      try {
        const data = await res.getAll({ domain: this.domain, days: this.days });
        const audiences = [];
        for (const classification of data) {
          const audienceId = classification.key;
          if (audienceId) {
            const audience = _.clone(
              this.aiTopics.find((interest) => interest.id === audienceId)
            );
            if (audience) {
              audience.classifications = {
                views: classification.doc_count,
                visitors: classification.counts.visitors.value,
                users: classification.counts.users.value,
              };
              audiences.push(audience);
            }
          }
        }
        this.AIAudiences = helpers.createTree(audiences);
      } catch (error) {
        this.AIAudiences = [];
        console.error(error);
      }
      this.filteredAudiences = this.AIAudiences;
    },
    async loadDomains() {
      const accountResource = new AccountResource();
      this.domains.push(
        ...(await accountResource.aiDomains()).map((domain) => {
          domain.value = domain.title;
          return domain;
        })
      );
    },
  },
};
</script>

<style scoped>
.container.audience-container {
  min-height: 150px;
  height: 100%;
}
.audience-card {
  background: black;
}
.audience-name {
  white-space: nowrap;
}

.v-select.fit {
  width: max-content;
  display: inline-flex;
}
</style>
