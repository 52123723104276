<template>
  <div>
    <refresh-users />
    <refresh-retargeting class="mt-4" />
  </div>
</template>

<script>
import RefreshUsers from '@/components/Admin/RefreshUsers';
import RefreshRetargeting from '@/components/Admin/RefreshRetargeting';

export default {
  components: {
    RefreshUsers,
    RefreshRetargeting,
  },
};
</script>
