/* ==================
 * Monitoring Module
 * ==================
 */
import Loadable from '@/utils/Loadable';
import LoadableCollection from '@/utils/LoadableCollection';
import * as types from './mutation-types';
import { getInitialState } from './state';

function dynamicSet(object, path, value) {
  if (path.length === 0) return value;
  const key = path.shift();
  object[key] = dynamicSet(object[key], path, value);
  return object;
}

export default {
  [types.UPDATE_RAW](state, { prop, value }) {
    const path = prop.split('.');
    dynamicSet(state, path, value);
  },
  [types.UPDATE_DATA](state, { prop, value, parameters, ownerId }) {
    const loadable = new Loadable(value, true, parameters);
    if (ownerId) {
      const loadabelCollection = state.get(prop);
      if (!(loadabelCollection instanceof LoadableCollection)) {
        console.error(`prop ${prop} is not an LoadableCollection`);
        return;
      }
      loadabelCollection.add(ownerId, loadable);
    } else {
      const path = prop.split('.');
      dynamicSet(state, path, loadable);
    }
  },
  [types.UPDATE_FILTER](state, { prop, value }) {
    const path = ['filter'].concat(prop.split('.'));
    dynamicSet(state, path, value);
  },
  [types.ADD_FILTER](state, { filter }) {
    state.filter.terms.push(filter);
  },
  [types.REMOVE_FILTER](state, { index, term, value }) {
    // if index defined
    if (index != null) {
      state.filter.terms.splice(index, 1);
    } else if (term) {
      state.filter.terms.splice(
        state.filter.terms.findIndex(
          (filter) => filter.term === term && filter.value === value
        ),
        1
      );
    }
  },
  [types.SET_TEMPORARY_FILTER_MODE](state, value) {
    state.isTemporaryFilterMode = value;
  },
  [types.UPDATE_SETTING](state, { prop, value }) {
    const path = ['settings'].concat(prop.split('.'));
    dynamicSet(state, path, value);
  },
  /**
   * @param {any} state Vuex state
   * @param {string|string[]|{prop: (string|string[]), ownerId}} options prop or prop-ownerId object
   * @returns {void}
   */
  [types.PURGE_LOADED](state, options) {
    const prop = options.prop ? options.prop : options;
    if (prop instanceof Array) {
      const loadable = state[prop[0]][prop[1]];
      if (loadable instanceof LoadableCollection) {
        if (!options.ownerId) {
          loadable.purgeAll();
        } else {
          loadable.purge(options.ownerId);
        }
      } else {
        loadable.loaded = false;
      }
    } else {
      Object.keys(state[prop]).forEach((property) => {
        if (state[prop][property] instanceof Loadable) {
          state[prop][property].loaded = false;
        }
      });
    }
  },
  [types.UPDATE_ACTIVE_VIEW](state, view) {
    state.activeView = view;
  },
  [types.UPDATE_HISTOGRAM](
    state,
    {
      histogramField,
      value,
      valueField = 'doc_count',
      realtimeAnimation = false,
    }
  ) {
    if (!state.realtime[histogramField].loaded) return;
    const histogram = state.realtime[histogramField].data;
    const fiveMinutes = 1000 * 60 * 5;

    // actual last entry (length - 1) gets updated all the time
    // we select length - 2, because it is the last fixed entry in the histogram
    const lastEntry = histogram[histogram.length - 2];

    const now = new Date();
    const lastFiveMinuteTimestamp =
      now.getTime() - (now.getTime() % fiveMinutes);
    const data = {
      key: lastFiveMinuteTimestamp,
      key_as_string: new Date(lastFiveMinuteTimestamp).toISOString(),
      [valueField]: value,
    };
    if (!lastEntry || data.key - lastEntry.key > fiveMinutes) {
      // add new entry
      histogram.push(data);
    } else if (realtimeAnimation) {
      // now new entry needed just update realtime value (actual last value)
      histogram[histogram.length - 1] = data;
    }
  },
  [types.UPDATE_AUDIENCE_HISTOGRAM](
    state,
    { histogramField, value, ownerId, valueField = 'audienceCount' }
  ) {
    if (!state.audienceGrowth[histogramField].isLoaded(ownerId)) return;
    const histogram = state.audienceGrowth[histogramField][ownerId].data;
    // Get the sum of all audiences in the histogram
    const sum = histogram.reduce((acc, point) => acc + point.audienceCount, 0);

    // Only increase count in case the received value is greater then the overall sum
    if (value > sum) {
      const diff = value - sum;

      // Get last data point
      const lastHistogramPoint = histogram[histogram.length - 1];

      const currentUtcDate = moment();

      // In case the data point already exists, add the difference to it
      if (
        lastHistogramPoint &&
        moment(lastHistogramPoint.date).minutes() <= currentUtcDate.minutes() &&
        currentUtcDate.minutes() - moment(lastHistogramPoint.date).minutes() <
          30
      ) {
        lastHistogramPoint.audienceCount += diff;
        histogram[histogram.length - 1] = lastHistogramPoint;
      } else {
        // create a new data point for the next future interval and add it to the histogram data
        let nextDataPointDate = currentUtcDate.clone();
        nextDataPointDate = nextDataPointDate.startOf('hour');

        if (currentUtcDate.minutes() >= 30) {
          nextDataPointDate = nextDataPointDate.add(30, 'minutes');
        }

        histogram.push({
          [valueField]: diff,
          date: nextDataPointDate.format(),
        });
      }
    }
  },
  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },

  [types.UPDATE_EVENT_HISTOGRAM](
    state,
    { histogramField, value, ownerId, valueField = 'eventCount' }
  ) {
    if (!state.eventTracking[histogramField].isLoaded(ownerId)) return;
    const histogram = state.eventTracking[histogramField][ownerId].data;
    // Get the sum of all audiences in the histogram
    const sum = histogram.reduce((acc, point) => acc + point.eventCount, 0);

    // Only increase count in case the received value is greater then the overall sum
    if (value.amount > sum) {
      const diff = value.amount - sum;

      // Get last data point
      const lastHistogramPoint = histogram[histogram.length - 1];

      const currentUtcDate = moment();

      // In case the data point already exists, add the difference to it
      if (
        lastHistogramPoint &&
        moment(lastHistogramPoint.key).minutes() <= currentUtcDate.minutes() &&
        currentUtcDate.minutes() - moment(lastHistogramPoint.key).minutes() < 30
      ) {
        lastHistogramPoint.eventCount += diff;
        histogram[histogram.length - 1] = lastHistogramPoint;
      } else {
        // create a new data point for the next future interval and add it to the histogram data
        let nextDataPointDate = currentUtcDate.clone();
        nextDataPointDate = nextDataPointDate.startOf('hour');

        if (currentUtcDate.minutes() >= 30) {
          nextDataPointDate = nextDataPointDate.add(30, 'minutes');
        }

        histogram.push({
          [valueField]: diff,
          key: nextDataPointDate.unix() * 1000,
        });
      }
    }
  },
  [types.ADD_NICKNAME](state, { key, name, type }) {
    const { nicknames } = state.settings;
    const array = nicknames[type];
    const index = array.findIndex((ele) => ele.key === key);

    if (index > -1) {
      array[index].name = name;
    } else {
      array.push({ key, name });
    }

    // Need to set nicknames again, so it gets saved in localstorage
    state.settings.nicknames = nicknames;
  },
  [types.SET_DASHBOARDS](state, dashboards) {
    state.realtime.dashboards = dashboards;
  },
  [types.SET_FILTER](state, filter) {
    // setting the whole filter removing object references to passed data
    state.filter = JSON.parse(JSON.stringify(filter));
  },
};
