/* ============
 * productDetector
 * ============
 *
 * Watches current route and detects the current product if changed
 *
 */

export default {
  data() {
    return {
      currentProduct: 'all',
      colors: {
        analytics: '#ff585d',
        dmp: '#df3766',
        ads: '#62c49e',
        all: '#103355',
      },
      dashboards: {
        analytics: {
          name: 'analyticsRealtime',
          permission: 'useProductAnalytics',
        },
        dmp: {
          name: 'showAudiences',
          permission: 'useProductDmp',
        },
        ads: {
          name: 'placeAds',
          permission: 'useProductAds',
        },
      },
    };
  },
  watch: {
    $route: 'setCurrentProduct',
  },
  computed: {
    /**
     * Get current subdomain or false
     *
     * @returns {string} currentSubDomain
     */
    currentSubDomain() {
      const subDomain = window.location.host.split('.')[1]
        ? window.location.host.split('.')[0]
        : false;
      return subDomain;
    },
    /**
     * Get (router) name of the current dashboard depending on current subdomain and permissions
     *
     * @returns {string} currentDashboardName
     */
    currentDashboardName() {
      if (
        this.currentSubDomain && // if there is a subdomain found (will fail on localhost)
        Object.keys(this.dashboards).includes(this.currentSubDomain) && // if the subdomain is contained in dashboard object
        this.$acl.check(this.dashboards[this.currentSubDomain].permission) // if the user has the permission for that dashboard
      ) {
        return this.dashboards[this.currentSubDomain].name;
      }
      // fallback to general dashboard
      return 'dashboard';
    },
  },
  methods: {
    /**
     * Set current product according to current route
     *
     * @returns {void}
     */
    setCurrentProduct() {
      const routeProduct = this.$route.meta.product;
      // only apply changes if the product name is not 'all', is different and if its an existing product
      if (
        routeProduct !== 'all' &&
        this.currentProduct !== routeProduct &&
        Object.keys(this.colors).includes(routeProduct)
      ) {
        // update currentProduct property
        this.currentProduct = routeProduct;
        // trigger product color changes
        this.setCurrentProductColors(routeProduct);
      }
    },
    /**
     * Set current product color according to current route
     *
     * @param {string} routeProduct - product from route
     *
     * @returns {void}
     */
    setCurrentProductColors(routeProduct) {
      const productColor = this.colors[routeProduct];

      // apply vuetify theme colors
      this.$vuetify.theme.themes.light.productColor = productColor;
      this.$vuetify.theme.themes.light.navigationDrawer = productColor;
      this.$vuetify.theme.themes.dark.productColor = productColor;
      this.$vuetify.theme.themes.dark.toolbar = productColor;
      this.$vuetify.theme.themes.dark.navigationDrawerHighlight = productColor;
    },
  },
};
