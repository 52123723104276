import BaseProxy from './BaseProxy';
import constants from '@/utils/constants';

class AuthProxy extends BaseProxy {
  /**
   * The constructor for the AuthProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('');
    // overwrite base url to auth url
    this.baseURL = `${constants.API_AUTH_URL}/api/v1`;
  }

  /**
   * Get account data
   *
   * @returns {Promise} The result in a promise.
   */
  me() {
    return this.submit('get', 'account/me');
  }

  /**
   * Method used to login.
   *
   * @param {String} username The username.
   * @param {String} password The password.
   *
   * @returns {Promise} The result in a promise.
   */
  login({ email, password }) {
    return this.submit('post', 'login', { email, password });
  }

  /**
   * Method used to refresh the token.
   *
   * @returns {Promise} The result in a promise.
   */
  refresh() {
    return this.submit('post', 'auth/refresh');
  }

  /**
   * Method used to logout the current user.
   *
   * @returns {Promise} The result in a promise.
   */
  logout() {
    return this.submit('post', 'logout');
  }

  /**
   * Method used to search for user items
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  search(params) {
    return this.submit('get', 'user/search', params);
  }
}

export default AuthProxy;
