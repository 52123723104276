/* ============
 * Bus
 * ============
 *
 * Bus to communicate between components
 */
import Vue from 'vue';

const bus = new (class {
  constructor() {
    this.vue = new Vue();
  }

  fire(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(event, callback) {
    this.vue.$on(event, callback);
  }

  remove(event, callback) {
    this.vue.$off(event, callback);
  }
})();

// Bind bus to Vue.
// Vue.$bus = bus;
Object.defineProperty(Vue.prototype, '$bus', {
  get() {
    return bus;
  },
});

export default bus;
