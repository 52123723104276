import _ from 'lodash';

export const REQUEST_ALREADY_RUNNING_ERROR = 'resource request already running';
class ResourceEndpoint {
  /**
   * @param {Resource} resource Resource to be used
   * @param {string} requestType HTTP method
   * @param {string} endpoint resource url
   * @param {object|function|null} data data to be sent to endpoint
   * @param {Object} options optional options
   * @param {String[]} options.excludeParams exclude parameters from data
   * @param {Function} options.mutation mutate endpoint data
   */
  constructor(
    resource,
    requestType,
    endpoint,
    data = null,
    { excludeParams, mutation } = {}
  ) {
    this.resource = resource;
    this.requestType = requestType;
    this.endpoint = endpoint;
    this.data = data;
    this.loading = false;
    this.loadingParams = null;
    this.excludeParams = excludeParams;
    this.mutation = mutation;
  }

  /**
   * Performs get request
   * @param {Object} options For request data.
   * @returns {Promise<{Object}>}
   */
  fetch(options = {}) {
    const parameters = this.getRequestParameters(options);
    return new Promise((resolve, reject) => {
      if (this.loading && _.isEqual(parameters, this.loadingParams)) {
        reject(new Error(REQUEST_ALREADY_RUNNING_ERROR));
        return;
      }
      this.loading = true;
      this.loadingParams = parameters;

      this.resource
        .submit(this.requestType, this.endpoint, parameters)
        .then((result) =>
          resolve({
            data: this.mutation ? this.mutation(result) : result,
            parameters,
          })
        )
        .catch(reject)
        .finally(() => {
          this.loading = false;
        });
    });
  }

  /**
   * Returns request parameters that would be used right now
   * @param {Object} options For request data.
   * @returns {Object}
   */
  getRequestParameters(options = {}) {
    const parameters =
      this.data instanceof Function ? this.data(options) : this.data;
    if (this.excludeParams) {
      this.excludeParams.forEach((param) => {
        delete parameters[param];
      });
    }
    return parameters;
  }

  /**
   * Add new request parameters
   * @param {Object|Function<Object>} newParams New parameter.
   * @returns {void}
   */
  addRequestParameters(newParams) {
    if (this.data instanceof Function) {
      const currentDataFunc = this.data;
      if (newParams instanceof Function) {
        this.data = (options) => ({
          ...currentDataFunc(options),
          ...newParams(options),
        });
      } else {
        this.data = (options) => ({
          ...currentDataFunc(options),
          ...newParams,
        });
      }
    } else {
      if (newParams instanceof Function) {
        console.warn(
          `ResourceEndpoint ${this.endpoint} should have parameter function instead of static object to add param function.`
        );
      }
      this.data = {
        ...this.data,
        ...newParams,
      };
    }
  }
}

export default ResourceEndpoint;
