<template>
  <v-list class="page-list py-0">
    <v-list-item
      v-for="page in dataRows"
      :key="page.key"
      ripple
      :class="{
        blue: $store.state.monitoring.filter.page === page.key,
      }"
      class="with-border lighten-4"
      @click="clicked(page)"
    >
      <v-list-item-content>
        <span class="limit-2-lines text-body-2">
          <a
            class="link textColor--text"
            :href="'//' + page.domain + page.key"
            target="_blank"
            @click.stop
          >
            <span v-if="$store.state.monitoring.settings.displayPageTitle">
              {{ page.title || '-' }}
            </span>
            <span v-else>
              {{ page.key }}
            </span>
          </a>
        </span>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    max: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
  },

  computed: {
    dataRows() {
      if (this.data.length > this.max) return this.data.slice(0, this.max);
      return this.data;
    },
  },

  methods: {
    clicked(page) {
      if (page.key === this.$store.state.monitoring.filter.page) {
        this.$store.dispatch('monitoring/updateFilter', {
          prop: 'page',
          value: null,
        });
      } else {
        this.$store.dispatch('monitoring/updateFilter', {
          prop: 'page',
          value: page.key,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-list {
  .with-border {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .limit-2-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.35em;
    max-height: 2.7em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

// apply different border color in dark mode
.theme--dark .with-border {
  border-top-color: rgba(255, 255, 255, 0.12);
}
</style>
