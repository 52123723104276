<template>
  <div>
    <transfer-overview />
    <transfer-dialog />
  </div>
</template>

<script>
import TransferOverview from '@/components/Transfer/Overview';
import TransferDialog from '@/components/Transfer/Dialog';

export default {
  components: {
    TransferOverview,
    TransferDialog,
  },
  data: () => ({}),
};
</script>

<style></style>
