<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8">
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="loadData"
        >
          <v-icon left> mdi-refresh </v-icon>
          <span class="hidden-xs-only"> Refresh </span>
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading || selected.length < 1"
          color="red"
          class="ml-1"
          @click="kill"
        >
          <v-icon left> mdi-delete </v-icon>
          <span class="hidden-xs-only"> Kill {{ selected.length }} tasks </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="actionFilter"
          :items="actionTypes"
          attach
          chips
          label="Actions"
          multiple
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :items="items"
          :headers="taskHeaders"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          show-select
        >
          <template #[`item.id`]="{ item }">
            <div>{{ item.id }}</div>
            <div class="text-caption font-weight-light">
              {{ item.node }}:{{ item.id }}
            </div>
          </template>
          <template #[`item.node`]="{ item }">
            <div class="text-no-wrap">
              {{ data.nodes[item.node].name }}
            </div>
          </template>
          <template #[`item.running_time_in_nanos`]="{ item }">
            <div>
              {{ (item.running_time_in_nanos / 1000000) | formatDuration }}
            </div>
            <div class="text-caption font-weight-light">
              {{ item.start_time_in_millis | formatTime }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ElasticResource from '@/resources/ElasticResource';

export default {
  data() {
    return {
      loading: false,
      data: null, // structure: elastic node map with task map as prop
      tasks: [],
      selected: [],
      actionTypes: [],
      actionFilter: [],
      rowsPerPageItems: [25, 50, -1],
      options: {
        page: 1,
        sortBy: ['running_time_in_nanos'],
        sortDesc: [true],
        itemsPerPage: 25,
      },
      taskHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Action', value: 'action' },
        { text: 'Node', value: 'node' },
        { text: 'Parent', value: 'parent_task_id' },
        { text: 'Runtime', value: 'running_time_in_nanos' },
        { text: 'Type', value: 'type' },
      ],
    };
  },
  computed: {
    items() {
      return this.tasks.filter(
        (task) =>
          this.actionFilter.length < 1 ||
          this.actionFilter.includes(task.action)
      );
    },
  },
  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      this.newData(await ElasticResource.getTasks());
      this.loading = false;
    },
    async kill() {
      this.loading = true;
      try {
        await ElasticResource.killTasks(
          this.selected.map((task) => `${task.node}:${task.id}`)
        );
        this.$bus.fire('show-snackbar', { message: 'success' });
      } catch (error) {
        this.$bus.fire('show-snackbar', { message: error });
        console.error(error);
      }
      this.loading = false;
    },
    newData(data) {
      this.data = data;
      this.tasks = Object.values(data.nodes)
        .map((node) => Object.values(node.tasks))
        .reduce((flat, ele) => flat.concat(ele), []);
      this.actionTypes = Object.values(
        this.tasks.reduce((obj, ele) => {
          obj[ele.action] = ele.action;
          return obj;
        }, {})
      );
      this.selected = [];
    },
  },
};
</script>
