/* ============
 * Mutations for the metatag module
 * ============
 *
 * The mutations that are available on the
 * metatag module.
 */

import helpers from '@/utils/helpers';
import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.SET_META_TAG](state, item) {
    state.metaTag = item.value;
    state.count = item.visitors;
    state.key = item.key;
    state.affinityScore = item.affinity_score || 1;
  },

  [types.SET_META_TAG_VISITORS](state, value) {
    state.count = value;
  },

  [types.SET_AVAILABLE_FIELDS](state, fields) {
    state.availableFields = fields.map((item) => ({
      value: item,
      text: helpers.cleanUpPrefix(item),
    }));
  },

  [types.UPDATE_META_TAG](state, value) {
    state.metaTag = value;
  },

  [types.SET_FILTERED_META_TAGS](state, data) {
    state.filteredMetaTags.items = data;
  },

  [types.UPDATE_META_TAG_VISITORS](state, { params, visitors }) {
    const record = _.find(state.filteredMetaTags.items, {
      key: params.key,
      value: params.value,
    });
    if (record) {
      record.visitors = visitors;
    }
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
