<template>
  <v-hover v-slot="hoverState">
    <v-card class="grid-card" style="position: relative">
      <div class="card-background">
        <generic-histogram-compare-chart
          class="no-circles"
          :histogram-data="$store.state.monitoring.toolsStats.today.getData([])"
          :histogram-data-compare="
            cardSettings.histogramCompare == 'yesterday'
              ? $store.state.monitoring.toolsStats.yesterday.getData([])
              : $store.state.monitoring.toolsStats.lastWeek.getData([])
          "
          :compare-timeframe="cardSettings.histogramCompare"
          keyField="hour"
          :valueField="cardSettings.metric"
          :size="size"
          utc
        />
      </div>
      <v-slide-x-reverse-transition>
        <v-btn
          v-if="hoverState && hoverState.hover"
          small
          icon
          class="closeButton"
          @click="$emit('close-card')"
        >
          <v-icon small> mdi-delete-forever </v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
      <!-- fontSize -->
      <v-card-text class="textColor--text" :style="fontSize">
        <div class="number">
          <number :data="todaySum" no-styling />
        </div>
        <div class="label">
          {{ $t('monitoring.data.eventTracking.new') }}
          {{ $t(metricLabel) }}
        </div>
        <div class="change" :class="{ 'mt-2': size && size.h > 3 }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <change-indicator
                  :value="comparisonChange"
                  class="tooltip-activator"
                />
              </span>
            </template>
            <span>Based on what comparison had around that time.</span>
          </v-tooltip>
        </div>
      </v-card-text>

      <!-- Card Footer -->
      <v-card
        v-if="cardSettings.audienceId !== null"
        color="infoBar"
        class="card-bar elevation-0"
        dark
      >
        <!-- loading bar -->
        <div class="card-progress-container">
          <v-progress-linear
            v-show="!$store.state.monitoring.toolsStats.today.loaded"
            :height="7"
            color="primary"
            indeterminate
          />
        </div>
        <v-card-text style="padding-left: 16px" class="white--text">
          <v-row>
            <v-col cols="6" class="text-md-left">
              <span>
                {{ $t('monitoring.data.audienceGrowth.yesterday') }}
                {{ yesterdaySum | formatNumber }}
              </span>
              <change-indicator :value="yesterdayProgression" />
            </v-col>
            <v-col cols="6" class="text-md-left">
              <span>
                {{ $t('monitoring.data.audienceGrowth.lastWeek') }}
                {{ lastWeekSum | formatNumber }}
              </span>
              <change-indicator :value="lastWeekProgression" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <options-menu right small :min-width="240">
        <v-card>
          <v-card-text>
            <h3 class="text-h6 mb-2">
              {{ $t('monitoring.headers.settings') }}
            </h3>
            <v-select
              v-model="cardSettings.histogramCompare"
              :items="timeframeOptions"
              :label="$t('monitoring.date.today') + ' vs.'"
              :hide-details="true"
              class="selectBox"
              @change="$emit('card-changed')"
            />
            <br />
            <v-select
              v-model="cardSettings.metric"
              :items="metricOptions"
              label="Metric"
              :hide-details="true"
              class="selectBox"
              @change="$emit('card-changed')"
            />
          </v-card-text>
        </v-card>
      </options-menu>
    </v-card>
  </v-hover>
</template>

<script>
import OptionsMenu from './OptionsMenu.vue';
import ChangeIndicator from './ChangeIndicator.vue';
import ResizableCardVue from './ResizableCard.vue';
import NumberComponent from './Number.vue';
import GenericHistogramCompareChart from './GenericHistogramCompareChart.vue';

export default {
  components: {
    Number: NumberComponent,
    GenericHistogramCompareChart,
    ChangeIndicator,
    OptionsMenu,
  },
  extends: ResizableCardVue,
  data() {
    return {
      data: null,
      timeframeOptions: ['yesterday', 'last_week'].map((value) => ({
        value,
        text: this.$t(`monitoring.date.${value}`),
      })),
      metricOptions: ['votes', 'views'].map((value) => ({
        value,
        text: this.$t(this.getMetricLabel(value)),
      })),
    };
  },

  mounted() {
    this.addActiveResources();
  },
  destroyed() {
    this.removeActiveResources();
  },

  computed: {
    fontSize() {
      if (this.size && !this.cardSettings.enablePages) {
        return {
          fontSize: `${10 + this.size.h * 2}px`,
        };
      }
      return {
        fontSize: '16px',
      };
    },

    metricLabel() {
      return this.getMetricLabel(this.cardSettings.metric);
    },

    todaySum() {
      return this.$store.state.monitoring.toolsStats.today
        .getData([])
        .reduce((sum, element) => sum + element[this.cardSettings.metric], 0);
    },
    yesterdaySum() {
      return this.$store.state.monitoring.toolsStats.yesterday
        .getData([])
        .reduce((sum, element) => sum + element[this.cardSettings.metric], 0);
    },
    lastWeekSum() {
      return this.$store.state.monitoring.toolsStats.lastWeek
        .getData([])
        .reduce((sum, element) => sum + element[this.cardSettings.metric], 0);
    },
    yesterdayProgression() {
      return this.todaySum / this.yesterdaySum - 1;
    },
    lastWeekProgression() {
      return this.todaySum / this.lastWeekSum - 1;
    },
    comparisonChange() {
      const now = new Date();

      let relevantItems = (this.cardSettings.histogramCompare == 'yesterday'
        ? this.$store.state.monitoring.toolsStats.yesterday
        : this.$store.state.monitoring.toolsStats.lastWeek
      )
        .getData([])
        .filter(
          (item) => new Date(item.hour).getUTCHours() <= now.getUTCHours()
        );
      if (!relevantItems.length) {
        return Number.POSITIVE_INFINITY;
      }

      const sum = relevantItems.reduce(
        (sum, element) => sum + element[this.cardSettings.metric],
        0
      );

      // need to calculate futureValues to subtract in case our is not full
      const lastItem = relevantItems[relevantItems.length - 1];
      const fraction =
        1 - (now - new Date(now.getTime()).setUTCMinutes(0, 0, 0)) / 3600000;
      const futureValues = Math.round(
        lastItem[this.cardSettings.metric] * fraction
      );

      return this.todaySum / (sum - futureValues) - 1;
    },
  },

  methods: {
    /**
     * initialize all resource needed for this card
     * @returns {void}
     */
    addActiveResources() {
      this.$store.dispatch('monitoring/addActiveResource', {
        name: 'toolsStats.today',
        ownerId: this.cardSettings.i,
        shared: true,
      });
      this.$store.dispatch('monitoring/addActiveResource', {
        name: 'toolsStats.yesterday',
        ownerId: this.cardSettings.i,
        shared: true,
      });
      this.$store.dispatch('monitoring/addActiveResource', {
        name: 'toolsStats.lastWeek',
        ownerId: this.cardSettings.i,
        shared: true,
      });
    },

    /**
     * Clear all active resources of this card
     * @returns {void}
     */
    removeActiveResources() {
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'toolsStats.today',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'toolsStats.yesterday',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'toolsStats.lastWeek',
        ownerId: this.cardSettings.i,
      });
    },

    getMetricLabel(metric) {
      switch (metric) {
        case 'views':
          return 'monitoring.data.views';
        case 'votes':
          return 'monitoring.data.votes';
        default:
          console.warn('unknown metric');
          return '';
      }
    },
  },
};
</script>

<style scoped>
.number {
  font-size: 2.8em;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: 'Roboto', sans-serif;
}
.label {
  font-size: 1em;
  font-weight: 400;
  display: flex;
}
.card-background {
  position: absolute;
  width: 100%;
}
.progress {
  z-index: 1;
  pointer-events: none;
}
.progressIcon {
  margin-right: -5px;
}
.closeButton.closeButton {
  position: absolute;
  margin: 0;
  right: 1.4em;
}
.progressionBar {
  font-size: 13px;
  height: 24px;
  font-weight: bold;
}
.card-bar .col {
  display: flex;
  flex-wrap: wrap;
}
.card-bar .col > span {
  white-space: nowrap;
}
.card-bar .change-indicator {
  font-weight: 400;
  font-size: 11px;
}
.tooltip-activator {
  z-index: 8;
  position: relative;
  cursor: help;
}
</style>
