<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-switch
          v-model="isAllExperienceActive"
          :label="$t('components.ads.inventory.type.allExperiences')"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isAllExperienceActive">
      <v-col class="align-center justify-space-between" cols="12">
        <v-autocomplete
          v-model="selectedQuestions"
          :label="$t('components.ads.inventory.assignQuestions')"
          :items="questions"
          :search-input.sync="searchedQuestion"
          cache-items
          :item-text="getQuestionSelectionItemText"
          item-value="id"
          multiple
          chips
          deletable-chips
          :menu-props="menuProps"
          :loading="loading || loadingElements"
        >
          <template #item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item" />
            </template>
            <template v-else>
              <v-list-item-content :disabled="data.item.disabled">
                <v-list-item-title>
                  <v-row>
                    <v-col sm="2" style="text-align: center">
                      {{ data.item.element_id }}
                    </v-col>
                    <v-col sm="10">
                      {{ data.item.question }}
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
              <i>{{
                data.item.disabled
                  ? $t('components.ads.inventory.disabledQuestion')
                  : ''
              }}</i>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="align-center justify-space-between" cols="12">
        <v-autocomplete
          v-model="selectedAnswers"
          :disabled="beforeVoteTimingSet"
          :label="$t('components.ads.inventory.assignAnswers')"
          :items="answers"
          :search-input.sync="searchedAnswer"
          cache-items
          item-text="answer"
          item-value="id"
          multiple
          chips
          deletable-chips
          :menu-props="menuProps"
          :loading="loading || loadingElements"
        >
          <template #item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item" />
            </template>
            <template v-else>
              <v-list-item-content :disabled="data.item.disabled">
                <v-list-item-title>
                  {{ data.item.answer }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.question }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <i>{{
                data.item.disabled
                  ? $t('components.ads.inventory.disabledAnswer')
                  : ''
              }}</i>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <template v-if="ptQElements && ptQElements.length > 0">
        <v-col class="align-center justify-space-between" cols="12">
          <v-tooltip bottom :disabled="ad.display != 'overlay'">
            <template #activator="{ on }">
              <div v-on="on">
                <v-autocomplete
                  v-model="selectedElementCovers"
                  :disabled="ad.display == 'overlay'"
                  :label="$t('components.ads.inventory.assignCovers')"
                  :items="ptQElements"
                  :search-input.sync="searchedElementCover"
                  cache-items
                  :item-text="getElementSelectionItemText"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  :menu-props="menuProps"
                  :loading="loading || loadingElements"
                >
                  <template #item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row>
                            <v-col sm="2" style="text-align: center">
                              {{ data.item.id }}
                            </v-col>
                            <v-col sm="10">
                              {{ data.item.name }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
            </template>
            <span>
              {{
                $t('components.ads.inventory.disabledBecausePosition', {
                  positionText: getSelectedPositionText,
                })
              }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col class="align-center justify-space-between" cols="12">
          <v-tooltip bottom :disabled="ad.display != 'overlay'">
            <template #activator="{ on }">
              <div v-on="on">
                <v-autocomplete
                  v-model="selectedElementResults"
                  :disabled="ad.display == 'overlay'"
                  :label="$t('components.ads.inventory.assignResults')"
                  :items="ptQElements"
                  :search-input.sync="searchedElementResult"
                  cache-items
                  :item-text="getElementSelectionItemText"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  :menu-props="menuProps"
                  :loading="loading || loadingElements"
                >
                  <template #item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row>
                            <v-col sm="2" style="text-align: center">
                              {{ data.item.id }}
                            </v-col>
                            <v-col sm="10">
                              {{ data.item.name }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
            </template>
            <span>
              {{
                $t('components.ads.inventory.disabledBecausePosition', {
                  positionText: getSelectedPositionText,
                })
              }}
            </span>
          </v-tooltip>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="isAllExperienceActive">
      <ad-all-experiences
        :loading="loading"
        :menu-props="menuProps"
        :ad-loaded="adLoaded"
      />
      <v-col cols="12">
        <v-alert outlined color="primary" icon="mdi-exclamation-thick">
          {{ $t('components.ads.inventory.defaultAdLimitHint') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import constants from '@/utils/constants';
import AdAllExperiences from './AdAllExperiences.vue';

export default {
  components: { AdAllExperiences },
  props: {
    /**
     * Current Loading indicator
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    adLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingQuestions: false,
      loadingElements: false,
      searchedQuestion: null,
      searchedAnswer: null,
      searchedElementCover: null,
      searchedElementResult: null,
      awaitingSearch: false,
      menuProps: {
        closeOnClick: false,
        closeOnContentClick: true,
        openOnClick: false,
        maxHeight: 300,
      },
    };
  },
  computed: {
    ...mapState('ad', [
      'elements',
      'questions',
      'answers',
      'adAllExperienceActive',
    ]),
    ...mapState('ad', ['ad']),
    getSelectedPositionText() {
      const adPositions = Object.values(
        this.$t('components.ads.settings.display.option')
      );
      return adPositions.find((position) => position.value == this.ad.display)
        .text;
    },
    selectedQuestions: {
      get() {
        return this.$store.state.ad.ad.questions;
      },
      set(values) {
        this.disableAnswers(
          values,
          this.$store.state.ad.ad.questions
            ? this.$store.state.ad.ad.questions
            : []
        );
        this.$store.commit('ad/SET_AD_QUESTION_MAPPING', values);
      },
    },
    selectedAnswers: {
      get() {
        return this.$store.state.ad.ad.answers;
      },
      set(values) {
        this.disableQuestions(
          values,
          this.$store.state.ad.ad.answers ? this.$store.state.ad.ad.answers : []
        );
        this.$store.commit('ad/SET_AD_ANSWER_MAPPING', values);
      },
    },
    selectedElementCovers: {
      get() {
        return this.$store.state.ad.ad.covers;
      },
      set(values) {
        this.$store.commit('ad/SET_AD_COVER_MAPPING', values);
      },
    },
    selectedElementResults: {
      get() {
        return this.$store.state.ad.ad.results;
      },
      set(values) {
        this.$store.commit('ad/SET_AD_RESULT_MAPPING', values);
      },
    },
    isAllExperienceActive: {
      get() {
        return this.adAllExperienceActive;
      },
      set(value) {
        this.$store.commit('ad/SET_AD_EXPERIENCES_ACTIVE', value);
      },
    },
    beforeVoteTimingSet: {
      get() {
        return this.$store.state.ad.ad.display_timing === 'before_vote';
      },
    },
    ptQElements() {
      return this.elements.filter(
        (element) =>
          element.type_id == constants.ELEMENT_TYPE.QUIZ ||
          element.type_id == constants.ELEMENT_TYPE.PERSONALITY_TEST
      );
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler() {
        this.setShowAllExperienceToggleToDefault();
      },
    },
    async searchedQuestion(val) {
      this.searchForElement(val, this.selectedQuestion, {
        question: this.searchedQuestion,
      });
    },
    async searchedElementCover(val) {
      this.searchForElement(val, this.selectedElementCovers, {
        element: this.searchedElementCover,
      });
    },
    async searchedElementResult(val) {
      this.searchForElement(val, this.selectedElementResults, {
        element: this.searchedElementResult,
      });
    },
    async searchedAnswer(val) {
      this.searchForElement(val, this.selectedAnswer, {
        answer: this.searchedAnswer,
      });
    },
    ad: {
      immediate: true,
      deep: true,
      handler(changedAd) {
        // only init if there is an id in the ad object
        if (changedAd.id) {
          this.init();
        }
      },
    },
    beforeVoteTimingSet: {
      immediate: true,
      handler() {
        if (this.beforeVoteTimingSet) {
          this.selectedAnswers = [];
        }
      },
    },
  },
  mounted() {
    this.getElementsOfUser();
    this.$bus.listen(
      'dialog-open-state-updated',
      this.setShowAllExperienceToggleToDefault
    );
    // this.$store.dispatch('ad/getOrganisationsOfUser');
  },
  destroyed() {
    this.$bus.remove(
      'dialog-open-state-updated ',
      this.setShowAllExperienceToggleToDefault
    );
  },
  methods: {
    setShowAllExperienceToggleToDefault() {
      this.isAllExperienceActive = !!(
        this.$store.state.ad?.ad?.adExperiences &&
        this.$store.state.ad?.ad?.adExperiences.length > 0
      );
    },
    async searchForElement(newValue, selectedValue, searchObject) {
      if (newValue && newValue !== selectedValue && !this.awaitingSearch) {
        this.awaitingSearch = true;
        await this.getElementsOfUserDebounced(searchObject);
        this.awaitingSearch = false;
      }
    },
    getQuestionSelectionItemText(item) {
      return `${item.element_id} ${item.question}`;
    },
    getElementSelectionItemText(item) {
      return `${item.id} ${item.name}`;
    },

    getElementsOfUserDebounced: _.debounce(function search(searchObject) {
      this.getElementsOfUser(searchObject);
    }, 200),
    /**
     * Get questions of user filtered by given question or answer search
     *
     * @param {string} question - question search string
     * @param {string} answer - answer search string
     *
     * @returns {void}
     */
    async getElementsOfUser({
      element = null,
      question = null,
      answer = null,
    } = {}) {
      this.loadingElements = true;
      try {
        await this.$store.dispatch('ad/getElementsOfUser', {
          element,
          question,
          answer,
        });
      } catch (err) {
        console.warn(err);
      }
      this.loadingElements = false;
    },
    init() {
      this.disableAnswers(this.$store.state.ad.ad.questions);
      this.disableQuestions(this.$store.state.ad.ad.answers);
    },
    disableAnswers(newValues, oldValues = []) {
      const diff =
        newValues.length > oldValues.length
          ? _.difference(newValues, oldValues)
          : _.difference(oldValues, newValues);
      this.$store.commit('ad/DISABLE_ANSWERS', diff);
    },
    disableQuestions(newValues, oldValues = []) {
      const diff =
        newValues.length > oldValues.length
          ? _.difference(newValues, oldValues)
          : _.difference(oldValues, newValues);
      const helpDiff = [];
      for (let i = 0; i < diff.length; i += 1) {
        for (let j = 0; j < this.questions.length; j += 1) {
          const answer = _.find(this.questions[j].answers, ['id', diff[i]]);
          let allowDisabling = true;
          if (answer) {
            let helpValues = [];
            helpDiff.push(diff[i]);
            if (newValues.length > 1 && oldValues.length === 0) {
              helpValues = _.difference(newValues, helpDiff);
            } else {
              helpValues =
                newValues.length > oldValues.length ? oldValues : newValues;
            }

            _.forEach(helpValues, (answerId) => {
              const answerObj = _.find(this.answers, ['id', answerId]);
              if (answerObj && answerObj.questionId === answer.question_id) {
                allowDisabling = false;
              }
            });
            const params = {
              answer,
              newValues,
              allowDisabling,
            };
            this.$store.commit('ad/DISABLE_QUESTIONS', params);
          }
        }
      }
    },
  },
};
</script>
