<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedForms.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedForms.info') }}</span>
      <v-list>
        <v-list-item v-for="form in tracking.assignedForms" :key="form.id">
          <v-list-item-action>
            <v-icon color="info"> mdi-calendar-account </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ form.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
