<template>
  <v-row>
    <tracking-detail />
    <tracking-dialog />
    <pixel-dialog />
  </v-row>
</template>

<script>
import TrackingDetail from '@/components/Tracking/Detail';
import TrackingDialog from '@/components/Tracking/Dialog';
import PixelDialog from '@/components/Pixels/PixelDialog';

export default {
  components: {
    TrackingDetail,
    TrackingDialog,
    PixelDialog,
  },
  data: () => ({}),
};
</script>

<style></style>
