var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"distribution-bar"},[_c('div',{class:{ distribution: true, 'elevation-1': _vm.data && _vm.data.length }},_vm._l((_vm.data),function(item,index){return _c('v-tooltip',{key:item.key,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"activator",class:{
            segment: true,
            small: item.doc_count / _vm.total < 0.14,
            tiny: item.doc_count / _vm.total < 0.05,
            micro: item.doc_count / _vm.total < 0.01,
          },style:({
            width: (item.doc_count / _vm.total) * 100 + '%',
            'background-color': _vm.colorMap ? _vm.colorMap[item.key] : _vm.colors[index],
          })},'div',attrs,false),on),[(_vm.translate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.translate + '.' + item.key))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.key)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.translate ? _vm.$t(_vm.translate + '.' + item.key) : item.key)+": "+_vm._s(_vm._f("formatPercentage")((item.doc_count / _vm.total))))])])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }