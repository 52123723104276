<template>
  <div>
    <span>{{ $t('components.addImageDialog.static.pixabay.title') }}</span>
    <v-text-field
      v-model="pixabay.search"
      :label="$t('components.addImageDialog.static.pixabay.search')"
      prepend-icon="mdi-image-multiple"
      @focus="$event.target.select()"
    />
    <v-container v-if="active && items.length" fluid>
      <v-row>
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="6"
          sm="4"
          md="3"
          lg="3"
          xl="3"
        >
          <v-card flat tile>
            <v-img
              :src="item.previewURL"
              :alt="item.tags"
              class="image"
              aspect-ratio="1"
              @click="selectFile(item.downloadURL)"
            />
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="3" xl="3" class="center-items">
          <v-btn color="primary" @click="loadMore">
            {{ $t('components.addImageDialog.more') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      pixabay: {
        library: 'pixabay',
        search: '',
        page: 1,
      },
    };
  },
  computed: {
    ...mapState('image', {
      items: (state) => state.items.pixabay,
    }),
  },
  watch: {
    'pixabay.search': function search() {
      this.searchPicDebounced();
    },
    active() {
      if (!this.active) {
        this.pixabay.search = '';
      }
    },
  },
  methods: {
    searchPicDebounced: _.debounce(function search() {
      this.searchPic();
    }, 500),
    async searchPic() {
      if (this.pixabay.search) {
        this.pixabay.page = 1;
        this.$emit('get-items', this.pixabay);
      }
    },
    async loadMore() {
      this.pixabay.page += 1;
      this.$emit('get-items', this.pixabay);
    },
    selectFile(url) {
      // set selected file to preview
      const data = {
        imageUrl: url,
        isBase64: false,
      };
      this.$store.commit('image/SELECT_ITEM', data);
    },
  },
};
</script>
<style scoped>
.image {
  cursor: pointer;
}
.center-items {
  align-items: center;
  justify-content: center;
}
</style>
