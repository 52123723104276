export const menu = [
  // Category: Analytics
  {
    type: 'header',
    name: 'analytics',
    label: 'analytics',
    permissions: ['viewModuleAnalytics'],
    product: 'analytics',
  },
  {
    type: 'item',
    name: 'analyticsRealtime',
    label: 'analyticsRealtime',
    icon: 'mdi-timelapse',
    permissions: ['viewRealtime'],
    product: 'analytics',
  },
  {
    type: 'item',
    name: 'analyticsInsightsMenu',
    label: 'analyticsInsights',
    icon: 'mdi-chart-timeline-variant',
    permissions: ['viewInsights'],
    product: 'analytics',
    items: [
      {
        type: 'item',
        name: 'analyticsInsightsContent',
        label: 'analyticsInsightsContent',
        permissions: ['viewInsights'],
        product: 'analytics',
      },
      {
        type: 'item',
        name: 'analyticsInsightsAudiences',
        label: 'analyticsInsightsAudiences',
        permissions: ['viewInsights'],
        product: 'analytics',
      },
    ],
  },

  // Category: DMP
  {
    type: 'header',
    name: 'marketing',
    label: 'dmp',
    permissions: ['viewModuleDMP'],
    product: 'dmp',
  },

  // Group: Audiences
  {
    type: 'item',
    name: 'audiences',
    icon: 'mdi-account-supervisor-circle',
    label: 'audiences',
    permissions: ['viewAudiences'],
    product: 'dmp',
    items: [
      {
        type: 'item',
        name: 'showAudiences',
        label: 'showAudiences',
        permissions: ['viewAudiences'],
        product: 'dmp',
      },
      {
        type: 'item',
        name: 'manageAudiences',
        label: 'manageAudiences',
        permissions: ['crudAudiences'],
        product: 'dmp',
      },
      {
        type: 'item',
        name: 'defineAudiences',
        label: 'defineAudiences',
        permissions: ['defineAudiences'],
        product: 'dmp',
        items: [
          {
            type: 'item',
            name: 'surfBehaviour',
            label: 'surfBehaviour',
            permissions: ['viewSurfBehaviour'],
            product: 'dmp',
          },
          {
            type: 'item',
            name: 'questionData',
            label: 'questionData',
            permissions: ['viewPolls'],
            product: 'dmp',
          },
          {
            type: 'item',
            name: 'formData',
            label: 'formData',
            permissions: ['viewForms'],
            product: 'dmp',
          },
        ],
      },
      {
        type: 'item',
        name: 'transferAudiences',
        label: 'transferAudiences',
        permissions: ['viewTransferAudiences'],
        product: 'dmp',
      },
    ],
  },

  {
    type: 'header',
    name: 'inspire',
    label: 'inspire',
    permissions: ['aiAudiences'],
    product: 'dmp',
    new: true,
  },
  {
    type: 'item',
    name: 'aiAudiences',
    label: 'aiAudiences',
    icon: 'mdi-book',
    permissions: ['aiAudiences'],
    product: 'dmp',
  },

  {
    type: 'header',
    name: 'settingsSection',
    label: 'settings',
    permissions: ['viewPixels'],
    product: 'dmp',
  },

  // Group: Tracking
  {
    type: 'item',
    name: 'tracking',
    icon: 'mdi-radar',
    permissions: ['viewTrackings'],
    label: 'tracking',
    product: 'dmp',
    items: [
      {
        type: 'item',
        name: 'trackEvents',
        label: 'trackEvents',
        permissions: ['viewTrackings'],
        product: 'dmp',
      },
    ],
  },

  // Group: Pixel
  {
    type: 'item',
    name: 'pixels',
    icon: 'mdi-memory',
    permissions: ['viewPixels'],
    label: 'pixels',
    product: 'dmp',
    items: [
      {
        type: 'item',
        name: 'managePixels',
        label: 'managePixels',
        permissions: ['viewPixels'],
        product: 'dmp',
      },
    ],
  },

  // Category: Ads
  {
    type: 'header',
    name: 'ads',
    label: 'ads',
    permissions: ['viewModuleAds'],
    product: 'ads',
  },

  // Group: Campaigns
  {
    type: 'item',
    name: 'campaigns',
    icon: 'mdi-picture-in-picture-top-right',
    permissions: ['viewAds'],
    label: 'campaigns',
    product: 'ads',
    items: [
      {
        type: 'item',
        name: 'placeAds',
        label: 'placeAds',
        permissions: ['crudAds'],
        product: 'ads',
      },
      {
        type: 'item',
        name: 'recommendContent',
        label: 'recommendContent',
        permissions: ['viewModuleAdmin'],
        product: 'ads',
      }, // for now show only for admin
    ],
  },

  // Category: Admin
  {
    type: 'divider',
    name: 'divider01',
    permissions: ['viewModuleAdmin'],
    product: 'all',
  },
  {
    type: 'header',
    name: 'admin',
    label: 'admin',
    permissions: ['viewModuleAdmin'],
    product: 'all',
  },

  // Group: Manage
  {
    type: 'item',
    name: 'manage',
    icon: 'mdi-database-settings',
    label: 'manage',
    permissions: ['manageSettings'],
    product: 'all',
    items: [
      {
        type: 'item',
        name: 'cronJobs',
        label: 'cronJobs',
        permissions: ['manageCronJobs'],
        product: 'all',
      },
      {
        type: 'item',
        name: 'refreshCache',
        label: 'refreshCache',
        permissions: ['manageCache'],
        product: 'all',
      },
      {
        type: 'item',
        name: 'elasticTasks',
        label: 'elasticTasks',
        permissions: ['manageElasticTasks'],
        product: 'all',
      },
    ],
  },

  // Category: Account
  {
    type: 'divider',
    name: 'divider02',
    permissions: ['viewModuleAccount'],
    product: 'all',
  },
  {
    type: 'header',
    name: 'account',
    label: 'account',
    permissions: ['viewModuleAccount'],
    product: 'all',
  },
  {
    type: 'item',
    name: 'channels',
    label: 'channels',
    icon: 'mdi-playlist-star',
    permissions: ['manageChannels'],
    product: 'analytics',
  },
  {
    type: 'item',
    name: 'settings',
    label: 'settings',
    icon: 'mdi-application-settings',
    permissions: ['editSettings'],
    product: 'all',
  },
];
