<template>
  <v-hover v-slot="hoverState">
    <v-card class="grid-card" style="position: relative">
      <div class="card-background">
        <line-chart
          v-if="showHistogram"
          :data="chartData"
          :height="chartHeight"
          :padding="chartPadding"
          :axis="false"
          :grid-size="size"
          datetime-format="HH:mm"
          base-zero
          timeseries
          compare-timeseries
        />
      </div>
      <v-slide-x-reverse-transition>
        <v-btn
          v-if="hoverState && hoverState.hover"
          small
          icon
          class="closeButton"
          @click="closeCard"
        >
          <v-icon small> mdi-delete-forever </v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
      <div v-if="cardSettings.audienceId !== null">
        <v-card-text :style="fontSize">
          <div class="number">
            <number :data="growthToday" no-styling />
          </div>
          <div class="label">
            {{ $t('monitoring.data.audienceGrowth.new') }}
            <editable-label
              :text="audienceText"
              @text-changed="updateCustomAudienceText"
            />
          </div>
          <div
            v-show="
              $store.state.monitoring.audienceGrowth.today.isLoaded(
                cardSettings.i
              )
            "
            class="progressionBar"
          >
            <v-row>
              <v-col
                cols="6"
                class="text-md-left progress pa-1"
                :style="{ color: getHighlightColor(progressionTodayYesterday) }"
              >
                <v-icon
                  :color="getHighlightColor(progressionTodayYesterday)"
                  class="progressIcon"
                >
                  {{ getProgressionIcon(progressionTodayYesterday) }}
                </v-icon>
                <span class="totalProgressionYesterday">{{
                  progressionTodayYesterday | percent
                }}</span>
              </v-col>
              <v-col
                cols="6"
                class="text-md-left progress pa-1"
                :style="{ color: getHighlightColor(progressionTodayLastWeek) }"
              >
                <v-icon
                  :color="getHighlightColor(progressionTodayLastWeek)"
                  class="progressIcon"
                >
                  {{ getProgressionIcon(progressionTodayLastWeek) }}
                </v-icon>
                <span class="totalProgressionLastWeek">{{
                  progressionTodayLastWeek | percent
                }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div class="card-progress-container">
          <v-progress-linear
            v-show="
              !$store.state.monitoring.audienceGrowth.today.isLoaded(
                cardSettings.i
              )
            "
            color="primary"
            indeterminate
          />
        </div>
        <v-card
          v-if="cardSettings.audienceId !== null"
          color="infoBar"
          class="card-bar elevation-0"
          dark
        >
          <v-card-text style="padding-left: 16px" class="white--text">
            <v-row>
              <v-col cols="6" class="text-md-left">
                <span>
                  {{ $t('monitoring.data.audienceGrowth.yesterday') }}
                </span>
                <span class="totalCountYesterday">
                  {{ growthYesterday | formatNumber }}
                </span>
              </v-col>
              <v-col cols="6" class="text-md-left">
                <span>{{ $t('monitoring.data.audienceGrowth.lastWeek') }}</span>
                <span class="totalCountLastWeek">
                  {{ growthLastWeek | formatNumber }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="text-center">
        <div class="pt-4 pb-2 text-body-2">
          {{ $t('monitoring.no_data') }}
        </div>
        <v-alert
          class="mx-6 mb-5 text-body-2"
          color="info"
          icon="mdi-information"
          outlined
        >
          {{ $t('monitoring.data.audienceGrowth.hint') }}
        </v-alert>
      </div>
      <options-menu left small :min-width="240">
        <audience-growth-settings
          :card-settings="cardSettings"
          @updated="updateCardSettings"
        />
      </options-menu>
    </v-card>
  </v-hover>
</template>

<script>
import { area } from 'billboard.js';
import EditableLabel from '@/components/EditableLabel';
import Number from '@/components/Monitoring/Number';
import LineChart from '@/components/Monitoring/LineChart';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import AudienceGrowthSettings from '@/components/Monitoring/AudienceGrowthSettings';
import ResizableCard from './ResizableCard';
import { paramFunctions } from '../../store/modules/monitoring/resources';

export default {
  components: {
    EditableLabel,
    Number,
    LineChart,
    OptionsMenu,
    AudienceGrowthSettings,
  },
  filters: {
    /* Format number to percentage */
    percent(value) {
      if (value === null || value === 0) {
        return '-';
      }
      if (numeral.locale() === 'en') {
        return numeral(value).format('0.00%');
      }
      return numeral(String(value).replace('.', ',')).format('0.00%');
    },
  },
  extends: ResizableCard,
  computed: {
    /* Data points for the chart */
    chartData() {
      return [
        {
          data: this.fullDayHistogramDataPoints,
          title: this.$t(
            `monitoring.date.${
              this.cardSettings.histogramCompare || 'last_week'
            }`
          ),
          valueField: 'count',
          type: area && area(),
          color: 'rgba(144, 202, 249, 0.6)',
        },
        {
          data: this.dayHistogramDataPoints,
          title: this.$t('monitoring.date.today'),
          valueField: 'count',
        },
      ];
    },
    /* Chart data points for the whole day (yesterday or last week comparison) */
    fullDayHistogramDataPoints() {
      let startDate = null;
      if (this.cardSettings.histogramCompare === 'yesterday') {
        startDate = moment().subtract(1, 'days').startOf('day');
      } else {
        startDate = moment().subtract(7, 'days').startOf('day');
      }

      return this.mergeDataPoints(
        this.getDayHistogramDataPoints(startDate),
        this.$store.state.monitoring.audienceGrowth.histogramCompare.getData(
          this.cardSettings.i,
          []
        )
      );
    },
    /* Chart data points for the current day */
    dayHistogramDataPoints() {
      const startDate = moment().startOf('day');
      const endDate = moment();

      return this.mergeDataPoints(
        this.getDayHistogramDataPoints(startDate, endDate),
        this.$store.state.monitoring.audienceGrowth.histogram.getData(
          this.cardSettings.i,
          []
        )
      );
    },
    /* Calculate chart height */
    chartHeight() {
      if (this.size) {
        return this.size.h * 53 - 30;
      }
      return 130;
    },
    /* Calculate chart padding */
    chartPadding() {
      if (this.size) {
        // prevent number clipping into chart: padding if low height or low width
        return {
          left:
            110 -
            Math.min(2, this.size.h - 3 + Math.max(0, this.size.w - 4)) * 55,
          right: 0,
        };
      }
      return { left: 110, right: 0 };
    },
    /* Calculate correct font size */
    fontSize() {
      if (this.size) {
        return {
          fontSize: `${10 + this.size.h * 2}px`,
        };
      }
      return {
        fontSize: '16px',
      };
    },
    /* Get audience growth of today */
    growthToday() {
      return this.$store.state.monitoring.audienceGrowth.today.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get audience growth of yesterday */
    growthYesterday() {
      return this.$store.state.monitoring.audienceGrowth.yesterday.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get audience growth of last week same day */
    growthLastWeek() {
      return this.$store.state.monitoring.audienceGrowth.lastWeek.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get the progression compared to yesterday */
    progressionTodayYesterday() {
      return this.calculateProgression(this.growthYesterday, this.growthToday);
    },
    /* Get the progression compared to last week */
    progressionTodayLastWeek() {
      return this.calculateProgression(this.growthLastWeek, this.growthToday);
    },
    /* Get the localized audience text based on the id */
    audienceText() {
      const nicknames = this.$store.state.monitoring.settings.nicknames
        .audiences;
      if (nicknames) {
        // find custom audience name in object where all audiences are stored
        const nickname = nicknames.find(
          (elem) => elem.key === this.cardSettings.audienceId
        );
        if (nickname) {
          return nickname.name;
        }
      }

      const localAud = this.$store.state.audience.localizedAudiences;
      if (localAud && this.cardSettings.audienceId) {
        return localAud[this.cardSettings.audienceId]
          ? localAud[this.cardSettings.audienceId].text
          : '';
      }
      return '';
    },
    /* Determine if histogram should be shown or not */
    showHistogram() {
      return (
        this.cardSettings.audienceId !== null &&
        (this.growthToday !== 0 ||
          (this.cardSettings.histogramCompare === 'yesterday' &&
            this.growthYesterday !== 0) ||
          (this.cardSettings.histogramCompare === 'last_week' &&
            this.growthLastWeek !== 0))
      );
    },
  },
  mounted() {
    this.addActiveResources();
  },
  destroyed() {
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'audienceGrowth.today',
      ownerId: this.cardSettings.i,
    });
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'audienceGrowth.yesterday',
      ownerId: this.cardSettings.i,
    });
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'audienceGrowth.lastWeek',
      ownerId: this.cardSettings.i,
    });
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'audienceGrowth.histogram',
      ownerId: this.cardSettings.i,
    });
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'audienceGrowth.histogramCompare',
      ownerId: this.cardSettings.i,
    });
  },
  methods: {
    addActiveResources() {
      if (this.cardSettings.audienceId) {
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'audienceGrowth.today',
          parameters: () => ({
            audienceId: this.cardSettings.audienceId,
          }),
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'audienceGrowth.yesterday',
          parameters: () => ({
            audienceId: this.cardSettings.audienceId,
          }),
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'audienceGrowth.lastWeek',
          parameters: () => ({
            audienceId: this.cardSettings.audienceId,
          }),
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'audienceGrowth.histogram',
          parameters: () => ({
            audienceId: this.cardSettings.audienceId,
          }),
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'audienceGrowth.histogramCompare',
          parameters: () => ({
            audienceId: this.cardSettings.audienceId,
            ...(this.cardSettings.histogramCompare === 'yesterday'
              ? paramFunctions.yesterdayUTC()
              : paramFunctions.lastWeekUTC()),
          }),
          ownerId: this.cardSettings.i,
        });
      }
    },
    /**
     * Get data points for histogram (30 min interval)
     * @param {object} start Start date (moment object)
     * @param {object} end Optional end date (moment object)
     * @returns {object[]} data points
     */
    getDayHistogramDataPoints(start, end = null) {
      let startDate = start;
      const compareData = [];
      for (let index = 0; index < 48; index += 1) {
        compareData.push({ count: 0, key: startDate.local().unix() * 1000 });
        startDate = startDate.add(30, 'minutes');
        // In case a end date is set
        if (end && startDate > end) {
          break;
        }
      }
      return compareData;
    },
    /**
     * Merge one data point array into another and sum up count
     * @param {array} array1 base array
     * @param {array} array2 array to merge
     * @returns {array} new merged data points
     */
    mergeDataPoints(array1, array2) {
      let sum = 0;
      const array2Map = array2.reduce((map, item) => {
        map[moment(item.date).local().unix() * 1000] = item.audienceCount;
        return map;
      }, {});

      return array1.map((point) => {
        const audienceCount = array2Map[point.key];

        if (audienceCount) {
          sum += audienceCount;
        }
        point.count = sum;

        return point;
      });
    },
    /**
     * Update audienceId in card
     * @param {object} cardSettings new settings
     * @param {number} cardSettings.audienceId Id of the audience
     * @param {string} cardSettings.histogramCompare which timeframe we should compare to
     * @returns {void}
     */
    updateCardSettings({ audienceId, histogramCompare }) {
      const previousValue = this.cardSettings.audienceId;
      this.cardSettings.audienceId = audienceId;
      this.cardSettings.histogramCompare = histogramCompare;

      if (!previousValue) {
        this.addActiveResources();
      }

      this.$emit('card-changed');
    },
    /* update custom audience name */
    updateCustomAudienceText(name) {
      this.$store.dispatch('monitoring/addNickname', {
        key: this.cardSettings.audienceId,
        name,
        type: 'audiences',
      });
    },
    /**
     * Get a progession between two values
     * @param {number} start start value
     * @param {number} end end value
     * @returns {number} progression between 0 and 1
     */
    calculateProgression(start, end) {
      if (start > 0) {
        return (end - start) / start;
      }
      if (start === 0 && end === 0) {
        return 0;
      }
      return 1;
    },
    /**
     * Get the highlight color for progression
     * @param {number} percentage Percentage of progression
     * @returns {string} color rgb value
     */
    getHighlightColor(percentage) {
      if (percentage >= 0) {
        return 'rgb(34, 171, 50)';
      }
      return 'rgb(241, 90, 90)';
    },
    /**
     * Get the icon for progression
     * @param {number} percentage Percentage of progression
     * @returns {string} icon name
     */
    getProgressionIcon(percentage) {
      if (percentage >= 0) {
        return 'mdi-arrow-up';
      }
      return 'mdi-arrow-down';
    },
    closeCard() {
      this.$emit('close-card');
    },
  },
};
</script>

<style scoped>
.number {
  font-size: 2.8em;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: 'Roboto', sans-serif;
}
.label {
  font-size: 1em;
  font-weight: 400;
  display: flex;
}
.card-background {
  position: absolute;
  width: 100%;
}
.progress {
  z-index: 1;
  pointer-events: none;
}
.progressIcon {
  font-size: 18px;
}
.closeButton.closeButton {
  position: absolute;
  margin: 0;
  right: 1.4em;
}
.progressionBar {
  font-size: 13px;
  height: 24px;
  font-weight: bold;
}
</style>
