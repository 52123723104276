import Resource from './Resource';

class MonitoringResource extends Resource {
  /**
   * The constructor for the MonitoringResource.
   */
  constructor() {
    super('/v1/monitoring');
  }

  /**
   * Method used to get all events from current user
   *
   * @param {Array} params domain.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getEvents(params) {
    return this.submit('get', 'events', params);
  }

  /**
   * Fetch labels of given domain
   * @param {{domain: string, event: string}} params domain and event.
   * @returns {Promise<string[]>} The result in a promise.
   */
  getLabels(params) {
    return this.submit('get', 'labels', params);
  }
}

export default MonitoringResource;
