/* ============
 * Moment
 * ============
 *
 * MomentJs
 *
 * http://momentjs.com/
 */

import moment from 'moment';

window.moment = moment;
moment.locale(localStorage.language || 'en');
