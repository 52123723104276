/* ============
 * State of the image module
 * ============
 *
 * The initial state of the image module.
 */

export const getInitialState = () => ({
  postData: {
    imageUrl: '',
    isBase64: false,
    ytVideoId: '',
  },
  items: {
    pixabay: [],
    giphy: [],
    youtube: [],
  },
});

export default getInitialState;
