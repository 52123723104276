/**
 * This class is for data which has a "is loaded" state
 * Each instance has following properties: loaded, data
 */
class Loadable {
  /**
   *
   * @param {*} data data
   * @param {Boolean} loaded Optional. Loaded state. Default to false.
   * @param {Object} parameters Optional. Parameters used for loading data. Used for preventing unnecessary request.
   */
  constructor(data, loaded = false, parameters = undefined) {
    if (data !== undefined) {
      this.data = data;
      this.loaded = loaded;
    }
    if (parameters) this.parameters = parameters;
  }

  /**
   * Returns data of Loadable only if loaded
   * @param {*} fallback Optinoal. Value if not loaded
   * @returns {*}
   */
  getData(fallback = null) {
    if (this.loaded) return this.data;
    return fallback;
  }
}

export default Loadable;
