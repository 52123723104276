<template>
  <v-expansion-panels v-model="expandedIndex" flat hover accordion>
    <v-expansion-panel
      v-for="(audience, index) in data"
      :key="audience.id"
      readonly
      ripple
      class="audience-tree-styling"
      @click.stop="clicked(audience)"
    >
      <div class="top-border" />
      <v-expansion-panel-header
        :hide-actions="!audience.children"
        :class="{ blue: selected.includes(audience.id) }"
        class="lighten-4"
      >
        <div
          :class="{ expanded: expandedIndex === index }"
          class="d-flex align-center"
        >
          <div :class="`pl-${4 * level}`">
            {{ audience.text }}
          </div>
          <v-btn
            v-if="audience.children"
            text
            icon
            class="inline-btn ligthen-4"
            :color="parentsOfSelected.includes(audience.id) ? 'blue' : ''"
            @mousedown.stop
            @click.stop.prevent="expand(audience, index)"
          >
            <v-icon class="icon">
              {{
                `mdi-numeric-${
                  audience.children.length > 9
                    ? '9-plus'
                    : audience.children.length
                }-box-multiple-outline`
              }}
            </v-icon>
          </v-btn>
        </div>
        <template #actions>
          <v-icon @click.stop.prevent="expand(audience, index)">
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <audience-tree-level
          v-if="audience.children"
          :class="{ expandable: true, expanded: !!audience.expanded }"
          :data="audience.children"
          :selected="selected"
          :parents-of-selected="parentsOfSelected"
          :level="level + 1"
        />
        <span else />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import AddAudienceFilterMethod from '@/mixins/monitoring/AddAudienceFilterMethod';

export default {
  name: 'AudienceTreeLevel',
  mixins: [AddAudienceFilterMethod],
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    parentsOfSelected: {
      type: Array,
      required: false,
      default: () => [],
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      expandedIndex: -1,
    };
  },

  methods: {
    expand(audience, index) {
      if (this.expandedIndex !== index) this.expandedIndex = index;
      else this.expandedIndex = -1;
    },
    clicked(audience) {
      if (this.selected.includes(audience.id))
        this.removeAudienceFilter(audience);
      else this.addAudienceFilter(audience);
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-btn {
  /* prevent btn to increase rowsize*/
  margin: -1em 0;

  .icon {
    font-size: 1.5em;
  }
}
.top-border {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

// apply different border color in dark mode
.theme--dark .top-border {
  border-top-color: rgba(255, 255, 255, 0.12);
}
</style>
<style lang="scss">
.audience-tree-styling {
  // remove top right and left radius on first child
  .v-expansion-panels > *:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  // remove padding in content of expansion panel
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
