/* ============
 * Mutation types for the pixel module
 * ============
 *
 * The mutation types that are available
 * on the pixel module.
 */

export const SET_PIXELS = 'SET_PIXELS';
export const SET_FILTERED_PIXELS = 'SET_FILTERED_PIXELS';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_PIXELS,
  SET_FILTERED_PIXELS,
  RESET_STATE,
};
