/* ============
 * Campaign Transformer
 * ============
 *
 * The transformer for the campaign.
 */

import Transformer from './Transformer';

export default class CampaignTransformer extends Transformer {
  /**
   * Method used to transform a fetched campaign.
   *
   * @param {Object} campaign The fetched campaign.
   *
   * @returns {Object} The transformed campaign.
   */
  static fetch(campaign) {
    return {
      ...campaign,
      autoAssignment: campaign.auto_assignment,
      deviceType: campaign.device_type,
      domainId: campaign.domain_id,
      endDate: campaign.end_date,
      startDate: campaign.start_date,
    };
  }

  /**
   * Method used to transform a send campaign.
   *
   * @param {Object} campaign The campaign to be send.
   *
   * @returns {Object} The transformed campaign.
   */
  static send(campaign) {
    return {
      ...campaign,
    };
  }
}
