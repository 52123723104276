/* ============
 * vue-intercom
 * ============
 *
 * An Intercom Javascript API plugin for Vue.js
 *
 * https://github.com/johnnynotsolucky/vue-intercom
 */

import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import constants from '@/utils/constants';

Vue.use(VueIntercom, { appId: constants.INTERCOM_APP_ID });
