<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('components.tracking.stepPixel.title') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepPixel.form.pixels.label')"
              vid="assignedPixels"
              rules=""
            >
              <v-autocomplete
                v-model="assignedPixels"
                :items="pixels"
                :label="$t('components.tracking.stepPixel.form.pixels.label')"
                :hint="$t('components.tracking.stepPixel.form.pixels.hint')"
                :loading="loading"
                item-text="title"
                item-value="id"
                multiple
                chips
                deletable-chips
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepPixel.form.events.label')"
              vid="assignedEvents"
              rules="oneOf:question_view,vote,lead_form_submit,cta_click,vote_click"
            >
              <v-select
                v-model="assignedEvents"
                :items="events"
                :label="$t('components.tracking.stepPixel.form.events.label')"
                :hint="$t('components.tracking.stepPixel.form.events.hint')"
                name="assignedEvents"
                multiple
                chips
                deletable-chips
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col class="justify-space-between" cols="12">
            <v-alert
              outlined
              color="primary"
              icon="mdi-information"
              class="info-box"
            >
              {{ $t('components.tracking.stepPixel.info') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'tracking/getField',
  mutationType: 'tracking/updateField',
});
export default {
  data() {
    return {
      loading: false,
      events: [
        {
          value: 'question_view',
          text: this.$t(
            'components.tracking.stepPixel.form.events.items.questionView'
          ),
        },
        {
          value: 'vote',
          text: this.$t('components.tracking.stepPixel.form.events.items.vote'),
        },
        {
          value: 'lead_form_submit',
          text: this.$t(
            'components.tracking.stepPixel.form.events.items.leadGenerated'
          ),
        },
        {
          value: 'cta_click',
          text: this.$t(
            'components.tracking.stepPixel.form.events.items.ctaClick'
          ),
        },
        {
          value: 'vote_click',
          text: this.$t(
            'components.tracking.stepPixel.form.events.items.vote_click'
          ),
        },
      ],
    };
  },
  computed: {
    ...mapState('tracking', ['currentStep']),
    ...mapState('pixel', ['pixels']),
    ...mapFields(['form.type', 'form.assignedPixels', 'form.assignedEvents']),
  },
  mounted() {
    this.getPixels();
  },
  destroyed() {},
  methods: {
    async getPixels() {
      this.loading = true;
      try {
        await this.$store.dispatch('pixel/getPixels');
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>
