<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span class="snack-message" v-html="snackbar.message" />
    <!-- eslint-enable vue/no-v-html -->
    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar.show = false">
        {{ $t('common.actions.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        timeout: 2000,
        message: '',
      },
    };
  },
  mounted() {
    this.$bus.listen('show-snackbar', this.showSnackbar);
  },
  destroyed() {
    this.$bus.remove('show-snackbar');
  },
  methods: {
    showSnackbar({ message, options = null }) {
      if (options) {
        this.snackbar = {
          show: false,
          ...options,
        };
      } else {
        this.snackbar.color = '';
        this.snackbar.timeout = 2000;
      }

      this.snackbar.message =
        message || this.$t('components.snackbar.text.unexpectedError');
      this.snackbar.show = true;
    },
  },
};
</script>

<style></style>
