<template>
  <v-container fluid>
    <v-card-title>
      <v-spacer />
      <filter-button-menu :menus="menus" @filters-updated="filtersUpdated" />
    </v-card-title>
    <v-card class="mx-3">
      <v-fab-transition>
        <v-btn
          fab
          color="productColor"
          dark
          absolute
          top
          left
          @click="showTransferDialog('create')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="12" sm="4" lg="3">
            <v-combobox
              v-model="search"
              :label="$t('components.transfer.overview.search')"
              multiple
              chips
              clearable
              deletable-chips
              prepend-inner-icon="mdi-magnify"
              append-icon=""
              @update:search-input="searchUpdated"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12">
            <v-data-table
              ref="table"
              :headers="adaptedHeaders"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
              @click:row="rowSelected"
            >
              <template #[`item.start_date`]="{ item }">
                {{ item.start_date | formatDate }}
              </template>
              <template #[`item.end_date`]="{ item }">
                {{ item.end_date | formatDate }}
              </template>
              <template #no-data>
                <v-responsive :aspect-ratio="2.5">
                  <v-container fluid fill-height>
                    <v-layout column align-center justify-center>
                      <h3 class="text-h4">
                        {{ $t('components.datatable.noData') }}
                      </h3>
                      <v-btn
                        class="ma-4"
                        color="productColor"
                        large
                        @click.stop="showTransferDialog('create')"
                      >
                        <span class="white--text">
                          {{
                            $t('components.transfer.overview.buttons.create')
                          }}
                        </span>
                      </v-btn>
                    </v-layout>
                  </v-container>
                </v-responsive>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FilterButtonMenu from '@/components/FilterButtonMenu';

export default {
  components: {
    FilterButtonMenu,
  },
  data() {
    return {
      menus: {
        // TODO: dicuss which filters
        // type: {
        //   selected: false,
        //   name: this.$t('components.transfer.overview.filters.type.label'),
        //   items: [
        //     { text: this.$t('components.transfer.overview.filters.type.remarketing'), value: 'Remarketing', selected: false },
        //     { text: this.$t('components.transfer.overview.filters.type.tracking'), value: 'Tracking', selected: false },
        //   ],
        // },
        // active: {
        //   selected: false,
        //   name: this.$t('components.transfer.overview.filters.state.label'),
        //   items: [
        //     { text: this.$t('components.transfer.overview.filters.state.active'), value: true, selected: false },
        //     { text: this.$t('components.transfer.overview.filters.state.inactive'), value: false, selected: false },
        //   ],
        // },
      },
      toggleButtonModel: [],
      activeFilters: {},
      search: [],
      loading: true,
      options: {
        page: 1,
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      rowsPerPageItems: [5, 10, 25, 50, 100],
      headers: [
        { text: '#', align: 'left', value: 'id' },
        {
          text: this.$t('components.transfer.overview.headers.email'),
          align: 'left',
          value: 'email',
        },
        {
          text: this.$t('components.transfer.overview.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('components.transfer.overview.headers.startDate'),
          value: 'start_date',
        },
        {
          text: this.$t('components.transfer.overview.headers.endDate'),
          value: 'end_date',
        },
      ],
    };
  },
  computed: {
    ...mapState('transfer', {
      items: (state) => state.filteredTransfers.items,
      total: (state) => state.filteredTransfers.total,
    }),
    adaptedHeaders() {
      if (this.$acl.check('viewModuleAdmin')) {
        return this.headers;
      }
      return this.headers.filter((item) => item.value !== 'email');
    },
    /**
     * Search Params
     *
     * @returns {object} searchParams
     */
    searchParams() {
      return {
        search: this.search,
        options: this.options,
        filters: this.activeFilters,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.getDataFromApiDebounced();
      },
      deep: true,
    },
    search() {
      this.pagination.page = 1;
      this.getDataFromApi();
    },
  },
  created() {
    this.$bus.listen('transfer-updated', () => this.getDataFromApi());
  },
  destroyed() {
    this.$bus.remove('transfer-updated');
  },
  methods: {
    getDataFromApiDebounced: _.debounce(function search(text) {
      this.getDataFromApi(text);
    }, 500),
    filtersUpdated({ activeFilters }) {
      this.options.page = 1;
      this.activeFilters = activeFilters;
      this.getDataFromApi();
    },
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getDataFromApiDebounced(searchText);
    },
    async getDataFromApi(searchText = null) {
      // set loading data
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      const params = this.searchParams;
      params.search = search;

      try {
        await this.$store.dispatch('transfer/datatable', params);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    /**
     * Called when row is selected which forwards to detail view
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.$router.push({
        name: 'transferAudiencesDetail',
        params: { id: item.id },
      });
    },
    showTransferDialog(type) {
      this.$bus.fire('show-transfer-dialog', type);
    },
  },
};
</script>
