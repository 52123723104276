var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('components.tracking.stepInventory.title'))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.tracking.stepInventory.form.auto_assignment.label'
              ),"vid":"auto_assignment","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.$t(
                  'components.tracking.stepInventory.form.auto_assignment.label'
                ),"hint":_vm.$t(
                  'components.tracking.stepInventory.form.auto_assignment.hint'
                ),"persistent-hint":"","color":"success","error-messages":errors},model:{value:(_vm.auto_assignment),callback:function ($$v) {_vm.auto_assignment=$$v},expression:"auto_assignment"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.tracking.stepInventory.form.questions.label'),"vid":"assignedQuestions","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.auto_assignment,"items":_vm.questions,"label":_vm.$t('components.tracking.stepInventory.form.questions.label'),"hint":_vm.$t('components.tracking.stepInventory.form.questions.hint'),"loading":_vm.loadingQuestions,"name":"assignedQuestions","item-text":"question","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":"","error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"disabled":data.item.disabled}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.question)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.answers.length)+" "+_vm._s(_vm.$t( 'components.tracking.stepInventory.form.questions.answers' ))+" | "+_vm._s(data.item.votes)+" "+_vm._s(_vm.$t( 'components.tracking.stepInventory.form.questions.votes' ))+" ")])],1)]}}],null,true),model:{value:(_vm.assignedQuestions),callback:function ($$v) {_vm.assignedQuestions=$$v},expression:"assignedQuestions"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.tracking.stepInventory.form.answers.label'),"vid":"assignedAnswers","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.auto_assignment,"items":_vm.questionsWithAnswers,"label":_vm.$t('components.tracking.stepInventory.form.answers.label'),"hint":_vm.$t('components.tracking.stepInventory.form.answers.hint'),"loading":_vm.loadingQuestions,"name":"assignedAnswers","item-text":"answer","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":"","error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"disabled":data.item.disabled}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.answer)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.question)+" | "+_vm._s(data.item.votes)+" "+_vm._s(_vm.$t( 'components.tracking.stepInventory.form.answers.votes' ))+" ")])],1)]}}],null,true),model:{value:(_vm.assignedAnswers),callback:function ($$v) {_vm.assignedAnswers=$$v},expression:"assignedAnswers"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.tracking.stepInventory.form.ads.label'),"vid":"assignedAds","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.auto_assignment,"items":_vm.ads,"label":_vm.$t('components.tracking.stepInventory.form.ads.label'),"hint":_vm.$t('components.tracking.stepInventory.form.ads.hint'),"loading":_vm.loadingAds,"name":"assignedAds","item-text":"name","item-value":"id","multiple":"","chips":"","deletable-chips":"","error-messages":_vm.adBlockerText || errors},model:{value:(_vm.assignedAds),callback:function ($$v) {_vm.assignedAds=$$v},expression:"assignedAds"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.tracking.stepInventory.form.forms.label'),"vid":"assignedForms","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.auto_assignment,"items":_vm.forms,"label":_vm.$t('components.tracking.stepInventory.form.forms.label'),"hint":_vm.$t('components.tracking.stepInventory.form.forms.hint'),"loading":_vm.loadingForms,"name":"assignedForms","item-text":"title","item-value":"id","multiple":"","chips":"","deletable-chips":"","multi-line":"","error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"disabled":data.item.disabled}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.title)+" ")])],1)]}}],null,true),model:{value:(_vm.assignedForms),callback:function ($$v) {_vm.assignedForms=$$v},expression:"assignedForms"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }