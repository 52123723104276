<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedPixels.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedPixels.info') }}</span>
      <v-list two-line>
        <v-list-item
          v-for="pixel in tracking.assignedPixels"
          :key="pixel.id"
          @click="showPixelDialog(pixel.id)"
        >
          <v-list-item-action>
            <v-icon color="info"> mdi-memory </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ pixel.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('components.tracking.assignedPixels.triggered') }}:
              {{ pixel.triggered }}
              - {{ $t('components.tracking.assignedPixels.approved') }}:
              {{
                pixel.approved
                  ? $t('components.tracking.assignedPixels.yes')
                  : $t('components.tracking.assignedPixels.no')
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {
    showPixelDialog(id) {
      this.$bus.fire('show-pixel-dialog', id);
    },
  },
};
</script>

<style></style>
