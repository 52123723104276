<template>
  <v-btn v-if="availableApps.length" icon>
    <v-menu offset-y>
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(app, i) in availableApps" :key="i" :href="app.url">
          <v-icon :color="app.color" class="mr-2">
            {{ app.icon }}
          </v-icon>
          <v-list-item-title>{{ $t(app.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>
<script>
import constants from '@/utils/constants';

export default {
  data() {
    return {
      apps: [
        {
          title: 'common.apps.tools',
          icon: 'mdi-poll-box',
          url: constants.TOOLS_URL,
          color: 'ppTools',
          permission: 'useProductTools',
        },
        {
          title: 'common.apps.dmp',
          icon: 'mdi-chart-donut-variant',
          url: `${constants.DMP_URL}/dmp/audiences/show`,
          color: 'ppDmp',
          permission: 'useProductDmp',
        },
        {
          title: 'common.apps.analytics',
          icon: 'mdi-chart-timeline-variant',
          url: `${constants.ANALYTICS_URL}/analytics/realtime`,
          color: 'ppAnalytics',
          permission: 'useProductAnalytics',
        },
        {
          title: 'common.apps.ads',
          icon: 'mdi-picture-in-picture-top-right-outline',
          url: `${constants.ADS_URL}/ads/campaigns/place-ads`,
          color: 'ppAds',
          permission: 'useProductAds',
        },
      ],
      availableApps: [],
    };
  },
  created() {
    this.$bus.listen('user-logged-in', this.filterAvailableApps);
    this.$bus.listen('user-authenticated', this.filterAvailableApps);
    this.filterAvailableApps();
  },
  destroyed() {
    this.$bus.remove('user-logged-in', this.filterAvailableApps);
    this.$bus.remove('user-authenticated', this.filterAvailableApps);
  },
  methods: {
    /**
     * Sets available apps depending on users permissions
     * @returns {void}
     */
    filterAvailableApps() {
      this.availableApps = this.apps.filter((item) =>
        this.$acl.check(item.permission)
      );
    },
  },
};
</script>
