/* ============
 * Getters for the question module
 * ============
 *
 * The getters that are available on the
 * question module.
 */
import { i18n } from '@/plugins/vue-i18n';

export default {
  questionsWithAnswers(state) {
    const questionsWithAnswers = [];
    state.questions.forEach((question, key) => {
      if (key !== 0) {
        questionsWithAnswers.push({ divider: 'true' });
      }
      questionsWithAnswers.push({ header: question.question });
      question.answers.forEach((answer, index) => {
        questionsWithAnswers.push({
          ...answer,
          answer:
            answer.answer ||
            i18n.t('common.answerMedia.fallback', { id: index + 1 }),
          question: question.question,
          disabled: false,
        });
      });
    });
    return questionsWithAnswers;
  },
};
