<template>
  <v-data-table
    :headers="headers"
    :items="rules"
    hide-default-footer
    :no-data-text="$t('components.audiences.rulesView.datatable.noData')"
    class="elevation-1"
    @click:row="rowSelected"
  >
    <template #[`item.text`]="{ item }">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="item.text" />
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn text icon color="error" @click.stop="removeRule(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import constants from '@/utils/constants';

export default {
  props: {
    rules: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t(
            'components.audiences.rulesView.datatable.headers.rule'
          ),
          value: 'text',
          align: 'left',
        },
        {
          text: this.$t(
            'components.audiences.rulesView.datatable.headers.affinity'
          ),
          value: 'affinityScore',
          align: 'center',
          width: 100,
        },
        {
          text: this.$t(
            'components.audiences.rulesView.datatable.headers.audience'
          ),
          value: 'audience',
          align: 'center',
          width: 120,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    removeRule(id) {
      this.$emit('remove-rule', { id, type: this.type });
    },
    /**
     * Called when row is selected which opens the embed or lead form
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      if (item.pollId) {
        window.open(`${constants.TOOLS_URL}/embed/${item.pollId}`, '_blank');
      }
      if (item.formId) {
        window.open(
          `${constants.TOOLS_URL}/marketing/forms/${item.formId}`,
          '_blank'
        );
      }
    },
  },
};
</script>
