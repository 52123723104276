<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="toolbar" app>
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title class="pl-0">
      <div class="d-flex align-center">
        <router-link :to="{ name: currentDashboardName }">
          <v-img
            :src="currentPinpollLogoPath"
            alt="Pinpoll"
            height="25"
            width="300"
            position="left center"
            class="logo"
            contain
          />
        </router-link>
      </div>
    </v-toolbar-title>
    <toolbar-search v-if="$acl.check('viewUsers')" />
    <v-spacer />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          tile
          icon
          v-bind="attrs"
          :href="constants.HELP_CENTER_URL"
          target="_blank"
          v-on="on"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('components.toolbar.helpCenter') }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn tile icon v-bind="attrs" v-on="on" @click="toggleDarkMode">
          <v-icon>{{ currentDarkModeIcon }}</v-icon>
        </v-btn>
      </template>
      <span>{{
        darkMode
          ? $t('components.toolbar.lightMode')
          : $t('components.toolbar.darkMode')
      }}</span>
    </v-tooltip>

    <app-switch />
    <v-btn icon large>
      <account-menu />
    </v-btn>
  </v-app-bar>
</template>
<script>
import productDetector from '@/mixins/productDetector';
import AccountMenu from '@/components/Account/AccountMenu';
import AppSwitch from '@/components/AppSwitch';
import { UPDATE_DARK_MODE } from '@/store/modules/account/mutation-types';
import ToolbarSearch from '@/components/ToolbarSearch';
import constants from '@/utils/constants';

export default {
  components: { AccountMenu, AppSwitch, ToolbarSearch },
  mixins: [productDetector],
  data() {
    return {
      constants,
    };
  },
  computed: {
    darkMode: {
      get() {
        return this.$store.state.account.displaySettings.showDarkModeBigboard;
      },
      set(status) {
        this.$store.commit(`account/${UPDATE_DARK_MODE}`, status);
        this.$store.dispatch('account/updateDisplaySettings');
      },
    },
    /**
     * Return current icon for toggeling dark mode depending on current mode
     *
     * @returns {string} mdi-icon
     */
    currentDarkModeIcon() {
      return this.darkMode ? 'mdi-brightness-7' : 'mdi-brightness-4';
    },
    /**
     * Return current path to logo depending on dark mode
     *
     * @returns {string} path to logo
     */
    currentPinpollLogoPath() {
      let basePath = '/logo/';

      // change logo color depending on dark mode
      basePath += this.darkMode
        ? `logo-${this.currentProduct}-dark-mode.svg`
        : `logo-${this.currentProduct}-light-mode.svg`;
      return basePath;
    },
  },
  created() {
    this.$bus.listen('user-logged-in', this.redraw);
    this.checkDarkMode();
  },
  destroyed() {
    this.$bus.remove('user-logged-in', this.redraw);
  },
  methods: {
    // redraw toolbar in case admin is logging in as user
    redraw() {
      this.$forceUpdate();
    },
    toggleDrawer() {
      this.$bus.fire('toggle-drawer');
    },
    /**
     * Check dark mode over local storage
     *
     * @returns {void}
     */
    checkDarkMode() {
      if (localStorage && localStorage.getItem('dark-mode')) {
        try {
          const darkMode = JSON.parse(localStorage.getItem('dark-mode'));
          this.setDarkMode(darkMode);
        } catch (err) {
          console.info(
            'Could not parse localStorage item "dark-mode", please reload the page.',
            err
          );
          // remove item from storage to avoid future parsing errors
          localStorage.removeItem('dark-mode');
        }
      }
    },
    /**
     * Toggle dark mode
     *
     * @returns {void}
     */
    toggleDarkMode() {
      this.setDarkMode(!this.darkMode);
    },
    /**
     * Set dark mode property on vuetify and local storage
     *
     * @param {boolean}  darkMode - indicator whether dark mode should be set active or not
     *
     * @returns {void}
     */
    setDarkMode(darkMode) {
      this.darkMode = darkMode;
      this.$vuetify.theme.dark = darkMode;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  /* style image depending on screen width */
  /* iPhone 5 */
  max-width: 125px;

  /* iPhone 6/7/8 */
  @media (min-width: 375px) {
    max-width: 170px;
  }

  /* other */
  @media (min-width: 568px) {
    max-width: 300px;
  }
}
</style>
