<template>
  <visitor-properties-view
    :data="visitorProperties"
    :loaded="audienceLoadable.loaded"
    class="scrollable"
  />
</template>

<script>
import VisitorPropertiesView from '@/components/Monitoring/VisitorPropertiesView';
import { VisitorProperties } from '@/mixins/monitoring/AudienceInterestProperties';

export default {
  components: {
    VisitorPropertiesView,
  },

  mixins: [VisitorProperties],

  computed: {
    audienceLoadable() {
      return this.$store.state.monitoring.realtime.audiences;
    },
  },
};
</script>
