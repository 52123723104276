<template>
  <overview />
</template>

<script>
import Overview from '@/components/Audiences/Overview';

export default {
  components: {
    Overview,
  },
  data: () => ({}),
};
</script>

<style></style>
