<template>
  <v-card>
    <v-card-text>
      <distribution-view
        :data="referrerData"
        :color-map="{
          Search: '#22AB32',
          Social: '#0074D9',
          Direct: '#F0C419',
          Other: '#F15A5A',
          Paid_Search: '#955BA5',
          None: '#C9ADA1',
          Self: '#4D6A6D',
        }"
        order-by="key"
        translate="monitoring.data"
      />
    </v-card-text>
    <v-card color="infoBar" class="card-bar elevation-0" dark>
      <div class="card-progress-container">
        <v-progress-linear
          v-show="!$store.state.monitoring.realtime.referrerTypes.loaded"
          :height="7"
          color="primary"
          indeterminate
        />
      </div>
      <v-card-text class="white--text">
        <v-row>
          <v-col cols="12">
            <span>Top Referrer:</span>
            <span class="ml-1">{{
              $store.state.monitoring.facts.referrer.data.length
                ? $store.state.monitoring.facts.referrer.data[0].key
                : '-'
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import DistributionView from './DistributionView';

export default {
  components: {
    DistributionView,
  },

  computed: {
    referrerData() {
      const sortMap = {
        Self: -1, // hide
        Search: 2,
        Social: 1,
        Direct: 5,
        Other: 6,
        Paid_Search: 7,
      };
      const data = this.$store.state.monitoring.realtime.referrerTypes.data.slice();
      if (data.length < 1) {
        if (this.$store.state.monitoring.realtime.referrerTypes.loaded)
          return [];
        return [{ key: 'None', doc_count: 1 }];
      }
      const filtered = data
        .filter((item) => sortMap[item.key] >= 0)
        .sort((a, b) => sortMap[a.key] - sortMap[b.key]);
      if (filtered.length < 1) return data; // display self if it is only data
      return filtered;
    },
  },
};
</script>
