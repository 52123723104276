/* ============
 * Mutations for the question module
 * ============
 *
 * The mutations that are available on the
 * question module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.SET_QUESTION](state, question) {
    state.question = question;
  },

  [types.SET_QUESTIONS](state, data) {
    state.questions = data.map((el) => ({
      ...el,
      disabled: false,
    }));
  },

  [types.SET_FILTERED_QUESTIONS](state, data) {
    state.filteredQuestions.items = data.data;
    state.filteredQuestions.total = data.total;
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
