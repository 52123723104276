<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels :value="[0, 1, 2]" multiple>
            <v-expansion-panel v-if="showDMP">
              <v-expansion-panel-header hide-actions>
                <span>
                  <v-chip label color="ppDmp" dark>
                    DMP
                    <v-tooltip
                      right
                      max-width="min(400px, 70%)"
                      content-class="mr-2"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          tile
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1"
                          small
                        >
                          <v-icon>mdi-help-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('components.ads.targeting.info.dmp') }}</span>
                    </v-tooltip>
                  </v-chip>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  v-model="audience_ids"
                  :items="userInterests"
                  :label="
                    $t('components.transfer.stepInventory.form.interests.label')
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.interests.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedInterests"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-model="audience_ids"
                  :items="userVisitorProperties"
                  :label="
                    $t(
                      'components.transfer.stepInventory.form.properties.label'
                    )
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.properties.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedProperties"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="showTools">
              <v-expansion-panel-header hide-actions>
                <span>
                  <v-chip label color="ppTools" dark>
                    Tools
                    <v-tooltip
                      right
                      max-width="min(400px, 70%)"
                      content-class="mr-2"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          tile
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1"
                          small
                        >
                          <v-icon>mdi-help-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t('components.ads.targeting.info.tools')
                      }}</span>
                    </v-tooltip>
                  </v-chip>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  v-model="category_ids"
                  :items="categoryAudiences"
                  :label="
                    $t(
                      'components.transfer.stepInventory.form.categories.label'
                    )
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.categories.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedCategories"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header hide-actions>
                <span>
                  <v-chip label color="ppAds" dark>
                    Custom
                    <v-tooltip
                      right
                      max-width="min(400px, 70%)"
                      content-class="mr-2"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          tile
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1"
                          small
                        >
                          <v-icon>mdi-help-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t('components.ads.targeting.info.custom')
                      }}</span>
                    </v-tooltip>
                  </v-chip>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <div
                        v-for="(ctEntry, entryIndex) in Object.entries(
                          typeof custom_targeting === 'string'
                            ? JSON.parse(custom_targeting)
                            : custom_targeting
                        )"
                        :key="'ct-etry-' + entryIndex"
                        class="d-flex align-center"
                      >
                        <v-text-field
                          class="ml-2"
                          required
                          :value="ctEntry[0]"
                          :label="
                            $t(
                              'components.ads.dialog.custom_targeting_entry.key'
                            )
                          "
                          hide-details="auto"
                          @change="editCustomTargeting($event, entryIndex, 0)"
                        />
                        <v-text-field
                          class="ml-2"
                          :value="ctEntry[1]"
                          :label="
                            $t(
                              'components.ads.dialog.custom_targeting_entry.value'
                            )
                          "
                          hide-details="auto"
                          @change="editCustomTargeting($event, entryIndex, 1)"
                        />
                        <v-btn
                          icon
                          color="error"
                          @click="removeCustomTargetingEntry(entryIndex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <v-btn
                        color="productColor"
                        class="mt-2"
                        dark
                        @click="addCustomTargetingEntry"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" v-if="!showDMP">
          <p>
            {{ $t('components.ads.targeting.hintNoDMP') }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('components.ads.dialog.custom_targeting_entry.title')"
      :options="{
        useQuestionWithThis: true,
        textModelName: $t(
          'components.ads.dialog.custom_targeting_entry.thisTitle'
        ),
      }"
      max-width="36em"
      @confirmed="deleteDialogDeferred.resolve(true)"
      @close-dialog="deleteDialogDeferred.resolve(false)"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import helpers, { Deferred } from '@/utils/helpers';
import DialogDelete from '@/components/DialogDelete';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'ad/getField',
  mutationType: 'ad/updateField',
});

export default {
  components: { DialogDelete },
  data() {
    return {
      helpers,
      loading: false,
      loadingAudiences: false,
      showDeleteDialog: false,
      deleteDialogDeferred: null,
    };
  },
  computed: {
    ...mapGetters('audience', [
      'userInterests',
      'userVisitorProperties',
      'categoryAudiences',
    ]),
    ...mapFields([
      'ad.audience_ids',
      'ad.category_ids',
      'ad.id',
      'ad.custom_targeting',
      'adTargetings',
    ]),
    showTools() {
      return this.$acl.check('useProductTools');
    },
    showDMP() {
      return this.$acl.check('viewAudiences');
    },
  },
  mounted() {
    this.getAudiences();
    this.prepareCustomTargeting();
    this.$bus.listen('clear-data', this.clearData);
  },
  destroyed() {
    this.$bus.remove('clear-data', this.clearData);
  },
  methods: {
    async getAudiences() {
      this.loadingAudiences = true;
      await this.$store.dispatch('audience/getAudiences');
      this.loadingAudiences = false;
    },
    clearData() {
      this.audience_ids = [];
      this.category_ids = [];
    },
    prepareCustomTargeting() {
      if (typeof this.custom_targeting === 'string') {
        this.custom_targeting = JSON.parse(this.custom_targeting);
      }
      if (!Object.entries(this.custom_targeting).length) {
        this.addCustomTargetingEntry();
      }
    },
    addCustomTargetingEntry() {
      this.updateCustomTargeting((entries) => {
        entries.push(['', '']);
      }, true);
    },
    async removeCustomTargetingEntry(index) {
      this.deleteDialogDeferred = new Deferred();
      this.showDeleteDialog = true;
      if (await this.deleteDialogDeferred.promise) {
        this.updateCustomTargeting((entries) => {
          entries.splice(index, 1);
        });
      }
      this.showDeleteDialog = false;
    },
    editCustomTargeting(newValue, entryIndex, keyIndex) {
      this.updateCustomTargeting((entries) => {
        entries[entryIndex][keyIndex] = newValue;
      });
    },
    updateCustomTargeting(f) {
      let entries = Object.entries(this.custom_targeting);
      f(entries);
      this.saveEntriesToCustomTargeting(entries);
    },
    saveEntriesToCustomTargeting(entries) {
      this.custom_targeting = entries.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    },
  },
};
</script>
