/* ==================
 * Monitoring Module
 * ==================
 */

export default {
  filterEndDateObject(state) {
    return moment(state.filter.endDate);
  },

  filterStartDateObject(state) {
    return moment(state.filter.startDate);
  },

  filterRangeDuration(state, getters) {
    return moment
      .duration(getters.filterEndDateObject.diff(getters.filterStartDateObject))
      .add(1, 'd');
  },

  insightsUseHourInterval(state, getters) {
    return getters.filterRangeDuration.asDays() < 9;
  },

  showDomains(state) {
    const domainFilter = state.filter.domain;
    return domainFilter && !(domainFilter instanceof Array);
  },
};
