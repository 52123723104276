<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template #activator="{ on }">
      <v-avatar size="32px" v-on="on">
        <v-img :src="avatar" alt="Avatar" />
      </v-avatar>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="avatar" :alt="user ? user.firstname : 'Avatar'" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span>{{ (user && user.firstname) || '' }}</span>
              <span>{{ (user && user.lastname) || '' }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{
              user ? user.email : ''
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item :href="manageLicencesLink">
          <v-list-item-title>
            <v-icon class="mr-2"> mdi-credit-card </v-icon>
            {{ $t('components.accountMenu.subscriptions') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :href="editProfileLink">
          <v-list-item-title class="settings">
            <v-icon class="mr-2"> mdi-cog </v-icon>
            {{ $t('components.accountMenu.edit') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="logout">
          <v-list-item-title>
            <v-icon class="mr-2"> mdi-exit-to-app </v-icon>
            {{ $t('components.accountMenu.logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';
import constants from '@/utils/constants';
import helpers from '@/utils/helpers';
import productDetector from '@/mixins/productDetector';

export default {
  mixins: [productDetector],
  data() {
    return {
      showMenu: false,
      loading: false,
    };
  },
  computed: {
    /**
     * Receive account data from store to access it in this component
     */
    ...mapState('account', ['user']),
    /**
     * The user image avatar
     *
     * @returns {string} avatar
     */
    avatar() {
      return this.user?.image || helpers.getPlaceholderImage();
    },
    /**
     * Returns product store link
     *
     * @returns {string} - product store link
     */
    manageLicencesLink() {
      let storeLink;
      switch (this.currentProduct) {
        case 'analytics':
          if (this.$store.state.account.user?.hasAnalyticsProduct) {
            storeLink = `${constants.ACCOUNT_URL}/subscriptions/my`;
          } else {
            storeLink = `${constants.ACCOUNT_URL}/store/analytics`;
          }
          break;
        case 'dmp':
          if (this.$store.state.account.user?.hasDMPProduct) {
            storeLink = `${constants.ACCOUNT_URL}/subscriptions/my`;
          } else {
            storeLink = `${constants.ACCOUNT_URL}/store/dmp`;
          }
          break;
        case 'ads':
          if (this.$store.state.account.user?.hasToolsProduct) {
            storeLink = `${constants.ACCOUNT_URL}/subscriptions/my`;
          } else {
            storeLink = `${constants.ACCOUNT_URL}/store/tools`;
          }
          break;
        default:
          storeLink = `${constants.ACCOUNT_URL}/store/analytics`;
      }
      return storeLink;
    },
    /**
     * Returns profile link
     *
     * @returns {string} - profile link
     */
    editProfileLink() {
      return `${constants.ACCOUNT_URL}/settings/account`;
    },
  },
  methods: {
    /**
     * Logout
     *
     * @returns {void}
     */
    async logout() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/logout');
      } catch (err) {
        console.error(err);
      }
      this.loading = false;

      // foward to login
      window.location = `${constants.LANDING_URL}/login`;
    },
  },
};
</script>
