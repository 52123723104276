<template>
  <ChannelsView
    :data="$store.state.monitoring.realtime.channels"
    class="scrollable"
  />
</template>

<script>
import ChannelsView from '@/components/Monitoring/ChannelsView';

export default {
  components: {
    ChannelsView,
  },

  mounted() {
    this.$store.dispatch('monitoring/updateSettings', {
      prop: 'useChannels',
      value: true,
    });
    this.$store.dispatch('monitoring/reopen', { sockets: ['/v1/channels'] });
  },
};
</script>
