var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('distribution-view',{attrs:{"data":_vm.referrerData,"color-map":{
    Search: '#22AB32',
    Social: '#0074D9',
    Direct: '#F0C419',
    Other: '#F15A5A',
    Paid_Search: '#955BA5',
    None: '#C9ADA1',
    Self: '#4D6A6D',
  },"order-by":"key","translate":"monitoring.data"}})}
var staticRenderFns = []

export { render, staticRenderFns }