<template>
  <v-container fluid>
    <v-card class="px-2">
      <v-card-title class="text-h5">
        {{ $t('components.cronjobsManagement.title') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(cronJob, key) in cronJobs" :key="key" cols="12" sm="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="subtitle-1">
                      {{ $t('components.cronjobsManagement.names.' + key) }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="info"> mdi-list-status </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span
                      >{{ $t('components.cronjobsManagement.labels.state') }}:
                    </span>
                    <span>
                      <strong>
                        {{
                          cronJob.state
                            ? $t('components.cronjobsManagement.active')
                            : $t('components.cronjobsManagement.stopped')
                        }}
                      </strong>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="info"> mdi-calendar-today </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span
                      >{{
                        $t(
                          'components.cronjobsManagement.labels.lastExecution'
                        )
                      }}:
                    </span>
                    <span
                      ><strong>{{
                        cronJob.lastExecution | formatDateTime
                      }}</strong></span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="info"> mdi-calendar-clock </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span
                      >{{
                        $t(
                          'components.cronjobsManagement.labels.nextExecution'
                        )
                      }}:
                    </span>
                    <span
                      ><strong>{{
                        cronJob.nextExecution | formatDateTime
                      }}</strong></span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="info"> mdi-pencil </v-icon>
                </v-list-item-action>
                <v-list-item-action>
                  <v-row align="center" justify="space-between">
                    <v-col>
                      <v-text-field
                        v-model="cronJob.pattern"
                        :label="
                          $t('components.cronjobsManagement.textbox.label')
                        "
                      />
                    </v-col>
                    <v-col>
                      <v-btn color="info" small outlined @click="setTime(key)">
                        {{ $t('components.cronjobsManagement.buttons.set') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="info">
                    {{ cronJob.state ? 'mdi-stop' : 'mdi-play' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <v-btn
                      v-show="!cronJob.state"
                      color="success"
                      small
                      @click="startJob(key)"
                    >
                      {{ $t('components.cronjobsManagement.buttons.start') }}
                    </v-btn>
                    <v-btn
                      v-show="cronJob.state"
                      color="error"
                      small
                      @click="stopJob(key)"
                    >
                      {{ $t('components.cronjobsManagement.buttons.stop') }}
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-alert outlined color="info" icon="mdi-information">
              Check
              <a
                href="https://github.com/kelektiv/node-cron#available-cron-patterns"
                target="_blank"
              >
                this
              </a>
              for more info about cron patterns.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cronJobs: {},
      params: {},
    };
  },
  computed: {},
  mounted() {
    this.getCronJobs();
  },
  methods: {
    async getCronJobs() {
      try {
        this.cronJobs = await this.$store.dispatch('cronjob/getCronJobs');
      } catch (err) {
        console.error(err);
        this.$bus.fire('show-snackbar', { message: err });
      }
    },
    async manageCronJob(name) {
      try {
        const data = await this.$store.dispatch(
          'cronjob/manageCronJob',
          this.params
        );
        this.cronJobs[name] = data;
      } catch (err) {
        console.error(err);
        this.$bus.fire('show-snackbar', { message: err });
      }
    },
    startJob(name) {
      this.params = {
        name,
        action: 'start',
      };
      this.manageCronJob(name);
    },
    stopJob(name) {
      this.params = {
        name,
        action: 'stop',
      };
      this.manageCronJob(name);
    },
    setTime(name) {
      this.params = {
        name,
        action: 'setTime',
        pattern: this.cronJobs[name].pattern,
      };
      this.manageCronJob(name);
    },
  },
};
</script>

<style></style>
