<template>
  <v-container fluid>
    <v-card class="mx-3 mt-5">
      <v-fab-transition>
        <v-btn
          fab
          color="productColor"
          dark
          absolute
          top
          left
          @click="$bus.fire('show-channel-dialog')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-card-text class="pt-6">
        <v-data-table
          :headers="headers"
          :items="$store.state.channel.channels"
          :loading="loading"
          sort-by="id"
          sort-desc
        >
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | formatDate }}
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn
                text
                icon
                color="error"
                @click.stop="prepareDeleteChannel(item.id, item.title)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template #no-data>
            <v-responsive :aspect-ratio="2.5">
              <v-container fluid fill-height>
                <v-layout column align-center justify-center>
                  <h3 class="text-h4">
                    {{ $t('components.datatable.noData') }}
                  </h3>
                  <v-btn
                    class="ma-4"
                    color="productColor"
                    large
                    @click.stop="$bus.fire('show-channel-dialog')"
                  >
                    <span class="white--text">
                      {{ $t('components.channelTable.createChannel') }}
                    </span>
                  </v-btn>
                </v-layout>
              </v-container>
            </v-responsive>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('common.models.channel')"
      :name="currentChannelName"
      :loading="deletingProgress"
      @confirmed="deleteChannel"
      @close-dialog="setDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import DialogDelete from '@/components/DialogDelete';

export default {
  components: {
    DialogDelete,
  },

  data() {
    return {
      loading: true,
      headers: [
        {
          text: this.$t('components.channelTable.headers.id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$t('components.channelTable.headers.title'),
          align: 'left',
          value: 'title',
        },
        {
          text: this.$t('components.channelTable.headers.path'),
          align: 'left',
          value: 'path',
        },
        {
          text: this.$t('components.channelTable.headers.created'),
          align: 'right',
          value: 'created_at',
        },
        { text: '', value: 'actions', sortable: false },
      ],
      showDeleteDialog: false,
      deletingProgress: false,
      currentChannelName: '',
      currentChannelIdToDelete: null,
    };
  },

  async mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch('channel/getChannels');
      this.loading = false;
    },
    prepareDeleteChannel(id, name) {
      this.currentChannelIdToDelete = id;
      this.currentChannelName = name;
      this.setDeleteDialog(true);
    },
    async deleteChannel() {
      this.deletingProgress = true;
      // post to controller
      try {
        await this.$store.dispatch(
          'channel/deleteChannel',
          this.currentChannelIdToDelete
        );
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelDeleted', {
            model: this.$t('common.models.channel'),
          }),
        });
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }

      // hide delete dialog and reset fields
      this.setDeleteDialog(false);
      this.deletingProgress = false;
      this.currentChannelName = '';
      this.currentChannelIdToDelete = null;
    },
    setDeleteDialog(state = false) {
      this.showDeleteDialog = state;
    },
  },
};
</script>
