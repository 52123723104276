<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedEvents.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedEvents.info') }}</span>
      <v-list>
        <v-list-item v-for="event in tracking.assignedEvents" :key="event.id">
          <v-list-item-action>
            <v-icon color="info"> mdi-star-outline </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('components.tracking.assignedEvents.events.' + event.event)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
