<template>
  <v-app>
    <template v-if="isLoggedIn">
      <pinpoll-navigation-drawer />
      <pinpoll-toolbar />
      <pinpoll-content />
    </template>
    <template v-else>
      <pinpoll-content />
    </template>
  </v-app>
</template>

<script>
import PinpollNavigationDrawer from './components/NavigationDrawer';
import PinpollToolbar from './components/Toolbar';
import PinpollContent from './components/Content';

export default {
  name: 'MasterLayout',
  components: {
    PinpollNavigationDrawer,
    PinpollToolbar,
    PinpollContent,
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.authenticated;
    },
  },
  mounted() {
    // init intercom chat
    this.$intercom.boot();

    // show login view if user is not authenticated
    if (!this.isLoggedIn) {
      this.showLogin();
    }
  },
  methods: {
    /**
     * Forward to login route
     *
     * @returns {void}
     */
    showLogin() {
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>
