<template>
  <v-card style="min-height: 400px">
    <v-card-title class="text-h5">
      {{
        $t('components.audienceAssignMetaTags.headline.keyword', {
          keyword: helpers.cleanUpPrefix(key),
        })
      }}
      <span v-show="count > 0">
        <template v-if="loadingVisitorCount">
          <v-progress-circular indeterminate color="primary" />
        </template>
        <template v-else>
          {{
            $t('components.audienceAssignMetaTags.headline.visitors', {
              visitors: count,
            })
          }}
        </template>
      </span>
    </v-card-title>
    <v-card-text>
      <v-list subheader>
        <v-list-item>
          <v-select
            v-model="logic"
            :loading="loadingVisitorCount"
            :disabled="loadingVisitorCount"
            :items="items"
            :label="$t('components.audienceAssignMetaTags.logic.label')"
          />
        </v-list-item>
        <br />
        <v-list-item>
          <v-text-field
            v-model="metaTag"
            :disabled="loadingVisitorCount"
            :loading="loadingVisitorCount"
          />
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import helpers from '@/utils/helpers';

export default {
  props: {
    metaTagValue: {
      type: String,
      required: false,
      default: '',
    },
    activeFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      helpers,
      items: [
        {
          text: this.$t('components.audienceAssignMetaTags.logic.items.equals'),
          value: 'equals',
        },
        {
          text: this.$t(
            'components.audienceAssignMetaTags.logic.items.beginsWith'
          ),
          value: 'beginsWith',
        },
        {
          text: this.$t(
            'components.audienceAssignMetaTags.logic.items.contains'
          ),
          value: 'contains',
        },
        {
          text: this.$t(
            'components.audienceAssignMetaTags.logic.items.endsWith'
          ),
          value: 'endsWith',
        },
      ],
      logic: 'equals',
      loadingVisitorCount: false,
    };
  },

  computed: {
    ...mapState('metatag', ['count', 'key']),
    metaTag: {
      get() {
        return this.$store.state.metatag.metaTag;
      },
      set: _.debounce(function updateField(value) {
        this.updateMetaTag(value);
      }, 1000),
    },
  },
  watch: {
    logic() {
      this.updateMetaTag();
    },
    metaTagValue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadLogicDebounced();
        }
      },
    },
    activeFilters: {
      immediate: true,
      deep: true,
      handler() {
        this.loadLogicDebounced();
      },
    },
  },
  created() {
    this.$bus.listen('hide-assign-detail-view', this.resetLogic);
  },
  destroyed() {
    this.$bus.remove('hide-assign-detail-view');
  },
  methods: {
    async updateMetaTag(metaTag = this.metaTag) {
      const currentLogic = this.logic;
      this.loadingVisitorCount = true;
      let regexValue = metaTag ? metaTag.replace(/\^|\*|\$/g, '') : '';
      if (currentLogic === 'beginsWith') {
        regexValue = `${regexValue}*`;
      } else if (currentLogic === 'contains') {
        regexValue = `*${regexValue}*`;
      } else if (currentLogic === 'endsWith') {
        regexValue = `*${regexValue}`;
      }
      this.$store.commit('metatag/UPDATE_META_TAG', regexValue);
      try {
        await this.$store.dispatch('metatag/getDistinctVisitors', {
          key: this.key,
          value: regexValue,
          filters: this.activeFilters,
        });
      } catch (err) {
        console.err(err);
      }
      this.loadingVisitorCount = false;
    },
    loadLogicDebounced: _.debounce(function loadLogic() {
      this.loadLogic();
    }, 200),
    loadLogic() {
      let logic = 'equals';
      if (this.metaTagValue.startsWith('*')) {
        logic = 'endsWith';
      } else if (this.metaTagValue.endsWith('*')) {
        logic = 'beginsWith';
      }
      if (
        this.metaTagValue.startsWith('*') &&
        this.metaTagValue.endsWith('*')
      ) {
        logic = 'contains';
      }
      this.logic = logic;
    },
    resetLogic() {
      this.logic = 'equals';
      this.$store.commit('metatag/UPDATE_META_TAG', '');
    },
  },
};
</script>

<style></style>
