var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"align-center d-flex",attrs:{"cols":"12"}},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":_vm.$t('components.ads.settings.name'),"vid":"name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"label":_vm.$t('components.ads.settings.name'),"loading":_vm.loading,"name":"name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('v-switch',{staticClass:"ml-4",attrs:{"label":_vm.$t('components.ads.detail.native')},model:{value:(_vm.native),callback:function ($$v) {_vm.native=$$v},expression:"native"}}),(!_vm.native)?_c('v-switch',{staticClass:"ml-4",attrs:{"label":_vm.$t('components.ads.settings.rotate')},model:{value:(_vm.rotate),callback:function ($$v) {_vm.rotate=$$v},expression:"rotate"}}):_vm._e()],1),(!_vm.native)?_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.$t('components.ads.settings.display.timing.label')))]),_c('v-btn-toggle',{staticClass:"flex-wrap",attrs:{"mandatory":"","group":""},model:{value:(_vm.display_timing),callback:function ($$v) {_vm.display_timing=$$v},expression:"display_timing"}},_vm._l((_vm.displayTimings),function(displayTiming,key){return _c('v-btn',{key:key,attrs:{"value":displayTiming}},[(!_vm.markedAsNew.timings.includes(displayTiming))?[_vm._v(" "+_vm._s(_vm.$t('components.ads.settings.display.timing.' + displayTiming))+" ")]:_c('v-badge',{staticClass:"new-badge",attrs:{"color":"warning","small":"","offset-x":"0.5em","offset-y":"-0.25em","content":_vm.$t('common.actions.new')}},[_vm._v(" "+_vm._s(_vm.$t('components.ads.settings.display.timing.' + displayTiming))+" ")])],2)}),1)],1):_vm._e(),(!_vm.native)?_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.settings.display.label'),"vid":"display","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"hint":_vm.$t(("components.ads.settings.display.option." + _vm.display + ".hint")),"items":_vm.filteredDisplayOptions,"loading":_vm.loading,"label":_vm.$t('components.ads.settings.display.label'),"persistent-hint":"","error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(!_vm.markedAsNew.positions.includes(data.item.value))?[_vm._v(" "+_vm._s(data.item.text)+" ")]:_c('v-badge',{staticClass:"new-badge",attrs:{"color":"warning","offset-x":"-0.25em","content":_vm.$t('common.actions.new')}},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}}],null,true),model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}})]}}],null,false,1150377981)})],1):_vm._e(),(!_vm.delayOptionDisabled)?_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.settings.delay'),"vid":"delay","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.ads.settings.delay'),"suffix":_vm.$t('components.ads.settings.seconds'),"min":1,"loading":_vm.loading,"disabled":_vm.delayOptionDisabled,"value":"2","type":"number","name":"delay","error-messages":errors},model:{value:(_vm.delay),callback:function ($$v) {_vm.delay=$$v},expression:"delay"}})]}}],null,false,3059237570)})],1):_vm._e(),(_vm.display_timing === 'interstitial')?_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.settings.duration'),"vid":"duration","rules":"required|numeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.ads.settings.duration'),"suffix":_vm.$t('components.ads.settings.seconds'),"min":0,"loading":_vm.loading,"value":"4","type":"number","name":"duration","error-messages":errors},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})]}}],null,false,2394974574)})],1):_vm._e(),_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.settings.domain.label'),"vid":"domain_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.extendedDomainsOfUser,"label":_vm.$t('components.ads.settings.domain.label'),"hint":_vm.$t('components.ads.settings.domain.hint'),"name":"domain_id","item-text":"title","item-value":"id","error-messages":errors},model:{value:(_vm.domain_id),callback:function ($$v) {_vm.domain_id=$$v},expression:"domain_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.settings.deviceType.label'),"vid":"device_type","rules":"required|oneOf:all,desktop,mobile,app"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.deviceTypes,"label":_vm.$t('components.ads.settings.deviceType.label'),"hint":_vm.$t('components.ads.settings.deviceType.hint'),"name":"device_type","item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.device_type),callback:function ($$v) {_vm.device_type=$$v},expression:"device_type"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }