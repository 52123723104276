<template>
  <v-card flat>
    <v-card-text>
      <template v-if="loading">
        <v-progress-linear :indeterminate="true" height="2" value="15" />
      </template>
      <v-tabs v-else v-model="currentTab" grow show-arrows>
        <v-tab v-for="tab in tabs" :key="tab" ripple>
          {{ tab }}
        </v-tab>
        <v-tab-item v-for="tabItem in tabItems" :key="tabItem">
          <rules-view
            :rules="rules[tabItem]"
            :type="tabItem"
            @remove-rule="prepareRemoveRule"
          />
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('models.single.rule')"
      :loading="deletingProgress"
      :options="{
        useQuestionWithThis: true,
        textModelName: $t('models.single.ruleThis'),
      }"
      @confirmed="removeRule"
      @close-dialog="setDeleteDialog(false)"
    />
  </v-card>
</template>

<script>
import DialogDelete from '@/components/DialogDelete';
import helpers from '@/utils/helpers';
import RulesView from './RulesView';

export default {
  components: {
    RulesView,
    DialogDelete,
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      showDeleteDialog: false,
      deleteOptions: {},
      deletingProgress: false,
      loading: false,
      removingRule: false,
      currentTab: null,
      tabs: [
        this.$t('components.audiences.assignedRules.tabs.surfBehaviour'),
        this.$t('components.audiences.assignedRules.tabs.questionData'),
        this.$t('components.audiences.assignedRules.tabs.formData'),
        this.$t('components.audiences.assignedRules.tabs.sdkData'),
      ],
      tabItems: ['behaviour', 'question', 'form', 'sdk'],
      rules: {
        behaviour: [],
        question: [],
        form: [],
        sdk: [],
      },
      validationErrors: {},
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getAssignedRules({ id: newVal });
        }
      },
    },
  },
  methods: {
    setDeleteDialog(state = true) {
      this.showDeleteDialog = state;
    },
    prepareRemoveRule(options) {
      this.deleteOptions = options;
      this.setDeleteDialog(true);
    },
    async removeRule() {
      this.deletingProgress = true;
      const { id, type } = this.deleteOptions;

      // prepare post data
      const postData = {
        id,
        type,
      };

      try {
        await this.$store.dispatch('audience/removeRule', postData);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.ruleRemoved'),
        });
        // remove rule
        this.rules[type] = this.rules[type].filter((item) => item.id !== id);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }

      // hide delete dialog
      this.setDeleteDialog(false);
      this.deletingProgress = false;
    },
    async getAssignedRules(params) {
      this.loading = true;
      try {
        const rules = await this.$store.dispatch(
          'audience/getAssignedRules',
          params
        );
        this.rules = this.unifyRules(rules);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }
      this.loading = false;
    },
    unifyRules(rules) {
      const behaviour = rules.assignedSurfBehaviour.map((item) => ({
        id: item.id,
        text: this.$t(
          'components.audiences.assignedRules.rules.textSurfBehaviour',
          { key: helpers.cleanUpPrefix(item.key), value: item.value }
        ),
        affinityScore: item.affinity_score,
        audience: item.audience,
      }));
      const question = rules.assignedQuestionData.map((item) => ({
        id: item.id,
        text: this.$t(
          'components.audiences.assignedRules.rules.textQuestionData',
          {
            answer: item.answer,
            question: item.question,
          }
        ),
        affinityScore: item.affinity_score,
        audience: item.audience,
        pollId: item.poll_id,
      }));
      const form = rules.assignedFormData.map((item) => ({
        id: item.id,
        text: this.$t('components.audiences.assignedRules.rules.textFormData', {
          formName: item.title,
          formData: item.form_data,
          fieldName: item.field_name,
        }),
        affinityScore: item.affinity_score,
        audience: item.audience,
        formId: item.form_id,
      }));

      const sdk = rules.assignedSdkData.map((item) => ({
        id: item.id,
        text: this.$t('components.audiences.assignedRules.rules.textSdkData', {
          startDate: moment(item.created_at).format('ll'),
        }),
        affinityScore: item.affinity_score,
        audience: item.audience,
      }));
      return {
        behaviour,
        question,
        form,
        sdk,
      };
    },
  },
};
</script>

<style></style>
