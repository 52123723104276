var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-3"},[_c('v-fab-transition',[_c('v-btn',{attrs:{"fab":"","color":"productColor","dark":"","absolute":"","top":"","left":""},on:{"click":_vm.showAddPixelDialog}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('v-combobox',{attrs:{"label":_vm.$t('components.searchBox.searchLabel'),"multiple":"","autofocus":"","chips":"","clearable":"","deletable-chips":"","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:search-input":_vm.searchUpdated},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-end",attrs:{"cols":"12","sm":"8","lg":"9"}},[_c('filter-button-menu',{attrs:{"menus":_vm.menus},on:{"filters-updated":_vm.filtersUpdated}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredPixels.items,"options":_vm.options,"server-items-length":_vm.filteredPixels.total,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': _vm.rowsPerPageItems }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35px"}},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.helpers.getPlaceholderImage(),"alt":"Avatar"}})],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelTable.items.type.' + item.type))+" ")]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approved ? _vm.$t('components.pixel.pixelTable.items.approved.yes') : _vm.$t('components.pixel.pixelTable.items.approved.no'))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"no-data",fn:function(){return [_c('v-responsive',{attrs:{"aspect-ratio":2.5}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('h3',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t('components.datatable.noData'))+" ")]),_c('v-btn',{staticClass:"ma-4",attrs:{"color":"productColor","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.showAddPixelDialog($event)}}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('components.pixel.pixelTable.buttons.create'))+" ")])])],1)],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }