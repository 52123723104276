import AudienceOverview from '@/pages/audiences/AudienceOverview';
import AIAudienceOverview from '@/pages/audiences/AIAudienceOverview';
import AudienceAssignQuestion from '@/pages/audiences/AudienceAssignQuestion';
import AudienceAssignBehaviour from '@/pages/audiences/AudienceAssignBehaviour';
import AudienceAssignForm from '@/pages/audiences/AudienceAssignForm';
import AudienceManage from '@/pages/audiences/AudienceManage';
import AudienceTransfer from '@/pages/audiences/AudienceTransfer';
import TransferDetailView from '@/pages/audiences/TransferDetailView';
import AdsOverview from '@/pages/campaigns/AdsOverview';
import TrackEventsOverview from '@/pages/tracking/TrackEventsOverview';
import TrackEventsDetailView from '@/pages/tracking/TrackEventsDetailView';
import PixelsOverview from '@/pages/pixels/PixelsOverview';
import CronJobs from '@/pages/admin/CronJobs';
import Taskmanger from '@/pages/admin/Tasks';
import RefreshCache from '@/pages/admin/RefreshCache';
import Settings from '@/pages/account/Settings';
import Channels from '@/pages/account/Channels';
import ViewNotFound from '@/pages/error/ViewNotFound';
import ViewNotAllowed from '@/pages/error/ViewNotAllowed';
import ComingSoon from '@/pages/error/ComingSoon';

import Login from '@/pages/auth/Login';
import Dashboard from '@/pages/Dashboard';

import Monitoring from '@/pages/analytics/Monitoring';
import Insights from '@/pages/analytics/Insights';
import InsightsAudiences from '@/pages/analytics/InsightsAudiences';
import InsightsContent from '@/pages/analytics/InsightsContent';

export default [
  {
    path: '/login',
    name: 'login',
    alias: ['/'],
    component: Login,
    meta: {
      rule: ['*'],
      product: 'all',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      rule: ['isLoggedIn'],
      product: 'all',
    },
  },
  {
    path: '/realtime',
    alias: ['/analytics', '/monitoring', '/analytics/realtime'],
    name: 'analyticsRealtime',
    component: Monitoring,
    meta: {
      rule: ['viewRealtime'],
      product: 'analytics',
      hideContentHeader: true,
    },
  },
  {
    path: '/insights/overview',
    alias: ['/analytics/insights/overview'],
    name: 'analyticsInsightsOverview',
    redirect: '/insights/content',
    component: Insights,
    meta: {
      rule: ['viewInsights'],
      product: 'analytics',
    },
  },
  {
    path: '/insights/content',
    alias: ['/analytics/insights/content'],
    name: 'analyticsInsightsContent',
    component: InsightsContent,
    meta: {
      rule: ['viewInsights'],
      product: 'analytics',
    },
  },
  {
    path: '/insights/audiences',
    alias: ['/analytics/insights/audiences'],
    name: 'analyticsInsightsAudiences',
    component: InsightsAudiences,
    meta: {
      rule: ['viewInsights'],
      product: 'analytics',
    },
  },
  {
    path: '/audiences/show',
    alias: ['/dmp/audiences/show'],
    name: 'showAudiences',
    component: AudienceOverview,
    meta: {
      rule: ['viewAudiences'],
      product: 'dmp',
      absoluteContentHeader: true,
    },
  },
  {
    path: '/audiences/ai',
    alias: ['/dmp/audiences/ai'],
    name: 'aiAudiences',
    component: AIAudienceOverview,
    meta: {
      rule: ['viewAudiences'],
      product: 'dmp',
      absoluteContentHeader: true,
    },
  },
  {
    path: '/audiences/manage-audiences',
    alias: ['/dmp/audiences/manage-audiences'],
    name: 'manageAudiences',
    component: AudienceManage,
    meta: {
      rule: ['crudAudiences'],
      product: 'dmp',
    },
  },
  {
    path: '/audiences/define/surf-behaviour',
    alias: ['/dmp/audiences/define/surf-behaviour'],
    name: 'surfBehaviour',
    component: AudienceAssignBehaviour,
    meta: {
      rule: ['viewSurfBehaviour'],
      product: 'dmp',
    },
  },
  {
    path: '/audiences/define/question-data',
    alias: ['/dmp/audiences/define/question-data'],
    name: 'questionData',
    component: AudienceAssignQuestion,
    meta: {
      rule: ['viewPolls'],
      product: 'dmp',
    },
  },
  {
    path: '/audiences/define/form-data',
    alias: ['/dmp/audiences/define/form-data'],
    name: 'formData',
    component: AudienceAssignForm,
    meta: {
      rule: ['viewForms'],
      product: 'dmp',
    },
  },
  {
    path: '/audiences/transfer',
    alias: ['/dmp/audiences/transfer'],
    name: 'transferAudiences',
    component: AudienceTransfer,
    meta: {
      rule: ['viewTransferAudiences'],
      product: 'dmp',
    },
  },
  {
    path: '/audiences/transfer/:id',
    alias: ['/dmp/audiences/transfer/:id'],
    name: 'transferAudiencesDetail',
    component: TransferDetailView,
    meta: {
      rule: ['crudTransferAudiences'],
      product: 'dmp',
    },
  },
  {
    path: '/tracking/track-events',
    alias: ['/dmp/tracking/track-events'],
    name: 'trackEvents',
    component: TrackEventsOverview,
    meta: {
      rule: ['viewTrackings'],
      product: 'dmp',
    },
  },
  {
    path: '/tracking/track-events/:id',
    alias: ['/dmp/tracking/track-events/:id'],
    name: 'trackEventsDetail',
    component: TrackEventsDetailView,
    meta: {
      rule: ['crudTrackings'],
      product: 'dmp',
    },
  },
  {
    path: '/pixel/manage',
    alias: ['/dmp/pixel/manage'],
    name: 'managePixels',
    component: PixelsOverview,
    meta: {
      rule: ['crudPixels'],
      product: 'dmp',
    },
  },
  {
    path: '/campaigns/place-ads',
    alias: ['/ads/campaigns/place-ads'],
    name: 'placeAds',
    component: AdsOverview,
    meta: {
      rule: ['crudAds'],
      product: 'ads',
    },
  },
  {
    path: '/campaigns/recommend-content',
    alias: ['/ads/campaigns/recommend-content'],
    name: 'recommendContent',
    component: ComingSoon,
    meta: {
      rule: ['viewModuleAdmin'],
      product: 'ads',
    },
  },
  {
    path: '/admin/manage/cron-jobs',
    name: 'cronJobs',
    component: CronJobs,
    meta: {
      rule: ['manageCronJobs'],
      product: 'all',
    },
  },
  {
    path: '/admin/manage/refresh-cache',
    name: 'refreshCache',
    component: RefreshCache,
    meta: {
      rule: ['manageCache'],
      product: 'all',
    },
  },
  {
    path: '/admin/elastic-tasks',
    name: 'elasticTasks',
    component: Taskmanger,
    meta: {
      rule: ['manageElasticTasks'],
      product: 'all',
    },
  },
  {
    path: '/account/channels',
    name: 'channels',
    component: Channels,
    meta: {
      rule: ['manageChannels'],
      product: 'analytics',
    },
  },
  {
    path: '/account/settings',
    name: 'settings',
    component: Settings,
    meta: {
      rule: ['editSettings'],
      product: 'all',
    },
  },
  {
    path: '*',
    name: '404',
    component: ViewNotFound,
    meta: {
      rule: ['isLoggedIn'],
      product: 'all',
    },
  },
  {
    path: '/error',
    name: '403',
    component: ViewNotAllowed,
    meta: {
      rule: ['isLoggedIn'],
      product: 'all',
    },
  },
];
