var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","right":"","transition":"scale-transition","content-class":"v-menu-content-full-width"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"filter-bar"},[(_vm.domain)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.clearFilter('domain')}}},'v-chip',attrs,false),on),[(_vm.isDomainTemporary)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.domain)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('monitoring.filter.fieldFilter', { field: _vm.$t('monitoring.data.domain'), })))]),(_vm.isDomainTemporary)?_c('div',[_c('v-icon',{attrs:{"small":"","dark":"","left":""}},[_vm._v("mdi-clock-outline")]),_vm._v(_vm._s(_vm.$t('monitoring.filter.temporary'))+" ")],1):_vm._e()]):_vm._e(),_vm._l((_vm.termFilters),function(termFilter,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.clearTermFilter(index)}}},'v-chip',attrs,false),on),[(termFilter.isTemporary)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-outline")]):_vm._e(),_vm._v(" "+_vm._s(termFilter.valueLabel || termFilter.value)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(termFilter.fieldLabel || _vm.$t('monitoring.filter.fieldFilter', { field: _vm.$t('monitoring.data.' + termFilter.field), })))]),(termFilter.isTemporary)?_c('div',[_c('v-icon',{attrs:{"small":"","dark":"","left":""}},[_vm._v("mdi-clock-outline")]),_vm._v(_vm._s(_vm.$t('monitoring.filter.temporary'))+" ")],1):_vm._e()])}),_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","icon":"","tabindex":"-1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.$store.state.monitoring.isTemporaryFilterMode)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),Object.assign({}, onMenu, onTooltip)),[_vm._v("mdi-clock-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('monitoring.filter.temporaryMode.tooltip')))])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('exit')}}},[_vm._v(_vm._s(_vm.$t('monitoring.filter.temporaryMode.exit')))]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(_vm._s(_vm.$t('monitoring.filter.temporaryMode.save')))])],1)],1):_vm._e()],2)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',{style:(_vm.$vuetify.breakpoint.xsOnly ? { width: '100vw' } : {})},[_c('add-filter-form',{on:{"done":function($event){_vm.showMenu = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }