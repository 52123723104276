<template>
  <taskmanager />
</template>

<script>
import Taskmanager from '@/components/Admin/Taskmanager';

export default {
  components: {
    Taskmanager,
  },
};
</script>
