<template>
  <span class="change-indicator">
    <v-icon :color="getHighlightColor(value)" small class="progressIcon">
      {{ getProgressionIcon(value) }}
    </v-icon>
    <span :style="{ color: getHighlightColor(value) }">
      {{ value | formatPercentageChange }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  methods: {
    /* Get the highlight color for progression */
    getHighlightColor(percentage) {
      if (percentage >= 0) {
        return 'rgb(34, 171, 50)';
      }
      return 'rgb(241, 90, 90)';
    },
    /* Get the icon for progression */
    getProgressionIcon(percentage) {
      if (percentage >= 0) {
        return 'mdi-menu-up';
      }
      return 'mdi-menu-down';
    },
  },
};
</script>

<style scoped>
.v-icon {
  margin-right: -4px;
}
</style>
