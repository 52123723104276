<template>
  <v-container fluid>
    <v-card class="px-2">
      <v-container fluid>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <div>
              <div class="text-h4">
                {{ transfer.name }}
              </div>
              <span class="grey--text text-body-2">
                <span>{{ transfer.startDate | formatDate }}</span>
                <span v-show="transfer.endDate">
                  - {{ transfer.endDate | formatDate }}
                </span>
              </span>
            </div>
            <v-switch
              v-model="currentState"
              :label="
                transfer.active
                  ? $t('components.transfer.detail.state.active')
                  : $t('components.transfer.detail.state.inactive')
              "
              :hint="$t('components.transfer.detail.state.hint')"
              color="success"
              @change="toggleTransferState"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="transfer.assignedPixels && transfer.assignedPixels.length"
            cols="12"
            sm="6"
          >
            <assigned-pixels />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-h5">
              {{ $t('components.transfer.detail.headline.inventory') }}
            </div>
          </v-col>
          <v-col
            v-if="
              transfer.assignedInterests && transfer.assignedInterests.length
            "
            cols="12"
            sm="6"
          >
            <assigned-interests />
          </v-col>
          <v-col
            v-if="
              transfer.assignedProperties && transfer.assignedProperties.length
            "
            cols="12"
            sm="6"
          >
            <assigned-properties />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row justify="end">
            <v-btn large text color="primary" @click="editTransfer">
              {{ $t('components.transfer.detail.buttons.edit') }}
            </v-btn>
            <v-btn large text color="error" @click="setDeleteDialog(true)">
              {{ $t('components.transfer.detail.buttons.delete') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('models.single.transfer')"
      :name="transfer.name"
      :loading="deletingProgress"
      @confirmed="deleteTransfer"
      @close-dialog="setDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import DialogDelete from '@/components/DialogDelete';
import AssignedPixels from './AssignedPixels';
import AssignedInterests from './AssignedInterests';
import AssignedProperties from './AssignedProperties';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'transfer/getField',
  mutationType: 'transfer/updateField',
});

export default {
  components: {
    AssignedPixels,
    AssignedInterests,
    AssignedProperties,
    DialogDelete,
  },
  data() {
    return {
      savingProgress: false,
      loading: true,
      show: false,
      form: {},
      validationErrors: {},
      dialogConfirmDelete: {
        show: false,
        loading: false,
      },
      type: 'Remarketing',
      showDeleteDialog: false,
      deletingProgress: false,
    };
  },
  computed: {
    ...mapState('transfer', ['transfer']),
    ...mapFields({
      currentState: 'transfer.active',
    }),
  },
  watch: {},
  mounted() {
    this.getTransfer();
    this.$bus.listen('transfer-updated', this.getTransfer);
    this.$bus.listen('pixels-updated', this.getTransfer);
  },
  destroyed() {
    this.$bus.remove('transfer-updated');
    this.$bus.remove('pixels-updated');
  },
  methods: {
    async getTransfer() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          'transfer/getAudienceTransfer',
          this.$route.params.id
        );
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelNotFound', {
            model: this.$t('models.single.transfer'),
          }),
        });
        this.$router.push({ name: 'transferAudiences' });
      }
      this.loading = false;
    },
    editTransfer() {
      this.$bus.fire('edit-transfer', this.transfer.id);
    },
    async deleteTransfer() {
      this.deletingProgress = true;
      // post to controller
      try {
        await this.$store.dispatch(
          'transfer/deleteAudienceTransfer',
          this.transfer.id
        );
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelDeleted', {
            model: this.transfer.name,
          }),
        });
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }

      // hide delete dialog
      this.setDeleteDialog(false);
      this.deletingProgress = false;

      // forward to transfer-overview
      this.$router.push({ name: 'transferAudiences' });
    },
    setDeleteDialog(state = false) {
      this.showDeleteDialog = state;
    },
    async toggleTransferState(newState) {
      const params = {
        id: this.transfer.id,
        newState,
      };
      try {
        await this.$store.dispatch('transfer/toggleState', params);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.stateChanged'),
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style></style>
