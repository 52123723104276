<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    right
    transition="scale-transition"
    content-class="v-menu-content-full-width"
  >
    <template #activator="{ on, attrs }">
      <!-- the whole div.filter-bar is in activator slot to get popup below filter bar,
      acutall activator is the button with mdi-plus icon -->
      <div class="filter-bar">
        <v-tooltip v-if="domain" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="mr-1"
              close
              v-bind="attrs"
              v-on="on"
              @click:close="clearFilter('domain')"
            >
              <v-icon v-if="isDomainTemporary" left>mdi-clock-outline</v-icon>
              {{ domain }}
            </v-chip>
          </template>
          <span>{{
            $t('monitoring.filter.fieldFilter', {
              field: $t('monitoring.data.domain'),
            })
          }}</span>
          <div v-if="isDomainTemporary">
            <v-icon small dark left>mdi-clock-outline</v-icon
            >{{ $t('monitoring.filter.temporary') }}
          </div>
        </v-tooltip>
        <v-tooltip
          v-for="(termFilter, index) in termFilters"
          :key="index"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="mr-1"
              close
              v-bind="attrs"
              v-on="on"
              @click:close="clearTermFilter(index)"
            >
              <v-icon v-if="termFilter.isTemporary" left
                >mdi-clock-outline</v-icon
              >
              {{ termFilter.valueLabel || termFilter.value }}
            </v-chip>
          </template>
          <span>{{
            termFilter.fieldLabel ||
            $t('monitoring.filter.fieldFilter', {
              field: $t('monitoring.data.' + termFilter.field),
            })
          }}</span>
          <div v-if="termFilter.isTemporary">
            <v-icon small dark left>mdi-clock-outline</v-icon
            >{{ $t('monitoring.filter.temporary') }}
          </div>
        </v-tooltip>

        <v-btn v-bind="attrs" text small icon tabindex="-1" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-menu v-if="$store.state.monitoring.isTemporaryFilterMode" offset-y>
          <template #activator="{ on: onMenu, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon v-bind="attrs" v-on="{ ...onMenu, ...onTooltip }"
                  >mdi-clock-outline
                </v-icon>
              </template>
              <span>{{ $t('monitoring.filter.temporaryMode.tooltip') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="$emit('exit')">{{
              $t('monitoring.filter.temporaryMode.exit')
            }}</v-list-item>
            <v-list-item @click="$emit('save')">{{
              $t('monitoring.filter.temporaryMode.save')
            }}</v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-card :style="$vuetify.breakpoint.xsOnly ? { width: '100vw' } : {}">
      <add-filter-form @done="showMenu = false" />
    </v-card>
  </v-menu>
</template>

<script>
import AddFilterForm from './AddFilterForm.vue';

export default {
  components: { AddFilterForm },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    domain() {
      const domain = this.$store.state.monitoring.filter.domain;
      if (domain instanceof Array) return domain.join(', ');
      return domain;
    },
    termFilters() {
      return this.$store.state.monitoring.filter.terms;
    },
    /**
     * If domain filter is temporary
     * @returns {boolean}
     */
    isDomainTemporary() {
      return this.$store.state.monitoring.filter.domainIsTemporary;
    },
  },

  watch: {
    domain() {
      this.scrollToTop();
    },
    termFilters() {
      this.scrollToTop();
    },
  },

  methods: {
    scrollToTop() {
      this.$vuetify.goTo(document.body, {
        duration: 300,
        easing: 'easeInOutCubic',
        offset: 0,
      });
    },
    clearFilter(prop) {
      this.$store.dispatch('monitoring/updateFilter', { prop, value: null });
      this.resetFilterState();
    },
    clearTermFilter(index) {
      this.$store.dispatch('monitoring/removeFilter', { index });
      this.resetFilterState();
    },
    /**
     * Checking if filter is empty and resetting temporary mode
     * @returns {void}
     */
    resetFilterState() {
      if (
        this.$store.state.monitoring.isTemporaryFilterMode &&
        !this.$store.state.monitoring.filter.domain &&
        this.$store.state.monitoring.filter.terms.length === 0
      ) {
        this.$emit('exit');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-bar {
  display: inline-block;
  line-height: 2.2em;
}
</style>
<style lang="scss">
.v-chip--disabled .v-chip__content {
  opacity: 0.3 !important;
}
.v-chip--disabled .v-chip__close {
  pointer-events: unset;
}
.v-menu-content-full-width {
  max-width: 95%;
}
</style>
