<template>
  <v-dialog v-model="show" width="800px" persistent @keydown.enter="save">
    <v-card>
      <ValidationObserver
        v-slot="{ invalid, touched, handleSubmit }"
        ref="validationObserver"
        slim
      >
        <v-card-title class="pp-custom-background py-6 text-h6">
          {{ $t('components.channelDialog.createChannel') }}
        </v-card-title>
        <v-container class="pa-6">
          <v-row>
            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.channelDialog.labels.title')"
                rules="required|min:3|max:50"
              >
                <v-text-field
                  v-model="channel.title"
                  :label="$t('components.channelDialog.labels.title')"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>

            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.channelDialog.labels.path')"
                rules="required|min:3|max:255|path"
              >
                <v-text-field
                  v-model="channel.path"
                  :label="$t('components.channelDialog.labels.path')"
                  :error-messages="errors"
                  :hint="$t('components.channelDialog.hints.path')"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="savingProgress"
            text
            color="warning"
            @click="closeDialog"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :disabled="savingProgress || (touched && invalid)"
            :loading="savingProgress"
            color="success"
            @click="handleSubmit(save)"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      savingProgress: false,
      channel: {},
    };
  },

  mounted() {
    this.$bus.listen('show-channel-dialog', this.initDialog);
  },
  destroyed() {
    this.$bus.remove('show-channel-dialog');
  },

  methods: {
    initDialog() {
      this.show = true;
    },
    resetFields() {
      this.mode = 'create';
      this.channel = {};
      this.$refs.validationObserver.reset();
    },
    closeDialog() {
      this.show = false;
      this.resetFields();
    },

    async save() {
      this.savingProgress = true;
      try {
        await this.$store.dispatch('channel/createChannel', this.channel);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelCreated', {
            model: this.$t('common.models.channel'),
          }),
        });
        this.closeDialog();
      } catch (errors) {
        this.validationErrors = errors;
      }
      this.savingProgress = false;
    },
  },
};
</script>
