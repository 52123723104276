import Socket from './Socket';

/**
 * This is a socket which will only be executed if condition evaluates to true
 */
class ConditionalSocket extends Socket {
  /**
   * The constructor of the BaseResource.
   *
   * @param {Function} condition  Condition if socket should be opened. Function get Socket object as parameter.
   * @param {string} endpoint     The endpoint being used.
   * @param {string} onupdate     Callack for socket update
   * @param {Function|Object} query Optional. Query paramter
   */
  constructor(condition, endpoint, onupdate, query = null) {
    super(endpoint, onupdate, query);
    this.condition = condition;
  }

  open(options = {}) {
    if (this.condition instanceof Function) {
      if (this.condition(options, this)) super.open(options);
    } else if (this.condition) {
      super.open(options);
    }
  }
}

export default ConditionalSocket;
