/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.RESET_FIELDS](state) {
    state.postData = {
      id: 0,
      imageUrl: '',
      isBase64: false,
      ytVideoId: '',
    };
    state.items = {
      pixabay: [],
      giphy: [],
      youtube: [],
    };
  },
  [types.SET_ITEMS](state, { data, response }) {
    // extend array or create new array depending on page number
    if (data.page > 1) {
      state.items[data.library] = _.concat(state.items[data.library], response);
    } else {
      state.items[data.library] = response;
    }
  },
  [types.SELECT_ITEM](state, data) {
    state.postData = data;
  },
  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
