/* ============
 * Vuetify
 * ============
 *
 * Vuetify css
 *
 * https://kazupon.github.io/vue-i18n/
 */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

// Translations provided by Vuetify (typescript)
import en from 'vuetify/src/locale/en.ts';
import de from 'vuetify/src/locale/de.ts';
import fr from 'vuetify/src/locale/fr.ts';

// product color of current product
// Info: if you set a color to product color it also needs to be changed also in productDetector.js (method setCurrentProductColors())
export const productColor = '#103355'; // base color

export const opts = {
  theme: {
    icons: {
      iconfont: 'mdi',
    },
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0b263f', // dark blue
        secondary: '#103355', // blue
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        productColor, // initially ppAnalytics color
        ppTools: '#2395a6',
        ppDmp: '#df3766',
        ppAnalytics: '#ff585d',
        ppMagazine: '#ffa448',
        ppAds: '#62c49e',
        toolbar: '#ffffff', // background color of toolbar
        navigationDrawer: productColor, // background color of navigation drawer,
        navigationDrawerHighlight: '#ffffff', // highlight color of items in navigation drawer
        textColor: '#000000',
        deviceIconColor: '#515151',
        sectionTitle: '#808080',
        infoBar: '#ff918f', // used for the info section in monitoring cards (=ppAnalytics color with lighten-2)
      },
      dark: {
        background: '#121212',
        primary: '#576f87', // light blue
        secondary: '#146dc3', // lighter blue
        productColor, // initially ppAnalytics color
        ppTools: '#2395a6',
        ppDmp: '#df3766',
        ppAnalytics: '#ff585d',
        ppMagazine: '#ffa448',
        ppAds: '#62c49e',
        toolbar: productColor, // background color of toolbar,
        navigationDrawer: '#272727', // background color of navigation drawer
        navigationDrawerHighlight: productColor, // highlight color of items in navigation drawer
        textColor: '#ffffff',
        deviceIconColor: '#ffffff',
        sectionTitle: '#808080',
        infoBar: '#576f87', // used for the info section in monitoring cards
      },
    },
  },
  lang: {
    locales: { en, de, fr },
    current: localStorage.language || 'en',
  },
};

Vue.use(Vuetify);

export default {
  opts,
};
