/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import AuthProxy from '@/proxies/AuthProxy';
import * as types from './mutation-types';

export const check = ({ commit }) => {
  commit(types.CHECK);
  commit('account/SET_LANGUAGE', null, { root: true });
};

export const login = ({ commit, dispatch }, user) =>
  new Promise((resolve, reject) => {
    new AuthProxy()
      .login(user)
      .then(async (response) => {
        commit(types.LOGIN, response.token);
        // get account data
        await dispatch('account/me', null, { root: true });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const logout = ({ commit }) =>
  new Promise((resolve, reject) => {
    new AuthProxy()
      .logout()
      .then(() => {
        commit(types.LOGOUT);
        // reset access control
        commit('account/SET_ACCESS', ['public'], { root: true });

        // clear vuex state
        commit('RESET_STATE', null, { root: true });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const refresh = () =>
  new Promise((resolve, reject) => {
    new AuthProxy()
      .refresh()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  check,
  login,
  logout,
  refresh,
};
