/* ============
 * Actions for the tracking module
 * ============
 *
 * The actions that are available on the
 * tracking module.
 */

import TrackingResource from '@/resources/TrackingResource';
import CampaignTransformer from '@/transformers/CampaignTransformer';
import * as types from './mutation-types';

export const datatable = ({ commit }, params) =>
  new Promise((resolve) => {
    new TrackingResource()
      .datatable(params)
      .then((data) => {
        commit(types.SET_FILTERED_TRACKINGS, data);
        resolve();
      })
      .catch((error) => {
        console.error(error);
      });
  });

export const createTracking = ({ state }) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .create(state.form)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateTracking = ({ state }, id) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .update(id, state.form)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteTracking = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .destroy(id)
      .then(() => {
        commit(types.DELETE_TRACKING, id);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getTracking = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .show(id)
      .then((data) => {
        commit(types.SET_TRACKING, CampaignTransformer.fetch(data));
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const getTrackingEdit = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .show(id)
      .then((data) => {
        commit(types.SET_TRACKING_TO_EDIT, data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const toggleState = (context, params) =>
  new Promise((resolve, reject) => {
    new TrackingResource()
      .toggleState(params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  datatable,
  createTracking,
  updateTracking,
  deleteTracking,
  getTracking,
  getTrackingEdit,
  toggleState,
};
