/* ============
 * Mutations for the form module
 * ============
 *
 * The mutations that are available on the
 * form module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.SET_FORM](state, form) {
    state.form = form;
  },

  [types.UPDATE_FORM_DATA_VALUE](state, value) {
    state.formDataValue = value;
  },

  [types.SET_FORMS](state, data) {
    state.forms = data.map((el) => ({
      ...el,
      disabled: false,
    }));
  },

  [types.SET_FILTERED_FORMS](state, data) {
    state.filteredForms.items = data.data;
    state.filteredForms.total = data.total;
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
