<template>
  <v-data-table
    :headers="headers"
    :items="dataRows"
    :item-key="keyField"
    hide-default-header
    hide-default-footer
    disable-pagination
    :class="{ 'fixed-table': fixedTable, [cssClass]: cssClass }"
    :loading="loading"
    :headers-length="keyColSpan + 1"
    class="numberlist"
  >
    <template #header="{ props }">
      <thead>
        <tr style="height: 51px">
          <th
            :key="props.headers[0].text"
            :colspan="keyColSpan"
            class="column text-start"
          >
            <div class="header-content">
              <span>{{ props.headers[0].text }}</span>
              <v-icon v-if="$slots['header-options-1']">
                mdi-dots-vertical
              </v-icon>
            </div>
            <options-menu
              v-if="$slots['header-options-1']"
              :css-class="cssClass ? cssClass + '-header-options-1' : null"
              in-header
              fill
              bottom
              close-on-content-click
            >
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" />
              </template>
              <slot name="header-options-1" />
            </options-menu>
          </th>
          <th :key="props.headers[1].text" class="column text-end">
            {{ props.headers[1].text }}
            <options-menu
              v-if="$slots['header-options-2']"
              :css-class="cssClass ? cssClass + '-header-options-1' : null"
              in-header
              bottom
              close-on-content-click
            >
              <slot name="header-options-2" />
            </options-menu>
          </th>
        </tr>
      </thead>
    </template>
    <template #item="{ item }">
      <tr :class="{ clickable: !!onRowClick }" @click="rowClick(item)">
        <td v-ripple="!!onRowClick && !isSafari" :colspan="keyColSpan">
          <a
            v-if="keyUrl && item[keyField] && keyUrl(item)"
            class="link textColor--text"
            :href="keyUrl(item)"
            target="_blank"
            @click.stop
          >
            {{ translations ? translate(item) : item[keyField] }}
          </a>
          <span v-else-if="!item[keyField] && emptyKeyValue">
            {{ emptyKeyValue }}
          </span>
          <span v-else>
            {{ translations ? translate(item) : item[keyField] }}
          </span>
        </td>
        <td
          v-ripple="!!onRowClick && !isSafari"
          class="text-right text-no-wrap"
        >
          {{ item[countField] | formatNumber }}
        </td>
      </tr>
    </template>
    <template #no-data>
      <td :colspan="keyColSpan + 1" class="text-center">
        <span v-if="dataLoaded === null || !loading">
          <slot name="no-data">
            {{ $t('monitoring.no_data') }}
          </slot>
        </span>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import helpers from '@/utils/helpers';

export default {
  components: {
    OptionsMenu,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    dataLoaded: {
      type: Boolean,
      required: false,
      default: null,
    },
    keyHeader: {
      type: String,
      required: false,
      default: 'Content',
    },
    valueHeader: {
      type: String,
      required: false,
      default: 'Users',
    },
    keyField: {
      type: String,
      required: false,
      default: 'key',
    },
    countField: {
      type: String,
      required: false,
      default: 'doc_count',
    },
    translations: {
      type: [Object, Function],
      required: false,
      default: null,
    },
    fixedTable: {
      type: Boolean,
      required: false,
      default: false,
    },
    onRowClick: {
      type: Function,
      required: false,
      default: null,
    },
    keyUrl: {
      type: Function,
      required: false,
      default: null,
    },
    emptyKeyValue: {
      type: String,
      required: false,
      default: 'null',
    },
    keyColSpan: {
      type: Number,
      required: false,
      default: 3,
    },
    cssClass: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  data() {
    return {
      isSafari: helpers.isSafari,
    };
  },
  computed: {
    dataRows() {
      if (this.data.length > this.max) return this.data.slice(0, this.max);
      return this.data;
    },
    loading() {
      if (this.dataLoaded === null) return this.data.length < 1;
      return !this.dataLoaded;
    },
    headers() {
      return [
        {
          text: this.keyHeader,
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.valueHeader,
          value: 'users',
          align: 'end',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    rowClick(item) {
      if (this.onRowClick) this.onRowClick(item);
    },
    translate(item, level) {
      if (this.translations instanceof Function) {
        return this.translations(item, level || 0);
      }
      if (this.translations[item[this.keyField]]) {
        return this.translations[item[this.keyField]].text;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.numberlist {
  &.v-data-table {
    .v-data-table__wrapper {
      overflow-x: hidden;

      th {
        position: relative;
      }
      table > tbody > tr {
        td:nth-child(2) {
          padding-left: 0;
        }
        /* we cannot add v-ripple to tr elements => hacky solution applied to td */
        td > .v-ripple__container {
          width: 1000px;
          left: -500px;
          .v-ripple__animation {
            left: 500px;
          }
        }
      }

      // remove top right and left radius on first child
      tbody tr:first-child:hover td:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  &.fixed-table > * > table {
    table-layout: fixed;
  }
}
.clickable {
  cursor: pointer;
}
</style>
