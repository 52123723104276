<template>
  <v-list :style="{ height }" class="select-list" subheader two-line>
    <v-list-item
      v-for="item in items"
      :key="item.key"
      ripple
      @click="toggle(item)"
    >
      <template #default>
        <v-list-item-action class="mr-2">
          <v-checkbox
            :key="item.key"
            v-model="model"
            :value="item.key"
            @click.native.stop
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-2">
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.key }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
  },

  data() {
    return {
      model: [],
    };
  },

  watch: {
    model(value) {
      this.$emit('input', value);
    },
  },

  mounted() {
    this.model = this.value;
  },

  methods: {
    toggle(item) {
      const index = this.model.findIndex((val) => val === item.key);
      if (index > -1) this.model.splice(index, 1);
      else this.model.push(item.key);
    },
  },
};
</script>
