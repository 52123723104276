<template>
  <v-card class="mx-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" lg="3">
          <v-combobox
            v-model="search"
            :label="$t('components.audiences.leadFormTable.search')"
            multiple
            autofocus
            chips
            clearable
            deletable-chips
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            @update:search-input="searchUpdated"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="8"
          lg="9"
          class="d-flex align-center justify-center justify-sm-end"
        >
          <filter-button-menu
            :menus="menus"
            :initial-filters="activeFilters"
            @filters-updated="filtersUpdated"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
        @click:row="rowSelected"
      >
        <template #[`item.created_at`]="{ item }">
          {{ item.created_at | formatDateTimeMinutes }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import FilterButtonMenu from '@/components/FilterButtonMenu';

export default {
  components: {
    FilterButtonMenu,
  },
  data() {
    return {
      menus: {
        timeframe: {
          selected: true,
          name: this.$t('components.filterButtonMenu.filters.timeframe.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.today'
              ),
              value: 'today',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.yesterday'
              ),
              value: 'yesterday',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last7Days'
              ),
              value: 'last7Days',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last30Days'
              ),
              value: 'last30Days',
              selected: true,
            },
          ],
        },
        minLeadDataCount: {
          selected: false,
          name: this.$t(
            'components.filterButtonMenu.filters.minLeadDataCount.label'
          ),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minLeadDataCount.moreThan100'
              ),
              value: '100',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minLeadDataCount.moreThan500'
              ),
              value: '500',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minLeadDataCount.moreThan1000'
              ),
              value: '1000',
              selected: false,
            },
          ],
        },
        mappings: {
          selected: false,
          name: this.$t('components.filterButtonMenu.filters.mappings.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.hasMappings'
              ),
              value: 'hasMappings',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.noMappings'
              ),
              value: 'noMappings',
              selected: false,
            },
          ],
        },
      },
      activeFilters: { timeframe: 'last30Days' },
      search: [],
      loading: true,
      options: {
        page: 1,
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 25,
      },
      rowsPerPageItems: [5, 10, 25, 50, 100],
      headers: [
        { text: '#', value: 'id' },
        {
          text: this.$t('components.audiences.leadFormTable.headers.title'),
          value: 'title',
        },
        {
          text: this.$t('components.audiences.leadFormTable.headers.email'),
          value: 'email',
        },
        {
          text: this.$t(
            'components.audiences.leadFormTable.headers.leadDataCount'
          ),
          align: 'center',
          value: 'lead_data_count',
        },
        {
          text: this.$t('components.audiences.leadFormTable.headers.mappings'),
          align: 'center',
          value: 'audiences',
        },
        {
          text: this.$t('components.audiences.leadFormTable.headers.createdAt'),
          align: 'right',
          value: 'created_at',
        },
      ],
    };
  },
  computed: {
    ...mapState('form', {
      items: (state) => state.filteredForms.items,
      total: (state) => state.filteredForms.total,
    }),
    /**
     * Search Params
     *
     * @returns {object} searchParams
     */
    searchParams() {
      return {
        search: this.search,
        options: this.options,
        filters: this.activeFilters,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.getDataFromApiDebounced();
      },
      deep: true,
    },
  },
  created() {
    this.$bus.listen('data-updated', () => this.getDataFromApi());
  },
  destroyed() {
    this.$bus.remove('data-updated');
  },
  methods: {
    getDataFromApiDebounced: _.debounce(function search(text) {
      this.getDataFromApi(text);
    }, 500),
    filtersUpdated({ activeFilters }) {
      this.options.page = 1;
      this.activeFilters = activeFilters;
      this.getDataFromApi();
    },
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getDataFromApiDebounced(searchText);
    },
    async getDataFromApi(searchText = null) {
      // set loading data
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      // prepare params data
      const params = this.searchParams;
      params.search = search;

      try {
        await this.$store.dispatch('form/datatable', params);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    /**
     * Called when row is selected which opens the form dialog
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.$bus.fire('form-selected', item.id);
    },
  },
};
</script>
