/* ============
 * Vee-Validate
 * ============
 *
 * Simple Vue.js input validation plugin
 *
 * https://github.com/baianat/vee-validate
 */

/* eslint-disable camelcase */

import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';
import { i18n } from '@/plugins/vue-i18n';

import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';

import {
  email,
  confirmed,
  image,
  max,
  min,
  max_value,
  min_value,
  numeric,
  oneOf,
  required,
  required_if,
  size,
} from 'vee-validate/dist/rules.umd';
// using rules.umd so jest is working properly https://github.com/logaretm/vee-validate/issues/2310#issuecomment-613341259

// Install english, german locales.
localize({
  en,
  de,
});

// define the rules which are needed
extend('email', email);
extend('confirmed', confirmed);
extend('image', image);
extend('max', max);
extend('min', min);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('oneOf', oneOf);
extend('required', required);
extend('required_if', required_if);
extend('size', size);

// add custom simple url regex, rule taken from https://stackoverflow.com/questions/1410311/regular-expression-for-url-validation-in-javascript
extend('url', {
  validate(value) {
    return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
  },
  message: (fieldName) =>
    i18n.t('common.veeValidate.urlFormat', { field: fieldName }),
});
extend('path', {
  validate(value) {
    return value.startsWith('/');
  },
  message: (fieldName) =>
    i18n.t('common.veeValidate.pathFormat', { field: fieldName }),
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
