<template>
  <ai-audiences />
</template>

<script>
import AiAudiences from '@/components/Audiences/AIAudiences';

export default {
  components: {
    AiAudiences,
  },
};
</script>

<style></style>
