import Vue from 'vue';
import ResourceEndpoint from './ResourceEndpoint';
import constants from '@/utils/constants';

function isAPIResponse(data) {
  return (
    Object.prototype.hasOwnProperty.call(data, 'status') &&
    Object.prototype.hasOwnProperty.call(data, 'data')
  );
}

class BaseResource {
  /**
   * The constructor of the BaseResource.
   *
   * @param {string} endpoint   The endpoint being used.
   */
  constructor(endpoint) {
    this.baseURL = constants.API_LOCATION + endpoint;
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string} requestType The request type.
   * @param {string} url The URL for the request. Excluding endpoint.
   * @param {object|null} data The data to be send with the request for 'PUT', 'POST', and 'PATCH'
   * @returns {Promise<{object}>} The result in a promise.
   */
  submit(requestType, url, data = null) {
    let options = { data, baseURL: this.baseURL };
    let path = url;
    if (requestType === 'get') {
      options = { params: data, baseURL: this.baseURL };
    } else if (requestType === 'post' || requestType === 'put') {
      options = { ...data };
      path = `${this.baseURL}/${url}`;
    }

    return new Promise((resolve, reject) => {
      Vue.$axios[requestType](path, options)
        .then((response) => {
          if (isAPIResponse(response.data)) {
            if (response.data.status === 'success') resolve(response.data.data);
            else reject(response.data.data);
          } else {
            resolve(response.data);
          }
        })
        .catch(({ response }) => {
          if (response) {
            if (isAPIResponse(response.data)) reject(response.data.data);
            else reject(response.data);
          } else {
            reject();
          }
        });
    });
  }

  /**
   * The method used to perform an GET AJAX-request.
   *
   * @param {string}      url         The URL for the request. Excluding endpoint.
   * @param {object|null} data        The data to be send with the request.
   * @returns {Promise<{object}>} The result in a promise.
   */
  query(url, data = null) {
    return this.submit('get', url, data);
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request. Excluding endpoint.
   * @param {object|null} data        The data to be send with the request.
   * @param {object}      options     Additional config
   * @returns {ResourceEndpoint} The result in a promise.
   */
  endpoint(requestType, url, data = null, options) {
    return new ResourceEndpoint(this, requestType, url, data, options);
  }

  /**
   * Method used to get all items from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  index() {
    return this.submit('get', '/');
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  create(item) {
    return this.submit('post', '', item);
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  show(id) {
    return this.submit('get', `/${id}`);
  }

  /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  update(id, item) {
    return this.submit('put', id, item);
  }

  /**
   * Method used to destroy an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy(id) {
    return this.submit('delete', `/${id}`);
  }

  /**
   * Method used to download a file as blob type.
   *
   * @param {string} requestType The request type.
   * @param {string} url The URL for the request. Excluding endpoint.
   * @param {object} params The data to be send with the request for.
   *
   * @returns {Promise} The result in a promise.
   */
  downloadFile(requestType, url, params) {
    const path = `${this.baseURL}/${url}`;
    const options = {
      responseType: 'blob',
      params,
    };
    return Vue.$axios[requestType](path, options);
  }

  /**
   * Converts object to query string
   * @param {object|null} obj Object to be serialized
   * @returns {string}
   */
  // static querystring(obj) {
  //   if (obj === null) return '';
  //   return `?${Object.keys(obj).reduce((a, k) => { a.push(`${k}=${encodeURIComponent(obj[k])}`); return a; }, []).join('&')}`;
  // }
}

export default BaseResource;
