/* ============
 * Mutation types for the cronjob module
 * ============
 *
 * The mutation types that are available
 * on the cronjob module.
 */

export const RESET_STATE = 'RESET_STATE';

export default {
  RESET_STATE,
};
