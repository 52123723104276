<template>
  <v-row class="device-view warp">
    <v-col
      v-for="item in structure"
      :key="item.key"
      class="text-center"
      cols="4"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" class="device" v-on="on">
            <v-icon
              :style="{ 'font-size': item.icon_height }"
              color="deviceIconColor"
            >
              {{ item.icon }}
            </v-icon>
            <span :style="{ right: item.bubble_right }" class="bubble">
              <span class="text">
                {{ (dataMap[item.key] / total) | formatPercentageSimple }}
              </span>
            </span>
          </span>
        </template>
        <span>
          {{ translate ? $t(translate + '.' + item.key) : item.key }}:
          {{ (dataMap[item.key] / total) | formatPercentage }}
        </span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  filters: {
    formatPercentage(number) {
      return `${numeral(number * 100).format('0.00')}%`;
    },
    formatPercentageSimple(number) {
      return `${numeral(number * 100).format('0')}%`;
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    translate: {
      type: String,
      required: false,
      default: null,
    },
    colorMap: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      structure: [
        {
          key: 'Desktop',
          icon: 'mdi-desktop-mac',
          icon_height: '62px !important',
          bubble_right: '-1.2em',
        },
        {
          key: 'Phone',
          icon: 'mdi-cellphone-android',
          icon_height: '57px !important',
          bubble_right: '-0.5em',
        },
        {
          key: 'Tablet',
          icon: 'mdi-tablet',
          icon_height: '78px !important',
          bubble_right: '-2em',
        },
      ],
    };
  },
  computed: {
    dataMap() {
      return this.data.reduce((map, obj) => {
        map[obj.key] = obj.doc_count;
        return map;
      }, {});
    },
    total() {
      return this.data.reduce((acc, val) => acc + val.doc_count, 0);
    },
  },
};
</script>

<style lang="scss">
.mobile-grid {
  padding-top: 4px;
}

.device-view {
  .v-tooltip {
    display: flex;
    justify-content: center;
  }
  .v-icon.material-icons {
    font-size: 64px !important;
    position: absolute;
  }
  .device {
    position: relative;
    height: 64px;
    width: 64px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    margin-right: 1em;
  }

  .bubble {
    font-size: 12px;
    border-radius: 50%;
    background-color: #1976d2;
    color: white;
    width: 2.8em;
    height: 2.8em;
    line-height: 1em;
    position: absolute;
    top: -1em;
    right: -1em;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
</style>
