/* ============
 * State of the pixel module
 * ============
 *
 * The initial state of the pixel module.
 */

export const getInitialState = () => ({
  pixels: [],
  pixel: {
    type: 'javascript',
  },
  filteredPixels: {},
});

export default getInitialState;
