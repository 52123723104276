<template>
  <div>
    <v-card-text class="d-md-flex align-end pb-0">
      <v-select
        v-model="filterField"
        :label="$t(`monitoring.labels.field`)"
        :items="filterFields"
        :item-disabled="isItemDisabled"
        :item-text="(item) => $t(`monitoring.data.${item.value}`)"
        @change="
          resetFilterValue();
          updateFilterValues();
        "
        class="mr-4"
        :style="{ width: $vuetify.breakpoint.mdAndUp ? '100px' : '100%' }"
      />
      <v-select
        v-model="filterType"
        :label="$t(`monitoring.labels.operation`)"
        :items="filterTypes"
        :item-text="(item) => $t(`monitoring.verbs.${item.value}`)"
        @change="updateFilterTypeStructure"
        class="mr-4"
        :style="{ width: $vuetify.breakpoint.mdAndUp ? '100px' : '100%' }"
      />
      <v-autocomplete
        v-model="filterValue"
        :items="filterValues"
        :loading="loading.length > 0"
        :search-input.sync="search"
        :label="
          filterType === 'contains'
            ? $t(`monitoring.labels.values`)
            : $t(`monitoring.labels.value`)
        "
        item-value="text"
        :chips="filterType === 'contains'"
        :multiple="filterType === 'contains'"
        return-object
      >
        <template #no-data>
          <v-list-item>
            <span v-if="filterValues.length < 1">
              {{ $t('monitoring.components.start_typing') }}
            </span>
            <span v-else>
              {{ $t('monitoring.no_data') }}
            </span>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetForm" text color="warning">
        {{ $t('monitoring.filter.cancel') }}
      </v-btn>
      <v-btn @click="addFilter" class="mr-1" color="success">
        {{ $t('monitoring.filter.add') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MonitoringResource from '../../resources/MonitoringResource';

const apiResource = new MonitoringResource();

export default {
  data() {
    return {
      filterFields: [
        { value: 'domain' },
        { value: 'page' },
        { value: 'audience' },
        { value: 'channel' },
      ],
      /* Filters that have an explicit definition in state.filters (other filters will be stored in terms filter array) */
      explicitFilters: ['domain'],
      /* Filters that are supported by our /completel api endpoint */
      autocompleteFilters: ['domain', 'page'],
      filterField: null,
      filterTypes: [{ value: 'equals' }, { value: 'contains' }],
      filterType: 'equals',
      filterValue: '',
      filterValues: [],
      search: null,
      searchResult: {
        domain: [],
        page: {
          all: [],
        },
      },
      loaded: [],
      loading: [],
    };
  },

  computed: {
    domain() {
      return this.$store.state.monitoring.filter.domain;
    },
    ...mapGetters('account', ['canSeeAllDomains', 'analyticsDomains']),
  },

  mounted() {
    if (!this.$store.state.channel.loaded) {
      this.$store.dispatch('channel/getChannels');
    }
  },

  watch: {
    search(value) {
      if (!value) return;
      if (value.length < 2) return;
      if (this.autocompleteFilters.includes(this.filterField)) {
        this.loadFilterValues();
      }
    },
  },
  methods: {
    isItemDisabled(item) {
      return !!item.disabled;
    },

    /*
     * Sets values for filterValues autocomplete depending on current filterField
     */
    updateFilterValues() {
      switch (this.filterField) {
        case 'domain': {
          if (this.canSeeAllDomains) {
            let filterValues = this.searchResult[this.filterField];
            if (this.$store.state.monitoring.activeView === 'realtime') {
              filterValues = filterValues.concat(
                this.$store.state.monitoring.realtime.domains
                  .getData([])
                  .map((ele) => ({ text: ele.key, score: ele.users }))
              );
            } else if (this.$store.state.monitoring.activeView === 'overview') {
              filterValues = filterValues.concat(
                this.$store.state.monitoring.users.domains
                  .getData([])
                  .map((ele) => ({ text: ele.key, score: ele.users }))
              );
              filterValues = filterValues.concat(
                this.$store.state.monitoring.views.domains
                  .getData([])
                  .map((ele) => ({ text: ele.key, score: ele.views }))
              );
            }
            this.filterValues = filterValues;
          } else {
            const recursiveMap = (ele) => {
              return ele.children
                ? [{ text: ele.key }, ...ele.children.flatMap(recursiveMap)]
                : [{ text: ele.key }];
            };
            let filterValues = this.searchResult[this.filterField];
            if (this.$store.state.monitoring.activeView === 'realtime') {
              filterValues = filterValues.concat(
                this.$store.state.monitoring.realtime.domains
                  .getData([])
                  .flatMap(recursiveMap)
              );
            }
            this.filterValues = this.analyticsDomains
              .map((domain) => ({ text: domain }))
              .concat(filterValues);
          }

          break;
        }
        case 'page': {
          let filterValues = [{ text: '/' }];
          const searchResultDomain =
            this.domain instanceof Array ? 'all' : this.domain || 'all';
          filterValues = filterValues.concat(
            this.searchResult[this.filterField][searchResultDomain] || []
          );
          if (this.$store.state.monitoring.activeView === 'realtime') {
            filterValues = filterValues.concat(
              this.$store.state.monitoring.realtime.pages
                .getData([])
                .map((ele) => ({ text: ele.key, score: ele.users }))
            );
          } else if (this.$store.state.monitoring.activeView === 'overview') {
            filterValues = filterValues.concat(
              this.$store.state.monitoring.users.pages
                .getData([])
                .map((ele) => ({ text: ele.key, score: ele.users }))
            );
            filterValues = filterValues.concat(
              this.$store.state.monitoring.views.pages
                .getData([])
                .map((ele) => ({ text: ele.key, score: ele.views }))
            );
          }
          this.filterValues = filterValues;
          break;
        }
        case 'channel': {
          this.filterValues = this.$store.state.channel.channels.map(
            (channel) => ({ text: channel.title, value: channel.path })
          );
          break;
        }
        case 'audience': {
          this.filterValues = Object.values(
            this.$store.state.audience.localizedAudiences
          ).map((audience) => ({ text: audience.text, value: audience.id }));
          break;
        }
        default:
          this.filterValues = [];
      }
    },

    /**
     * Load suggestions from server
     */
    loadFilterValues: _.debounce(function loadFilterValuesDebounce() {
      const field = this.filterField;
      const value = this.search;
      if (!value) return;
      if (value.length < 2) return;
      const id = `${field}__${value}`;
      if (this.loaded.includes(id)) return;
      if (this.filterValues.findIndex((ele) => ele.text === this.search) >= 0)
        return;
      this.loaded.push(id);
      this.loading.push(id);
      const params = { field, value };
      if (field !== 'domain') params.domain = this.domain || undefined;
      apiResource
        .submit('get', 'complete', params)
        .then((data) => {
          if (field === 'domain') {
            this.searchResult[field] = this.searchResult[field].concat(data);
          } else {
            const searchResultDomain =
              this.domain instanceof Array ? 'all' : this.domain || 'all';

            if (!this.searchResult[field][searchResultDomain]) {
              this.searchResult[field][searchResultDomain] = [];
            }

            // eslint-disable-next-line prettier/prettier
            this.searchResult[field][searchResultDomain] =
              this.searchResult[field][searchResultDomain].concat(data);
          }
          this.updateFilterValues();
        })
        .catch((err) => {
          this.loaded = this.loaded.filter((ele) => ele !== id);
          console.error(err);
        })
        .finally(() => {
          this.loading = this.loading.filter((ele) => ele !== id);
        });
    }, 400),

    /**
     * Changes this.filterValue to be Array or single object dependin on
     * whether input type will be multiple
     */
    updateFilterTypeStructure() {
      switch (this.filterType) {
        case 'equals':
          if (this.filterValue instanceof Array) {
            this.filterValue = this.filterValue[0];
          }
          break;
        case 'contains':
          if (!(this.filterValue instanceof Array)) {
            this.filterValue = [this.filterValue];
          }
          break;
      }
    },

    addFilter() {
      if (this.filterValue) {
        let value;
        let valueLabel;
        if (this.filterValue instanceof Array) {
          value = this.filterValue
            .map((filterValue) => filterValue.value || filterValue.text)
            .filter((value) => !!value);
          valueLabel = this.filterValue
            .map((filterValue) => filterValue.text)
            .filter((value) => !!value)
            .join(', ');
        } else {
          value = this.filterValue.value || this.filterValue.text;
          valueLabel = this.filterValue.text;
        }

        if (this.explicitFilters.includes(this.filterField)) {
          this.$store.dispatch('monitoring/updateFilter', {
            prop: this.filterField,
            value,
          });
          if (
            this.filterField === 'domain' &&
            this.$store.state.monitoring.isTemporaryFilterMode
          ) {
            this.$store.dispatch('monitoring/updateFilter', {
              prop: 'domainIsTemporary',
              value: true,
            });
          }
        } else {
          this.$store.dispatch('monitoring/addFilter', {
            filter: {
              field: this.filterField,
              value,
              valueLabel,
              ...(this.$store.state.monitoring.isTemporaryFilterMode
                ? { isTemporary: true }
                : {}),
            },
          });
        }
        this.resetForm();
      }
    },
    resetForm() {
      this.filterField = null;
      this.filterType = 'equals';
      this.resetFilterValue();
      this.search = '';
      this.updateFilterValues();
      this.$emit('done');
    },
    resetFilterValue() {
      switch (this.filterType) {
        case 'equals':
          this.filterValue = '';
          break;
        case 'contains':
          this.filterValue = [];
          break;
      }
    },
  },
};
</script>
