/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import Vue from 'vue';

/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins and mixins.
 */

import Vuetify from 'vuetify';
import { opts } from './plugins/vuetify';
import './plugins/vuex';
import './plugins/animate-numbers';
import './plugins/axios';
import './plugins/vuex-router-sync';
import './plugins/font-awesome';
import './plugins/vee-validate';
import './plugins/lodash';
import './plugins/moment';
import './plugins/numeral';
import './plugins/vue-intercom';
import './utils/auth';
import './utils/bus';
import './utils/filters';

/* ============
 * Main App
 * ============
 *
 * Import the main application.
 */

import App from './App';
import store from './store';
import { i18n } from './plugins/vue-i18n';
import { router } from './plugins/vue-router';
import acl from './plugins/vue-acl';
import SocketService from './sockets/SocketService';

Vue.config.productionTip = false;

store.dispatch('auth/check');
SocketService.init();

new Vue({
  i18n,
  router,
  acl,
  store,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
}).$mount('#app');
