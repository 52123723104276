<template>
  <v-container fluid>
    <v-card class="mx-3">
      <v-fab-transition>
        <v-btn
          fab
          color="productColor"
          dark
          absolute
          top
          left
          @click="showAddPixelDialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="12" sm="4" lg="3">
            <v-combobox
              v-model="search"
              :label="$t('components.searchBox.searchLabel')"
              multiple
              autofocus
              chips
              clearable
              deletable-chips
              prepend-inner-icon="mdi-magnify"
              append-icon=""
              @update:search-input="searchUpdated"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="8"
            lg="9"
            class="d-flex align-center justify-center justify-sm-end"
          >
            <filter-button-menu
              :menus="menus"
              @filters-updated="filtersUpdated"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12">
            <v-data-table
              :headers="headers"
              :items="filteredPixels.items"
              :options.sync="options"
              :server-items-length="filteredPixels.total"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
              @click:row="rowSelected"
            >
              <template #[`item.icon`]="{ item }">
                <v-avatar size="35px">
                  <v-img
                    :src="
                      item.image ? item.image : helpers.getPlaceholderImage()
                    "
                    alt="Avatar"
                  />
                </v-avatar>
              </template>
              <template #[`item.type`]="{ item }">
                {{ $t('components.pixel.pixelTable.items.type.' + item.type) }}
              </template>
              <template #[`item.approved`]="{ item }">
                {{
                  item.approved
                    ? $t('components.pixel.pixelTable.items.approved.yes')
                    : $t('components.pixel.pixelTable.items.approved.no')
                }}
              </template>
              <template #[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>
              <template #no-data>
                <v-responsive :aspect-ratio="2.5">
                  <v-container fluid fill-height>
                    <v-layout column align-center justify-center>
                      <h3 class="text-h4">
                        {{ $t('components.datatable.noData') }}
                      </h3>
                      <v-btn
                        class="ma-4"
                        color="productColor"
                        large
                        @click.stop="showAddPixelDialog"
                      >
                        <span class="white--text">
                          {{ $t('components.pixel.pixelTable.buttons.create') }}
                        </span>
                      </v-btn>
                    </v-layout>
                  </v-container>
                </v-responsive>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FilterButtonMenu from '@/components/FilterButtonMenu';
import helpers from '@/utils/helpers';

export default {
  components: {
    FilterButtonMenu,
  },
  data() {
    return {
      helpers,
      menus: {
        type: {
          selected: false,
          name: this.$t('components.pixel.pixelTable.filters.type.label'),
          items: [
            {
              text: this.$t(
                'components.pixel.pixelTable.filters.type.javascript'
              ),
              value: 'javascript',
              selected: false,
            },
            {
              text: this.$t('components.pixel.pixelTable.filters.type.google'),
              value: 'google',
              selected: false,
            },
            {
              text: this.$t(
                'components.pixel.pixelTable.filters.type.facebook'
              ),
              value: 'facebook',
              selected: false,
            },
            {
              text: this.$t('components.pixel.pixelTable.filters.type.image'),
              value: 'image',
              selected: false,
            },
          ],
        },
        approved: {
          selected: false,
          name: this.$t('components.pixel.pixelTable.filters.approved.label'),
          items: [
            {
              text: this.$t('components.pixel.pixelTable.filters.approved.yes'),
              value: true,
              selected: false,
            },
            {
              text: this.$t('components.pixel.pixelTable.filters.approved.no'),
              value: false,
              selected: false,
            },
          ],
        },
      },
      headers: [
        {
          text: this.$t('components.pixel.pixelTable.headers.id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$t('components.pixel.pixelTable.headers.image'),
          align: 'left',
          value: 'icon',
        },
        {
          text: this.$t('components.pixel.pixelTable.headers.title'),
          align: 'left',
          value: 'title',
        },
        {
          text: this.$t('components.pixel.pixelTable.headers.type'),
          align: 'left',
          value: 'type',
        },
        {
          text: this.$t('components.pixel.pixelTable.headers.approved'),
          align: 'center',
          value: 'approved',
        },
        {
          text: this.$t('components.pixel.pixelTable.headers.created'),
          align: 'right',
          value: 'created_at',
        },
      ],
      rowsPerPageItems: [5, 10, 25, 50, 100],
      options: {
        page: 1,
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      loading: true,
      search: [],
      activeFilters: {},
    };
  },
  computed: {
    ...mapState('pixel', ['filteredPixels']),
    /**
     * Search Params
     *
     * @returns {object} searchParams
     */
    searchParams() {
      return {
        search: this.search,
        options: this.options,
        filters: this.activeFilters,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.getPixelsOfUserDebounced();
      },
      deep: true,
    },
  },
  created() {
    this.$bus.listen('pixels-updated', () => this.getPixelsOfUser());
  },
  destroyed() {
    this.$bus.remove('pixels-updated');
  },
  methods: {
    getPixelsOfUserDebounced: _.debounce(function search(text) {
      this.getPixelsOfUser(text);
    }, 500),
    filtersUpdated({ activeFilters }) {
      this.options.page = 1;
      this.activeFilters = activeFilters;
      this.getPixelsOfUser();
    },
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getPixelsOfUserDebounced(searchText);
    },
    async getPixelsOfUser(searchText = null) {
      // set loading data
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      // prepare params data
      const params = this.searchParams;
      params.search = search;

      try {
        await this.$store.dispatch('pixel/datatable', params);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    /**
     * Called when row is selected which opens the pixel dialog
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.$bus.fire('show-pixel-dialog', item.id);
    },
    showAddPixelDialog() {
      this.$bus.fire('show-pixel-dialog');
    },
  },
};
</script>
