/* ============
 * State of the tracking module
 * ============
 *
 * The initial state of the tracking module.
 */

export const getInitialState = () => ({
  tracking: {
    assignedQuestions: [],
    assignedAnswers: [],
    assignedAds: [],
    assignedForms: [],
    assignedPixels: [],
    assignedEvents: [],
  },
  filteredTrackings: {
    items: [],
    total: 0,
  },
  currentStep: 1,
  steps: [
    { step: 1, rules: true },
    { step: 2, rules: true },
    { step: 3, rules: true },
  ],
  validationErrors: {},
  form: {
    active: true,
    name: null,
    start_date: null,
    end_date: null,
    domain_id: '0',
    device_type: 'all',
    auto_assignment: false,
    assignedQuestions: [],
    assignedAnswers: [],
    assignedAds: [],
    assignedForms: [],
    assignedPixels: [],
    assignedEvents: [],
  },
});

export default getInitialState;
