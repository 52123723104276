import Vue from 'vue';
import moment from 'moment';

Vue.prototype.$filters = Vue.options.filters;

Vue.filter('formatNumber', (number) => {
  if (number < 100000) {
    return numeral(number).format();
  }
  return numeral(number).format('0.[00] a').toUpperCase();
});
Vue.filter('formatPercentage', (number) => {
  return `${numeral(number * 100).format('0.00')}%`;
});
Vue.filter('formatPercentageOf', (count, base) => {
  return numeral(base > 0 ? count / base : 0).format('0.00 %');
});
Vue.filter('formatPercentageChange', (number) => {
  return `${numeral(number * 100).format('+0.00')}%`;
});

Vue.filter('formatNumberAlign', (number) =>
  numeral(number).format('0.[00] a').toUpperCase()
);

Vue.filter('formatDate', (dateTime) =>
  dateTime
    ? moment(String(dateTime))
        .locale(localStorage.language || 'en')
        .format('ll')
    : ''
);
Vue.filter('formatDateTime', (dateTime) =>
  dateTime ? moment(String(dateTime)).format('DD.MM.YYYY hh:mm:ss') : ''
);
Vue.filter('formatDateTimeMinutes', (dateTime) =>
  dateTime ? moment(String(dateTime)).format('DD.MM.YYYY hh:mm') : ''
);

Vue.filter('formatDurationHuman', (number) =>
  moment.duration(number).humanize()
);
const timeUnits = [
  { string: 'ms', factorToNext: 1000 },
  { string: 's', factorToNext: 60 },
  { string: 'm', factorToNext: 60 },
  { string: 'h', factorToNext: 24 },
  { string: 'd', factorToNext: 365 },
  { string: 'y' },
];
Vue.filter('formatDuration', (num) => {
  let unit = 0;
  let number = num;
  while (
    timeUnits[unit].factorToNext &&
    number > timeUnits[unit].factorToNext
  ) {
    number /= timeUnits[unit].factorToNext;
    unit += 1;
  }
  return `${numeral(number).format('0.[00]')} ${timeUnits[unit].string}`;
});
Vue.filter('formatTime', (number) => moment(number).format('HH:mm:ss'));
