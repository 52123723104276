<template>
  <v-container class="main pl-3" fluid>
    <filter-bar />
    <v-row>
      <v-col>
        <date-range-filter :max-range="45" />
      </v-col>
    </v-row>

    <v-card>
      <v-row>
        <v-col
          cols="12"
          :md="showContent ? 3 : 12"
          order="2"
          order-md="1"
          class="pt-0 left-bar"
        >
          <audience-chooser
            v-model="selectedAudiences"
            :parallel="parallelAudienceChooser"
            @input="checkSelectedInterests"
          />
        </v-col>
        <v-col v-if="showContent" md="9" order-md="2" order="1">
          <v-row justify="center" class="mb-4 mt-2">
            <v-avatar
              v-for="id in selectedAudiences"
              :key="id"
              :size="64"
              class="overlapping avatar"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    :width="64"
                    :height="64"
                    :src="
                      audienceMap[id].filename || helpers.getPlaceholderImage()
                    "
                    :alt="audienceMap[id].text"
                    class="avatar-image"
                    v-on="on"
                  />
                </template>
                <span>{{ audienceMap[id].text }}</span>
              </v-tooltip>
            </v-avatar>
          </v-row>

          <div>
            <div class="card-progress-container ma-0">
              <v-progress-linear
                v-if="!$store.state.monitoring.users.histogram.loaded"
                :height="7"
                color="primary"
                indeterminate
              />
            </div>
            <line-chart
              :data="chartData"
              timeseries
              :interval="
                $store.getters['monitoring/insightsUseHourInterval'] ? 'h' : 'd'
              "
            />
          </div>

          <v-col>
            <v-tabs
              :value="tabsModel"
              fixed-tabs
              show-arrows
              class="transparent"
              @change="tabChanged"
            >
              <v-tab href="#info" to="#info">
                {{ $t('monitoring.headers.info') }}
              </v-tab>
              <v-tab href="#devices" to="#devices">
                {{ $t('monitoring.data.devices') }}
              </v-tab>
              <v-tab href="#referrer" to="#referrer">
                {{ $t('monitoring.data.referrer') }}
              </v-tab>
              <v-tab href="#pages" to="#pages">
                {{ $t('monitoring.data.pages') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabsModel">
              <v-tab-item value="info">
                <v-card>
                  <v-card-text class="textColor--text">
                    <v-row
                      v-for="id in selectedAudiences"
                      :key="id"
                      style="align-items: center"
                    >
                      <v-avatar :size="64" class="mr-4">
                        <v-img
                          :width="64"
                          :height="64"
                          :src="
                            audienceMap[id].filename ||
                            helpers.getPlaceholderImage()
                          "
                          :alt="audienceMap[id].text"
                          class="avatar-image"
                        />
                      </v-avatar>
                      <div>
                        <h2 class="text-h5">
                          {{ audienceMap[id].text }}
                        </h2>
                        <div class="subtitle-1">
                          <animated-number
                            :data="audienceMap[id].visitorCount"
                            :duration="2000"
                          />
                          {{ $t('monitoring.data.visitors') }}
                          {{ $t('monitoring.date.inLast30Days') }}
                          <br />
                          <animated-number
                            :data="audienceMap[id].leadCount"
                            :duration="1000"
                          />
                          {{ $t('components.audienceOverview.cards.leads') }}
                        </div>
                      </div>
                    </v-row>
                  </v-card-text>
                </v-card>
                &nbsp;
                <!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="devices">
                <v-card>
                  <v-card-text>
                    <devices-view
                      :data="$store.state.monitoring.insights.devices.data"
                      translate="monitoring.data"
                    />
                  </v-card-text>
                  <div class="card-progress-container">
                    <v-progress-linear
                      v-if="!$store.state.monitoring.insights.devices.loaded"
                      :height="7"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </v-card>
                &nbsp;
                <!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="referrer">
                <v-card>
                  <v-card-text>
                    <referrer-distribution
                      :data="
                        $store.state.monitoring.insights.referrerTypes.data
                      "
                    />
                  </v-card-text>
                  <div class="card-progress-container">
                    <v-progress-linear
                      v-show="
                        !$store.state.monitoring.insights.referrerTypes.loaded
                      "
                      :height="7"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </v-card>
                <v-card>
                  <expandable-number-list
                    :data="
                      $store.state.monitoring.insights.referrer.getData([])
                    "
                    :data-loaded="
                      $store.state.monitoring.insights.referrer.loaded
                    "
                    :translations="
                      (item, level) =>
                        level === 0
                          ? $t('monitoring.data.' + item.key)
                          : item.key
                    "
                    :value-header="$t('monitoring.data.views')"
                    :key-header="$t('monitoring.data.referrer')"
                    :key-url="
                      (item, level) => {
                        switch (level) {
                          case 0:
                            return false;
                          case 1:
                            return '//' + item.key;
                          default:
                            return item.key;
                        }
                      }
                    "
                    :empty-key-value="$t('monitoring.components.no_referrer')"
                    count-field="doc_count"
                    fixed-table
                    class="mt-2"
                  />
                </v-card>
                &nbsp;<!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="pages">
                <DomainPageView
                  :domain-data="$store.state.monitoring.users.domains"
                  :page-data="$store.state.monitoring.users.pages"
                  page-filter
                  flat
                />
                &nbsp;<!-- prevents animation bug -->
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterBar from '@/components/Monitoring/FilterBar';
import DateRangeFilter from '@/components/Monitoring/DateRangeFilter';
import AudienceChooser from '@/components/Monitoring/AudienceChooser';
import LineChart from '@/components/Monitoring/LineChart';
import DevicesView from '@/components/Monitoring/DevicesView';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import ReferrerDistribution from '@/components/Monitoring/ReferrerDistribution';
import DomainPageView from '@/components/Monitoring/DomainPageView';
import AnimatedNumber from '@/components/AnimatedNumber';
import InsightTabResources from '@/mixins/monitoring/InsightTabResources';
import helpers from '@/utils/helpers';

export default {
  components: {
    FilterBar,
    DateRangeFilter,
    AudienceChooser,
    LineChart,
    DevicesView,
    ReferrerDistribution,
    DomainPageView,
    ExpandableNumberList,
    AnimatedNumber,
  },

  mixins: [InsightTabResources],

  data() {
    return {
      helpers,
      selectedAudiences: [],
      showContent: false,
      parallelAudienceChooser: true,
    };
  },

  computed: {
    ...mapGetters('audience', ['audienceMap']),
    domainFilter() {
      return this.$store.state.monitoring.filter.domain;
    },
    chartData() {
      return [
        {
          data: this.$store.state.monitoring.users.histogram.data,
          title: this.$t('monitoring.data.users'),
          valueField: 'users',
        },
      ];
    },
  },

  watch: {
    domainFilter() {
      this.checkPageResources();
    },
  },

  mounted() {
    this.checkSelectedInterests(
      this.$store.state.monitoring.filter.terms.filter(
        (termfilter) => termfilter.field === 'audience'
      )
    );
    this.checkPageResources();

    const params = { filters: { timeframe: 'last30Days' } };
    this.$store.dispatch('audience/getLeadsCount', params);
    this.$store.dispatch('audience/getVisitorCount', params);
  },

  beforeDestroy() {
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'users.histogram',
    });
  },

  methods: {
    checkSelectedInterests(interests) {
      if (interests && interests.length > 0) {
        this.showContent = true;
        this.parallelAudienceChooser = false;
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'users.histogram',
        });
        this.changeTabResources(null, this.tabsModel);
      } else {
        this.showContent = false;
        window.setTimeout(() => {
          this.parallelAudienceChooser = true;
        }); // delay to prevent transition artifacts
        this.$store.dispatch('monitoring/removeActiveResource', {
          name: 'users.histogram',
        });
        this.changeTabResources(this.tabsModel);
      }
    },
    checkPageResources() {
      if (this.domainFilter) {
        this.tabResources.pages = ['users.pages'];
      } else {
        this.tabResources.pages = ['users.domains'];
      }

      if (this.tabsModel === 'pages') {
        if (this.domainFilter) {
          this.$store.dispatch('monitoring/addActiveResource', {
            name: 'users.pages',
          });
          this.$store.dispatch('monitoring/removeActiveResource', {
            name: 'users.domains',
          });
        } else {
          this.$store.dispatch('monitoring/addActiveResource', {
            name: 'users.domains',
          });
          this.$store.dispatch('monitoring/removeActiveResource', {
            name: 'users.pages',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  &.overlapping:not(:first-child) {
    margin-left: -1.8em;
  }
}
// transition for showing / hiding audience chooser
.left-bar {
  transition: max-width 400ms, flex-basis 400ms;
}
</style>
<style>
/* TODO: Check if needed and adapt */
/* .v-tabs.transparent .theme--light.v-tabs-bar {
  background: transparent;
}
.v-image.avatar-image {
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 960px) {
  .left-bar .audience-chooser {
    border-right: 1px solid rgba(0,0,0,0.12);
  }
} */
</style>
