<template>
  <div
    :class="{
      'line-chart': true,
      bb: true,
      dense: density > HIGH,
      sparse: density < LOW,
      'density-medium': density > MEDIUM,
    }"
  />
</template>

<script>
import { bb, line } from 'billboard.js';

const DENSITIES = {
  HIGH: 1 / 4,
  MEDIUM: 1 / 14,
  LOWER_MEDIUM: 1 / 20,
  LOW: 1 / 40,
};

export default {
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    height: {
      type: Number,
      required: false,
      default: 300,
    },
    interval: {
      type: String,
      required: false,
      default: 'd',
    },
    padding: {
      type: Object,
      required: false,
      default: null,
    },
    padStart: {
      type: Number,
      required: false,
      default: null,
    },
    axis: {
      type: Boolean,
      required: false,
      default: true,
    },
    gridSize: {
      type: Object,
      required: false,
      default: null,
    },
    baseZero: {
      type: Boolean,
      required: false,
      default: false,
    },
    compareTimeseries: {
      type: Boolean,
      required: false,
      default: false,
    },
    datetimeFormat: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      initialized: false,
      density: DENSITIES.MEDIUM,
      chart: null,
      datasets: [],
      loadingPromise: Promise.resolve(),
      ...DENSITIES,
    };
  },

  watch: {
    data() {
      const idleTimeout = window.requestIdleCallback || window.setTimeout;
      idleTimeout(this.updateChart); // prevent animation lagging
    },
    gridSize() {
      const idleTimeout = window.requestIdleCallback || window.setTimeout;
      idleTimeout(this.updateChart); // prevent animation lagging
    },
    height(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.chart.resize({ height: newValue });
      }
    },
    padding(value, old) {
      // Note: if chart is not redrawing after padding change: add 3rd parma: true
      if (value.left !== old.left)
        this.chart.config('padding.left', value.left);
      if (value.right !== old.right)
        this.chart.config('padding.right', value.right);
    },
  },

  mounted() {
    setTimeout(this.initChart);
  },

  destroy() {
    if (this.chart) this.chart.destroy();
  },

  methods: {
    initChart() {
      if (this.data === null || this.data.length === 0) return;
      this.density = this.data[0].data.length / this.$el.clientWidth;
      const options = this.getChartOptions();
      this.chart = bb.generate(options);
      this.initialized = true;
      this.previousDataLength = this.data[0].data.length;
    },
    updateChart() {
      this.density = this.data[0].data.length / this.$el.clientWidth;
      if (!this.initialized) {
        this.initChart();
      } else {
        this.loadingPromise.then(() => {
          this.loadingPromise = new Promise((resolve) => {
            const data = this.transformToChartData(this.data);
            data.done = resolve;
            this.chart.load(data);
          });
        });
      }
    },

    getChartOptions() {
      const options = {
        bindto: this.$el,
        data: this.transformToChartData(this.data),
        axis: {
          x: {
            show: this.axis,
            type: 'timeseries',
            tick: {
              format: (val) => {
                if (this.datetimeFormat) {
                  return moment(val).format(this.datetimeFormat);
                }
                if (this.interval === 'h') {
                  if (this.density < DENSITIES.LOWER_MEDIUM) {
                    return moment(val).format('HH:mm');
                  }
                  return moment(val).format('DD. MMM - HH:mm');
                }
                return moment(val).format('DD. MMM');
              },
              culling: {
                max: 7,
              },
            },
          },
          y: {
            show: this.axis,
            min: this.baseZero ? 0 : undefined,
            tick: {
              format: (value) => this.$filters.formatNumberAlign(value),
            },
          },
        },
        padding: {
          right: 15,
          ...this.padding,
        },
        size: {
          height: this.height,
        },
        legend: {
          show: false,
        },
      };

      return options;
    },

    /**
     * @param {{data: Object[], title: String, valueField: String, color: String}[]} data Array of histogram data
     * @returns {Array}
     */
    transformToChartData(data) {
      const valueFields = data.map((dataObject) => dataObject.title);
      const unload = this.datasets.filter((id) => !valueFields.includes(id));
      const mergedData = Object.values(
        data.reduce((accumulator, dataObject) => {
          const valueField = dataObject.valueField || 'doc_count';
          dataObject.data.forEach((ele) => {
            let { key } = ele;
            if (
              data[0] &&
              data[0].data &&
              data[0].data.length &&
              this.compareTimeseries
            ) {
              key += data[0].data[0].key - dataObject.data[0].key;
            }
            if (!accumulator[key]) {
              accumulator[key] = { key };
            }
            accumulator[key][dataObject.title] = ele[valueField];
          });
          return accumulator;
        }, {})
      );
      const paddedData = this.pad(mergedData, valueFields[0]);
      const defaultColors = ['#82B1FF', '#90CAF9', '#B388FF', '#FF8A80'];
      let nextDefaultColor = 0;
      // eslint-disable-next-line no-plusplus
      const getNextDefaultColor = () => defaultColors[nextDefaultColor++];

      this.datasets = valueFields; // store datasets for next unload change
      return {
        json: paddedData,
        keys: {
          x: 'key',
          value: valueFields,
        },
        colors: data.reduce((map, dataObject) => {
          map[dataObject.title] = dataObject.color || getNextDefaultColor();
          return map;
        }, {}),
        types: data.reduce((map, dataObject) => {
          map[dataObject.title] = line && line();
          return map;
        }, {}),
        unload,
      };
    },

    /**
     * if padding is configured set then this returns padded data
     * meaning it inserts 0 placehodlder for missing values
     * @param {Object[]} data data
     * @param {String} [valueField] name of the field
     * @returns {Object[]} with padded data
     */
    pad(data, valueField = 'doc_count') {
      if (this.padStart) {
        if (data.length > 1 && this.padStart < data[0].key) {
          const stepSize = data[1].key - data[0].key;
          const padLength = Math.floor(
            (data[0].key - this.padStart) / stepSize
          );
          const padding = Array.from({ length: padLength }, (item, index) => ({
            key: this.padStart + index * stepSize,
            [valueField]: 0,
          }));
          return padding.concat(data);
        }
        return data;
      }
      return data;
    },
  },
};
</script>

<style>
@import '~billboard.js/dist/theme/graph.min.css';

.line-chart.density-medium
  .bb-chart-lines
  .bb-circles
  .bb-circle:not(._expanded_),
.line-chart.no-circles .bb-chart-lines .bb-circles .bb-circle:not(._expanded_) {
  display: none;
}
.line-chart .bb-line {
  stroke-width: 3px;
}
.line-chart {
  cursor: default;
}
.bb-axis-y2 text,
.bb-axis-y text,
.bb text {
  fill: #666;
  fill: rgba(0, 0, 0, 0.64);
}
.theme--dark .line-chart .bb-tooltip-container {
  color: black;
}
.theme--dark .line-chart .bb-axis-y2 text,
.theme--dark .line-chart .bb-axis-y text,
.theme--dark .line-chart.bb text {
  fill: #999;
  fill: rgba(255, 255, 255, 0.64);
}
</style>
