/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const SET = 'SET';
export const SET_ACCESS = 'SET_ACCESS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const UPDATE_DISPLAY_SETTING = 'UPDATE_DISPLAY_SETTING';
export const SET_DISPLAY_SETTINGS = 'SET_DISPLAY_SETTINGS';
export const RESET_STATE = 'RESET_STATE';
export const SET_BIGBOARD_STATE = 'SET_BIGBOARD_STATE';
export const UPDATE_DARK_MODE = 'UPDATE_DARK_MODE';

export default {
  SET,
  SET_ACCESS,
  SET_LANGUAGE,
  UPDATE_DISPLAY_SETTING,
  SET_DISPLAY_SETTINGS,
  RESET_STATE,
  SET_BIGBOARD_STATE,
  UPDATE_DARK_MODE,
};
