import Vue from 'vue';
import io from 'socket.io-client';
import auth from '@/utils/auth';
import bus from '@/utils/bus';
import constants from '@/utils/constants';
import store from '@/store';

const socketPath = '/socket';
class Socket {
  /**
   * @param {string} endpoint   The endpoint being used.
   * @param {Function} onupdate   Callack for socket update
   * @param {Function|Object} query Optional. Query paramter
   */
  constructor(endpoint, onupdate, query = null) {
    this.baseURL = constants.API_LOCATION;
    this.path = socketPath;
    this.endpoint = endpoint;
    this.onupdate = onupdate;
    this.query = query;
    this.socket = null;
  }

  open(options = {}) {
    // initialize socket
    if (!this.socket) {
      const config = {
        path: this.path,
        transports: ['websocket'],
        autoConnect: false,
      };
      this.socket = io(this.baseURL + this.endpoint, config);
      this.socket.on('update', (data) => {
        options.parameters = this.loadingParams;
        this.onupdate(data, options);
      });
      this.socket.on('connect', () => {
        this.socket
          .emit('authenticate')
          .on('authenticated', () => {
            // do other things
          })
          .on('unauthorized', async (msg) => {
            if (msg.message === 'jwt expired') {
              await Socket.refreshToken(options);
            } else {
              console.log(`unauthorized: ${JSON.stringify(msg.data)}`);
              this.close();
              if (msg.data && msg.data.code === 'invalid_token') {
                store.dispatch('auth/logout');
                Vue.router.push({ name: 'login' });
              } else {
                console.log(msg);
                Vue.router.push({
                  name: '403',
                });
              }
            }
          })
          .on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
              // the disconnection was initiated by the server, you need to reconnect manually
              this.socket.connect();
            }
            // else the socket will automatically try to reconnect // comment from docu but not sure if true
          });
      });
    }

    // set query params
    if (this.query instanceof Function)
      this.socket.query = this.getRequestParameters(options);
    else if (this.query) this.socket.query = this.query;
    this.loadingParams = this.socket.query;

    // open
    this.socket.open();
  }

  /**
   * Method to refresh the token of a given socket
   *
   * @param {object} options - options of the socket
   *
   * @returns {void}
   */
  static async refreshToken(options) {
    // only call refresh token if request is not already running
    if (!Socket.refreshTokenRequest) {
      // if token is expired reopen the socket
      Socket.refreshTokenRequest = auth
        .refreshToken()
        .then(() => {
          bus.fire('reopenAllSocketConnections', options);
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('auth/logout');
          Vue.router.push({ name: 'login' });
        })
        .finally(async () => {
          // reset refresh token to null
          Socket.refreshTokenRequest = null;
        });
    }
    return Socket.refreshTokenRequest;
  }

  close() {
    if (this.socket) this.socket.close();
  }

  reopen(options) {
    this.close();
    this.open(options);
  }

  isConnected() {
    return this.socket && this.socket.connected;
  }

  getRequestParameters(options = {}) {
    const query = this.query(options);
    Object.keys(query).forEach((param) => {
      if (query[param] === undefined) {
        delete query[param];
      } else if (query[param] instanceof Array) {
        query[param] = JSON.stringify(query[param]);
      }
    });
    return query;
  }

  /**
   * Add new request parameters
   * @param {Object|Function<Object>} newParams New parameter.
   * @returns {void}
   */
  addRequestParameters(newParams) {
    if (this.query instanceof Function) {
      const currentDataFunc = this.query;
      if (newParams instanceof Function) {
        this.query = (options) => ({
          ...currentDataFunc(options),
          ...newParams(options),
        });
      } else {
        this.query = (options) => ({
          ...currentDataFunc(options),
          ...newParams,
        });
      }
    } else {
      if (newParams instanceof Function) {
        console.warn(
          `SocketEndpoint ${this.endpoint} should have parameter function instead of static object to add query function.`
        );
      }
      this.query = {
        ...this.query,
        ...newParams,
      };
    }
  }
}

export default Socket;
