<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    :class="{
      light,
      'cover-column': coverColumn,
      'icon-left': iconLeft,
      [cssClass]: cssClass,
    }"
    :left="!right"
    :right="right"
    :nudge-bottom="bottom ? 57 : 0"
    :content-class="contentClass"
    :min-width="minWidth"
    bottom
    @input="menuChanged"
  >
    <template #activator="{ on, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            :small="small"
            icon
            :class="{
              'options-menu': true,
              header: inHeader,
              rect: !rounded,
              static: staticPosition,
              fill,
            }"
            v-on="{ ...tooltip, ...on }"
          >
            <slot name="activator">
              <v-icon v-if="horizontal"> mdi-dots-horizontal </v-icon>
              <v-icon v-else> mdi-dots-vertical </v-icon>
            </slot>
          </v-btn>
        </template>
        <span>{{ $t('monitoring.dashboard.tooltips.add_remove_cards') }}</span>
      </v-tooltip>
    </template>
    <slot />
  </v-menu>
</template>

<script>
export default {
  props: {
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    inHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    coverColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    cssClass: {
      type: String,
      required: false,
      default: null,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    minWidth: {
      type: Number,
      required: false,
      default: undefined,
    },
    staticPosition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    contentClass() {
      let value = 'options-menu-content';
      if (this.inHeader) value += ' header';
      if (this.cssClass) value += ` ${this.cssClass}-content`;
      return value;
    },
  },

  methods: {
    menuChanged(value) {
      if (value) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.options-menu {
  z-index: 2;

  &.v-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;

    &.static {
      position: static;

      &::before {
        top: unset;
        right: unset;
      }
    }

    &.header {
      height: 50px;
    }

    &.rect {
      border-radius: unset;
      width: 25px;

      &::before {
        border-radius: unset;
      }
    }
    &.fill {
      width: 100%;
      height: 100%;
    }
  }
}

.options-menu-content {
  // fix for transparent menu on scroll issue
  background: inherit;
}
</style>
