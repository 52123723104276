<template>
  <v-row justify="center" class="mt-6">
    <v-col cols="10" sm="5" class="pb-0">
      <v-slider
        v-model="affinityScore"
        :min="1"
        :max="10"
        :label="label"
        :disabled="!selectedField"
        step="1"
        ticks
        thumb-label
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      label: this.$t('components.audienceAssignAffinitySlider.affinityScore'),
    };
  },
  computed: {
    ...mapState('audience', ['selectedField']),
    affinityScore: {
      get() {
        return this.$store.state.audience.affinityScore;
      },
      set(value) {
        this.$store.commit('audience/SET_AFFINITY_SCORE', value);
        this.$bus.fire('affinity-score-updated');
      },
    },
  },
  created() {
    this.$bus.listen('hide-assign-detail-view', this.resetAffinityScore);
  },
  destroyed() {
    this.$bus.remove('hide-assign-detail-view');
  },
  methods: {
    resetAffinityScore() {
      this.$store.commit('audience/SET_AFFINITY_SCORE', 1);
    },
  },
};
</script>

<style></style>
