var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent numberlist expandable-numberlist",class:{ 'fixed-table': _vm.fixedTable },attrs:{"headers":_vm.headers,"items":_vm.dataRows,"item-key":_vm.keyField,"hide-default-header":"","hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"headers-length":_vm.keyColSpan + 1,"expanded":_vm.expandedItems},on:{"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [(!_vm.hideHeaders)?_c('thead',[_c('tr',[_c('th',{key:props.headers[0].text,staticClass:"column text-start",attrs:{"colspan":_vm.keyColSpan}},[_c('span',[_vm._v(" "+_vm._s(props.headers[0].text)+" "),_vm._t("header-options-1")],2)]),_c('th',{key:props.headers[1].text,staticClass:"column text-end",class:{ 'pr-6': _vm.withOptionsMenu }},[_vm._v(" "+_vm._s(props.headers[1].text)+" ")])])]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('tr',{class:{
        expandable: _vm.isExpandable(item),
        expanded: !!isExpanded,
        'with-row-click': !!_vm.onRowClick,
        clickable: _vm.isExpandable(item) || !!_vm.onRowClick,
      },on:{"click":function (event) { return _vm.rowClick(item, event.target, isExpanded); }}},[_c('td',{directives:[{name:"ripple",rawName:"v-ripple",value:((_vm.isExpandable(item) || !!_vm.onRowClick) && !_vm.isSafari),expression:"(isExpandable(item) || !!onRowClick) && !isSafari"}],style:({ 'padding-left': 24 + _vm.level * 15 + 'px' }),attrs:{"colspan":_vm.keyColSpan}},[(_vm.keyUrl && item[_vm.keyField] && _vm.keyUrl(item, _vm.level))?_c('a',{staticClass:"link textColor--text",attrs:{"href":_vm.keyUrl(item, _vm.level),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.translations ? _vm.translate(item, _vm.level) : item[_vm.keyField])+" ")]):(!item[_vm.keyField] && _vm.emptyKeyValue)?_c('span',[_vm._v(" "+_vm._s(_vm.emptyKeyValue)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.translations ? _vm.translate(item, _vm.level) : item[_vm.keyField])+" ")]),(_vm.isExpandable(item))?_c('span',{staticClass:"icon-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleItem(item, isExpanded)}}},[_c('v-icon',{attrs:{"size":18}},[_vm._v(" "+_vm._s(("mdi-numeric-" + (item[_vm.childrenField].length > 9 ? '9-plus' : item[_vm.childrenField].length) + "-box-multiple-outline"))+" ")])],1):_vm._e(),(_vm.rowOverlay && _vm.isExpandable(item))?_c('div',{staticClass:"row-overlay"},[_c('v-btn',{staticClass:"expand",attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function (event) {
                _vm.toggleItem(item, isExpanded);
                _vm.removeOverlay(event.target);
              })($event)}}},[_vm._v(" "+_vm._s(item.expanded ? _vm.$t('monitoring.actions.collapse') : _vm.$t('monitoring.actions.expand'))+" ")]),_c('v-btn',{staticClass:"click",attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function (event) {
                _vm.onRowClick(item);
                _vm.removeOverlay(event.target);
              })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.actions.filter'))+" ")]),_c('v-btn',{staticClass:"close",attrs:{"text":"","icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function (event) { return _vm.removeOverlay(event.target); })($event)}}},[_c('v-icon',[_vm._v("close")])],1)],1):_vm._e()]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple",value:((_vm.isExpandable(item) || !!_vm.onRowClick) && !_vm.isSafari),expression:"(isExpandable(item) || !!onRowClick) && !isSafari"}],staticClass:"text-right text-no-wrap",class:{ 'pr-6': _vm.withOptionsMenu }},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item[_vm.countField]))+" ")])])]}},{key:"expanded-item",fn:function(ref){
              var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.keyColSpan + 1}},[_c('expandable-number-list',{attrs:{"data":item[_vm.childrenField],"translations":_vm.translations,"key-field":_vm.keyField,"count-field":_vm.countField,"level":_vm.level + 1,"fixed-table":_vm.fixedTable,"key-url":_vm.keyUrl,"on-row-click":_vm.onRowClick,"with-options-menu":_vm.withOptionsMenu,"hide-headers":"","with-bottom-border":""}})],1)]}},(_vm.withBottomBorder)?{key:"body.append",fn:function(){return [_c('tr')]},proxy:true}:null,{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.keyColSpan + 1}},[(_vm.dataLoaded === null || !_vm.loading)?_c('span',[_vm._t("no-data",[_vm._v(" "+_vm._s(_vm.$t('monitoring.no_data'))+" ")])],2):_vm._e()])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }