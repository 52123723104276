<template>
  <div>
    <div class="text-h6">
      {{ $t('components.tracking.assignedQuestions.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.tracking.assignedQuestions.info') }}</span>
      <v-list>
        <v-list-item
          v-for="question in tracking.assignedQuestions"
          :key="question.id"
        >
          <v-list-item-action>
            <v-icon color="info"> mdi-poll-box </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ question.question }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('components.tracking.assignedQuestions.votes') }}:
              {{ question.votes }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('tracking', ['tracking']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
