<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-hover v-slot:default="{ hover }" open-delay="100">
          <v-card
            class="hotlink-container"
            :elevation="hover ? 10 : 0"
            flat
            color="background"
            :href="destination"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-badge
                  v-if="withAlert"
                  color="warning"
                  icon="mdi-exclamation-thick"
                  overlap
                >
                  <v-icon class="hotlink-icon" :color="iconColor">
                    {{ iconKey }}
                  </v-icon>
                </v-badge>
                <v-icon v-else class="hotlink-icon" :color="iconColor">
                  {{ iconKey }}
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="text-h6 font-weight-bold text-center text-uppercase"
                cols="12"
              >
                {{ title }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-subtitle-1 pt-0 text-center" cols="12">
                {{ description }}
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    /**
     * The icon that should be displayed
     */
    iconKey: {
      type: String,
      required: true,
    },
    /**
     * The color of the icon
     */
    iconColor: {
      type: String,
      required: true,
    },
    /**
     * The title of the hot link
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * The descriptions displayed below the title
     */
    description: {
      type: String,
      required: true,
    },
    /**
     * The destination of the hot link as link
     */
    destination: {
      type: String,
      required: true,
    },
    /**
     * Indicator whether the link should forward to internal or external link
     */
    forwardExternal: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Show small badge with an exclamation mark
     */
    withAlert: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.hotlink-container {
  .hotlink-icon {
    font-size: 4rem;
  }
}
</style>
