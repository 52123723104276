<template>
  <v-card min-height="400">
    <v-card-title v-if="form.title" class="text-h5">
      {{ form.title }}
    </v-card-title>
    <v-card-text>
      <template v-if="form.title">
        {{ $t('components.audiences.assignFormData.fields') }}
        <v-radio-group v-model="selectedField" :mandatory="false">
          <v-list subheader>
            <v-list-item v-for="field in form.fields" :key="field.value">
              <v-radio
                :value="field.value"
                :label="field.name"
                color="success"
              />
            </v-list-item>
          </v-list>
        </v-radio-group>
        <template v-if="selectedField">
          <v-select
            v-model="logic"
            :items="items"
            :label="$t('components.audiences.assignFormData.logic.label')"
            @change="logicUpdated"
          />
          <v-text-field
            v-model="formDataValue"
            :label="
              $t('components.audiences.assignFormData.formDataValue.label')
            "
            :hint="
              $t('components.audiences.assignFormData.formDataValue.hint', {
                sample: form.sample ? form.sample[selectedField] : '-',
              })
            "
            persistent-hint
          />
        </template>
      </template>
      <v-progress-circular v-else indeterminate color="primary" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      items: [
        {
          text: this.$t(
            'components.audiences.assignFormData.logic.items.equals'
          ),
          value: 'equals',
        },
        {
          text: this.$t(
            'components.audiences.assignFormData.logic.items.beginsWith'
          ),
          value: 'beginsWith',
        },
        {
          text: this.$t(
            'components.audiences.assignFormData.logic.items.contains'
          ),
          value: 'contains',
        },
        {
          text: this.$t(
            'components.audiences.assignFormData.logic.items.endsWith'
          ),
          value: 'endsWith',
        },
      ],
      logic: 'equals',
    };
  },
  computed: {
    ...mapState('form', ['form', 'formDataValue']),
    formDataValue: {
      get() {
        return this.$store.state.form.formDataValue;
      },
      set(value) {
        this.matchLogic(value);
        this.$store.commit('form/UPDATE_FORM_DATA_VALUE', value);
        this.$store.commit('audience/UPDATE_AFFINITY_SCORE', value);
      },
    },
    selectedField: {
      get() {
        return this.$store.state.audience.selectedField;
      },
      set(value) {
        this.$store.commit('audience/SET_SELECTED_FIELD', value);
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getForm(newVal);
        }
      },
    },
  },
  created() {
    this.$bus.listen('hide-assign-detail-view', this.resetForm);
  },
  destroyed() {
    this.$bus.remove('hide-assign-detail-view');
  },
  methods: {
    getForm(id) {
      this.$store.dispatch('form/getForm', id);
    },
    logicUpdated(newLogic) {
      this.updateMatchedValue(this.formDataValue, newLogic);
    },
    async updateMatchedValue(
      formDataValue = this.formDataValue,
      currentLogic = this.logic
    ) {
      let regexValue = formDataValue
        ? formDataValue.replace(/\^|\*|\$/g, '')
        : '';
      if (currentLogic === 'beginsWith') {
        regexValue = `${regexValue}*`;
      } else if (currentLogic === 'contains') {
        regexValue = `*${regexValue}*`;
      } else if (currentLogic === 'endsWith') {
        regexValue = `*${regexValue}`;
      }
      this.formDataValue = regexValue;
    },
    matchLogic(value) {
      let logic = 'equals';
      if (value.startsWith('*')) {
        logic = 'endsWith';
      } else if (value.endsWith('*')) {
        logic = 'beginsWith';
      }
      if (value.startsWith('*') && value.endsWith('*')) {
        logic = 'contains';
      }
      this.logic = logic;
    },
    resetForm() {
      this.formDataValue = '';
      this.$store.commit('form/SET_FORM', '');
      this.logic = 'equals';
      this.selectedField = null;
    },
  },
};
</script>

<style></style>
