var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('components.transfer.stepData.title'))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.transfer.stepData.form.name.label'),"vid":"name","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"label":_vm.$t('components.transfer.stepData.form.name.label'),"hint":_vm.$t('components.transfer.stepData.form.name.hint'),"name":"name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.transfer.stepData.form.startDate.label'),"vid":"start_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.start_date,"min-width":"290px","transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.start_date=$event},"update:return-value":function($event){_vm.start_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('components.transfer.stepData.form.startDate.label'),"hint":_vm.$t('components.transfer.stepData.form.startDate.hint'),"name":"start_date","prepend-icon":"mdi-calendar","clearable":"","error-messages":errors},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"min":"2018-01-01"},on:{"input":function($event){return _vm.$refs.startDateMenu.save(_vm.start_date)}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.transfer.stepData.form.endDate.label'),"vid":"end_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.end_date,"min-width":"290px","transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.end_date=$event},"update:return-value":function($event){_vm.end_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('components.transfer.stepData.form.endDate.label'),"hint":_vm.$t('components.transfer.stepData.form.endDate.hint'),"name":"end_date","prepend-icon":"mdi-calendar","clearable":"","error-messages":errors},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.start_date},on:{"input":function($event){return _vm.$refs.endDateMenu.save(_vm.end_date)}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }