import Resource from './Resource';

class UserResource extends Resource {
  /**
   * The constructor for the AudienceResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/users');
  }

  /**
   * Method used to get all Users
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getUsers(params) {
    return this.submit('get', 'getUsers', params);
  }

  /**
   * Method used to refresh Users
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  refreshUsers(params) {
    return this.submit('post', 'refreshUsers', params);
  }

  /**
   * Method used to refresh the cache for all retargeting stuff
   *
   * @param {Object} params The query parameters.
   *
   * @returns {Promise<Object>}  The result in a promise.
   */
  refreshRetargetingCache(params) {
    return this.submit('post', 'refreshRetargetingCache', params);
  }
}

export default UserResource;
