var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","persistent":"","scrollable":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeNameDialog($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveAudience($event)}]},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"dialog-card"},[_c('ValidationObserver',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var touched = ref.touched;
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-h5"},[_c('span',[_vm._v(_vm._s(_vm.headline))]),(_vm.isAdmin)?_c('span',{staticClass:"ml-2"},[_vm._v("(id = "+_vm._s(_vm.audience.id)+")")]):_vm._e()]),_c('v-card-text',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audiences.dialogName.name')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('components.audiences.dialogName.form.german'),"vid":"german","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.audiences.dialogName.form.german'),"counter":30,"loading":_vm.loading,"disabled":_vm.isPinpollAudience,"required":"","error-messages":errors},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.audience.german),callback:function ($$v) {_vm.$set(_vm.audience, "german", $$v)},expression:"audience.german"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('components.audiences.dialogName.form.english'),"vid":"english","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('components.audiences.dialogName.form.english'),"counter":30,"loading":_vm.loading,"disabled":_vm.isPinpollAudience,"required":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.audience.english),callback:function ($$v) {_vm.$set(_vm.audience, "english", $$v)},expression:"audience.english"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('components.audiences.dialogName.form.hideAnalytics.label'),"hint":_vm.$t('components.audiences.dialogName.form.hideAnalytics.hint', {
                model: _vm.localizedModelName,
              }),"persistent-hint":"","color":"success"},model:{value:(_vm.audience.hide_analytics),callback:function ($$v) {_vm.$set(_vm.audience, "hide_analytics", $$v)},expression:"audience.hide_analytics"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('components.audiences.dialogName.form.hideDmp.label'),"hint":_vm.$t('components.audiences.dialogName.form.hideDmp.hint', {
                model: _vm.localizedModelName,
              }),"persistent-hint":"","color":"success"},model:{value:(_vm.audience.hide_dmp),callback:function ($$v) {_vm.$set(_vm.audience, "hide_dmp", $$v)},expression:"audience.hide_dmp"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('components.audiences.dialogName.form.isPinpoll.label', {
                model: _vm.modelName,
              }),"hint":_vm.$t('components.audiences.dialogName.form.isPinpoll.hint'),"persistent-hint":"","color":"success"},model:{value:(_vm.audience.is_pinpoll),callback:function ($$v) {_vm.$set(_vm.audience, "is_pinpoll", $$v)},expression:"audience.is_pinpoll"}})],1):_vm._e(),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('components.audiences.dialogName.form.isAiTopic.label', {
                model: _vm.modelName,
              }),"hint":_vm.$t('components.audiences.dialogName.form.isAiTopic.hint'),"persistent-hint":"","color":"success"},model:{value:(_vm.audience.is_aitopic),callback:function ($$v) {_vm.$set(_vm.audience, "is_aitopic", $$v)},expression:"audience.is_aitopic"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.closeNameDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.cancel'))+" ")]),_c('v-btn',{attrs:{"loading":_vm.savingProgress,"disabled":touched && invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.saveAudience)}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.save'))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }