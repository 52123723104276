<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">
        {{ $t('components.tracking.stepData.title') }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepData.form.name.label')"
              vid="name"
              rules="required|min:3|max:50"
            >
              <v-text-field
                v-model="name"
                :counter="50"
                :label="$t('components.tracking.stepData.form.name.label')"
                :hint="$t('components.tracking.stepData.form.name.hint')"
                name="name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepData.form.startDate.label')"
              vid="start_date"
              rules=""
            >
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="start_date"
                min-width="290px"
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    v-bind="attrs"
                    :label="
                      $t('components.tracking.stepData.form.startDate.label')
                    "
                    :hint="
                      $t('components.tracking.stepData.form.startDate.hint')
                    "
                    name="start_date"
                    prepend-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start_date"
                  min="2018-01-01"
                  @input="$refs.startDateMenu.save(start_date)"
                />
              </v-menu>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepData.form.endDate.label')"
              vid="end_date"
              rules=""
            >
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="end_date"
                min-width="290px"
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    v-bind="attrs"
                    :label="
                      $t('components.tracking.stepData.form.endDate.label')
                    "
                    :hint="$t('components.tracking.stepData.form.endDate.hint')"
                    name="end_date"
                    prepend-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="start_date"
                  @input="$refs.endDateMenu.save(end_date)"
                />
              </v-menu>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepData.form.domain.label')"
              vid="domain_id"
              rules="required"
            >
              <v-autocomplete
                v-model="domain_id"
                :items="extendedDomainsOfUser"
                :label="$t('components.tracking.stepData.form.domain.label')"
                :hint="$t('components.tracking.stepData.form.domain.hint')"
                name="domain_id"
                item-text="title"
                item-value="id"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepData.form.deviceType.label')"
              vid="device_type"
              rules="required|oneOf:all,desktop,mobile,app"
            >
              <v-autocomplete
                v-model="device_type"
                :items="deviceTypes"
                :label="
                  $t('components.tracking.stepData.form.deviceType.label')
                "
                :hint="$t('components.tracking.stepData.form.deviceType.hint')"
                name="device_type"
                item-text="text"
                item-value="value"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'tracking/getField',
  mutationType: 'tracking/updateField',
});
export default {
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      deviceTypes: [
        {
          value: 'all',
          text: this.$t(
            'components.tracking.stepData.form.deviceType.items.all'
          ),
        },
        {
          value: 'desktop',
          text: this.$t(
            'components.tracking.stepData.form.deviceType.items.desktop'
          ),
        },
        {
          value: 'mobile',
          text: this.$t(
            'components.tracking.stepData.form.deviceType.items.mobile'
          ),
        },
        {
          value: 'app',
          text: this.$t(
            'components.tracking.stepData.form.deviceType.items.app'
          ),
        },
      ],
      domains: [],
    };
  },
  computed: {
    ...mapState('tracking', ['currentStep']),
    ...mapState('account', ['user']),
    ...mapFields([
      'form.name',
      'form.start_date',
      'form.end_date',
      'form.domain_id',
      'form.device_type',
    ]),
    extendedDomainsOfUser() {
      let domains = [
        {
          id: '0',
          title: this.$t('components.tracking.stepData.form.domain.items.all'),
        },
      ];
      if (this.user) {
        domains = [...domains, ...this.user.domains];
      }
      return domains;
    },
  },
};
</script>
