/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from 'vue';
import Axios from 'axios';
import constants from '@/utils/constants';

Axios.defaults.baseURL = constants.API_AUTH_URL;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.defaults.withCredentials = true;

// Bind Axios to Vue.
Vue.$axios = Axios;
Object.defineProperty(Vue.prototype, '$axios', {
  get() {
    return Axios;
  },
});
