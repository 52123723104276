var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-3 mt-2"},[_c('v-fab-transition',[_c('v-btn',{attrs:{"fab":"","color":"productColor","dark":"","absolute":"","top":"","left":""},on:{"click":_vm.showAddAdDialog}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('v-combobox',{attrs:{"label":_vm.$t('components.searchBox.searchLabel'),"multiple":"","autofocus":"","chips":"","clearable":"","deletable-chips":"","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:search-input":_vm.searchUpdated},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.adaptedHeaders,"items":_vm.ads,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAds,"footer-props":{ 'items-per-page-options': _vm.rowsPerPageItems }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-responsive',{attrs:{"aspect-ratio":2.5}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('h3',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t('components.datatable.noData'))+" ")]),_c('v-btn',{staticClass:"ma-4",attrs:{"color":"productColor","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.showAddAdDialog()}}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('components.ads.table.buttons.create'))+" ")])])],1)],1)],1)]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35px"}},[_c('v-img',{attrs:{"src":item.image
                    ? _vm.getFullPath(item.image)
                    : _vm.helpers.getPlaceholderImage(),"alt":"Avatar"}})],1)]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.is_static",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_static ? _vm.$t('components.ads.table.type.static') : _vm.$t('components.ads.table.type.dynamic'))+" ")]}},{key:"item.approved",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_static || item.approved ? _vm.$t('components.ads.table.approved.yes') : _vm.$t('components.ads.table.approved.no'))+" ")]}},{key:"item.clickthrough_rate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percent")(item.clickthrough_rate))+" ")]}},{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeMinutes")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(_vm.isAdmin || _vm.enableDuplicate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"productColor"},on:{"click":function($event){$event.stopPropagation();return _vm.showDuplicateAdDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.ads.table.buttons.duplicate')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"error","disabled":!(_vm.isAdmin || _vm.enableDelete)},on:{"click":function($event){$event.stopPropagation();return _vm.setAdDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.ads.table.buttons.delete')))])])]}}],null,true)})],1)],1)],1),_c('dialog-delete',{attrs:{"show":_vm.showDeleteDialog,"model-name":_vm.$t('models.single.ad'),"name":_vm.currentAdNameToDelete,"loading":_vm.deletingProgress},on:{"confirmed":_vm.deleteAd,"close-dialog":_vm.setAdDeleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }