<template>
  <v-container fluid>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <v-combobox
            v-model="searchAudience"
            :label="$t('components.audienceOverview.search.audiences')"
            chips
            multiple
            clearable
            deletable-chips
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            @update:search-input="searchAudiencesDebounced"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="8"
          md="6"
          class="d-flex justify-center justify-sm-end"
        >
          <filter-button-menu
            :menus="menus"
            :initial-filters="activeFilters"
            @filters-updated="filtersUpdated"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="7" class="order-sm-first">
          <v-breadcrumbs :items="breadcrumbs" class="text-subtitle-1 pl-3 pb-0">
            <template #item="{ item }">
              <v-breadcrumbs-item
                :disabled="item.id === rootAudienceId"
                link
                :class="{ pointer: item.id !== rootAudienceId }"
                @click="loadChildren(item)"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          class="d-flex align-center justify-center justify-sm-end order-first"
        >
          <v-tooltip v-if="$acl.check('viewModuleAdmin')" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :loading="loadingCache"
                :disabled="loading"
                color="primary"
                v-on="on"
                @click="refreshCache"
              >
                <v-icon left> mdi-refresh </v-icon>
                <span class="hidden-xs-only">
                  {{
                    $t('components.audienceOverview.buttons.refreshCache.label')
                  }}
                </span>
                <span class="hidden-sm-and-up">
                  {{
                    $t(
                      'components.audienceOverview.buttons.refreshCache.labelXs'
                    )
                  }}
                </span>
              </v-btn>
            </template>
            <span>{{
              $t('components.audienceOverview.buttons.refreshCache.tooltip')
            }}</span>
          </v-tooltip>
          <v-btn
            :loading="loadingDownload"
            :disabled="loadingDownload"
            color="primary"
            class="ml-1"
            @click.stop="downloadAudiences"
          >
            <v-icon left> mdi-download </v-icon>
            <span>{{
              $t('components.audienceOverview.buttons.download')
            }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-container fluid>
        <v-row class="mt-3">
          <v-col
            v-for="item in filteredUserAudiences"
            :key="item.id"
            cols="12"
            sm="6"
            lg="3"
            class="pa-1"
          >
            <v-card class="elevation-10">
              <v-img
                :src="
                  item.filename ? item.filename : helpers.getPlaceholderImage()
                "
                :class="{ 'is-navigatable': hasChildren(item.id) }"
                aspect-ratio="2.4"
                class="audience-card"
                height="100%"
                @click="loadChildren(item)"
              >
                <v-container
                  class="audience-container d-flex flex-column justify-space-between"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <span
                        :class="{
                          'max-font-size-big': $vuetify.breakpoint.mdAndDown,
                        }"
                        class="white--text text-uppercase audience-name"
                      >
                        {{ item.text }}
                      </span>
                      <v-btn
                        small
                        text
                        icon
                        color="white"
                        :class="{
                          'negative-top-margin-lg-and-down':
                            $vuetify.breakpoint.lgAndDown,
                        }"
                        @click.stop="showDetails(item.id)"
                      >
                        <v-icon> mdi-information </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div class="number count">
                        <span v-if="item.visitorsLoaded" class="white--text">
                          <animated-number
                            :data="item.visitorCount"
                            :duration="2000"
                          />
                        </span>
                        <span v-else>
                          <v-progress-circular
                            :size="30"
                            indeterminate
                            color="white"
                          />
                        </span>
                      </div>
                      <div
                        v-if="activeFilters.timeframe !== 'today'"
                        :class="{
                          'leads-font-size-bigger': $vuetify.breakpoint.lgAndUp,
                        }"
                      >
                        <span class="white--text">
                          <template v-if="item.leadsLoaded">
                            <animated-number
                              :data="item.leadsCount"
                              :duration="500"
                            />
                          </template>
                          <template v-else>
                            <v-progress-circular
                              :size="10"
                              :width="2"
                              indeterminate
                              color="white"
                            />
                          </template>
                        </span>
                        <span class="white--text">
                          {{ $t('components.audienceOverview.cards.leads') }}
                        </span>
                        <span class="white--text">
                          ({{
                            item.visitorCount
                              | formatPercentage(item.leadsCount)
                          }})
                        </span>
                        <v-btn
                          v-show="item.leadsCount"
                          class="no-margin"
                          small
                          text
                          icon
                          color="white"
                          @click.stop="downloadLeads(item.id, item.text)"
                        >
                          <span
                            v-if="
                              loadingLeads || loadingDownloadLeads === item.id
                            "
                          >
                            <v-progress-circular
                              :size="15"
                              :width="3"
                              indeterminate
                              color="white"
                            />
                          </span>
                          <v-tooltip v-else bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-download
                              </v-icon>
                            </template>
                            <span>{{
                              $t('components.audienceOverview.icons.leads')
                            }}</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <dialog-detail
      :id="currentAudienceId"
      :show="showDetailsDialog"
      :type="activeFilters.type"
      @close-dialog="setDetailsDialog(false)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUDIENCE_TYPES } from '@/utils/constants';

import AnimatedNumber from '@/components/AnimatedNumber';
import FilterButtonMenu from '@/components/FilterButtonMenu';
import DialogDetail from './DialogDetail';
import AudienceResource from '../../resources/AudienceResource';
import helpers from '@/utils/helpers';

export default {
  components: { AnimatedNumber, FilterButtonMenu, DialogDetail },
  filters: {
    formatCount(count) {
      return numeral(count).format('0.[00] a').toUpperCase();
    },
    formatPercentage(count, base) {
      return numeral(base > 0 ? count / base : 0).format('0.00 %');
    },
    formatProgress(value) {
      return numeral(value).format('0 %');
    },
  },
  data() {
    return {
      helpers,
      searchAudience: [],
      rootAudienceId: null,
      totalItems: 0,
      affinityScore: 1,
      affinityLabel: this.$t(
        'components.audienceOverview.affinitySlider.label'
      ),
      breadcrumbs: [
        {
          text: this.$t('components.audienceOverview.breadcrumbs.interests'),
          id: null,
          isNavigatable: true,
        },
      ],
      loading: false,
      loadingCache: false,
      loadingLeads: false,
      loadingDownload: false,
      loadingDownloadLeads: 0,
      activeFilters: { timeframe: 'today', type: AUDIENCE_TYPES.INTEREST },
      menus: {
        type: {
          selected: true,
          selectedItemRequired: true,
          name: this.$t('components.filterButtonMenu.filters.type.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.type.interests'
              ),
              value: AUDIENCE_TYPES.INTEREST,
              selected: true,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.type.properties'
              ),
              value: AUDIENCE_TYPES.PROPERTY,
              selected: false,
            },
          ],
        },
        timeframe: {
          selected: true,
          selectedItemRequired: true,
          name: this.$t('components.filterButtonMenu.filters.timeframe.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.today'
              ),
              value: 'today',
              selected: true,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.yesterday'
              ),
              value: 'yesterday',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last7Days'
              ),
              value: 'last7Days',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last30Days'
              ),
              value: 'last30Days',
              selected: false,
            },
          ],
        },
        minVisitors: {
          selected: false,
          name: this.$t(
            'components.filterButtonMenu.filters.minVisitors.label'
          ),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan10k'
              ),
              value: '10000',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan100k'
              ),
              value: '100000',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVisitors.moreThan1m'
              ),
              value: '1000000',
              selected: false,
            },
          ],
        },
        leads: {
          selected: false,
          name: this.$t('components.filterButtonMenu.filters.leads.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.leads.hasLeads'
              ),
              value: 'hasLeads',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.leads.noLeads'
              ),
              value: 'noLeads',
              selected: false,
            },
          ],
        },
      },
      showDetailsDialog: false,
      currentAudienceId: 0,
    };
  },
  computed: {
    dataQuality() {
      let text = this.$t(
        'components.audienceOverview.affinitySlider.dataQuality.low'
      );
      if (this.affinityScore > 3)
        text = this.$t(
          'components.audienceOverview.affinitySlider.dataQuality.medium'
        );
      if (this.affinityScore > 6)
        text = this.$t(
          'components.audienceOverview.affinitySlider.dataQuality.high'
        );
      return text;
    },
    ...mapGetters('audience', [
      'visitorCountPercentage',
      'userInterestsDmp',
      'userVisitorPropertiesDmp',
      'aiTopics',
    ]),
    userAudiences() {
      if (this.activeFilters.type === AUDIENCE_TYPES.INTEREST) {
        return this.userInterestsDmp;
      }
      return this.userVisitorPropertiesDmp;
    },
    filteredUserAudiences() {
      return this.userAudiences.filter((item) => item.show);
    },
    initialBreadcrumbs() {
      let audienceTypeName = this.$t(
        'components.audienceOverview.breadcrumbs.properties'
      );
      if (this.activeFilters.type === AUDIENCE_TYPES.INTEREST) {
        audienceTypeName = this.$t(
          'components.audienceOverview.breadcrumbs.interests'
        );
      }
      return [{ text: audienceTypeName, id: null, isNavigatable: true }];
    },
  },
  mounted() {
    this.overrideBrowserBackButtonBehavior();
    this.getAudiences();
  },
  methods: {
    getStatsForAudienceDebounced: _.debounce(function search() {
      this.getStatsForAudience();
    }, 500),
    async refreshCache() {
      this.loadingCache = true;
      await this.getAudienceStats(true);
      this.loadingCache = false;
    },
    overrideBrowserBackButtonBehavior() {
      window.history.pushState(null, null, document.URL);
      window.addEventListener('popstate', () => {
        if (this.breadcrumbs.length > 1) {
          const activeItem = this.breadcrumbs[this.breadcrumbs.length - 2];
          this.rootAudienceId = activeItem.id;
          // filter audiences
          this.searchAudiences();
          this.refreshBreadcrumbs(activeItem);
        }
        window.history.pushState(null, null, document.URL);
      });
    },
    async filtersUpdated({ activeFilters, lastUpdatedKey }) {
      this.activeFilters = activeFilters;
      //  if key is timeframe then make a request
      if (lastUpdatedKey === 'timeframe') {
        this.loading = true;
        await this.getAudienceStats();
        this.loading = false;
      } else {
        // otherwise just filter the audiences locally
        if (lastUpdatedKey === 'type') {
          // if the type was changed reset view and breadcrumbs
          this.breadcrumbs = this.initialBreadcrumbs;
          this.rootAudienceId = null;
        }
        this.searchAudiences();
      }
    },
    hasChildren(id) {
      return !!this.userAudiences.filter(
        (item) => item.parent_id === id && item.filtered
      ).length;
    },
    loadChildren(item) {
      if (this.hasChildren(item.id)) {
        // this.searchAudience = [];
        this.rootAudienceId = item.id;
        // filter audiences
        this.searchAudiences();
        this.refreshBreadcrumbs(item);
      }
    },
    refreshBreadcrumbs(activeitem) {
      let index = this.breadcrumbs.indexOf(activeitem);
      if (index > -1) {
        index += 1;
        this.breadcrumbs = this.breadcrumbs.slice(0, index);
      } else this.breadcrumbs.push(activeitem);
    },
    async getVisitorCount(refreshCache = false) {
      const params = {
        refreshCache,
        filters: this.activeFilters,
      };
      await this.$store.dispatch('audience/getVisitorCount', params);
      // trigger search audience to display the right audiences
      this.searchAudiences();
    },
    async getLeadsCount(refreshCache = false) {
      // only get leads if they are not from today
      if (this.activeFilters.timeframe !== 'today') {
        this.loadingLeads = true;
        const params = {
          refreshCache,
          filters: this.activeFilters,
        };
        await this.$store.dispatch('audience/getLeadsCount', params);
        this.loadingLeads = false;
        // trigger search audience to display the right audiences
        this.searchAudiences();
        this.checkForMissingLeadsCount(params);
      }
    },
    async checkForMissingLeadsCount(params) {
      const missingAudienceIds = this.userAudiences.reduce((res, item) => {
        if (!item.leadsLoaded) {
          res.push(item.id);
        }
        return res;
      }, []);
      // if there are are audiences missing call them explicitly
      if (missingAudienceIds.length) {
        params.refreshCache = true;
        params.missingAudienceIds = missingAudienceIds;
        await this.$store.dispatch('audience/getLeadsCount', params);
      }
    },
    async getAudienceStats(refreshCache = false) {
      return Promise.all([
        this.getVisitorCount(refreshCache),
        this.getLeadsCount(refreshCache),
      ]);
    },
    async getAudiences() {
      this.loading = true;
      await this.$store.dispatch('audience/getAudiences');
      await this.getAudienceStats();
      this.$store.commit('audience/FILTER_AUDIENCES', {
        rootAudienceId: null,
        activeFilters: this.activeFilters,
      });
      this.$store.commit('audience/SET_TOTAL_COUNT');
      this.loading = false;
    },
    searchAudiencesDebounced: _.debounce(function search(searchText) {
      this.searchAudiences(searchText);
    }, 200),
    searchAudiences(searchText = null) {
      // add current searchText to search array (if any)
      const search = _.clone(this.searchAudience);
      if (searchText) search.push(searchText);
      // if search bar is filled filter by search
      if (search.length) {
        this.$store.commit('audience/SEARCH_AUDIENCE', search);
      } else {
        // otherwise filter
        this.$store.commit('audience/FILTER_AUDIENCES', {
          rootAudienceId: this.rootAudienceId,
          activeFilters: this.activeFilters,
        });
      }
    },
    async downloadLeads(id, audienceName) {
      const params = {
        id,
        affinityScore: this.affinityScore,
        timeframe: this.activeFilters.timeframe,
        type: 'csv',
      };
      this.loadingDownloadLeads = id;
      try {
        const response = await this.$store.dispatch(
          'audience/downloadLeadsOfAudience',
          params
        );
        // set link to download leads as csv
        // taken from https://stackoverflow.com/questions/3499597/javascript-jquery-to-download-file-via-post-with-json-data
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${audienceName}_Leads.csv`;
        link.target = '_blank';
        link.click();
      } catch (err) {
        console.error(err);
      }
      this.loadingDownloadLeads = 0;
    },
    async downloadAudiences() {
      this.loadingDownload = true;
      try {
        const response = await new AudienceResource().downloadAudiences({
          locale: localStorage.language || 'en',
        });
        // set link to download leads as csv
        // taken from https://stackoverflow.com/questions/3499597/javascript-jquery-to-download-file-via-post-with-json-data
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `audiences_${new Date()
          .toISOString()
          .substring(0, 19)
          .replace(':', '_')}.csv`;
        link.target = '_blank';
        link.click();
      } catch (err) {
        console.error(err);
      }
      this.loadingDownload = false;
    },
    showDetails(id) {
      this.currentAudienceId = id;
      this.setDetailsDialog(true);
    },
    setDetailsDialog(state = true) {
      this.showDetailsDialog = state;
      // reset current audience id if details view closed
      if (!state) {
        this.currentAudienceId = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.number {
  font-size: 3vmax;
  font-weight: 600;
}
.number.count {
  max-height: 3vmax;
  margin-top: -0.8vmax;
  margin-bottom: 0.8vmax;
}
.leads-font-size-bigger {
  font-size: 1vmax;
}
.audience-name {
  font-size: 1.5vmax;
  font-weight: 500;
  min-height: 2.2em;
  line-height: 1.5vmax;
  word-break: break-word;
}
.max-font-size-big {
  font-size: 2.5vmax;
  line-height: 2.5vmax;
}
.audience-count {
  max-height: 50px;
}
.audience {
  color: white !important;
}
.audience-card > .v-image__image {
  filter: brightness(65%);
}
.container.audience-container {
  min-height: 150px;
  height: 100%;
}
.audience.card {
  background-color: black !important;
}
.is-navigatable:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.v-btn {
  &.no-margin {
    margin: 0px;
  }
  /* needed to position the (i) icon on the same line like the name */
  &.negative-top-margin-lg-and-down {
    margin-top: -0.3em;
  }
}
</style>
