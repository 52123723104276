import BaseProxy from './BaseProxy';
class AdProxy extends BaseProxy {
  /**
   * The constructor for the AdProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super('/v1/recommendations', parameters);
  }

  /**
   * Method to retrieve all ads of user as datatable
   *
   * @param {Object} params The query parameters.
   * @returns {Promise} The result in a promise.
   */
  datatables(params) {
    return this.submit('get', 'datatables', params);
  }

  /**
   * Method to retrieve an ad by id
   *
   * @param {Integer} id The id of the ad.
   * @returns {Promise} The result in a promise.
   */
  getAdById(id) {
    return this.submit('get', `${id}`);
  }

  /**
   * Method to retrieve pixels of type "cta_click"
   *
   * @returns {Promise} The result in a promise.
   */
  getPixelsOfUser() {
    return this.submit('get', 'pixels');
  }

  /**
   * Method to retrieve all questions of a user
   *
   * @param {Object} params The query parameters.
   * @returns {Promise} The result in a promise.
   */
  /**
   * Method to retrieve all elements of a user
   *
   * @param {Object} params The query parameters.
   * @returns {Promise} The result in a promise.
   */
  getElementsOfUser(params) {
    return this.submit('get', 'elements', params);
  }

  /**
   * Method to retrieve all ad targetings of a user
   *
   * @returns {Promise} The result in a promise.
   */
  getAdTargetingsOfUser() {
    return this.submit('get', 'targeting');
  }
}

export default AdProxy;
