/**
 * Helper Service to generate card id for monitoring dashboard/bigboard
 * stores current maximum card id in localstorage (initialized by looking at vuex store)
 * id is continouly incremented
 */
import helpers from './helpers';
import store from '../store';

const state = helpers.generateLocalStorageFields(
  {
    maxCardId: { type: 'number', default: 0 },
  },
  'monitoring.status.'
);

function init() {
  const maxId = (prev, current) => (prev > current.i ? prev : current.i);
  state.maxCardId = Math.max(
    (store.state.monitoring.settings.monitoringGridLayout || []).reduce(
      maxId,
      0
    ),
    (store.state.monitoring.settings.monitoringGridLayoutBigboard || []).reduce(
      maxId,
      0
    )
  );
}

export default {
  /**
   * Generate card id for monitoring dashboard/bigboard
   * @returns {number} new unique card id
   */
  getNewCardId() {
    if (!state.maxCardId) {
      init();
    }
    state.maxCardId += 1;
    return state.maxCardId;
  },
};
