<template>
  <div
    v-show="user"
    class="content-header font-weight-bold text-uppercase"
    :class="{ absolute: absoluteContentHeader }"
  >
    <div class="section sectionTitle--text">
      {{ sectionTitle }}
    </div>
    <div class="page productColor--text">
      {{ pageTitle }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    /**
     * Receive account data from store to access it in this component
     */
    ...mapState('account', ['user']),
    /**
     * The current page title
     *
     * @returns {string}
     */
    pageTitle() {
      const routeName = this.$route.name;
      if (routeName) {
        return this.$t(`menu.contentHeader.${routeName}.page`);
      }
      return '';
    },
    /**
     * The section title the page belongs to
     *
     * @returns {string}
     */
    sectionTitle() {
      const routeName = this.$route.name;
      if (routeName) {
        return this.$t(`menu.contentHeader.${routeName}.section`);
      }
      return '';
    },
    /**
     * Indicator whether to place content header as absolute prop
     *
     * @returns {boolean} absoluteContentHeader
     */
    absoluteContentHeader() {
      return this.$route.meta && this.$route.meta.absoluteContentHeader;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-header {
  &.absolute {
    position: absolute;
    z-index: 1;
  }
  .section {
    font-size: 0.8rem;
    opacity: 0.6;
  }

  .page {
    font-size: 1.6rem;
  }
}
</style>
