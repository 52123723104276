/* ============
 * NumeralJs
 * ============
 *
 * NumeralJs
 *
 * http://numeraljs.com/
 */

import numeral from 'numeral';

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});
numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal() {
    return '.';
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale(localStorage.language);
window.numeral = numeral;
