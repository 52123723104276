<template>
  <div
    :class="['dropzone', 'full-width', hovering && 'hovered']"
    @dragenter="hovering = true"
    @dragleave="hovering = false"
    @drop="hovering = false"
  >
    <input
      ref="image"
      type="file"
      accept="image/*"
      class="input-file"
      @change="onFilePicked"
    />
    <div v-if="!hasData" class="dropzone-text" @click="pickFile">
      <span class="dropzone-title">
        {{ $t('components.addImageDialog.preview.instructions') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    hasData: {
      default: false,
      type: Boolean,
    },
    active: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      imageName: '',
      hovering: false,
    };
  },
  watch: {
    'errors.items': function error(items) {
      if (this.errors.has('dropzone') && items.length > 0) {
        this.$bus.fire('show-snackbar', {
          message: this.errors.first('dropzone'),
        });
      }
    },
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const { files } = e.target;

      if (files[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.selectFile(fr.result);
        });
      }
    },
    selectFile(url) {
      this.$emit('set-active', 'dropzone');

      // set selected file to preview
      const data = {
        imageUrl: url,
        isBase64: true,
      };
      this.$store.commit('image/SELECT_ITEM', data);
    },
    clearImage() {
      this.$bus.fire('clear-image');
    },
  },
};
</script>
<style lang="scss" scoped>
.dropzone {
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  &.hovered {
    border: 3px dashed #2e94c4;
  }
}
.dropzone-text {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 100%;
  span {
    display: block;
    line-height: 1.9;
  }
}
.dropzone-title {
  font-size: 13px;
  letter-spacing: 0.4px;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}
</style>
