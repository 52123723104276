<template>
  <v-card>
    <p-image v-if="topImage && data.loaded" :src="topImage" height="134px" />
    <expandable-number-list
      :data="channelData"
      :data-loaded="data.loaded"
      :value-header="$t('monitoring.data.users')"
      :key-header="$t('monitoring.data.channels')"
      :key-url="
        (item, level) => (level === 0 ? false : '//' + item.domain + item.key)
      "
      :empty-key-value="$t('monitoring.components.no_data')"
      :translations="rowTranslations"
      :on-row-click="addChannelFilter"
      :max="10"
      count-field="users"
      key-field="title"
      children-field="pages"
      fixed-table
    >
      <template slot="no-data">
        <div class="pt-4 pb-2">
          {{ $t('monitoring.no_data') }}
        </div>
        <router-link :to="{ name: 'channels' }" class="alert">
          <v-alert
            color="info"
            icon="mdi-information"
            outlined
            style="margin-bottom: 20px"
          >
            {{ $t('components.channelTable.noDataHint') }}
          </v-alert>
        </router-link>
      </template>
    </expandable-number-list>
  </v-card>
</template>

<script>
import PImage from '@/components/PImage';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import Loadable from '@/utils/Loadable';
import helpers from '@/utils/helpers';

export default {
  components: {
    PImage,
    ExpandableNumberList,
  },

  props: {
    data: {
      type: Loadable,
      required: true,
      default: new Loadable([], false),
    },
  },

  computed: {
    topImage() {
      if (this.data.data.length < 1) return false;
      return helpers.firstImage(this.data.data[0].pages);
    },
    channelData() {
      return this.data.getData([]).filter((channel) => channel.users > 0);
    },
  },

  methods: {
    rowTranslations(item, level) {
      if (level === 0) return item.title;
      if (this.$store.state.monitoring.settings.displayPageTitle)
        return item.title || '-';
      return item.key;
    },

    addChannelFilter(channel) {
      const filter = {
        field: 'channel',
        value: channel.key,
        valueLabel: channel.title,
        fieldLabel: null,
      };

      this.$store.dispatch('monitoring/addFilter', { filter });
    },
  },
};
</script>

<style>
a.alert.alert {
  text-decoration: none;
}
a.alert:hover .v-alert > div {
  text-decoration: underline;
}
</style>
