<template>
  <div class="distribution-bar">
    <div :class="{ distribution: true, 'elevation-1': data && data.length }">
      <v-tooltip v-for="(item, index) in data" :key="item.key" bottom>
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="activator"
            :style="{
              width: (item.doc_count / total) * 100 + '%',
              'background-color': colorMap ? colorMap[item.key] : colors[index],
            }"
            :class="{
              segment: true,
              small: item.doc_count / total < 0.14,
              tiny: item.doc_count / total < 0.05,
              micro: item.doc_count / total < 0.01,
            }"
            v-on="on"
          >
            <span v-if="translate">
              {{ $t(translate + '.' + item.key) }}
            </span>
            <span v-else>
              {{ item.key }}
            </span>
          </div>
        </template>
        <span
          >{{ translate ? $t(translate + '.' + item.key) : item.key }}:
          {{ (item.doc_count / total) | formatPercentage }}</span
        >
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    formatPercentage(number) {
      return `${numeral(number * 100).format('0.00')}%`;
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    translate: {
      type: String,
      required: false,
      default: null,
    },
    colorMap: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      colors: [
        '#0074D9',
        '#22AB32',
        '#FF6347',
        '#F0C419',
        '#85144b',
        '#3D9970',
      ],
    };
  },
  computed: {
    total() {
      return this.data.reduce((acc, val) => acc + val.doc_count, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-grid {
  .distribution-bar {
    height: 68px;
    padding: 4px 0;
  }
}
.distribution-bar {
  height: 80px;
  padding: 10px 0;

  * {
    cursor: default;
  }

  .distribution {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
  }

  .segment {
    color: white;
    text-shadow: black 0 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: width 400ms ease;
  }

  .activator {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;

    &.segment {
      &.small > span {
        writing-mode: vertical-rl;
        text-orientation: sideways;
        line-height: 1em;
      }

      &.tiny > span {
        font-size: 8px;
      }

      &.micro {
        border: 0;

        & > span {
          display: none;
        }
      }
      &:not(:first-child) {
        border-left: 1px solid white;
      }
    }
  }
}
</style>
