<template>
  <div>
    <ValidationObserver ref="validationObserver" slim>
      <span>{{ $t('components.addImageDialog.static.url.title') }}</span>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('components.addImageDialog.static.url.search')"
        rules="url"
      >
        <v-text-field
          v-model="url"
          :label="$t('components.addImageDialog.static.url.search')"
          :error-messages="errors"
          prepend-icon="mdi-paperclip"
          @keydown.enter="selectFile"
          @click="selectFile"
          @focus="$event.target.select()"
          @blur="url = ''"
        />
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      url: '',
    };
  },
  watch: {
    url() {
      this.selectFileDebounced();
    },
  },
  methods: {
    /**
     * Select file debounced with a delay of 500 ms
     *
     * @returns {void}
     */
    selectFileDebounced: _.debounce(function search() {
      this.selectFile();
    }, 500),
    /**
     * Select file
     *
     * @param {string} url - url to load
     *
     * @returns {void}
     */
    async selectFile() {
      const valid = await this.$refs.validationObserver.validate();
      if (this.url && valid) {
        // set selected file to preview
        const data = {
          imageUrl: this.url,
          isBase64: true,
        };
        this.$store.commit('image/SELECT_ITEM', data);
      }
    },
  },
};
</script>

<style></style>
