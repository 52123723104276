/* ============
 * Mutations for the tracking module
 * ============
 *
 * The mutations that are available on the
 * tracking module.
 */

import { updateField } from 'vuex-map-fields';
import helpers from '@/utils/helpers';
import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  // Add the `updateField` mutation to the
  // `mutations` of the Vuex store instance.
  updateField,

  [types.SET_TRACKING](state, data) {
    state.tracking = data;
  },

  [types.SET_TRACKING_TO_EDIT](state, data) {
    // format date objects (only if they are defined)
    if (data.start_date || data.end_date) {
      data.start_date =
        data.start_date && moment(data.start_date).format('YYYY-MM-DD');
      data.end_date =
        data.end_date && moment(data.end_date).format('YYYY-MM-DD');
    }

    // prepare arrays to fit to select box
    data.assignedAds =
      data.assignedAds && data.assignedAds.map((item) => item.id);
    data.assignedQuestions =
      data.assignedQuestions && data.assignedQuestions.map((item) => item.id);
    data.assignedAnswers =
      data.assignedAnswers && data.assignedAnswers.map((item) => item.id);
    data.assignedForms =
      data.assignedForms && data.assignedForms.map((item) => item.id);
    data.assignedEvents =
      data.assignedEvents && data.assignedEvents.map((item) => item.event);
    data.assignedPixels = data.assignedPixels.map((item) => item.id);

    // format domain
    data.domain_id = data.domain_id || '0';

    // set data to form object
    state.form = data;
  },

  [types.SET_FILTERED_TRACKINGS](state, data) {
    state.filteredTrackings.items = data.data;
    state.filteredTrackings.total = data.total;
  },

  [types.NEXT_STEP](state) {
    if (state.steps.length > state.currentStep) {
      state.currentStep += 1;
    }
  },

  [types.PREV_STEP](state) {
    if (state.currentStep > 1) {
      state.currentStep -= 1;
    }
  },

  [types.SET_STEP](state, newStep) {
    state.currentStep = newStep;
  },

  [types.RESET_FORM](state) {
    state.validationErrors = {};
    state.form = {
      active: true,
      name: null,
      start_date: null,
      end_date: null,
      domain_id: '0',
      device_type: 'all',
      auto_assignment: false,
      assignedQuestions: [],
      assignedAnswers: [],
      assignedForms: [],
      assignedAds: [],
      assignedPixels: [],
      assignedEvents: [],
    };
    state.currentStep = 1;
    // reset rules property
    state.steps.map((step) => {
      step.rules = true;
      return step;
    });
  },

  [types.SET_ERRORS](state, errors) {
    // transform object to array
    state.validationErrors = helpers.transformErrorObject(errors);

    // set rules property depending on error name (to show error in steppper header)
    const keysArray = Object.keys(state.validationErrors);
    if (
      _.intersection(keysArray, [
        'name',
        'start_date',
        'end_date',
        'domain_id',
        'device_type',
      ]).length
    ) {
      state.steps[0].rules = false;
    }
    if (
      _.intersection(keysArray, [
        'auto_assignment',
        'assignedQuestions',
        'assignedAnswers',
        'assignedForms',
        'assignedAds',
      ]).length
    ) {
      state.steps[1].rules = false;
    }
    if (
      _.intersection(keysArray, ['assignedPixels', 'assignedEvents']).length
    ) {
      state.steps[2].rules = false;
    }
  },

  [types.DELETE_TRACKING](state, id) {
    state.filteredTrackings.items = state.filteredTrackings.items.filter(
      (campaign) => campaign.id !== id
    );
  },

  [types.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
};
