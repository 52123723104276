<template>
  <v-card class="mx-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" lg="3">
          <v-combobox
            v-model="search"
            :label="$t('components.audienceQuestionTable.search')"
            multiple
            autofocus
            chips
            clearable
            deletable-chips
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            @update:search-input="searchUpdated"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="8"
          lg="9"
          class="d-flex align-center justify-center justify-sm-end"
        >
          <filter-button-menu
            :menus="menus"
            @filters-updated="filtersUpdated"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
        @click:row="rowSelected"
      >
        <template #[`item.question_created_at`]="{ item }">
          {{ item.question_created_at | formatDateTimeMinutes }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import FilterButtonMenu from '@/components/FilterButtonMenu';

export default {
  components: {
    FilterButtonMenu,
  },
  data() {
    return {
      menus: {
        timeframe: {
          selected: true,
          name: this.$t('components.filterButtonMenu.filters.timeframe.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.today'
              ),
              value: 'today',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.yesterday'
              ),
              value: 'yesterday',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last7Days'
              ),
              value: 'last7Days',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.timeframe.last30Days'
              ),
              value: 'last30Days',
              selected: true,
            },
          ],
        },
        minVotes: {
          selected: false,
          name: this.$t('components.filterButtonMenu.filters.minVotes.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVotes.moreThan100'
              ),
              value: '100',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVotes.moreThan500'
              ),
              value: '500',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.minVotes.moreThan1000'
              ),
              value: '1000',
              selected: false,
            },
          ],
        },
        mappings: {
          selected: false,
          name: this.$t('components.filterButtonMenu.filters.mappings.label'),
          items: [
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.hasMappings'
              ),
              value: 'hasMappings',
              selected: false,
            },
            {
              text: this.$t(
                'components.filterButtonMenu.filters.mappings.noMappings'
              ),
              value: 'noMappings',
              selected: false,
            },
          ],
        },
      },
      activeFilters: { timeframe: 'last30Days' },
      search: [],
      loading: true,
      options: {
        page: 1,
        sortBy: ['question_created_at'],
        sortDesc: [true],
        itemsPerPage: 25,
      },
      rowsPerPageItems: [5, 10, 25, 50, 100],
      headers: [
        { text: '#', align: 'left', value: 'id' },
        {
          text: this.$t('components.audienceQuestionTable.headers.question'),
          value: 'question',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.votes'),
          align: 'center',
          value: 'votes',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.category'),
          value: 'title',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.domain'),
          value: 'domain',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.mappings'),
          align: 'center',
          value: 'audiences',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.language'),
          value: 'language',
        },
        {
          text: this.$t('components.audienceQuestionTable.headers.createdAt'),
          align: 'right',
          value: 'question_created_at',
        },
      ],
    };
  },
  computed: {
    ...mapState('question', {
      items: (state) => state.filteredQuestions.items,
      total: (state) => state.filteredQuestions.total,
    }),
    /**
     * Search Params
     *
     * @returns {object} searchParams
     */
    searchParams() {
      return {
        search: this.search,
        options: this.options,
        filters: this.activeFilters,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.getDataFromApiDebounced();
      },
      deep: true,
    },
  },
  created() {
    this.$bus.listen('data-updated', () => this.getDataFromApi());
  },
  destroyed() {
    this.$bus.remove('data-updated');
  },
  methods: {
    getDataFromApiDebounced: _.debounce(function search(text) {
      this.getDataFromApi(text);
    }, 500),
    filtersUpdated({ activeFilters }) {
      this.options.page = 1;
      this.activeFilters = activeFilters;
      this.getDataFromApi();
    },
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getDataFromApiDebounced(searchText);
    },
    async getDataFromApi(searchText = null) {
      // set loading data
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      // prepare params data
      const params = this.searchParams;
      params.search = search;

      try {
        await this.$store.dispatch('question/datatable', params);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    /**
     * Called when row is selected which opens the question dialog
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.$bus.fire('question-selected', item.id);
    },
  },
};
</script>
