/* ============
 * Actions for the form module
 * ============
 *
 * The actions that are available on the
 * form module.
 */

import FormResource from '@/resources/FormResource';
import * as types from './mutation-types';

export const getForm = ({ commit }, formId) => {
  new FormResource()
    .show(formId)
    .then((response) => {
      commit(types.SET_FORM, response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getForms = ({ commit }) =>
  new Promise((resolve, reject) => {
    new FormResource()
      .getForms()
      .then((response) => {
        commit(types.SET_FORMS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const datatable = ({ commit }, params) =>
  new Promise((resolve) => {
    new FormResource()
      .datatable(params)
      .then((data) => {
        commit(types.SET_FILTERED_FORMS, data);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export default {
  getForm,
  getForms,
  datatable,
};
