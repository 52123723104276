<template>
  <div class="full-width">
    <v-card style="min-height: 196px">
      <v-card-title class="text-h5">
        {{ pluralModelName }}
      </v-card-title>
      <v-card-text>
        <multiselect
          v-if="flattenTreeDataOneLevel.length > 0 && assignedAudiencesLoaded"
          v-model="selectedAudiences"
          :max-height="300"
          :disabled="!selectedField"
          :options="flattenTreeDataOneLevel"
          :multiple="true"
          :taggable="true"
          :tag-placeholder="
            $t('components.audiences.assignMultiSelect.tagPlaceholder', {
              model: modelName,
            })
          "
          :placeholder="
            $t('components.audiences.assignMultiSelect.placeholder', {
              model: modelName,
            })
          "
          :select-label="
            $t('components.audiences.assignMultiSelect.selectLabel')
          "
          :selected-label="
            $t('components.audiences.assignMultiSelect.selectedLabel')
          "
          :deselect-label="
            $t('components.audiences.assignMultiSelect.deselectLabel')
          "
          label="text"
          track-by="id"
          group-values="children"
          group-label="text"
          @tag="addTag"
          @select="assignAudience"
          @remove="unassignAudience"
        />
        <v-progress-circular v-else indeterminate color="primary" />
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="addParentCategoryDialog"
      max-width="500px"
      persistent
      @keydown.esc="closeParentCategoryDialog"
      @keydown.enter="saveNewTag"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{
              $t(
                'components.audiences.assignMultiSelect.parentCategoryDialog.title',
                { parentName: currentTag }
              )
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <multiselect
                  v-model="tagParentCategory"
                  :tabindex="10"
                  :options="treeData"
                  :allow-empty="false"
                  :tag-placeholder="
                    $t(
                      'components.audiences.assignMultiSelect.tagPlaceholder',
                      { model: modelName }
                    )
                  "
                  :placeholder="
                    $t('components.audiences.assignMultiSelect.placeholder', {
                      model: modelName,
                    })
                  "
                  :select-label="
                    $t('components.audiences.assignMultiSelect.selectLabel')
                  "
                  :selected-label="
                    $t('components.audiences.assignMultiSelect.selectedLabel')
                  "
                  :deselect-label="
                    $t('components.audiences.assignMultiSelect.deselectLabel')
                  "
                  track-by="id"
                  label="text"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            text
            tabindex="11"
            @click="closeParentCategoryDialog"
          >
            {{
              $t(
                'components.audiences.assignMultiSelect.parentCategoryDialog.cancel'
              )
            }}
          </v-btn>
          <v-btn color="success" tabindex="12" @click="saveNewTag">
            {{
              $t(
                'components.audiences.assignMultiSelect.parentCategoryDialog.save'
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { mapState, mapGetters } from 'vuex';
import helpers from '@/utils/helpers';

export default {
  components: { Multiselect },
  props: {
    audienceType: {
      default: 1,
      type: Number,
    },
    modelName: {
      type: String,
      default: '',
    },
    pluralModelName: {
      type: String,
      default: '',
    },
    mode: {
      default: 'question',
      type: String,
    },
    treeData: {
      default: () => [],
      type: Array,
    },
    assignedAudiencesLoaded: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      audienceLoaded: false,
      addParentCategoryDialog: false,
      currentTag: '',
      tagParentCategory: {},
      selectedAudiences: [],
      // define as data property to avoid problems debounce with multi components
      // check https://stackoverflow.com/questions/45908853/vuejs-2-debounce-on-multiple-components
      updateAffinityScoreDebounced: _.debounce(function assign() {
        if (this.selectedAudiences.length) {
          this.updateAffinityScore();
        }
      }, 500),
    };
  },
  computed: {
    ...mapState('audience', [
      'selectedField',
      'assignedAudiences',
      'affinityScore',
    ]),
    ...mapState('question', ['question']),
    ...mapState('metatag', ['metaTag']),
    ...mapState('form', ['form', 'formDataValue']),
    ...mapGetters('audience', ['userInterests']),
    flattenTreeDataOneLevel() {
      return helpers.createOneLevelTree(_.cloneDeep(this.treeData));
    },
    modeParams() {
      return {
        behaviour: { metaTag: this.metaTag },
        question: {},
        form: {
          formId: this.form && this.form.id,
          formData: this.formDataValue,
        },
      };
    },
  },
  watch: {
    selectedField() {
      this.updateSelectBox();
      this.$store.commit('audience/UPDATE_AFFINITY_SCORE');
    },
    formDataValue() {
      // update select box when form data is changing
      this.updateSelectBox();
    },
  },
  mounted() {
    this.$bus.listen('affinity-score-updated', () =>
      this.updateAffinityScoreDebounced()
    );
    this.getAudiences();
  },
  destroyed() {
    this.$bus.remove('affinity-score-updated');
  },
  methods: {
    updateSelectBox() {
      this.selectedAudiences = this.assignedAudiences[this.selectedField] || [];

      // set to selected and filter to audience type
      this.selectedAudiences = this.selectedAudiences.filter(
        (item) => item.type_id === this.audienceType
      );

      // filter to values with exact match if mode is form
      if (this.mode === 'form') {
        this.selectedAudiences = this.selectedAudiences.filter(
          (item) => item.form_data === this.formDataValue
        );
      }
    },
    // get all audiences as tree
    async getAudiences() {
      await this.$store.dispatch('audience/getAudiences');
      this.audienceLoaded = true;
    },
    setParentCategory() {
      // preselect to the category of the question
      this.tagParentCategory = _.find(this.flattenTreeDataOneLevel, {
        id: this.question && this.question.category_id,
      });
    },
    addTag(newTag) {
      // set a predefined parent category
      this.setParentCategory();

      // show dialog
      this.addParentCategoryDialog = true;
      this.$bus.fire('show-parent-category-dialog', true);

      // set new tag to uppercase
      this.currentTag = _.upperFirst(newTag);
    },
    saveNewTag() {
      if (this.tagParentCategory) {
        // prepare post data
        const postData = {
          mode: this.mode,
          audience: {
            text: this.currentTag,
            parent_id: this.tagParentCategory.id,
            type_id: this.audienceType,
          },
          selectedField: this.selectedField,
          affinityScore: this.affinityScore || 1,
          modeParams: this.modeParams[this.mode],
        };

        this.postNewTag(postData);

        // close dialog
        this.closeParentCategoryDialog();
      } else {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.selectParentAudience'),
        });
      }
    },
    // post new audience to controller
    async postNewTag(postData) {
      try {
        await this.$store.dispatch('audience/assignNewAudience', postData);
        this.showSnackbarResponse(
          this.$t('components.snackbar.text.audienceAssigned')
        );
      } catch (error) {
        console.log(error);
        this.$bus.fire('show-snackbar', {
          message: error,
          options: { color: 'error' },
        });
      }
      this.updateSelectBox();
    },
    // action when closing the dialog
    closeParentCategoryDialog() {
      this.$bus.fire('show-parent-category-dialog', false);
      this.addParentCategoryDialog = false;
      this.currentTag = '';
    },
    // post assigned audiences to controller
    async assignAudience(audience) {
      if (audience) {
        // prepare post data
        const postData = {
          mode: this.mode,
          audienceId: audience.id,
          selectedField: this.selectedField,
          affinityScore: this.affinityScore || 1,
          modeParams: this.modeParams[this.mode],
        };

        try {
          await this.$store.dispatch('audience/assignAudience', {
            postData,
            audience,
          });
          this.showSnackbarResponse(
            this.$t('components.snackbar.text.audienceAssigned')
          );
        } catch (error) {
          console.log(error);
          this.$bus.fire('show-snackbar', {
            message: error,
            options: { color: 'error' },
          });
        }
      }
    },
    async unassignAudience(item) {
      if (item) {
        let { mappedAudienceId } = item;
        // if the item doesnt have a mapped audience id take it from the selectedField array
        // fixes bug if user is deselecting item over list box and not by clicking on the X of the tag
        if (!mappedAudienceId) {
          /* eslint-disable-next-line prefer-destructuring */
          mappedAudienceId = this.selectedAudiences.find((field) => {
            // filter to values with exact match if mode is form
            if (this.mode === 'form') {
              return (
                field.id === item.id && field.form_data === this.formDataValue
              );
            }
            return field.id === item.id;
          }).mappedAudienceId;
        }
        // prepare post data
        const postData = {
          mode: this.mode,
          mappedAudienceId,
        };

        try {
          await this.$store.dispatch('audience/unassignAudience', postData);
          this.showSnackbarResponse(
            this.$t('components.snackbar.text.audienceUnassigned')
          );
        } catch (error) {
          console.log(error);
          this.$bus.fire('show-snackbar', {
            message:
              (error.mappedAudienceId && error.mappedAudienceId.msg) || error,
            options: { color: 'error' },
          });
        }
      }
    },
    async updateAffinityScore() {
      // prepare post data
      const postData = {
        mode: this.mode,
        mappedAudienceIds: this.selectedAudiences.map(
          (el) => el.mappedAudienceId
        ),
        affinityScore: this.affinityScore,
      };
      try {
        await this.$store.dispatch('audience/updateAffinityScore', postData);
        this.showSnackbarResponse(
          this.$t('components.snackbar.text.affinityScoreUpdated')
        );
      } catch (error) {
        console.log(error);
        this.$bus.fire('show-snackbar', {
          message: error,
          options: { color: 'error' },
        });
      }
    },
    showSnackbarResponse(message) {
      this.$bus.fire('show-snackbar', {
        message,
      });

      this.$emit('data-updated');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
/* change the overflow to display the muliiselect properly in a dialog */
.v-dialog.v-dialog--active {
  overflow-y: visible;
}

.v-card {
  /* overwrite style in multiselect content since vuetify is setting a default padding-left for list (.ul and .ol of 24px) */
  .multiselect__content-wrapper {
    ul,
    ol {
      padding-left: 0px;
    }
  }
}

/* overwrite style of multiselect for dark mode */
.v-card.v-sheet.theme--dark {
  .multiselect {
    color: white;
    .multiselect__input::placeholder {
      color: white;
    }
    .multiselect__tags,
    .multiselect__content-wrapper,
    .multiselect__input,
    .multiselect__single {
      background-color: #424242;
    }
    .multiselect__option--group,
    .multiselect__option--disabled {
      background-color: #252525 !important;
    }
    &.multiselect--disabled {
      .multiselect__select {
        background-color: #252525;
      }
    }
  }
}
</style>
