<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
        scrollable
        @keydown.esc="closeDetail"
      >
        <v-card>
          <v-card-title class="pp-custom-background text-h5">
            {{
              $t('components.audiences.assignDetailView.title', {
                model: modelName,
              })
            }}
            <v-row justify="end">
              <v-btn icon @click="closeDetail">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-card-text>
            <assign-affinity-slider />
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <assign-question-simple
                    v-if="mode === 'question'"
                    :id="currentId"
                  />
                  <assign-meta-tags
                    v-if="mode === 'behaviour'"
                    :meta-tag-value="currentValue"
                    :active-filters="activeFilters"
                  />
                  <assign-form-data v-if="mode === 'form'" :id="currentId" />
                </v-col>
                <v-col cols="12" sm="6">
                  <assign-multi-select
                    :audience-type="1"
                    :model-name="$t('models.single.interest')"
                    :plural-model-name="$t('models.plural.interest')"
                    :mode="mode"
                    :tree-data="userInterests"
                    :assigned-audiences-loaded="assignedAudiencesLoaded"
                    @data-updated="handleDataUpdatedDebounced"
                  />
                  <assign-multi-select
                    :audience-type="2"
                    :model-name="$t('models.single.property')"
                    :plural-model-name="$t('models.plural.property')"
                    :mode="mode"
                    :tree-data="userVisitorProperties"
                    :assigned-audiences-loaded="assignedAudiencesLoaded"
                    class="mt-2"
                    @data-updated="handleDataUpdatedDebounced"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn color="primary" text @click="closeDetail">
                  {{ $t('components.audiences.assignDetailView.close') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssignQuestionSimple from './AssignQuestionSimple';
import AssignMetaTags from './AssignMetaTags';
import AssignFormData from './AssignFormData';
import AssignMultiSelect from './AssignMultiSelect';
import AssignAffinitySlider from './AssignAffinitySlider';

export default {
  components: {
    AssignMetaTags,
    AssignQuestionSimple,
    AssignMultiSelect,
    AssignFormData,
    AssignAffinitySlider,
  },
  data() {
    return {
      mode: 'question',
      dialog: false,
      assignedAudiencesLoaded: false,
      currentId: 0,
      currentValue: '',
      activeFilters: {},
    };
  },
  computed: {
    ...mapGetters('audience', ['userInterests', 'userVisitorProperties']),
    ...mapState('question', ['question']),
    ...mapState('metatag', ['metaTag', 'key']),
    ...mapState('form', ['form']),
    modelName() {
      if (this.mode === 'question')
        return this.$t('models.plural.questionData');
      if (this.mode === 'behaviour')
        return this.$t('models.plural.surfBehaviour');
      if (this.mode === 'form') return this.$t('models.plural.formData');
      return '';
    },
  },
  watch: {
    // update box when the question is loaded
    question(value) {
      this.assignedAudiencesLoaded = false;
      if (value.id) {
        this.getAssignedAudiences();
      }
    },
    async metaTag(value) {
      this.assignedAudiencesLoaded = false;
      if (value) {
        await this.getAssignedAudiences();
        this.$store.commit('audience/SET_SELECTED_FIELD', this.key);
      }
    },
    form(value) {
      this.assignedAudiencesLoaded = false;
      if (value) {
        this.getAssignedAudiences();
      }
    },
  },
  created() {
    this.$bus.listen('meta-tag-selected', this.setMetaTagProps);
    this.$bus.listen('question-selected', this.setQuestionId);
    this.$bus.listen('form-selected', this.setFormId);
  },
  methods: {
    openDetail(mode) {
      this.mode = mode;
      this.dialog = true;
    },
    closeDetail() {
      this.dialog = false;
      this.currentId = 0;
      this.currentValue = '';
      this.activeFilters = {};
      this.$bus.fire('hide-assign-detail-view');
    },
    /**
     * Set current id to question id and open detail view of question
     *
     * @param {number} id - question id
     *
     * @returns {void}
     */
    setQuestionId(id) {
      this.currentId = id;
      this.openDetail('question');
    },
    /**
     * Set current id to form id and open detail view of form
     *
     * @param {number} id - form id
     *
     * @returns {void}
     */
    setFormId(id) {
      this.currentId = id;
      this.openDetail('form');
    },
    /**
     * Set meta tags props and open detail view of behaviour
     *
     * @param {object} props - meta tag props
     *
     * @returns {void}
     */
    setMetaTagProps(props) {
      if (props) {
        this.activeFilters = props.activeFilters;
        this.currentValue = props.value;
      }
      this.openDetail('behaviour');
    },
    // get all assigned audiences for the current fields
    async getAssignedAudiences() {
      // define specific params depending on mode
      const specificParams = {
        behaviour: { metaTag: this.metaTag, key: this.key },
        question: { questionId: this.question.id },
        form: { formId: this.form.id },
      };
      const params = {
        mode: this.mode,
        ...specificParams[this.mode],
      };
      // load assigned audiences
      await this.$store.dispatch('audience/getAssignedAudiences', params);
      this.assignedAudiencesLoaded = true;
    },
    handleDataUpdatedDebounced: _.debounce(function dataUpdated() {
      this.$bus.fire('data-updated');
    }, 200),
  },
};
</script>

<style></style>
