<template>
  <v-card style="min-height: 400px">
    <v-card-title v-if="question.question" class="text-h5">
      {{ question.question }} ({{ question.votes }})
    </v-card-title>
    <v-card-text>
      <template v-if="question.question">
        {{ $t('components.audienceAssignQuestionSimple.answers') }}
        <v-radio-group v-model="selectedField" :mandatory="false">
          <v-list subheader>
            <v-list-item
              v-for="(answer, index) in question.answers"
              :key="answer.id"
            >
              <v-radio
                :value="answer.id"
                :label="
                  answer.answer ||
                  $t('common.answerMedia.fallback', { id: index + 1 }) +
                    ' (' +
                    answer.votes +
                    ')'
                "
                color="success"
              />
            </v-list-item>
          </v-list>
        </v-radio-group>
      </template>
      <v-progress-circular v-else indeterminate color="primary" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('question', ['question']),
    selectedField: {
      get() {
        return this.$store.state.audience.selectedField;
      },
      set(value) {
        this.$store.commit('audience/SET_SELECTED_FIELD', value);
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getQuestion(newVal);
        }
      },
    },
  },
  created() {
    this.$bus.listen('hide-assign-detail-view', this.resetQuestion);
  },
  destroyed() {
    this.$bus.remove('hide-assign-detail-view');
  },
  methods: {
    getQuestion(id) {
      this.$store.dispatch('question/getQuestion', id);
    },
    resetQuestion() {
      this.$store.commit('question/SET_QUESTION', '');
      this.selectedField = null;
    },
  },
};
</script>

<style></style>
