<template>
  <audience-tree-level
    :data="data"
    :selected="value"
    :parents-of-selected="parentsOfSelected"
  />
</template>

<script>
import AudienceTreeLevel from './AudienceTreeLevel';

export default {
  components: {
    AudienceTreeLevel,
  },

  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    parentsOfSelected() {
      const result = new Set([]);
      const traverse = (audiences, parents = []) => {
        for (let i = 0; i < audiences.length; i += 1) {
          if (parents.length && this.value.includes(audiences[i].id)) {
            result.add(...parents);
          }
          if (audiences[i].children)
            traverse(audiences[i].children, [audiences[i].id, ...parents]);
        }
      };
      traverse(this.data);
      return Array.from(result);
    },
  },
};
</script>
