/* ============
 * German Language File
 * ============
 *
 * The german language file.
 */

import menu from './menu.json';
import common from './common.json';
import components from './components.json';
import models from './models.json';
import monitoring from './monitoring.json';
import pages from './pages.json';
import validations from './validations.json';

export default {
  menu,
  common,
  components,
  models,
  monitoring,
  pages,
  validations,
};
