<template>
  <div class="wrapper">
    <!-- Referrer -->
    <v-card
      v-if="referrerData && currentDataListMode === 'referrer'"
      class="scrollable"
    >
      <p-image
        v-if="currentPageImage && referrerData.loaded"
        :src="currentPageImage"
        height="134px"
      />
      <expandable-number-list
        :data="referrerData.getData([])"
        :data-loaded="referrerData.loaded"
        :translations="
          (item, level) =>
            level === 0
              ? $t('monitoring.data.' + item.key.replace(' ', '_'))
              : item.key
        "
        :value-header="$t('monitoring.data.views')"
        :key-header="$t('monitoring.data.referrer')"
        :key-url="
          (item, level) => {
            switch (level) {
              case 0:
                return false;
              case 1:
                return '//' + item.key;
              default:
                return item.key;
            }
          }
        "
        :empty-key-value="$t('monitoring.components.no_referrer')"
        count-field="doc_count"
        fixed-table
      >
        <span slot="header-options-1">
          <v-icon>mdi-dots-vertical</v-icon>
          <options-menu in-header fill>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" />
            </template>
            <expandable-number-list-switcher
              :list-mode="currentDataListMode"
              :list-modes="listModes"
              @list-mode-changed="listModeChanged"
            />
          </options-menu>
        </span>
      </expandable-number-list>
    </v-card>
    <!-- Pages -->
    <v-card v-if="currentDataListMode === 'pages'" class="scrollable">
      <p-image v-if="pageImage" :src="pageImage" height="134px" />
      <number-list
        :data="
          $store.state.monitoring.settings.usePageViews && pageDataViews
            ? filteredPageDataViews
            : filteredPageData
        "
        :data-loaded="
          $store.state.monitoring.settings.usePageViews && pageDataViews
            ? pageDataViews.loaded
            : pageData.loaded
        "
        :value-header="
          $store.state.monitoring.settings.usePageViews && pageDataViews
            ? $t('monitoring.data.views')
            : $t('monitoring.data.users')
        "
        :key-header="$t('monitoring.data.pages')"
        :on-row-click="pageFilter ? setPageFilter : null"
        :key-url="(item) => '//' + item.domain + item.key"
        :translations="
          $store.state.monitoring.settings.displayPageTitle
            ? (item) => item.title || '-'
            : (item) => item.key
        "
        :count-field="
          $store.state.monitoring.settings.usePageViews && pageDataViews
            ? 'views'
            : 'users'
        "
        :max="10"
        fixed-table
        css-class="pagelist"
      >
        <v-card v-if="pageDataViews" slot="header-options-2">
          <v-card-text>
            <v-btn-toggle
              v-model="modelUsePageViews"
              mandatory
              @change="updateUsePageViews"
            >
              <v-btn text value="false">
                {{ $t('monitoring.data.users') }}
              </v-btn>
              <v-btn text value="true">
                {{ $t('monitoring.data.views') }}
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
        </v-card>
        <expandable-number-list-switcher
          slot="header-options-1"
          :list-mode="currentDataListMode"
          :list-modes="listModes"
          @list-mode-changed="listModeChanged"
        >
          <span slot="append">
            <v-card-text class="d-flex align-center justify-space-between">
              <span class="subtitle-1">
                {{ $t('monitoring.options.display') }}
              </span>
              <v-btn-toggle
                v-model="modelDisplayPageTitle"
                class="ml-1"
                mandatory
                @change="updateDisplayPageTitle"
              >
                <v-btn text value="false">
                  {{ $t('monitoring.data.url') }}
                </v-btn>
                <v-btn text value="true">
                  {{ $t('monitoring.data.title') }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-0">
              <h3 class="subtitle-1">
                {{ $t('monitoring.options.hiddenItems') }}
              </h3>
            </v-card-text>
            <select-list
              v-model="pageBlacklist"
              :items="pageBlacklistItems"
              class="pt-0"
              height="240px"
              @click.native.stop
            />
          </span>
        </expandable-number-list-switcher>
      </number-list>
    </v-card>
    <!-- Domains -->
    <v-card v-if="currentDataListMode === 'domains'" class="scrollable">
      <p-image v-if="domainImage" :src="domainImage" height="134px" />
      <div style="position: relative">
        <options-menu
          v-if="domainDataViews"
          in-header
          cover-column
          small
          close-on-content-click
        >
          <v-card>
            <v-card-text>
              <v-btn-toggle
                v-model="modelUsePageViews"
                mandatory
                @change="updateUsePageViews"
              >
                <v-btn text value="false">
                  {{ $t('monitoring.data.users') }}
                </v-btn>
                <v-btn text value="true">
                  {{ $t('monitoring.data.views') }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
        </options-menu>
        <expandable-number-list
          v-if="
            $store.state.monitoring.settings.usePageViews && domainDataViews
          "
          :data="domainDataViews.data"
          :data-loaded="domainDataViews.loaded"
          :value-header="$t('monitoring.data.views')"
          :key-header="$t('monitoring.data.domains')"
          :max="10"
          :on-row-click="setDomainFilter"
          count-field="views"
          fixed-table
          hide-single-children
          with-options-menu
        >
          <span slot="header-options-1">
            <v-icon>mdi-dots-vertical</v-icon>
            <options-menu in-header fill>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" />
              </template>
              <expandable-number-list-switcher
                :list-mode="currentDataListMode"
                :list-modes="listModes"
                @list-mode-changed="listModeChanged"
              />
            </options-menu>
          </span>
        </expandable-number-list>
        <expandable-number-list
          v-else
          :data="domainData.data"
          :data-loaded="domainData.loaded"
          :value-header="$t('monitoring.data.users')"
          :key-header="$t('monitoring.data.domains')"
          :max="10"
          :on-row-click="setDomainFilter"
          count-field="users"
          fixed-table
          hide-single-children
          with-options-menu
        >
          <span slot="header-options-1">
            <v-icon>mdi-dots-vertical</v-icon>
            <options-menu in-header fill>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" />
              </template>
              <expandable-number-list-switcher
                :list-mode="currentDataListMode"
                :list-modes="listModes"
                @list-mode-changed="listModeChanged"
              />
            </options-menu>
          </span>
        </expandable-number-list>
      </div>
    </v-card>
  </div>
</template>

<script>
import PImage from '@/components/PImage';
import NumberList from '@/components/Monitoring/NumberList';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import ExpandableNumberListSwitcher from '@/components/Monitoring/ExpandableNumberListSwitcher';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import SelectList from '@/components/Monitoring/SelectList';
import Loadable from '@/utils/Loadable';
import helpers from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: {
    PImage,
    NumberList,
    OptionsMenu,
    SelectList,
    ExpandableNumberList,
    ExpandableNumberListSwitcher,
  },

  props: {
    domainData: {
      type: Loadable,
      required: true,
      default: new Loadable([], false),
    },
    pageData: {
      type: Loadable,
      required: true,
      default: new Loadable([], false),
    },
    domainDataViews: {
      type: Loadable,
      required: false,
      default: null,
    },
    pageDataViews: {
      type: Loadable,
      required: false,
      default: null,
    },
    pageFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    referrerData: {
      type: Loadable,
      required: false,
      default: null,
    },
    initialListMode: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    const data = {
      currentPageImage: null,
      modelUsePageViews: `${this.$store.state.monitoring.settings.usePageViews}`,
      modelDisplayPageTitle: `${this.$store.state.monitoring.settings.displayPageTitle}`,
      /* {String} currently choosen data type to be listed */
      currentDataListMode: 'pages',
    };
    Object.defineProperty(
      data,
      'pageBlacklist',
      helpers.localstorageProperty('monitoring.settings.pageBlacklist', [])
    );
    return data;
  },
  mounted() {
    this.setCurrentDataListMode(this.initialListMode);
    // resetting the current data list mode due to changed filter state
    this.$bus.listen('filter-changed', (newFilter) => {
      // only if domain or page filter set
      if (
        newFilter.domain ||
        newFilter.page ||
        newFilter.terms?.find((el) => el.field == 'page')
      ) {
        this.setCurrentDataListMode();
      }
    });
  },
  destroyed() {
    this.$bus.remove('filter-changed');
  },

  computed: {
    /**
     * Currently avaliable data type modes (based on filter state)
     *
     * @return {array} list modes
     */
    listModes() {
      return [...(this.showDomains ? [] : ['domains']), 'pages', 'referrer'];
    },
    ...mapGetters('monitoring', ['showDomains']),
    pageImage() {
      if (
        this.pageDataViews &&
        this.$store.state.monitoring.settings.usePageViews
      )
        return helpers.firstImage(this.pageDataViews.data, '/');
      return helpers.firstImage(this.pageData.data, '/');
    },
    domainImage() {
      if (
        this.domainDataViews &&
        this.$store.state.monitoring.settings.usePageViews
      )
        return helpers.firstImage(this.domainDataViews.data);
      return helpers.firstImage(this.domainData.data);
    },
    pageBlacklistItems() {
      return this.pageData.data.map((a) => ({ key: a.key, title: a.title }));
    },
    filteredPageData() {
      return this.filterKeys(this.pageData.getData([]), this.pageBlacklist);
    },
    filteredPageDataViews() {
      return this.filterKeys(
        this.pageDataViews.getData([]),
        this.pageBlacklist
      );
    },
  },

  methods: {
    setDomainFilter(domain) {
      this.$store.dispatch('monitoring/updateFilter', {
        prop: 'domain',
        value: domain.key,
      });
    },
    setPageFilter(page) {
      if (this.referrerData) {
        this.currentPageImage = page.image || this.pageImage;
      }
      this.$store.dispatch('monitoring/addFilter', {
        filter: {
          field: 'page',
          value: page.key,
          valueLabel: page.key,
          ...(this.$store.state.monitoring.isTemporaryFilterMode
            ? { isTemporary: true }
            : {}),
        },
      });
    },
    updateUsePageViews(value) {
      this.$store.dispatch('monitoring/updateSettings', {
        prop: 'usePageViews',
        value,
      });
      this.$store.dispatch('monitoring/purge', { prop: 'views.domains' });
      this.$store.dispatch('monitoring/purge', { prop: 'views.pages' });
      this.$store.dispatch('monitoring/purge', { prop: 'users.domains' });
      this.$store.dispatch('monitoring/purge', { prop: 'users.pages' });
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
    updateDisplayPageTitle(value) {
      this.$store.dispatch('monitoring/updateSettings', {
        prop: 'displayPageTitle',
        value,
      });
    },
    filterKeys(items, blacklist) {
      return items.filter(
        (item) => blacklist.findIndex((filter) => filter === item.key) < 0
      );
    },
    /**
     * Handler for list mode toggling
     *
     * @param {String} newListMode list mode to be set
     * @returns {void}
     */
    listModeChanged(newListMode) {
      this.$emit('updated', { listMode: newListMode });
    },
    /**
     * Applying a data list mode due to  current filter conditions
     *
     * @returns {void}
     */
    setCurrentDataListMode() {
      if (
        this.initialListMode &&
        !(this.showDomains && this.initialListMode === 'domains')
      ) {
        this.currentDataListMode = this.initialListMode;
      } else {
        // by default "pages" mode is set
        let listMode = 'pages';
        if (
          this.referrerData &&
          this.$store.state.monitoring.filter.page &&
          this.showDomains
        ) {
          listMode = 'referrer';
        }
        if (!this.showDomains) {
          listMode = 'domains';
        }
        this.currentDataListMode = listMode;
        this.listModeChanged(listMode);
      }
    },
  },
  watch: {
    initialListMode() {
      this.setCurrentDataListMode(this.initialListMode);
    },
  },
};
</script>

<style lang="scss">
.v-card.numberlist-header-options {
  overflow-y: auto;
}
.options-menu-content.pagelist-header-options-1-content {
  max-width: 400px;
}
</style>
