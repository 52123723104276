<script>
export default {
  props: {
    position: {
      type: Object,
      required: false,
      default: null,
    },
    size: {
      type: Object,
      required: false,
      default: null,
    },
    cardSettings: {
      type: Object,
      required: true,
      default: null,
    },
  },
};
</script>

<style>
.vue-grid-item > .v-card,
.vue-grid-item > .wrapper,
.vue-grid-item > .wrapper > .v-card {
  height: 100%;
}
.vue-grid-item > .v-card,
.vue-grid-item > .wrapper > .v-card {
  overflow: hidden;
}
.vue-grid-item > .v-card.scrollable,
.vue-grid-item > .wrapper > .v-card.scrollable {
  overflow-y: auto;
}

.vue-grid-item > .v-card .card-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.mobile-grid .card-bar .card-progress-container {
  position: absolute;
  width: 100%;
}

/* Firefox scrollbar */
.vue-grid-item .scrollable {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0);
}
.theme--dark .vue-grid-item .scrollable {
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0);
}

/* Chrome, Safari scrollbar */
.vue-grid-item .scrollable::-webkit-scrollbar-track {
  opacity: 0;
}

.vue-grid-item .scrollable::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

.vue-grid-item .scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.theme--dark .vue-grid-item .scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
