<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('components.transfer.stepInventory.title') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="
                $t('components.transfer.stepInventory.form.interests.label')
              "
              vid="assignedInterests"
              rules=""
            >
              <v-autocomplete
                v-model="assignedAudiences"
                :items="userInterests"
                :label="
                  $t('components.transfer.stepInventory.form.interests.label')
                "
                :hint="
                  $t('components.transfer.stepInventory.form.interests.hint')
                "
                :loading="loadingAudiences"
                name="assignedInterests"
                item-text="text"
                item-value="id"
                multiple
                chips
                deletable-chips
                multi-line
                :error-messages="errors"
              >
                <template #item="data">
                  <v-list-item-avatar>
                    <img
                      :src="data.item.filename || helpers.getPlaceholderImage()"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="
                $t('components.transfer.stepInventory.form.properties.label')
              "
              vid="assignedProperties"
              rules=""
            >
              <v-autocomplete
                v-model="assignedAudiences"
                :items="userVisitorProperties"
                :label="
                  $t('components.transfer.stepInventory.form.properties.label')
                "
                :hint="
                  $t('components.transfer.stepInventory.form.properties.hint')
                "
                :loading="loadingAudiences"
                name="assignedProperties"
                item-text="text"
                item-value="id"
                multiple
                chips
                deletable-chips
                multi-line
                :error-messages="errors"
              >
                <template #item="data">
                  <v-list-item-avatar>
                    <img
                      :src="data.item.filename || helpers.getPlaceholderImage()"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <p>{{ $t('components.transfer.stepInventory.hint') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import helpers from '@/utils/helpers';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'transfer/getField',
  mutationType: 'transfer/updateField',
});
export default {
  data() {
    return {
      helpers,
      loadingAudiences: false,
    };
  },
  computed: {
    ...mapState('transfer', ['currentStep']),
    ...mapGetters('audience', ['userInterests', 'userVisitorProperties']),
    ...mapFields(['form.assignedAudiences']),
  },
  mounted() {
    this.getAudiences();
  },
  methods: {
    async getAudiences() {
      this.loadingAudiences = true;
      try {
        await this.$store.dispatch('audience/getAudiences');
      } catch (err) {
        console.error(err);
      }
      this.loadingAudiences = false;
    },
  },
};
</script>
