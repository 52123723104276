/* ============
 * State of the ad module
 * ============
 *
 * The initial state of the ad module.
 */
import AdSettings from './adSettings';
export const getInitialState = () => ({
  ads: [],
  ad: {
    isStatic: true,
    name: '',
    headline: '',
    cta_label: '',
    target_url: '',
    delay: 2,
    duration: 4,
    rotate: false,
    approved: false,
    tag: '',
    nativeTag: '',
    settings: new AdSettings({}),
    native: false,
    answers: [],
    questions: [],
    covers: [],
    results: [],
    image_data: '',
    audience_ids: [],
    category_ids: [],
    display: 'inline',
    display_timing: 'after_vote',
    domain_id: '0',
    device_type: 'all',
    custom_targeting: {},
  },
  adTargetings: [],
  elements: [],
  questions: [],
  answers: [],
  adExperiences: [],
  adAllExperienceActive: false,
  total_ads: 0,
  total_questions: 0,
});

export default getInitialState;
