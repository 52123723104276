<template>
  <v-container class="main insights pl-3" fluid>
    <filter-bar />
    <v-row>
      <v-col>
        <date-range-filter :max-range="31" />
      </v-col>
    </v-row>
    <v-card>
      <v-row>
        <v-col md="3" sm="12" order="2" order-md="1" class="py-0 left-bar">
          <div class="fake-tabs v-tabs">
            <div
              class="fake-tab v-tab v-tab--active"
              style="position: relative"
            >
              {{ $t('monitoring.headers.trendingPages') }}
              <options-menu
                in-header
                bottom
                close-on-content-click
                style="right: -2px"
              >
                <v-card>
                  <v-card-text>
                    <span class="subtitle-1">
                      {{ $t('monitoring.options.display') }}
                    </span>
                    <v-btn-toggle
                      v-model="modelDisplayPageTitle"
                      class="ml-1"
                      mandatory
                      @change="
                        (value) =>
                          $store.dispatch('monitoring/updateSettings', {
                            prop: 'displayPageTitle',
                            value,
                          })
                      "
                    >
                      <v-btn text value="false">
                        {{ $t('monitoring.data.url') }}
                      </v-btn>
                      <v-btn text value="true">
                        {{ $t('monitoring.data.title') }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-card-text>
                </v-card>
              </options-menu>
            </div>
          </div>
          <v-progress-linear
            v-if="!$store.state.monitoring.facts.trendingPages.loaded"
            color="primary"
            indeterminate
            class="ma-0"
          />
          <page-list
            :data="$store.state.monitoring.facts.trendingPages.data"
            class="pt-0"
            :max="15"
          />
        </v-col>
        <v-col md="9" order-md="2" order="1" class="pt-0 pl-0">
          <div class="card-progress-container ma-0">
            <v-progress-linear
              v-if="!$store.state.monitoring.views.histogram.loaded"
              :height="7"
              color="primary"
              indeterminate
            />
          </div>
          <line-chart
            :data="chartData"
            timeseries
            :interval="
              $store.getters['monitoring/insightsUseHourInterval'] ? 'h' : 'd'
            "
            :pad-start="
              $store.getters['monitoring/filterStartDateObject'].unix() * 1000
            "
          />

          <v-container class="px-3">
            <v-tabs
              :value="tabsModel"
              fixed-tabs
              show-arrows
              class="transparent"
              @change="tabChanged"
            >
              <v-tab href="#info" to="#info">
                {{ $t('monitoring.headers.info') }}
              </v-tab>
              <v-tab href="#devices" to="#devices">
                {{ $t('monitoring.data.devices') }}
              </v-tab>
              <v-tab href="#referrer" to="#referrer">
                {{ $t('monitoring.data.referrer') }}
              </v-tab>
              <v-tab href="#audiences" to="#audiences">
                {{ $t('monitoring.data.audiences') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabsModel">
              <v-tab-item value="info">
                <v-card>
                  <v-card-text class="textColor--text">
                    <div class="text-h5">
                      <animated-number
                        v-if="$store.state.monitoring.views.histogram.loaded"
                        :data="totalCount"
                        :duration="2000"
                      /><span v-else> - </span>
                      {{ $t('monitoring.components.totalViews') }}
                    </div>
                    <div class="caption">
                      ({{
                        $store.getters['monitoring/filterRangeDuration']
                          | formatDurationHuman
                      }})
                    </div>
                    <div
                      v-if="$store.state.monitoring.filter.page && selectedPage"
                    >
                      <v-divider class="my-6" />
                      <v-row style="align-items: center">
                        <v-avatar :size="84" class="mr-4">
                          <v-img
                            slot="activator"
                            :width="84"
                            :height="84"
                            :src="
                              selectedPage.image ||
                              helpers.getPlaceholderImage()
                            "
                            :alt="selectedPage.title"
                            class="avatar-image"
                          />
                        </v-avatar>
                        <div>
                          <h2 class="subtitle-1">
                            {{ selectedPage.title }}
                          </h2>
                          <div class="subtitle-2">
                            <a
                              :href="
                                '//' + selectedPage.domain + selectedPage.key
                              "
                              target="_blank"
                              class="link textColor--text"
                            >
                              {{ selectedPage.key }}
                            </a>
                            <br />
                            <animated-number
                              :data="selectedPage.views"
                              :duration="2000"
                            />
                            {{ $t('monitoring.data.views') }}
                            {{ $t('monitoring.date.inLast24Hours') }}
                          </div>
                        </div>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
                &nbsp;
                <!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="devices">
                <v-card>
                  <v-card-text>
                    <devices-view
                      :data="$store.state.monitoring.insights.devices.data"
                      translate="monitoring.data"
                    />
                  </v-card-text>
                  <div class="card-progress-container">
                    <v-progress-linear
                      v-if="!$store.state.monitoring.insights.devices.loaded"
                      :height="7"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </v-card>
                &nbsp;
                <!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="referrer">
                <v-card>
                  <v-card-text>
                    <referrer-distribution
                      :data="
                        $store.state.monitoring.insights.referrerTypes.data
                      "
                    />
                  </v-card-text>
                  <div class="card-progress-container">
                    <v-progress-linear
                      v-show="
                        !$store.state.monitoring.insights.referrerTypes.loaded
                      "
                      :height="7"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </v-card>
                <v-card>
                  <expandable-number-list
                    :data="
                      $store.state.monitoring.insights.referrer.getData([])
                    "
                    :data-loaded="
                      $store.state.monitoring.insights.referrer.loaded
                    "
                    :translations="
                      (item, level) =>
                        level === 0
                          ? $t('monitoring.data.' + item.key)
                          : item.key
                    "
                    :value-header="$t('monitoring.data.views')"
                    :key-header="$t('monitoring.data.referrer')"
                    :key-url="
                      (item, level) => {
                        switch (level) {
                          case 0:
                            return false;
                          case 1:
                            return '//' + item.key;
                          default:
                            return item.key;
                        }
                      }
                    "
                    :empty-key-value="$t('monitoring.components.no_referrer')"
                    count-field="doc_count"
                    fixed-table
                    class="mt-2"
                  />
                </v-card>
                &nbsp;<!-- prevents animation bug -->
              </v-tab-item>
              <v-tab-item value="audiences">
                <v-row>
                  <v-col sm="6" cols="12">
                    <audiences-view
                      :audiences="interests"
                      :loaded="audienceLoadable.loaded"
                    />
                  </v-col>
                  <v-col sm="6" cols="12">
                    <visitor-properties-view
                      :data="visitorProperties"
                      :loaded="audienceLoadable.loaded"
                    />
                  </v-col>
                </v-row>
                &nbsp;<!-- prevents animation bug -->
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import VisitorPropertiesView from '@/components/Monitoring/VisitorPropertiesView';
import AudiencesView from '@/components/Monitoring/AudiencesView';
import AnimatedNumber from '@/components/AnimatedNumber';
import ReferrerDistribution from '@/components/Monitoring/ReferrerDistribution';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import DevicesView from '@/components/Monitoring/DevicesView';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import PageList from '@/components/Monitoring/PageList';
import LineChart from '@/components/Monitoring/LineChart';
import FilterBar from '@/components/Monitoring/FilterBar';
import DateRangeFilter from '@/components/Monitoring/DateRangeFilter';
import InsightTabResources from '@/mixins/monitoring/InsightTabResources';
import AudienceInterestProperties from '@/mixins/monitoring/AudienceInterestProperties';
import helpers from '@/utils/helpers';

export default {
  components: {
    FilterBar,
    DateRangeFilter,
    LineChart,
    PageList,
    OptionsMenu,
    DevicesView,
    ExpandableNumberList,
    ReferrerDistribution,
    AnimatedNumber,
    AudiencesView,
    VisitorPropertiesView,
  },

  mixins: [InsightTabResources, AudienceInterestProperties],

  data() {
    return {
      helpers,
      modelDisplayPageTitle: `${this.$store.state.monitoring.settings.displayPageTitle}`,
    };
  },

  computed: {
    chartData() {
      return [
        {
          data: this.$store.state.monitoring.views.histogram.data,
          title: this.$t('monitoring.data.views'),
        },
      ];
    },
    totalCount() {
      return this.$store.state.monitoring.views.histogram.data.reduce(
        (sum, item) => sum + item.doc_count,
        0
      );
    },
    selectedPage() {
      const pages = this.$store.state.monitoring.facts.trendingPages;
      if (!pages.loaded) return null;
      const index = pages.data.findIndex(
        (page) => page.key === this.$store.state.monitoring.filter.page
      );
      if (index < 0) return null;
      return pages.data[index];
    },
    audienceLoadable() {
      return this.$store.state.monitoring.insights.audiences;
    },
  },

  mounted() {
    this.$store.dispatch('monitoring/addActiveResource', {
      name: 'views.histogram',
    });
    this.$store.dispatch('monitoring/addActiveResource', {
      name: 'facts.trendingPages',
    });
  },

  destroyed() {
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'views.histogram',
    });
    this.$store.dispatch('monitoring/removeActiveResource', {
      name: 'facts.trendingPages',
    });
  },
};
</script>

<style lang="scss" scoped>
.fake-tabs {
  height: 48px;

  .fake-tab {
    max-width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
@media (min-width: 960px) {
  // set border color of right side of title depending on dark mode setting
  .left-bar > div {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  // apply different border color in dark mode
  .theme--dark .left-bar > div {
    border-right-color: rgba(255, 255, 255, 0.12);
  }
}
</style>
