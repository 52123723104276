<template>
  <cronjobs-management />
</template>

<script>
import CronjobsManagement from '@/components/Admin/CronjobsManagement';

export default {
  components: {
    CronjobsManagement,
  },
  data: () => ({}),
};
</script>

<style></style>
