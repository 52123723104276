var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.ads.inventory.selectType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":_vm.$t('components.ads.inventory.selectType'),"items":_vm.allExperiencesItems,"item-text":_vm.getAdExperiencesSelectionItemText,"item-value":_vm.getAdExperiencesSelectionItemValue,"cache-items":"","multiple":"","chips":"","deletable-chips":"","return-object":"","menu-props":_vm.menuProps,"loading":_vm.loading,"error-messages":errors},on:{"change":function($event){return _vm.onSelectionChanged($event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.isExperienceDisabledForPosition(data.item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-content',_vm._g({class:{
                'pointer-active': _vm.isExperienceDisabledForPosition(data.item),
              }},on),[_c('v-list-item-title',{class:{
                  'sub-item':
                    data.item.experience_type_id !=
                    _vm.constants.EXPERIENCE_TYPE.ALL,
                }},[_vm._v(" "+_vm._s(data.item.title)+" ")])],1),_c('i',[_vm._v(_vm._s(!_vm.isExperienceDisabledForPosition(data.item) && (_vm.isItemSelected(data.item) || _vm.isSubItemOfSelected(data.item)) ? _vm.$t('components.ads.inventory.disabledBecauseSelected') : ''))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.ads.inventory.disabledBecausePosition', { positionText: _vm.getSelectedPositionText, }))+" ")])])]}}],null,true),model:{value:(_vm.selectedAdExperiences),callback:function ($$v) {_vm.selectedAdExperiences=$$v},expression:"selectedAdExperiences"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }