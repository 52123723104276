/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export const getInitialState = () => ({
  user: null,
  access: JSON.parse(localStorage.getItem('permissions')) || ['public'],
  availableLocales: ['en', 'de'],
  bigBoardEnabled: false,
  displaySettings: {
    showNavigationDrawer: true,
    showDarkModeBigboard: false,
  },
});

export default getInitialState;
