import Resource from './Resource';

class ClassificationsResource extends Resource {
  /**
   * The constructor for the AudienceResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/classifications');
  }

  /**
   * Method used to get all classifications
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAll(params) {
    return this.submit('get', '/', params);
  }
}

export default ClassificationsResource;
