import BaseProxy from './BaseProxy';

class ImageProxy extends BaseProxy {
  /**
   * The constructor for the ImageProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super('/v1/images', parameters);
  }

  /**
   * Method used to search for image/gif/youtube
   *
   * @param {Array} params payload.
   *
   * @returns {Promise} The result in a promise.
   */
  search(params) {
    return this.submit('post', 'search', params);
  }

  /**
   * Method used to store image/gif/youtube of audience
   *
   * @param {Array} params payload.
   *
   * @returns {Promise} The result in a promise.
   */
  store(params) {
    return this.submit('post', 'storeAudience', params);
  }
}

export default ImageProxy;
