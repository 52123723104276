/* ============
 * State of the metatag module
 * ============
 *
 * The initial state of the metatag module.
 */

export const getInitialState = () => ({
  metaTag: '',
  count: 0,
  key: '',
  affinityScore: 1,
  availableFields: [],
  filteredMetaTags: {
    items: [],
  },
});

export default getInitialState;
