<template>
  <audiences-view
    :audiences="interests"
    :loaded="audienceLoadable.loaded"
    class="scrollable"
  />
</template>

<script>
import AudiencesView from '@/components/Monitoring/AudiencesView';
import { Interests } from '@/mixins/monitoring/AudienceInterestProperties';

export default {
  components: {
    AudiencesView,
  },

  mixins: [Interests],

  computed: {
    audienceLoadable() {
      return this.$store.state.monitoring.realtime.audiences;
    },
  },
};
</script>
