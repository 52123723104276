var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('ValidationObserver',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var touched = ref.touched;
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"pp-custom-background py-6 text-h6"},[_vm._v(" "+_vm._s(_vm.$t('components.channelDialog.createChannel'))+" ")]),_c('v-container',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.channelDialog.labels.title'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.channelDialog.labels.title'),"error-messages":errors},model:{value:(_vm.channel.title),callback:function ($$v) {_vm.$set(_vm.channel, "title", $$v)},expression:"channel.title"}})]}}],null,true)})],1),_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('components.channelDialog.labels.path'),"rules":"required|min:3|max:255|path"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('components.channelDialog.labels.path'),"error-messages":errors,"hint":_vm.$t('components.channelDialog.hints.path')},model:{value:(_vm.channel.path),callback:function ($$v) {_vm.$set(_vm.channel, "path", $$v)},expression:"channel.path"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.savingProgress,"text":"","color":"warning"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.savingProgress || (touched && invalid),"loading":_vm.savingProgress,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.save'))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }