/* ============
 * Actions for the meta tag serach module
 * ============
 *
 * The actions that are available on the
 * meta tag serach module.
 */
import MetatagResource from '@/resources/MetatagResource';
import * as types from './mutation-types';

export const getDistinctVisitors = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new MetatagResource()
      .getDistinctVisitors(params)
      .then((response) => {
        commit(types.SET_META_TAG_VISITORS, response.value);
        // only update meta tag table if param is set
        if (params.updateTable) {
          commit(types.UPDATE_META_TAG_VISITORS, {
            params,
            visitors: response.value,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAvailableFields = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new MetatagResource()
      .getAvailableFields(params)
      .then((response) => {
        commit(types.SET_AVAILABLE_FIELDS, response);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMetaTags = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new MetatagResource()
      .getMetaTags(params)
      .then((data) => {
        commit(types.SET_FILTERED_META_TAGS, data);
        resolve();
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });

export default {
  getDistinctVisitors,
  getAvailableFields,
  getMetaTags,
};
