<template>
  <v-card class="audience-card">
    <p-image
      v-if="audiences.length > 0 && audiences[0].image"
      :src="audiences[0].image"
      height="134px"
    />
    <div style="position: relative">
      <options-menu in-header small>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="enablePinpollAudiences"
                @change="updateUsePinpollAudiences"
              />
            </v-list-item-action>
            <v-list-item-title>{{
              $t('monitoring.options.pinpollAudiences')
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </options-menu>
      <expandable-number-list
        :data="audiences"
        :data-loaded="loaded"
        :translations="$store.state.audience.localizedAudiences"
        :value-header="$t('monitoring.data.users')"
        :key-header="$t('monitoring.data.interests')"
        :count-field="countField"
        :max="10"
        :on-row-click="addAudienceFilter"
        with-options-menu
      />
    </div>
  </v-card>
</template>

<script>
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import PImage from '@/components/PImage';
import ExpandableNumberList from '@/components/Monitoring/ExpandableNumberList';
import AddAudienceFilterMethod from '@/mixins/monitoring/AddAudienceFilterMethod';

export default {
  components: {
    OptionsMenu,
    PImage,
    ExpandableNumberList,
  },
  mixins: [AddAudienceFilterMethod],

  props: {
    audiences: {
      type: Array,
      required: true,
      default: () => [],
    },
    loaded: {
      type: Boolean,
      required: false,
      default: true,
    },
    countField: {
      type: String,
      required: false,
      default: 'visitors',
    },
  },

  data() {
    return {
      enablePinpollAudiences: this.$store.state.monitoring.settings
        .usePinpollAudiences,
    };
  },

  mounted() {
    this.$store.dispatch('audience/getAudiences');
  },

  methods: {
    updateUsePinpollAudiences() {
      this.$store.dispatch('monitoring/updateSettings', {
        prop: 'usePinpollAudiences',
        value: this.enablePinpollAudiences,
      });
      this.$store.dispatch('monitoring/purge', { prop: 'realtime.audiences' });
      this.$store.dispatch('monitoring/purge', { prop: 'insights.audiences' });
      if (this.$store.state.monitoring.activeView === 'realtime')
        this.$store.dispatch('monitoring/reopen', {
          sockets: ['/v1/audiences'],
        });
      else this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
  },
};
</script>
