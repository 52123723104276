<template>
  <v-row class="mt-3">
    <tracking-overview />
    <tracking-dialog />
  </v-row>
</template>

<script>
import TrackingOverview from '@/components/Tracking/Overview';
import TrackingDialog from '@/components/Tracking/Dialog';

export default {
  components: {
    TrackingOverview,
    TrackingDialog,
  },
  data: () => ({}),
};
</script>

<style></style>
