/* ============
 * Actions for the question module
 * ============
 *
 * The actions that are available on the
 * question module.
 */

import QuestionResource from '@/resources/QuestionResource';
import * as types from './mutation-types';

export const getQuestion = ({ commit }, questionId) => {
  new QuestionResource()
    .show(questionId)
    .then((response) => {
      commit(types.SET_QUESTION, response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getQuestions = ({ commit }) =>
  new Promise((resolve, reject) => {
    new QuestionResource()
      .getQuestions()
      .then((response) => {
        commit(types.SET_QUESTIONS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const datatable = ({ commit }, params) =>
  new Promise((resolve) => {
    new QuestionResource()
      .datatable(params)
      .then((data) => {
        commit(types.SET_FILTERED_QUESTIONS, data);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export default {
  getQuestion,
  getQuestions,
  datatable,
};
