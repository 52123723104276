/* ============
 * Mutation types for the ad module
 * ============
 *
 * The mutation types that are available
 * on the ad module.
 */

export const RESET_STATE = 'RESET_STATE';
export const SET_ADS_ANALYTICS = 'SET_ADS_ANALYTICS';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const SAVE = 'SAVE';
export const SET_ADS = 'SET_ADS';
export const SET_AD = 'SET_AD';
export const SET_ELEMENTS_QUESTIONS_ANSWERS = 'SET_ELEMENTS_QUESTIONS_ANSWERS';
export const SET_PIXELS = 'SET_PIXELS';
export const RESET_QUESTIONS_AND_ANSWERS = 'RESET_QUESTIONS_AND_ANSWERS';
export const DISABLE_ANSWERS = 'DISABLE_ANSWERS';
export const DISABLE_QUESTIONS = 'DISABLE_QUESTIONS';
export const RESET_FIELDS = 'RESET_FIELDS';
export const SET_TARGET_URL = 'SET_TARGET_URL';
export const SET_AD_QUESTION_MAPPING = 'SET_AD_QUESTION_MAPPING';
export const SET_AD_ANSWER_MAPPING = 'SET_AD_ANSWER_MAPPING';
export const SET_AD_COVER_MAPPING = 'SET_AD_COVER_MAPPING';
export const SET_AD_RESULT_MAPPING = 'SET_AD_RESULT_MAPPING';
export const SET_AD_EXPERIENCES_ITEM_MAPPING =
  'SET_AD_EXPERIENCES_ITEM_MAPPING';
export const SET_AD_EXPERIENCES_ACTIVE = 'SET_AD_EXPERIENCES_ACTIVE';
export const DISABLE_QUESTIONS_AND_ANSWERS = 'DISABLE_QUESTIONS_AND_ANSWERS';
export const SET_AD_TARGETINGS = 'SET_AD_TARGETINGS';
export const UPDATE_AD_PARAMS = 'UPDATE_AD_PARAMS';
// export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';

export default {
  RESET_STATE,
  UPDATE,
  DELETE,
  SAVE,
  SET_ADS,
  SET_AD,
  SET_ELEMENTS_QUESTIONS_ANSWERS,
  SET_PIXELS,
  RESET_QUESTIONS_AND_ANSWERS,
  DISABLE_ANSWERS,
  DISABLE_QUESTIONS,
  SET_ADS_ANALYTICS,
  RESET_FIELDS,
  SET_TARGET_URL,
  SET_AD_QUESTION_MAPPING,
  SET_AD_ANSWER_MAPPING,
  SET_AD_COVER_MAPPING,
  SET_AD_RESULT_MAPPING,
  SET_AD_EXPERIENCES_ITEM_MAPPING,
  SET_AD_EXPERIENCES_ACTIVE,
  DISABLE_QUESTIONS_AND_ANSWERS,
  SET_AD_TARGETINGS,
  UPDATE_AD_PARAMS,
  // SET_ORGANISATIONS,
};
