<template>
  <v-banner
    v-if="isInTrial && remainingTrialDays > 0"
    sticky
    single-line
    dark
    color="ppMagazine"
    class="banner"
  >
    <div class="banner-text">
      {{
        $tc('components.trialUser.bannerText', remainingTrialDays, {
          days: remainingTrialDays,
        })
      }}
    </div>
    <template #actions class="mb-0">
      <div class="banner-action">
        <v-btn :color="productColorBtn" :href="upgradeNowLink">
          {{ $t('components.trialUser.upgradeBtn') }}
        </v-btn>
      </div>
    </template>
  </v-banner>
</template>
<script>
import constants from '@/utils/constants';
import productDetector from '@/mixins/productDetector';

export default {
  mixins: [productDetector],
  computed: {
    productColorBtn() {
      const productColorClass = {
        ads: 'ppAds',
        dmp: 'ppDmp',
        analytics: 'ppAnalytics',
      };

      return productColorClass[this.currentProduct];
    },

    /**
     * Calculates remainig time in trial
     *
     * @returns {Number} difference in days
     */
    remainingTrialDays() {
      if (this.$store.state.account.user) {
        const today = moment();
        const trialEndsAt = moment(
          this.$store.state.account.user.trial_ends_at
        );
        return Math.round(trialEndsAt.diff(today, 'days', true));
      }
      return 0;
    },
    /**
     * Indicates if user is in trial mode
     *
     * @returns {Boolean} is in trial or not
     */
    isInTrial() {
      if (this.$store.state.account.user) {
        let isInTrial = {
          ads: this.$store.state.account.user.hasToolsTrial,
          dmp: this.$store.state.account.user.hasDMPTrial,
          analytics: this.$store.state.account.user.hasAnalyticsTrial,
        };
        return isInTrial[this.currentProduct];
      }
      return false;
    },
    /**
     * Returns pinpoll tools store link of product
     *
     * @returns {string} - store link
     */
    upgradeNowLink() {
      const product =
        this.currentProduct === 'ads' ? 'tools' : this.currentProduct;
      return `${constants.ACCOUNT_URL}/store/${product}`;
    },
  },
};
</script>
<style scoped>
.banner {
  top: 64px !important;
  z-index: 3 !important;
}

.banner .banner-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.banner.v-banner--is-mobile {
  top: 0 !important;
}
</style>
