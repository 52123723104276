/* ============
 * Getters for the ad module
 * ============
 *
 * The getters that are available on the
 * ad module.
 */

import { getField } from 'vuex-map-fields';

export default {
  // Add the `getField` getter to the
  // `getters` of your Vuex store instance.
  getField,
};
