<template>
  <v-select
    v-model="select"
    :items="items"
    :label="$t('monitoring.date.today') + ' vs.'"
    :hide-details="true"
  />
</template>

<script>
export default {
  data() {
    return {
      select: this.$store.state.monitoring.settings.histogram_compare,
      items: ['yesterday', 'last_week'].map((value) => ({
        value,
        text: this.$t(`monitoring.date.${value}`),
      })),
    };
  },

  computed: {
    vuexValue() {
      return this.$store.state.monitoring.settings.histogram_compare;
    },
  },

  watch: {
    vuexValue(value) {
      this.select = value;
    },
    select(value) {
      this.$store.dispatch('monitoring/updateSettings', {
        prop: 'histogram_compare',
        value,
      });
      this.$store.dispatch('monitoring/purge', {
        prop: 'realtime.histogram_compare',
      });
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
  },
};
</script>
