/**
 * This mixin adds a simple addAudienceFilter method to the component
 * which adds an audience object to monitoring filters
 *
 * Created by Sebastian 23.01.2019
 */

export default {
  methods: {
    addAudienceFilter(audience) {
      const id = audience.key || audience.id;
      const filter = {
        field: 'audience',
        value: id,
        valueLabel: this.$store.state.audience.localizedAudiences[id].text,
        fieldLabel: null,
        type_id: audience.type_id,
      };

      this.$store.dispatch('monitoring/addFilter', { filter });
    },

    removeAudienceFilter(audience) {
      this.$store.dispatch('monitoring/removeFilter', {
        field: 'audience',
        value: audience.key || audience.id,
      });
    },
  },
};
