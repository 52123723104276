import Resource from './Resource';

class PixelResource extends Resource {
  /**
   * The constructor for the PixelResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/pixels');
  }

  /**
   * Method used to get all pixels from current user
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getPixels() {
    return this.submit('get', 'getPixels');
  }

  /**
   * Method used to get all filtered pixels as datatable request
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  datatable(params) {
    return this.submit('get', 'datatable', params);
  }
}

export default PixelResource;
