import Resource from './Resource';

class CronJobResource extends Resource {
  /**
   * The constructor for the CronJobResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/cronJobs');
  }
}

export default new CronJobResource();
