/* ============
 * Actions for the pixel module
 * ============
 *
 * The actions that are available on the
 * pixel module.
 */

import PixelResource from '@/resources/PixelResource';
import * as types from './mutation-types';

export const getPixels = ({ commit }) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .getPixels()
      .then((data) => {
        commit(types.SET_PIXELS, data);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const createPixel = (context, pixel) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .create(pixel)
      .then(() => {
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const getPixel = (context, pixelId) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .show(pixelId)
      .then((response) => {
        resolve(response);
      })
      .catch((response) => {
        reject(response);
      });
  });

export const updatePixel = (context, { id, pixel }) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .update(id, pixel)
      .then(() => {
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const deletePixel = (context, id) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .destroy(id)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const datatable = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new PixelResource()
      .datatable(params)
      .then((response) => {
        commit(types.SET_FILTERED_PIXELS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export default {
  getPixels,
  getPixel,
  createPixel,
  updatePixel,
  deletePixel,
  datatable,
};
