export const AUDIENCE_TYPES = {
  INTEREST: 1,
  PROPERTY: 2,
};

export default {
  INTERCOM_APP_ID: process.env.VUE_APP_INTERCOM_APP_ID || 'yxl3dqe0',
  LANDING_URL: process.env.VUE_APP_LANDING_URL || 'https://www.pinpoll.com',
  TOOLS_URL: process.env.VUE_APP_TOOLS_URL || 'https://tools.pinpoll.com',
  ACCOUNT_URL: process.env.VUE_APP_ACCOUNT_URL || 'https://account.pinpoll.com',
  DMP_URL: process.env.VUE_APP_DMP_URL || 'https://analytics.pinpoll.com',
  ANALYTICS_URL:
    process.env.VUE_APP_ANALYTICS_URL || 'https://analytics.pinpoll.com',
  ADS_URL: process.env.VUE_APP_ADS_URL || 'https://analytics.pinpoll.com',
  CDN_STORAGE: process.env.VUE_APP_CDN_STORAGE || 'https://static.pinpoll.com',
  CDN_STORAGE_ACCOUNT:
    process.env.VUE_APP_CDN_STORAGE_ACCOUNT ||
    'https://static.pinpoll.com/account',
  CDN_STORAGE_AUDIENCES:
    process.env.VUE_APP_CDN_STORAGE_AUDIENCES || 'https://static.pinpoll.com',
  API_LOCATION:
    process.env.VUE_APP_API_LOCATION || 'https://analytics.pinpoll.com',
  API_LOCATION_LEGACY:
    process.env.VUE_APP_API_LOCATION_LEGACY || 'https://tools.pinpoll.com',
  API_AUTH_URL:
    process.env.VUE_APP_API_AUTH_URL || 'https://account.pinpoll.com',
  HELP_CENTER_URL:
    process.env.VUE_APP_HELP_CENTER_URL || 'https://help.pinpoll.com',
  ELEMENT_TYPE: {
    POLL: 1,
    QUIZ: 2,
    PERSONALITY_TEST: 6,
  },
  EXPERIENCE_TYPE: {
    ALL: 1,
    QUESTIONS: 2,
    COVERS: 3,
    RESULTS: 4,
  },
  QUESTION_NUMBER: {
    SINGLE: 1,
    MULTI: 2,
  },
};
