<template>
  <div>
    <question-table />
    <assign-detail-view />
  </div>
</template>

<script>
import QuestionTable from '@/components/Audiences/QuestionTable';
import AssignDetailView from '@/components/Audiences/AssignDetailView';

export default {
  components: {
    QuestionTable,
    AssignDetailView,
  },
  data: () => ({}),
};
</script>

<style></style>
