<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <manage-tree
          :tree-data="treeDataUserInterests"
          :type="AUDIENCE_TYPES.INTEREST"
        />
      </v-col>
      <v-col cols="12" md="6">
        <manage-tree
          :tree-data="treeDataUserVisitorProperties"
          :type="AUDIENCE_TYPES.PROPERTY"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUDIENCE_TYPES } from '@/utils/constants';
import ManageTree from '@/components/Audiences/ManageTree';

export default {
  components: {
    ManageTree,
  },
  data: () => ({
    AUDIENCE_TYPES,
  }),
  computed: {
    ...mapGetters('audience', [
      'treeDataUserInterests',
      'treeDataUserVisitorProperties',
    ]),
  },
};
</script>

<style></style>
