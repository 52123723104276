<template>
  <div :class="{ 'text-h3': !noStyling }">
    <animate-number
      ref="number"
      :formatter="$filters.formatNumber"
      :to="data"
      :animate-end="animateEnd"
      from="0"
      mode="manual"
      duration="500"
      easing="easeOutQuad"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Number,
      required: true,
      default: 0,
    },
    noStyling: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      animating: false,
      animationQueued: false,
    };
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.number.reset(oldVal, newVal);
      this.start();
    },
  },
  mounted() {
    if (this.data !== 0) {
      this.start();
    }
  },

  methods: {
    start() {
      if (this.animating) {
        this.animationQueued = true;
      } else {
        this.$refs.number.start();
        this.animating = true;
      }
    },
    animateEnd() {
      this.animating = false;
      if (this.animationQueued) {
        this.animationQueued = false;
        this.start();
      }
    },
  },
};
</script>
