/* ============
 * Mutation types for the question module
 * ============
 *
 * The mutation types that are available
 * on the question module.
 */

export const SET_QUESTION = 'SET_QUESTION';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_FILTERED_QUESTIONS = 'SET_FILTERED_QUESTIONS';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_QUESTION,
  SET_QUESTIONS,
  SET_FILTERED_QUESTIONS,
  RESET_STATE,
};
