/* ============
 * Mutation types for the pixel module
 * ============
 *
 * The mutation types that are available
 * on the pixel module.
 */

export const UPDATE_RAW = 'UPDATE_RAW'; // shouldn't be use
export const UPDATE_DATA = 'UPDATE_DATA'; // handles Loadable objects

export const UPDATE_FILTER = 'UPDATE_FILTER'; // for fixed filters like date or domain
// for dynamic filters like term queries e.g. for audience filtering
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';

export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_ACTIVE_VIEW = 'UPDATE_ACTIVE_VIEW'; // manage resources
export const PURGE_LOADED = 'PURGE_LOADED'; // set loaded state of Loadables to false
export const UPDATE_HISTOGRAM = 'UPDATE_HISTOGRAM'; // Update histogram with newest data entry
export const UPDATE_AUDIENCE_HISTOGRAM = 'UPDATE_AUDIENCE_HISTOGRAM'; // Update histogram with newest data entry
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_EVENT_HISTOGRAM = 'UPDATE_EVENT_HISTOGRAM'; // Update histogram with newest event data entry

export const ADD_NICKNAME = 'ADD_NICKNAME';

export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const SET_FILTER = 'SET_FILTER';
export const SET_TEMPORARY_FILTER_MODE = 'SET_TEMPORARY_FILTER_MODE';

export default {
  UPDATE_RAW,
  UPDATE_DATA,
  UPDATE_FILTER,
  ADD_FILTER,
  REMOVE_FILTER,
  UPDATE_SETTING,
  PURGE_LOADED,
  UPDATE_ACTIVE_VIEW,
  UPDATE_HISTOGRAM,
  UPDATE_AUDIENCE_HISTOGRAM,
  RESET_STATE,
  UPDATE_EVENT_HISTOGRAM,
  ADD_NICKNAME,
  SET_DASHBOARDS,
  SET_FILTER,
  SET_TEMPORARY_FILTER_MODE,
};
