<template>
  <v-dialog
    v-model="show"
    max-width="600"
    persistent
    scrollable
    @keydown.esc="closeNameDialog"
    @keydown.enter="saveAudience"
  >
    <v-card class="dialog-card">
      <ValidationObserver
        v-slot="{ invalid, touched, handleSubmit }"
        ref="validationObserver"
        slim
      >
        <v-card-title class="text-h5">
          <span>{{ headline }}</span
          ><span v-if="isAdmin" class="ml-2">(id = {{ audience.id }})</span>
        </v-card-title>
        <v-card-text class="row">
          <v-col cols="12">
            <span>{{ $t('components.audiences.dialogName.name') }}</span>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.audiences.dialogName.form.german')"
              vid="german"
              rules="required|min:3|max:30"
            >
              <v-text-field
                v-model="audience.german"
                :label="$t('components.audiences.dialogName.form.german')"
                :counter="30"
                :loading="loading"
                :disabled="isPinpollAudience"
                required
                :error-messages="errors"
                @focus="$event.target.select()"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.audiences.dialogName.form.english')"
              vid="english"
              rules="required|min:3|max:30"
            >
              <v-text-field
                v-model="audience.english"
                :error-messages="errors"
                :label="$t('components.audiences.dialogName.form.english')"
                :counter="30"
                :loading="loading"
                :disabled="isPinpollAudience"
                required
                @focus="$event.target.select()"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="audience.hide_analytics"
              :label="
                $t('components.audiences.dialogName.form.hideAnalytics.label')
              "
              :hint="
                $t('components.audiences.dialogName.form.hideAnalytics.hint', {
                  model: localizedModelName,
                })
              "
              persistent-hint
              color="success"
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="audience.hide_dmp"
              :label="$t('components.audiences.dialogName.form.hideDmp.label')"
              :hint="
                $t('components.audiences.dialogName.form.hideDmp.hint', {
                  model: localizedModelName,
                })
              "
              persistent-hint
              color="success"
            />
          </v-col>
          <v-col v-if="isAdmin" cols="6">
            <v-switch
              v-model="audience.is_pinpoll"
              :label="
                $t('components.audiences.dialogName.form.isPinpoll.label', {
                  model: modelName,
                })
              "
              :hint="$t('components.audiences.dialogName.form.isPinpoll.hint')"
              persistent-hint
              color="success"
            />
          </v-col>
          <v-col v-if="isAdmin" cols="6">
            <v-switch
              v-model="audience.is_aitopic"
              :label="
                $t('components.audiences.dialogName.form.isAiTopic.label', {
                  model: modelName,
                })
              "
              :hint="$t('components.audiences.dialogName.form.isAiTopic.hint')"
              persistent-hint
              color="success"
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" text @click="closeNameDialog">
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :loading="savingProgress"
            :disabled="touched && invalid"
            color="success"
            @click="handleSubmit(saveAudience)"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from '@/utils/helpers';

export default {
  props: {
    modelName: {
      type: String,
      required: true,
      default: '',
    },
    type: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      helpers,
      show: false,
      isEdit: false,
      headline: '',
      savingProgress: false,
      loading: false,
      audience: {
        hide_analytics: false,
        hide_dmp: false,
        is_pinpoll: false,
        is_aitopic: false,
      },
      isPinpollAudience: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$acl.check('viewModuleAdmin');
    },
    localizedModelName() {
      // transform model to lowercase if language is english
      return localStorage.language === 'en'
        ? this.modelName.toLowerCase()
        : this.modelName;
    },
  },
  mounted() {
    this.$bus.listen(`${this.modelName}:show-name-dialog`, this.showNameDialog);
  },
  destroyed() {
    this.$bus.remove(`${this.modelName}:show-name-dialog`);
  },
  methods: {
    showNameDialog(params) {
      if (params.isEdit) {
        this.isEdit = true;
        this.headline = this.$t('components.audiences.dialogName.title.edit', {
          model: this.modelName,
        });
        this.getAudience(params.parentId);
        this.isPinpollAudience = params.isPinpollAudience;
      } else {
        this.headline = this.$t('components.audiences.dialogName.title.new', {
          model: this.modelName,
        });
        this.audience.parent_id = params.parentId;
      }
      this.show = true;
    },
    resetFields() {
      this.$refs.validationObserver.reset();
      this.audience = {
        hide_analytics: false,
        hide_dmp: false,
        is_pinpoll: false,
      };
      this.isEdit = false;
      this.isPinpollAudience = false;
    },
    closeNameDialog() {
      this.resetFields();
      this.show = false;
    },
    async saveAudience() {
      this.savingProgress = true;

      const params = {
        ...this.audience,
        type_id: this.type,
      };

      try {
        if (this.isEdit) {
          await this.$store.dispatch('audience/updateAudience', params);
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.modelUpdated', {
              model: this.modelName,
            }),
          });
        } else {
          await this.$store.dispatch('audience/createAudience', params);
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.modelCreated', {
              model: this.modelName,
            }),
          });
        }
        this.closeNameDialog();
      } catch (errors) {
        this.$refs.validationObserver.setErrors(
          helpers.transformErrorObject(errors)
        );
      }
      this.savingProgress = false;
    },
    async getAudience(id) {
      this.loading = true;
      try {
        const audience = await this.$store.dispatch('audience/getAudience', id);
        this.audience = audience;
        if (audience.translations) {
          // transform german and english translation
          this.audience.german = audience.translations.filter(
            (el) => el.locale === 'de'
          )[0].text;
          this.audience.english = audience.translations.filter(
            (el) => el.locale === 'en'
          )[0].text;
          this.audience.translations = undefined;
        }
      } catch (err) {
        console.error(err);
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.dialog-card {
  height: 100%;
  overflow: auto;
}
</style>
