<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" xl="3">
        <hot-link
          icon-key="mdi-poll-box"
          icon-color="ppTools"
          :title="$t('components.dashboardActions.tools.title')"
          :description="
            $acl.check('useProductTools')
              ? $t('components.dashboardActions.open')
              : $t('components.dashboardActions.buy')
          "
          :destination="
            $acl.check('useProductTools')
              ? destinations.tools.use
              : destinations.tools.buy
          "
          :withAlert="!$acl.check('useProductTools')"
        />
      </v-col>
      <v-col cols="12" sm="6" xl="3">
        <hot-link
          icon-key="mdi-chart-donut-variant"
          icon-color="ppDmp"
          :title="$t('components.dashboardActions.dmp.title')"
          :description="
            $acl.check('useProductDmp')
              ? $t('components.dashboardActions.open')
              : $t('components.dashboardActions.buy')
          "
          :destination="
            $acl.check('useProductDmp')
              ? destinations.dmp.use
              : destinations.dmp.buy
          "
          :withAlert="!$acl.check('useProductDmp')"
        />
      </v-col>
      <v-col cols="12" sm="6" xl="3">
        <hot-link
          icon-key="mdi-chart-timeline-variant"
          icon-color="ppAnalytics"
          :title="$t('components.dashboardActions.analytics.title')"
          :description="
            $acl.check('useProductAnalytics')
              ? $t('components.dashboardActions.open')
              : $t('components.dashboardActions.buy')
          "
          :destination="
            $acl.check('useProductAnalytics')
              ? destinations.analytics.use
              : destinations.analytics.buy
          "
          :withAlert="!$acl.check('useProductAnalytics')"
        />
      </v-col>
      <v-col cols="12" sm="6" xl="3">
        <hot-link
          icon-key="mdi-picture-in-picture-top-right-outline"
          icon-color="ppAds"
          :title="$t('components.dashboardActions.ads.title')"
          :description="
            $acl.check('useProductAds')
              ? $t('components.dashboardActions.open')
              : $t('components.dashboardActions.buy')
          "
          :destination="
            $acl.check('useProductAds')
              ? destinations.ads.use
              : destinations.ads.buy
          "
          :withAlert="!$acl.check('useProductAds')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HotLink from '@/components/HotLink';
import constants from '@/utils/constants';

export default {
  components: { HotLink },
  data() {
    return {
      destinations: {
        tools: {
          buy: `${constants.ACCOUNT_URL}/store/tools`,
          use: `${constants.TOOLS_URL}`,
        },
        analytics: {
          buy: `${constants.ACCOUNT_URL}/store/analytics`,
          use: `${constants.ANALYTICS_URL}/realtime`,
        },
        dmp: {
          buy: `${constants.ACCOUNT_URL}/store/dmp`,
          use: `${constants.DMP_URL}/audiences/show`,
        },
        ads: {
          // ads can only be used when buying Pinpoll Tools Enterprise
          buy: `${constants.ACCOUNT_URL}/store/tools`,
          use: `${constants.ADS_URL}/campaigns/place-ads`,
        },
      },
    };
  },
};
</script>
