import moment from 'moment';
import MonitoringResource from '../../../resources/MonitoringResource';
import Socket from '../../../sockets/Socket';
import ConditionalSocket from '../../../sockets/ConditionalSocket';
import * as types from './mutation-types';

/**
 * Definition for VuexStore.
 * @typedef {{commit: Function, dispatch: Function, getters: Object, state:Object, rootGetters: Object, rootState: Object}} VuexStore
 */

export const paramFunctions = {
  /**
   * @param {Object} options Options
   * @param {VuexContext} options.VuexContext context of monitoring store
   * @returns {Object} with all current configured filters excluding datereange
   */
  filters: ({ VuexContext }) => {
    const data = {};
    if (VuexContext.state.filter.domain)
      data.domain = VuexContext.state.filter.domain;
    if (VuexContext.state.filter.page)
      data.page = VuexContext.state.filter.page;
    if (VuexContext.state.filter.terms.length) {
      data.terms = VuexContext.state.filter.terms.map((filter) => ({
        field: filter.field,
        value: filter.value,
      }));
    }
    return data;
  },

  /**
   * @param {Object} options Options
   * @param {VuexContext} options.VuexContext context of monitoring store
   * @returns {Object} with all current configured filters
   */
  rangeFilters: ({ VuexContext }) => {
    const params = {
      from: VuexContext.state.filter.startDate,
      to: VuexContext.state.filter.endDate,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...paramFunctions.filters({ VuexContext }),
    };

    return params;
  },

  /**
   * today timerange + global filters
   * @param {VuexContext} options.VuexContext context of monitoring store
   * @returns {Object} with all current configured filters range fixed for today
   */
  todayFilter: ({ VuexContext }) => {
    const today = new Date().toISOString().substr(0, 10);
    const params = {
      from: today,
      to: today,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...paramFunctions.filters({ VuexContext }),
    };
    return params;
  },

  /**
   * @returns {object} today utc timerange filter
   */
  todayUTC: () => ({
    from: moment().startOf('day').utc().format(),
    to: moment().startOf('day').utc().add(1, 'days').format(),
  }),

  /**
   * @returns {object} today timerange filter
   */
  today: () => ({
    from: moment().format('YYYY-MM-DDT00:00:00.000'),
    to: moment().add(1, 'days').format('YYYY-MM-DDT00:00:00.000'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }),

  /**
   * @returns {object} yesterday utc timerange filter
   */
  yesterdayUTC: () => ({
    from: moment().startOf('day').utc().subtract(1, 'days').format(),
    to: moment().startOf('day').utc().format(),
  }),

  /**
   * @returns {object} yesterday timerange filter
   */
  yesterday: () => ({
    from: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000'),
    to: moment().format('YYYY-MM-DDT00:00:00.000'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }),

  /**
   * @returns {object} same day last week utc timerange filter
   */
  lastWeekUTC: () => ({
    from: moment().startOf('day').utc().subtract(7, 'days').format(),
    to: moment().startOf('day').utc().subtract(6, 'days').format(),
  }),

  /**
   * @returns {object} same day last week timerange filter
   */
  lastWeek: () => ({
    from: moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00.000'),
    to: moment().subtract(6, 'days').format('YYYY-MM-DDT00:00:00.000'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }),

  /**
   * Generate custom filter function, allows overrides like other timeranges
   * @param {Function} overrides Function which returns custom param values.
   * @returns {Function} which returns Object with all current configured filters
   */
  customRangeFilter: (overrides) => (options) => ({
    ...paramFunctions.rangeFilters(options),
    ...overrides(options),
  }),

  /**
   * Combines multiple filter functions into one
   * @param {Array<Function>} functions Array of filter functions
   * @returns {Function} combined filter function
   */
  combine(functions) {
    return (...args) => {
      const data = {};
      functions.forEach((filterFunction) =>
        Object.assign(data, filterFunction(...args))
      );
      return data;
    };
  },
};
const apiResource = new MonitoringResource();
export const apiEndpoints = {
  traffic: {
    'views.histogram': apiResource.endpoint(
      'get',
      '/views/histogram',
      ({ VuexContext }) => {
        const params = {
          from: VuexContext.state.filter.startDate,
          to: VuexContext.state.filter.endDate,
          interval: '1h',
        };
        if (VuexContext.state.filter.domain)
          params.domain = VuexContext.state.filter.domain;
        return params;
      }
    ),
  },
  realtime: {
    'realtime.histogram': apiResource.endpoint(
      'get',
      '/users/histogram',
      paramFunctions.customRangeFilter(() => ({
        from: new Date().toISOString().substr(0, 10),
        to: 'now',
        interval: '5m',
      }))
    ),
    'realtime.histogram_compare': apiResource.endpoint(
      'get',
      '/users/histogram',
      paramFunctions.customRangeFilter(({ VuexContext }) => {
        const from = new Date();
        const to = new Date();
        switch (VuexContext.state.settings.histogram_compare) {
          case 'last_week':
            from.setDate(from.getDate() - 7);
            to.setDate(to.getDate() - 7);
            break;
          case 'yesterday':
          default:
            from.setDate(from.getDate() - 1);
            to.setDate(to.getDate() - 1);
            break;
        }
        return {
          from: from.toISOString().substr(0, 10),
          to: to.toISOString().substr(0, 10),
          interval: '5m',
        };
      })
    ),
    'facts.browsers': apiResource.endpoint(
      'get',
      '/browsers',
      paramFunctions.todayFilter
    ),
    'facts.oslist': apiResource.endpoint(
      'get',
      '/operatingSystems',
      paramFunctions.todayFilter
    ),
    'facts.referrer': apiResource.endpoint(
      'get',
      '/referrer',
      paramFunctions.customRangeFilter(() => {
        const today = new Date().toISOString().substr(0, 10);
        return {
          from: today,
          to: today,
          page: undefined,
        };
      })
    ),
  },
  overview: {
    'users.total': apiResource.endpoint(
      'get',
      '/users',
      paramFunctions.rangeFilters
    ),
    'insights.devices': apiResource.endpoint(
      'get',
      '/devices',
      paramFunctions.rangeFilters
    ),
    'users.pages': {
      condition: ({ VuexContext }) =>
        !!VuexContext.getters.showDomains &&
        !VuexContext.state.filter.page &&
        !VuexContext.state.settings.usePageViews,
      endpoint: apiResource.endpoint(
        'get',
        '/users/pages',
        paramFunctions.rangeFilters
      ),
    },
    'users.domains': {
      condition: ({ VuexContext }) =>
        !VuexContext.getters.showDomains &&
        !VuexContext.state.settings.usePageViews,
      endpoint: apiResource.endpoint(
        'get',
        '/users/domains',
        paramFunctions.rangeFilters
      ),
    },
    'insights.audiences': apiResource.endpoint(
      'get',
      '/audiences',
      ({ VuexContext }) => ({
        usePinpollAudiences: VuexContext.state.settings.usePinpollAudiences,
        ...paramFunctions.rangeFilters({ VuexContext }),
      })
    ),
    'views.total': apiResource.endpoint(
      'get',
      '/views',
      paramFunctions.rangeFilters
    ),
    'views.domains': {
      condition: ({ VuexContext }) =>
        !VuexContext.getters.showDomains &&
        VuexContext.state.settings.usePageViews,
      endpoint: apiResource.endpoint(
        'get',
        '/views/domains',
        paramFunctions.rangeFilters
      ),
    },
    'views.pages': {
      condition: ({ VuexContext }) =>
        !!VuexContext.getters.showDomains &&
        !VuexContext.state.filter.page &&
        VuexContext.state.settings.usePageViews,
      endpoint: apiResource.endpoint(
        'get',
        '/views/pages',
        paramFunctions.rangeFilters
      ),
    },
    'insights.referrer': {
      condition: ({ VuexContext }) =>
        !!VuexContext.getters.showDomains && !!VuexContext.state.filter.page,
      endpoint: apiResource.endpoint(
        'get',
        '/referrerByType',
        paramFunctions.rangeFilters
      ),
    },
    'insights.channels': {
      condition: ({ VuexContext }) =>
        !!VuexContext.state.settings.useChannels &&
        !VuexContext.state.filter.page,
      endpoint: apiResource.endpoint(
        'get',
        '/users/channels',
        paramFunctions.rangeFilters
      ),
    },
  },
};

const socketQueryParams = paramFunctions.filters;
export const sockets = {
  '/v1/users': new Socket(
    '/v1/users',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, { prop: 'realtime.users', value });
      VuexContext.commit(types.UPDATE_HISTOGRAM, {
        histogramField: 'histogram',
        value: value.total,
        valueField: 'users',
      });
    },
    socketQueryParams
  ),
  '/v1/devices': new Socket(
    '/v1/devices',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.devices',
        value,
      });
    },
    socketQueryParams
  ),
  '/v1/referrer/types': new Socket(
    '/v1/referrer/types',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.referrerTypes',
        value: value.map((item) => {
          item.key = item.key.replace(' ', '_');
          return item;
        }),
      });
    },
    socketQueryParams
  ),
  '/v1/referrer/domains': new Socket(
    '/v1/referrer/domains',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.referrer',
        value,
      });
    },
    socketQueryParams
  ),
  '/v1/pages': new Socket(
    '/v1/pages',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, { prop: 'realtime.pages', value });
    },
    socketQueryParams
  ),
  '/v1/channels': new ConditionalSocket(
    ({ VuexContext }) => !!VuexContext.state.settings.useChannels,
    '/v1/channels',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.channels',
        value,
      });
    },
    socketQueryParams
  ),
  '/v1/domains': new ConditionalSocket(
    ({ VuexContext }) => !VuexContext.getters.showDomains,
    '/v1/domains',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.domains',
        value,
      });
    },
    socketQueryParams
  ),
  '/v1/audiences': new Socket(
    '/v1/audiences',
    (value, { VuexContext }) => {
      VuexContext.commit(types.UPDATE_DATA, {
        prop: 'realtime.audiences',
        value,
      });
    },
    ({ VuexContext }) => ({
      usePinpollAudiences: VuexContext.state.settings.usePinpollAudiences,
      ...socketQueryParams({ VuexContext }),
    })
  ),
};

/**
 * Definitions for Resources that are used in state.activeResources
 * Can contain ResourceEndpoint and Socket objects
 */
export const resources = {
  'audienceGrowth.yesterday': () =>
    apiResource.endpoint(
      'get',
      '/audiences/growth/history',
      paramFunctions.yesterdayUTC
    ),
  'audienceGrowth.lastWeek': () =>
    apiResource.endpoint(
      'get',
      '/audiences/growth/history',
      paramFunctions.lastWeekUTC
    ),
  'audienceGrowth.today': () =>
    new Socket(
      '/v1/audienceGrowth',
      (value, { VuexContext, ownerId, parameters }) => {
        VuexContext.commit(types.UPDATE_DATA, {
          prop: 'audienceGrowth.today',
          value,
          parameters,
          ownerId,
        });
        VuexContext.commit(types.UPDATE_AUDIENCE_HISTOGRAM, {
          histogramField: 'histogram',
          value,
          ownerId,
          valueField: 'audienceCount',
        });
      },
      paramFunctions.todayUTC
    ),
  'eventTracking.yesterday': () =>
    apiResource.endpoint(
      'get',
      '/eventHistory',
      paramFunctions.combine([paramFunctions.yesterday, paramFunctions.filters])
    ),
  'eventTracking.lastWeek': () =>
    apiResource.endpoint(
      'get',
      '/eventHistory',
      paramFunctions.combine([paramFunctions.lastWeek, paramFunctions.filters])
    ),
  'eventTracking.today': () =>
    new Socket(
      '/v1/eventGrowth',
      (value, { VuexContext, ownerId, parameters }) => {
        VuexContext.commit(types.UPDATE_DATA, {
          prop: 'eventTracking.today',
          value,
          parameters,
          ownerId,
        });
        VuexContext.commit(types.UPDATE_EVENT_HISTOGRAM, {
          histogramField: 'histogram',
          value,
          ownerId,
          valueField: 'eventCount',
        });
      },
      paramFunctions.combine([paramFunctions.today, paramFunctions.filters])
    ),
  'eventTracking.pages': () =>
    new Socket(
      '/v1/eventPages',
      (value, { VuexContext, ownerId, parameters }) => {
        VuexContext.commit(types.UPDATE_DATA, {
          prop: 'eventTracking.pages',
          value,
          parameters,
          ownerId,
        });
      },
      paramFunctions.combine([paramFunctions.today, paramFunctions.filters])
    ),
  'users.histogram': () =>
    apiResource.endpoint(
      'get',
      '/users/histogram',
      paramFunctions.customRangeFilter(({ VuexContext }) => ({
        interval: VuexContext.getters.insightsUseHourInterval ? '1h' : '1d',
      }))
    ),
  'audienceGrowth.histogram': () =>
    apiResource.endpoint('get', '/audiences/growth/histogram', () => ({
      ...paramFunctions.todayUTC(),
      interval: 30,
    })),
  'audienceGrowth.histogramCompare': () =>
    apiResource.endpoint('get', '/audiences/growth/histogram', () => ({
      interval: 30,
    })),
  'eventTracking.histogram': () =>
    apiResource.endpoint(
      'get',
      '/eventHistogram',
      paramFunctions.combine([
        paramFunctions.today,
        paramFunctions.filters,
        () => ({ interval: '30m' }),
      ])
    ),
  'eventTracking.histogramCompare': () =>
    apiResource.endpoint(
      'get',
      '/eventHistogram',
      paramFunctions.combine([
        paramFunctions.filters,
        () => ({ interval: '30m' }),
      ])
    ),
  'views.histogram': () =>
    apiResource.endpoint(
      'get',
      '/views/histogram',
      paramFunctions.customRangeFilter(({ VuexContext }) => ({
        interval: VuexContext.getters.insightsUseHourInterval ? '1h' : '1d',
      }))
    ),
  'insights.audiences': () =>
    apiResource.endpoint('get', '/audiences', ({ VuexContext }) => ({
      usePinpollAudiences: VuexContext.state.settings.usePinpollAudiences,
      ...paramFunctions.rangeFilters({ VuexContext }),
    })),
  'insights.devices': () =>
    apiResource.endpoint('get', '/devices', paramFunctions.rangeFilters),
  'insights.referrerTypes': () =>
    apiResource.endpoint('get', '/referrerTypes', paramFunctions.rangeFilters),
  'users.pages': () =>
    apiResource.endpoint('get', '/users/pages', paramFunctions.rangeFilters),
  'views.pages': () =>
    apiResource.endpoint('get', '/views/pages', paramFunctions.rangeFilters),
  'users.domains': () =>
    apiResource.endpoint('get', '/users/domains', paramFunctions.rangeFilters),
  'views.domains': () =>
    apiResource.endpoint('get', '/views/domains', paramFunctions.rangeFilters),
  'insights.referrer': () =>
    apiResource.endpoint(
      'get',
      '/referrerByType',
      paramFunctions.rangeFilters,
      {
        mutation: (value) =>
          value.map((item) => {
            item.key = item.key.replace(' ', '_');
            return item;
          }),
      }
    ),
  'facts.trendingPages': () =>
    apiResource.endpoint('get', '/views/pages', paramFunctions.todayFilter, {
      excludeParams: ['page'],
    }),

  'toolsStats.today': () =>
    new Socket(
      '/v1/toolsStats',
      (value, { VuexContext }) => {
        VuexContext.commit(types.UPDATE_DATA, {
          prop: 'toolsStats.today',
          value,
        });
      },
      paramFunctions.today
    ),
  'toolsStats.yesterday': () =>
    apiResource.endpoint('get', '/toolsStats', paramFunctions.yesterday),

  'toolsStats.lastWeek': () =>
    apiResource.endpoint('get', '/toolsStats', paramFunctions.lastWeek),
};

export function getResource(name) {
  if (resources[name]) {
    return resources[name]();
  }
  console.warn(`resource ${name} does not exist`);
  return null;
}

export default {
  apiEndpoints,
  sockets,
  resources,
  getResource,
};
