import Resource from './Resource';

class AudienceResource extends Resource {
  /**
   * The constructor for the AudienceResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/audiences');
  }

  /**
   * Method used to get all audiences from current user
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAudiences(params) {
    return this.submit('get', 'getAudiences', params);
  }

  /**
   * Method used to get all audiences
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAllAudiences(params) {
    return this.submit('get', 'getAllAudiences', params);
  }

  /**
   * Method used to get visitor count of own audiences
   *
   * @param {Array} params params contains filters.
   *
   * @returns {EventSource} The result in a promise.
   */
  getVisitorCount(params) {
    return this.submit('post', 'getVisitorCount', params);
  }

  /**
   * Method used to get leads count of own audiences
   *
   * @param {Array} params params contains filters.
   *
   * @returns {EventSource} The result in a promise.
   */
  getLeadsCount(params) {
    return this.submit('post', 'getLeadsCount', params);
  }

  /**
   * Method used to get all assigned audiences
   *
   * @param {Object} params audience_id.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAssignedAudiences(params) {
    return this.submit('get', 'getAssignedAudiences', params);
  }

  /**
   * Method used to assign audience
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  assignAudience(params) {
    return this.submit('post', 'assignAudience', params);
  }

  /**
   * Method used to unassign audience
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  unassignAudience(params) {
    return this.submit('post', 'unassignAudience', params);
  }

  /**
   * Method used to assign a new audience
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  assignNewAudience(params) {
    return this.submit('post', 'assignNewAudience', params);
  }

  /**
   * Method used to assign a new audience
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  updateAffinityScore(params) {
    return this.submit('post', 'updateAffinityScore', params);
  }

  /**
   * Method used to downloadLeadsOfAudience
   *
   * @param {Object} params params.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  downloadLeadsOfAudience(params) {
    return this.downloadFile('get', 'downloadLeadsOfAudience', params);
  }

  /**
   * Method used to downloadLeadsOfAudience
   *
   * @param {Object} params params.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  downloadAudiences(params) {
    return this.downloadFile('get', 'download', params);
  }

  /**
   * Method used to get assigned rules
   *
   * @param {Object} params params.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAssignedRules(params) {
    return this.submit('get', 'getAssignedRules', params);
  }
}

export default AudienceResource;
