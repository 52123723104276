<template>
  <v-card class="settings">
    <v-select
      v-model="select"
      :items="items"
      :label="$t('monitoring.date.today') + ' vs.'"
      :hide-details="true"
      class="selectBox"
      @change="changeCompare"
    />
    <v-autocomplete
      v-model="assignedInterest"
      :items="userInterestsAnalytics"
      :label="$t('components.transfer.stepInventory.form.interests.label')"
      :loading="loadingAudiences"
      name="assignedInterests"
      item-text="text"
      item-value="id"
      clearable
      :disabled="!!assignedProperty"
      class="selectBox"
      @change="changeAudienceId"
    >
      <template #item="data">
        <v-list-item-avatar>
          <img :src="data.item.filename || helpers.getPlaceholderImage()" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="assignedProperty"
      :items="userVisitorPropertiesAnalytics"
      :label="$t('components.transfer.stepInventory.form.properties.label')"
      :loading="loadingAudiences"
      name="assignedProperties"
      item-text="text"
      item-value="id"
      clearable
      :disabled="!!assignedInterest"
      class="selectBox"
      @change="changeAudienceId"
    >
      <template #item="data">
        <v-list-item-avatar>
          <img :src="data.item.filename || helpers.getPlaceholderImage()" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import helpers from '@/utils/helpers';

export default {
  props: {
    cardSettings: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      helpers,
      loadingAudiences: false,
      assignedInterest: null,
      assignedProperty: null,
      layoutSettings: null,
      select: null,
      items: ['yesterday', 'last_week'].map((value) => ({
        value,
        text: this.$t(`monitoring.date.${value}`),
      })),
    };
  },
  computed: {
    ...mapGetters('audience', [
      'userInterestsAnalytics',
      'userVisitorPropertiesAnalytics',
    ]),
    ...mapState('monitoring', ['settings']),
  },
  watch: {
    cardSettings() {
      this.readCardSettings();
    },
  },
  mounted() {
    this.getAudiences();
    this.$bus.listen('clear-errors', this.clearErrors);
    this.layoutSettings = this.settings.monitoringGridLayout;
    this.readCardSettings();
  },
  methods: {
    readCardSettings() {
      const isInterest = this.userInterestsAnalytics.findIndex(
        (interest) => interest.id === this.cardSettings.audienceId
      );
      if (isInterest === -1) {
        this.assignedInterest = null;
        this.assignedProperty = this.cardSettings.audienceId;
      } else {
        this.assignedInterest = this.cardSettings.audienceId;
        this.assignedProperty = null;
      }
      this.select = this.cardSettings.histogramCompare;
    },
    async getAudiences() {
      this.loadingAudiences = true;
      await this.$store.dispatch('audience/getAudiences');
      this.loadingAudiences = false;
    },
    changeAudienceId(audienceId) {
      if (audienceId != null) {
        this.$emit('updated', { audienceId, histogramCompare: this.select });
        this.$store.dispatch('monitoring/purgeActiveResources');
        this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
      }
    },
    changeCompare(value) {
      this.$emit('updated', {
        audienceId: this.cardSettings.audienceId,
        histogramCompare: value,
      });
      this.$store.dispatch('monitoring/purge', {
        prop: 'audienceGrowth.histogramCompare',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
  },
};
</script>

<style scoped>
.title {
  width: 250px;
}
.settings {
  padding: 16px;
}
.selectBox {
  height: 50px;
}
</style>
