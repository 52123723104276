var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('hot-link',{attrs:{"icon-key":"mdi-poll-box","icon-color":"ppTools","title":_vm.$t('components.dashboardActions.tools.title'),"description":_vm.$acl.check('useProductTools')
            ? _vm.$t('components.dashboardActions.open')
            : _vm.$t('components.dashboardActions.buy'),"destination":_vm.$acl.check('useProductTools')
            ? _vm.destinations.tools.use
            : _vm.destinations.tools.buy,"withAlert":!_vm.$acl.check('useProductTools')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('hot-link',{attrs:{"icon-key":"mdi-chart-donut-variant","icon-color":"ppDmp","title":_vm.$t('components.dashboardActions.dmp.title'),"description":_vm.$acl.check('useProductDmp')
            ? _vm.$t('components.dashboardActions.open')
            : _vm.$t('components.dashboardActions.buy'),"destination":_vm.$acl.check('useProductDmp')
            ? _vm.destinations.dmp.use
            : _vm.destinations.dmp.buy,"withAlert":!_vm.$acl.check('useProductDmp')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('hot-link',{attrs:{"icon-key":"mdi-chart-timeline-variant","icon-color":"ppAnalytics","title":_vm.$t('components.dashboardActions.analytics.title'),"description":_vm.$acl.check('useProductAnalytics')
            ? _vm.$t('components.dashboardActions.open')
            : _vm.$t('components.dashboardActions.buy'),"destination":_vm.$acl.check('useProductAnalytics')
            ? _vm.destinations.analytics.use
            : _vm.destinations.analytics.buy,"withAlert":!_vm.$acl.check('useProductAnalytics')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"3"}},[_c('hot-link',{attrs:{"icon-key":"mdi-picture-in-picture-top-right-outline","icon-color":"ppAds","title":_vm.$t('components.dashboardActions.ads.title'),"description":_vm.$acl.check('useProductAds')
            ? _vm.$t('components.dashboardActions.open')
            : _vm.$t('components.dashboardActions.buy'),"destination":_vm.$acl.check('useProductAds')
            ? _vm.destinations.ads.use
            : _vm.destinations.ads.buy,"withAlert":!_vm.$acl.check('useProductAds')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }