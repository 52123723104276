/* ============
 * Actions for the audience transfer module
 * ============
 *
 * The actions that are available on the
 * audience transfer module.
 */

import AudienceTransferResource from '@/resources/AudienceTransferResource';
import CampaignTransformer from '@/transformers/CampaignTransformer';
import * as types from './mutation-types';

export const datatable = ({ commit }, params) =>
  new Promise((resolve) => {
    new AudienceTransferResource()
      .datatable(params)
      .then((data) => {
        commit(types.SET_FILTERED_AUDIENCE_TRANSFERS, data);
        resolve();
      })
      .catch((error) => {
        console.error(error);
      });
  });

export const createAudienceTransfer = ({ state }) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .create(state.form)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateAudienceTransfer = ({ state }, id) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .update(id, state.form)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteAudienceTransfer = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .destroy(id)
      .then(() => {
        commit(types.DELETE_AUDIENCE_TRANSFER, id);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAudienceTransfer = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .show(id)
      .then((data) => {
        commit(types.SET_AUDIENCE_TRANSFER, CampaignTransformer.fetch(data));
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const getAudienceTransferEdit = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .show(id)
      .then((data) => {
        commit(types.SET_AUDIENCE_TRANSFER_TO_EDIT, data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const toggleState = (context, params) =>
  new Promise((resolve, reject) => {
    new AudienceTransferResource()
      .toggleState(params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  datatable,
  createAudienceTransfer,
  updateAudienceTransfer,
  deleteAudienceTransfer,
  getAudienceTransfer,
  getAudienceTransferEdit,
  toggleState,
};
