/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from './mutation-types';

export default {
  [types.CHECK](state) {
    // set the state to the existence of the authenticated cookie
    state.authenticated = document.cookie.includes('authenticated=');
  },

  [types.LOGIN](state) {
    state.authenticated = true;
  },

  [types.LOGOUT](state) {
    state.authenticated = false;
  },
};
