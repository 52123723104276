/* ============
 * State of the cronjob module
 * ============
 *
 * The initial state of the cronjob module.
 */

export const getInitialState = () => ({});

export default getInitialState;
