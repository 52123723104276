<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('components.tracking.stepInventory.title') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="
                $t(
                  'components.tracking.stepInventory.form.auto_assignment.label'
                )
              "
              vid="auto_assignment"
              rules=""
            >
              <v-switch
                v-model="auto_assignment"
                :label="
                  $t(
                    'components.tracking.stepInventory.form.auto_assignment.label'
                  )
                "
                :hint="
                  $t(
                    'components.tracking.stepInventory.form.auto_assignment.hint'
                  )
                "
                persistent-hint
                color="success"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="
                $t('components.tracking.stepInventory.form.questions.label')
              "
              vid="assignedQuestions"
              rules=""
            >
              <v-autocomplete
                v-model="assignedQuestions"
                :disabled="auto_assignment"
                :items="questions"
                :label="
                  $t('components.tracking.stepInventory.form.questions.label')
                "
                :hint="
                  $t('components.tracking.stepInventory.form.questions.hint')
                "
                :loading="loadingQuestions"
                name="assignedQuestions"
                item-text="question"
                item-value="id"
                multiple
                chips
                deletable-chips
                multi-line
                :error-messages="errors"
              >
                <template #item="data">
                  <v-list-item-content :disabled="data.item.disabled">
                    <v-list-item-title>
                      {{ data.item.question }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.answers.length }}
                      {{
                        $t(
                          'components.tracking.stepInventory.form.questions.answers'
                        )
                      }}
                      | {{ data.item.votes }}
                      {{
                        $t(
                          'components.tracking.stepInventory.form.questions.votes'
                        )
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepInventory.form.answers.label')"
              vid="assignedAnswers"
              rules=""
            >
              <v-autocomplete
                v-model="assignedAnswers"
                :disabled="auto_assignment"
                :items="questionsWithAnswers"
                :label="
                  $t('components.tracking.stepInventory.form.answers.label')
                "
                :hint="
                  $t('components.tracking.stepInventory.form.answers.hint')
                "
                :loading="loadingQuestions"
                name="assignedAnswers"
                item-text="answer"
                item-value="id"
                multiple
                chips
                deletable-chips
                multi-line
                :error-messages="errors"
              >
                <template #item="data">
                  <v-list-item-content :disabled="data.item.disabled">
                    <v-list-item-title>
                      {{ data.item.answer }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.question }} | {{ data.item.votes }}
                      {{
                        $t(
                          'components.tracking.stepInventory.form.answers.votes'
                        )
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepInventory.form.ads.label')"
              vid="assignedAds"
              rules=""
            >
              <v-autocomplete
                v-model="assignedAds"
                :disabled="auto_assignment"
                :items="ads"
                :label="$t('components.tracking.stepInventory.form.ads.label')"
                :hint="$t('components.tracking.stepInventory.form.ads.hint')"
                :loading="loadingAds"
                name="assignedAds"
                item-text="name"
                item-value="id"
                multiple
                chips
                deletable-chips
                :error-messages="adBlockerText || errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.tracking.stepInventory.form.forms.label')"
              vid="assignedForms"
              rules=""
            >
              <v-autocomplete
                v-model="assignedForms"
                :disabled="auto_assignment"
                :items="forms"
                :label="
                  $t('components.tracking.stepInventory.form.forms.label')
                "
                :hint="$t('components.tracking.stepInventory.form.forms.hint')"
                :loading="loadingForms"
                name="assignedForms"
                item-text="title"
                item-value="id"
                multiple
                chips
                deletable-chips
                multi-line
                :error-messages="errors"
              >
                <template #item="data">
                  <v-list-item-content :disabled="data.item.disabled">
                    <v-list-item-title>
                      {{ data.item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'tracking/getField',
  mutationType: 'tracking/updateField',
});
export default {
  data() {
    return {
      loadingAds: false,
      loadingQuestions: false,
      loadingAudiences: false,
      loadingForms: false,
      adBlockerText: '',
    };
  },
  computed: {
    ...mapState('tracking', ['currentStep']),
    ...mapState('question', ['questions']),
    ...mapState('ad', ['ads']),
    ...mapState('form', ['forms']),
    ...mapGetters('question', ['questionsWithAnswers']),
    ...mapFields([
      'form.auto_assignment',
      'form.assignedQuestions',
      'form.assignedAnswers',
      'form.assignedAds',
      'form.assignedForms',
    ]),
  },
  watch: {
    // TODO disable questions when answer is selected, waiting for #3103
    // assignedQuestions(newValues, oldValues) {
    //   console.log(newValues, oldValues);
    // },
    // TODO disable answer when question is selected, waiting for #3103
    // assignedAnswers(newValues, oldValues) {
    //   console.log(newValues, oldValues);
    // },
    auto_assignment(newValue) {
      if (newValue) {
        // empty assigned items if auto assignment is selected
        this.assignedQuestions = [];
        this.assignedAnswers = [];
        this.assignedAds = [];
        this.assignedForms = [];
      }
    },
  },
  mounted() {
    this.getQuestions();
    this.getAds();
    this.getForms();
  },
  methods: {
    async getQuestions() {
      this.loadingQuestions = true;
      try {
        await this.$store.dispatch('question/getQuestions');
      } catch (err) {
        console.error(err);
      }
      this.loadingQuestions = false;
    },
    async getAds() {
      this.loadingAds = true;
      try {
        await this.$store.dispatch('ad/getAds');
      } catch (err) {
        // if error is empty it may be blocked by adblocker
        if (!err) {
          this.adBlockerText = this.$t(
            'components.tracking.stepInventory.form.ads.errorAdBlocker'
          );
        }
      }
      this.loadingAds = false;
    },
    async getForms() {
      this.loadingForms = true;
      try {
        await this.$store.dispatch('form/getForms');
      } catch (err) {
        console.error(err);
      }
      this.loadingForms = false;
    },
  },
};
</script>
