/* ============
 * Getters for the audience module
 * ============
 *
 * The getters that are available on the
 * audience module.
 */
import { AUDIENCE_TYPES } from '@/utils/constants';
import helpers from '@/utils/helpers';

export default {
  visitorCountPercentage(state) {
    let currentCount = 0;
    if (state.audiences.length) {
      currentCount = state.audiences
        .map((item) => item.visitorCount)
        .reduce((prev, next) => prev + next);
    }
    return state.visitorCountTotal ? currentCount / state.visitorCountTotal : 1;
  },
  treeDataUserInterests(state, getters) {
    // show all user interests in tree
    return helpers.createTree(_.cloneDeep(getters.userInterests));
  },
  treeDataUserVisitorProperties(state, getters) {
    // show all user visitor properties in tree
    return helpers.createTree(_.cloneDeep(getters.userVisitorProperties));
  },
  treeDataAudiencesAnalytics(state) {
    // show all audiences in tree
    return helpers.createTree(
      _.cloneDeep(
        state.allAudiences.filter((audience) => !audience.hide_analytics)
      )
    );
  },
  userInterests(state, getters, rootState, rootGetters) {
    // show only interests
    return state.audiences.filter(
      (audience) =>
        audience.type_id === AUDIENCE_TYPES.INTEREST &&
        (rootGetters['account/isAdmin'] ||
          audience.is_pinpoll ||
          !audience.is_aitopic)
    );
  },
  userInterestsAnalytics(state, getters, rootState, rootGetters) {
    // show only interests for analytics
    return state.audiences.filter(
      (audience) =>
        audience.type_id === AUDIENCE_TYPES.INTEREST &&
        !audience.hide_analytics &&
        !audience.is_pinpoll &&
        (rootGetters['account/isAdmin'] ||
          audience.is_pinpoll ||
          !audience.is_aitopic)
    );
  },
  userInterestsDmp(state, getters) {
    return getters.userInterests.filter((audience) => !audience.hide_dmp);
  },
  categoryAudiences(state, getters) {
    return getters.userInterests.filter(
      (audience) => audience.user_id || audience.is_pinpoll
    );
  },
  aiTopics(state) {
    return state.audiences.filter(
      (audience) => !audience.hide_dmp && audience.is_aitopic
    );
  },
  userVisitorProperties(state, getters, rootState, rootGetters) {
    return state.audiences.filter(
      (audience) =>
        audience.type_id === AUDIENCE_TYPES.PROPERTY &&
        (rootGetters['account/isAdmin'] ||
          audience.is_pinpoll ||
          !audience.is_aitopic)
    );
  },
  userVisitorPropertiesAnalytics(state, getters, rootState, rootGetters) {
    return state.audiences.filter(
      (audience) =>
        audience.type_id === AUDIENCE_TYPES.PROPERTY &&
        !audience.hide_analytics &&
        !audience.is_pinpoll &&
        (rootGetters['account/isAdmin'] ||
          audience.is_pinpoll ||
          !audience.is_aitopic)
    );
  },
  userVisitorPropertiesDmp(state, getters) {
    return getters.userVisitorProperties.filter(
      (audience) => !audience.hide_dmp
    );
  },
  audienceMap(state) {
    return state.audiences.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});
  },
};
