<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">
        {{ $t('components.transfer.stepData.title') }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.transfer.stepData.form.name.label')"
              vid="name"
              rules="required|min:3|max:50"
            >
              <v-text-field
                v-model="name"
                :counter="50"
                :label="$t('components.transfer.stepData.form.name.label')"
                :hint="$t('components.transfer.stepData.form.name.hint')"
                name="name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.transfer.stepData.form.startDate.label')"
              vid="start_date"
              rules=""
            >
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="start_date"
                min-width="290px"
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    v-bind="attrs"
                    :label="
                      $t('components.transfer.stepData.form.startDate.label')
                    "
                    :hint="
                      $t('components.transfer.stepData.form.startDate.hint')
                    "
                    name="start_date"
                    prepend-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start_date"
                  min="2018-01-01"
                  @input="$refs.startDateMenu.save(start_date)"
                />
              </v-menu>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.transfer.stepData.form.endDate.label')"
              vid="end_date"
              rules=""
            >
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="end_date"
                min-width="290px"
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    v-bind="attrs"
                    :label="
                      $t('components.transfer.stepData.form.endDate.label')
                    "
                    :hint="$t('components.transfer.stepData.form.endDate.hint')"
                    name="end_date"
                    prepend-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="start_date"
                  @input="$refs.endDateMenu.save(end_date)"
                />
              </v-menu>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'transfer/getField',
  mutationType: 'transfer/updateField',
});
export default {
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  computed: {
    ...mapState('transfer', ['currentStep']),
    ...mapState('account', ['user']),
    ...mapFields(['form.name', 'form.start_date', 'form.end_date']),
  },
};
</script>
