/* ============
 * Mutation types for the metatag module
 * ============
 *
 * The mutation types that are available
 * on the metatag module.
 */

export const SET_META_TAG = 'SET_META_TAG';
export const SET_META_TAG_VISITORS = 'SET_META_TAG_VISITORS';
export const SET_AVAILABLE_FIELDS = 'SET_AVAILABLE_FIELDS';
export const UPDATE_META_TAG = 'UPDATE_META_TAG';
export const SET_FILTERED_META_TAGS = 'SET_FILTERED_META_TAGS';
export const UPDATE_META_TAG_VISITORS = 'UPDATE_META_TAG_VISITORS';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_META_TAG,
  SET_META_TAG_VISITORS,
  SET_AVAILABLE_FIELDS,
  UPDATE_META_TAG,
  SET_FILTERED_META_TAGS,
  UPDATE_META_TAG_VISITORS,
  RESET_STATE,
};
