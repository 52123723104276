<template>
  <div>
    <channel-table />
    <channel-dialog />
  </div>
</template>

<script>
import ChannelDialog from '@/components/Channels/ChannelDialog';
import ChannelTable from '@/components/Channels/ChannelTable';

export default {
  components: {
    ChannelTable,
    ChannelDialog,
  },
};
</script>
<style></style>
