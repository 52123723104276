export const SET_CHANNELS = 'SET_CHANNELS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const RESET_STATE = 'RESET_STATE';
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL';

export default {
  SET_CHANNELS,
  ADD_CHANNEL,
  RESET_STATE,
  REMOVE_CHANNEL,
};
