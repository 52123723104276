<template>
  <v-card class="realtime-histogram-setting">
    <v-card-text>
      <histogram-settings />
      <v-progress-linear
        v-show="!$store.state.monitoring.realtime.histogram_compare.loaded"
        color="primary"
        indeterminate
      />
    </v-card-text>
  </v-card>
</template>

<script>
import HistogramSettings from '@/components/Monitoring/HistogramSettings';

export default {
  components: {
    HistogramSettings,
  },
};
</script>

<style>
.realtime-histogram-setting .switch-tile {
  padding-top: 1px;
  padding-bottom: 1px;
}
.realtime-histogram-setting .switch-tile .v-label {
  color: unset;
}
</style>
