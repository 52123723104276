<template>
  <v-col class="align-center justify-space-between" cols="12">
    <ValidationProvider
      v-slot="{ errors }"
      :name="$t('components.ads.inventory.selectType')"
      rules="required"
    >
      <v-autocomplete
        v-model="selectedAdExperiences"
        :label="$t('components.ads.inventory.selectType')"
        :items="allExperiencesItems"
        :item-text="getAdExperiencesSelectionItemText"
        :item-value="getAdExperiencesSelectionItemValue"
        cache-items
        multiple
        chips
        deletable-chips
        return-object
        :menu-props="menuProps"
        :loading="loading"
        :error-messages="errors"
        @change="onSelectionChanged($event)"
      >
        <template #item="data">
          <v-tooltip
            bottom
            :disabled="!isExperienceDisabledForPosition(data.item)"
          >
            <template #activator="{ on }">
              <v-list-item-content
                v-on="on"
                :class="{
                  'pointer-active': isExperienceDisabledForPosition(data.item),
                }"
              >
                <v-list-item-title
                  :class="{
                    'sub-item':
                      data.item.experience_type_id !=
                      constants.EXPERIENCE_TYPE.ALL,
                  }"
                >
                  {{ data.item.title }}
                </v-list-item-title>
              </v-list-item-content>
              <i>{{
                !isExperienceDisabledForPosition(data.item) &&
                (isItemSelected(data.item) || isSubItemOfSelected(data.item))
                  ? $t('components.ads.inventory.disabledBecauseSelected')
                  : ''
              }}</i>
            </template>
            <span>
              {{
                $t('components.ads.inventory.disabledBecausePosition', {
                  positionText: getSelectedPositionText,
                })
              }}
            </span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>
<script>
import constants from '@/utils/constants';

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuProps: {
      type: Object,
      required: true,
    },
    adLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allExperiencesItems: [],
      constants,
      selectedAdExperiences: [],
    };
  },
  computed: {
    /** @returns {string} */
    getSelectedPositionText() {
      const adPositions = Object.values(
        this.$t('components.ads.settings.display.option')
      );
      return adPositions.find((position) => position.value == this.adDisplay)
        .text;
    },
    /** @returns {string} */
    adDisplay: {
      get() {
        return this.$store.state.ad.ad.display;
      },
    },
  },
  watch: {
    adDisplay: {
      immediate: true,
      handler() {
        this.updateDisabledStateOfItems();
        this.removeDisabledItemsFromSelectedItems();
      },
    },
    selectedAdExperiences: {
      handler(newValue) {
        if (!this.loading && this.adLoaded) {
          this.$store.commit('ad/SET_AD_EXPERIENCES_ITEM_MAPPING', newValue);
        }
      },
      deep: true,
    },
    '$store.state.ad.ad.adExperiences': {
      handler(newValue) {
        if (newValue != this.selectedAdExperiences) {
          this.selectedAdExperiences = newValue;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initItemList();

    this.$bus.listen('clear-data', this.updateDisabledStateOfItems);

    if (!this.adLoaded) {
      this.$bus.listen('ad-loaded', this.onAdLoaded);
    } else {
      this.onAdLoaded();
    }
  },
  destroyed() {
    this.$bus.remove('clear-data', this.clearData);
    this.$bus.remove('ad-loaded', this.clearData);
  },
  methods: {
    initSelection() {
      if (
        !this.$store.state.ad?.ad?.adExperiences ||
        this.$store.state.ad?.ad?.adExperiences.length == 0
      ) {
        this.setSelectedAllExperienceToDefault();
      }
    },
    setSelectedAllExperienceToDefault() {
      const foundExperienceItem = this.allExperiencesItems.find(() => (item) =>
        item.element_type_id == constants.ELEMENT_TYPE.POLL &&
        item.experience_type_id == constants.EXPERIENCE_TYPE.ALL &&
        item.restrict_to_question_number == constants.QUESTION_NUMBER.SINGLE
      );

      this.selectedAdExperiences = [foundExperienceItem];
    },
    onAdLoaded() {
      this.initSelection();
      this.updateDisabledStateOfItems();
    },
    removeDisabledItemsFromSelectedItems() {
      this.selectedAdExperiences = this.$store.state.ad?.ad?.adExperiences?.filter(
        (item) => !this.isExperienceDisabledForPosition(item)
      );
    },
    isItemSelected(itemToCheck) {
      return !!this.$store.state.ad?.ad?.adExperiences?.find(
        (item) =>
          item.element_type_id == itemToCheck.element_type_id &&
          item.experience_type_id == itemToCheck.experience_type_id &&
          item.restrict_to_question_number ==
            itemToCheck.restrict_to_question_number
      );
    },
    onSelectionChanged(selectedItems) {
      this.updateDisabledStateOfItems();

      if (!selectedItems || selectedItems.length == 0) {
        return;
      }

      const newItem = selectedItems[selectedItems.length - 1];

      // remove other experience entries from selected list if new selected item is of experience_type all
      // (e.g. Quiz - All Question should be removed if Quiz is selected)
      if (newItem.experience_type_id == constants.EXPERIENCE_TYPE.ALL) {
        this.selectedAdExperiences = selectedItems.filter(
          (adExperience, index) =>
            index == selectedItems.length - 1 ||
            adExperience.element_type_id != newItem.element_type_id ||
            adExperience.restrict_to_question_number !=
              newItem.restrict_to_question_number
        );
      }
    },
    updateDisabledStateOfItems() {
      this.allExperiencesItems?.forEach((item) => {
        item.disabled = this.isItemDisabled(item);
      });
    },
    isExperienceDisabledForPosition(experience) {
      if (this.adDisplay != 'overlay') {
        return false;
      }

      return (
        experience.experience_type_id == constants.EXPERIENCE_TYPE.COVERS ||
        experience.experience_type_id == constants.EXPERIENCE_TYPE.RESULTS
      );
    },
    isItemDisabled(item) {
      return (
        this.isSubItemOfSelected(item) ||
        this.isExperienceDisabledForPosition(item)
      );
    },
    isSubItemOfSelected(experienceItem) {
      // in case the item is of experience type all it should never be disabled (for unselecting)
      if (experienceItem.experience_type_id == constants.EXPERIENCE_TYPE.ALL) {
        return false;
      }

      // disable the item if an experience with of the same elementtype with experiencetype all is selected
      return this.$store.state.ad?.ad?.adExperiences?.some(
        (selExperience) =>
          selExperience.element_type_id == experienceItem.element_type_id &&
          selExperience.experience_type_id == constants.EXPERIENCE_TYPE.ALL &&
          selExperience.restrict_to_question_number ==
            experienceItem.restrict_to_question_number
      );
    },
    getAdExperiencesSelectionItemText(selectedItem) {
      if (selectedItem.constructor === Array) {
        return;
      }

      // if the selectedItem was fetched from the backend no title is there yet (only known on frontend)
      if (!selectedItem.title) {
        selectedItem.title = this.getSelecteItemTitle(selectedItem);
      }

      if (selectedItem.experience_type_id == constants.EXPERIENCE_TYPE.ALL) {
        return selectedItem.title;
      }

      let parentOfSelectedItem = this.getAllItemOfSelectedItem(selectedItem);

      return `${parentOfSelectedItem.title} - ${selectedItem.title}`;
    },
    getAdExperiencesSelectionItemValue(selectedItem) {
      return `${selectedItem.element_type_id}_${selectedItem.experience_type_id}_${selectedItem.restrict_to_question_number}`;
    },
    getSelecteItemTitle(selectedItem) {
      return this.allExperiencesItems.find(
        (item) =>
          item.element_type_id == selectedItem.element_type_id &&
          item.experience_type_id == selectedItem.experience_type_id &&
          item.restrict_to_question_number ==
            selectedItem.restrict_to_question_number
      ).title;
    },
    getAllItemOfSelectedItem(selectedItem) {
      return this.allExperiencesItems.find(
        (item) =>
          item.element_type_id == selectedItem.element_type_id &&
          item.experience_type_id == constants.EXPERIENCE_TYPE.ALL &&
          item.restrict_to_question_number ==
            selectedItem.restrict_to_question_number
      );
    },
    initItemList() {
      if (
        this.$acl.check('ALL_ENTITIES.VIEW') ||
        this.$acl.check('POLLS.VIEW')
      ) {
        this.addPollItems();
      }

      if (
        this.$acl.check('ALL_ENTITIES.VIEW') ||
        this.$acl.check('QUIZZES.VIEW')
      ) {
        this.addQuizItems();
      }

      if (
        this.$acl.check('ALL_ENTITIES.VIEW') ||
        this.$acl.check('PERSONALITY_TESTS.VIEW')
      ) {
        this.addPersonalityTestItems();
      }
    },
    addPollItems() {
      let singlequestionPollItem = this.createItem(
        this.$t('common.singlequestionPoll.title'),
        constants.ELEMENT_TYPE.POLL,
        constants.EXPERIENCE_TYPE.ALL,
        constants.QUESTION_NUMBER.SINGLE
      );

      let multiquestionPollItem = this.createItem(
        this.$t('common.multiquestionPoll.title'),
        constants.ELEMENT_TYPE.POLL,
        constants.EXPERIENCE_TYPE.ALL,
        constants.QUESTION_NUMBER.MULTI
      );

      this.allExperiencesItems.push(
        singlequestionPollItem,
        multiquestionPollItem
      );
    },
    addQuizItems() {
      let quizItem = this.createItem(
        this.$t('common.quiz.title'),
        constants.ELEMENT_TYPE.QUIZ,
        constants.EXPERIENCE_TYPE.ALL
      );

      let allQuestions = this.createItem(
        this.$t('components.allQuestions'),
        constants.ELEMENT_TYPE.QUIZ,
        constants.EXPERIENCE_TYPE.QUESTIONS
      );

      let allCovers = this.createItem(
        this.$t('components.allCovers'),
        constants.ELEMENT_TYPE.QUIZ,
        constants.EXPERIENCE_TYPE.COVERS
      );

      let allResults = this.createItem(
        this.$t('components.allResults'),
        constants.ELEMENT_TYPE.QUIZ,
        constants.EXPERIENCE_TYPE.RESULTS
      );

      this.allExperiencesItems.push(
        quizItem,
        allQuestions,
        allCovers,
        allResults
      );
    },
    addPersonalityTestItems() {
      let personalityTestItem = this.createItem(
        this.$t('common.personalityTest.title'),
        constants.ELEMENT_TYPE.PERSONALITY_TEST,
        constants.EXPERIENCE_TYPE.ALL
      );

      let allQuestions = this.createItem(
        this.$t('components.allQuestions'),
        constants.ELEMENT_TYPE.PERSONALITY_TEST,
        constants.EXPERIENCE_TYPE.QUESTIONS
      );

      let allCovers = this.createItem(
        this.$t('components.allCovers'),
        constants.ELEMENT_TYPE.PERSONALITY_TEST,
        constants.EXPERIENCE_TYPE.COVERS
      );

      let allResults = this.createItem(
        this.$t('components.allResults'),
        constants.ELEMENT_TYPE.PERSONALITY_TEST,
        constants.EXPERIENCE_TYPE.RESULTS
      );

      this.allExperiencesItems.push(
        personalityTestItem,
        allQuestions,
        allCovers,
        allResults
      );
    },
    createItem(
      title,
      elementType,
      experienceType,
      restrictToQuestionNumber = null
    ) {
      return {
        title: title,
        element_type_id: elementType,
        experience_type_id: experienceType,
        restrict_to_question_number: restrictToQuestionNumber,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-item {
  padding-left: 20px;
}

.pointer-active {
  pointer-events: auto !important;
}
</style>
