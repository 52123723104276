/* ============
 * Mutations for the audience module
 * ============
 *
 * The mutations that are available on the
 * audience module.
 */
import _ from 'lodash';
import constants from '@/utils/constants';
import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.SET_AUDIENCES](state, data) {
    const audiences = data.map((audience) => {
      // only show audiences if they are root
      const isRoot = !audience.parent_id;

      const updatedAudience = _.extend(
        {
          show: isRoot,
          visitorCount: 0,
          leadsCount: 0,
          leadsLoaded: false,
          visitorsLoaded: false,
        },
        audience
      );
      // if there is a filename and not a link save the full path
      if (audience.filename && !audience.filename.includes('://')) {
        updatedAudience.filename = `${constants.CDN_STORAGE_AUDIENCES}/audiences/${audience.filename}`;
      }
      return updatedAudience;
    });
    // sort audiences by name
    audiences.sort((a, b) => a.text.localeCompare(b.text));

    state.audiences = audiences;
  },

  [types.SET_ALL_AUDIENCES](state, audiences) {
    // sort audiences by name
    audiences.sort((a, b) => a.text.localeCompare(b.text));

    state.allAudiences = audiences.map((audience) => {
      // if there is a filename and not a link save the full path
      if (audience.filename && !audience.filename.includes('://')) {
        audience.filename = `${constants.CDN_STORAGE_AUDIENCES}/audiences/${audience.filename}`;
      }
      return audience;
    });
  },

  [types.UPDATE_VISITOR_COUNT](state, audiences) {
    state.audiences.forEach((audience) => {
      const audienceStats = _.find(audiences, { id: audience.id });
      if (audienceStats) {
        audience.visitorCount = audienceStats.visitorCount;
      }
      audience.visitorsLoaded = true;
    });
  },

  [types.UPDATE_LEADS_COUNT](state, audiences) {
    state.audiences.forEach((audience) => {
      const audienceStats = _.find(audiences, { id: audience.id });
      if (audienceStats) {
        audience.leadsCount = audienceStats.leadsCount;
      }
      audience.leadsLoaded = true;
    });
  },

  [types.SET_LOCALIZED_AUDIENCES](state, audiences) {
    state.localizedAudiences = audiences.reduce((obj, item) => {
      obj[item.id] = { ...item };
      return obj;
    }, {});
  },

  [types.SET_LOCALIZED_AUDIENCE](state, audience) {
    state.localizedAudiences[audience.id] = audience;
  },

  [types.FILTER_AUDIENCES](state, { rootAudienceId, activeFilters }) {
    state.audiences.forEach((audience) => {
      let show = true;
      let filtered = true;
      const { visitorCount } = audience;
      // only show audiences if they are root
      if (audience.parent_id !== rootAudienceId) {
        show = false;
      }

      if (activeFilters.minVisitors) {
        if (visitorCount < activeFilters.minVisitors) {
          show = false;
          filtered = false;
        }
      }
      if (activeFilters.leads) {
        if (activeFilters.leads === 'hasLeads' && audience.leadsCount === 0) {
          show = false;
          filtered = false;
        }
        if (activeFilters.leads === 'noLeads' && audience.leadsCount > 0) {
          show = false;
          filtered = false;
        }
      }
      audience.show = show;
      audience.filtered = filtered;
    });
  },

  [types.SET_TOTAL_COUNT](state) {
    state.visitorCountTotal = state.audiences
      .map((item) => item.visitorCount)
      .reduce((prev, next) => prev + next);
  },

  [types.CREATE_AUDIENCE](state, data) {
    // create child
    const newChild = {
      id: data.id,
      text: data.text,
      parent_id: data.parent_id,
      type_id: data.type_id,
      is_pinpoll: data.is_pinpoll,
      is_aitopic: data.is_aitopic,
      hide_analytics: data.hide_analytics,
      hide_dmp: data.hide_dmp,
      filename: null,
    };
    state.audiences.push(newChild);
    state.audiences.sort((a, b) => a.text.localeCompare(b.text));
  },

  [types.UPDATE_AUDIENCE](state, data) {
    const item = _.find(state.audiences, { id: data.id });
    item.text = data.text;
    item.hide_analytics = data.hide_analytics;
    item.hide_dmp = data.hide_dmp;
    state.audiences.sort((a, b) => a.text.localeCompare(b.text));
  },

  [types.UPDATE_AUDIENCE_IMAGE](state, { image }) {
    const audience = _.find(state.audiences, { id: image.audience_id });
    audience.filename = `${constants.CDN_STORAGE_AUDIENCES}/audiences/${image.filename}`;
  },

  [types.DELETE_AUDIENCE](state, id) {
    state.audiences = state.audiences.filter(
      (audience) => audience.parent_id !== id && audience.id !== id
    );
  },

  [types.SEARCH_AUDIENCE](state, search) {
    // only filter if there is a search term defined
    if (search.length) {
      // fill patterns with regex expressions
      const patterns = [];
      search.forEach((searchTerm) => {
        patterns.push(new RegExp(`^.*${searchTerm}.*$`, 'i'));
      });
      // show audiences only if they match at least one pattern
      state.audiences.forEach((audience) => {
        audience.show = false;
        audience.filtered = false;
        patterns.forEach((pattern) => {
          if (audience.text.match(pattern)) {
            audience.show = true;
          }
        });
      });
    } else {
      // otherwise show all audiences
      state.audiences.map((audience) => {
        audience.show = true;
        return audience;
      });
    }
  },

  [types.SET_SELECTED_FIELD](state, data) {
    state.selectedField = data;
  },

  [types.SET_ASSIGNED_AUDIENCES](state, data) {
    // reset the selected field
    state.selectedField = null;
    state.assignedAudiences = data;
  },

  [types.ADD_ASSIGNED_AUDIENCE_TO_FIELD](state, audience) {
    // init field if not existing yet
    if (!state.assignedAudiences[state.selectedField])
      state.assignedAudiences[state.selectedField] = [];
    state.assignedAudiences[state.selectedField].push(audience);
  },

  [types.REMOVE_ASSIGNED_AUDIENCE_FROM_FIELD](state, mappedAudienceId) {
    // remove item from assigned audiences array which matches the mappedAudienceId
    state.assignedAudiences[state.selectedField] = state.assignedAudiences[
      state.selectedField
    ].filter((item) => item.mappedAudienceId !== mappedAudienceId);
  },

  [types.UPDATE_ASSIGNED_AUDIENCES_AFFINITY](state) {
    state.assignedAudiences[state.selectedField].map((audience) => {
      audience.affinity_score = state.affinityScore;
      return audience;
    });
  },

  [types.SET_AFFINITY_SCORE](state, value) {
    state.affinityScore = value;
  },

  [types.UPDATE_AFFINITY_SCORE](state, formDataValue = null) {
    if (state.selectedField) {
      // if the selected field in assigned audiences is not found init it with an empty array
      if (!state.assignedAudiences[state.selectedField])
        state.assignedAudiences[state.selectedField] = [];

      let matchedFields = [];
      // if there is a formDataValue, do additional check
      if (formDataValue) {
        matchedFields = state.assignedAudiences[state.selectedField].filter(
          (item) => item.form_data === formDataValue
        );
      } else {
        matchedFields = state.assignedAudiences[state.selectedField];
      }
      // if there is a match, update length
      if (matchedFields.length) {
        // update the affinity score
        // everytime take the affinity score from the first selected field and its first audience (for now)
        state.affinityScore = matchedFields[0].affinity_score;
      }
    }
  },
  [types.RESET_FIELDS](state) {
    state.selectedField = null;
    state.selectedAudiences = [];
  },
  [types.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
};
