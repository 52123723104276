/* ============
 * State of the audience module
 * ============
 *
 * The initial state of the audience module.
 */

export const getInitialState = () => ({
  audiences: [],
  localizedAudiences: {},
  allAudiences: [],
  audiencesLoaded: false,
  selectedField: null,
  selectedAudiences: [],
  assignedAudiences: {},
  visitorCountTotal: 0,
  affinityScore: 1,
});

export default getInitialState;
