/* ============
 * Actions for the cronJob module
 * ============
 *
 * The actions that are available on the
 * cronjob module.
 */

import CronJobResource from '@/resources/CronJobResource';

export const getCronJobs = () => CronJobResource.index();

export const manageCronJob = (context, params) =>
  new Promise((resolve, reject) => {
    CronJobResource.update(params.name, params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  getCronJobs,
  manageCronJob,
};
