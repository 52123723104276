<template>
  <v-btn-toggle v-model="toggleButtonModel" multiple>
    <v-menu
      v-for="(dropdown, key) in currentMenus"
      :key="key"
      open-on-hover
      transition="slide-y-transition"
      top
      offset-y
    >
      <template #activator="{ on }">
        <v-btn
          text
          color="productColor"
          :class="{ 'v-btn--active': dropdown.selected }"
          v-on="on"
          @click.stop="removeFilter(key)"
        >
          {{ dropdown.name }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in dropdown.items"
          :key="item.value"
          color="productColor"
          :input-value="!!item.selected"
          @click.prevent="toggleFilter(item, key)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    menus: {
      type: Object,
      required: true,
    },
    initialFilters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      toggleButtonModel: [],
      activeFilters: {},
    };
  },
  computed: {
    currentMenus() {
      return this.menus;
    },
  },
  mounted() {
    this.activeFilters = this.initialFilters;
  },
  methods: {
    removeFilter(key) {
      // only if option selectedItemRequired is false
      if (!this.currentMenus[key].selectedItemRequired) {
        // reset menu of button
        this.resetMenu(key);

        // update the filter value
        this.activeFilters[key] = null;

        // update filters
        this.postUpdatedFilters(key);
      }
    },
    toggleFilter(item, key) {
      // save toggle value
      const newValue = !item.selected;

      // only if the newValue would enable the menu or option selectedItemRequired is false
      if (newValue || !this.currentMenus[key].selectedItemRequired) {
        // reset menu of button
        this.resetMenu(key, newValue);

        // toggle item
        item.selected = newValue;

        // update the filter value
        this.activeFilters[key] = newValue ? item.value : null;

        // update filters
        this.postUpdatedFilters(key);
      }
    },
    resetMenu(key, buttonActive = false) {
      // set all items to false
      this.currentMenus[key].items.forEach((item) => {
        item.selected = false;
      });

      // update button
      this.currentMenus[key].selected = buttonActive;
    },
    postUpdatedFilters(key) {
      this.$emit('filters-updated', {
        activeFilters: this.activeFilters,
        lastUpdatedKey: key,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/** remove box shadow when toggle button is clicked **/
.v-btn-toggle--selected {
  box-shadow: none;
}

/** reset background color otherwise it gets the wrong color if its not in a card in dark mode **/
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: initial;
}
</style>
