/* ============
 * State of the question module
 * ============
 *
 * The initial state of the question module.
 */

export const getInitialState = () => ({
  question: {},
  questions: [],
  filteredQuestions: {
    items: [],
    total: 0,
  },
});

export default getInitialState;
