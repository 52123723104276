/**
 * Helper functions
 */
import _ from 'lodash';
import { i18n } from '@/plugins/vue-i18n';
import constants from './constants';

export const startOfDay = new Date();
startOfDay.setHours(0, 0, 0, 0);
export const endOfDay = new Date();
endOfDay.setHours(23, 59, 59, 999);

/**
 * Deferred class
 * https://stackoverflow.com/a/34637436/5168770
 */
export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.isPending = true;
      this.reject = reject;
      this.resolve = resolve;
    });
    this.promise
      .finally(() => {
        this.isPending = false;
      })
      .catch(() => {});
  }
}

export default {
  startOfDay,
  endOfDay,
  Deferred,

  // https://stackoverflow.com/a/23522755/5168770
  isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),

  /**
   * https://stackoverflow.com/a/11381730/5168770
   * @returns {Boolean} if site is on mobile device
   */
  isMobileDevice() {
    const a = navigator.userAgent || navigator.vendor || window.opera;
    // eslint-disable-next-line
    const regexTest = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
    if (regexTest) return true;
    return false;
  },

  /**
   * Returns first image in data array
   * @param {{image}[]} data Data array
   * @param {*} skip Optional. Skip specific key
   * @returns {String|null}
   */
  firstImage(data, skip = null) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].image && data[i].key !== skip) return data[i].image;
      }
    }
    return null;
  },

  /**
   * Creates a tree
   * @param {Object[]} data array of elements
   * @param {String} parentColumn column of parent id
   * @param {String} idColumn name of id colum
   * @returns {Object} tree data
   */
  createTree(data, parentColumn = 'parent_id', idColumn = 'id') {
    const dataMap = data.reduce((obj, item) => {
      obj[item[idColumn]] = item;
      return obj;
    }, {});
    // prepare tree by adding children to root elements
    data.forEach((element) => {
      if (element[parentColumn] && dataMap[element[parentColumn]]) {
        const parent = dataMap[element[parentColumn]];
        if (!parent.children) parent.children = [];
        parent.children.push(element);
      }
    });
    // create tree by filtering to only root elements
    const tree = data.filter((element) => !element[parentColumn]);
    return tree;
  },

  /**
   * Creates a one level tree
   * @param {Object[]} data array of elements
   * @param {String} [parentColumn] name of property parentId
   * @param {String} [idColumn] name of propterty id
   * @returns {Object} tree data
   */
  createOneLevelTree(data, parentColumn = 'parent_id', idColumn = 'id') {
    const dataMap = data.reduce((obj, item) => {
      obj[item[idColumn]] = item;
      return obj;
    }, {});
    // prepare tree by adding children to root elements
    data.forEach((element) => {
      if (element[parentColumn] && dataMap[element[parentColumn]]) {
        const rootParent = this.getRootItem(dataMap, element);
        if (!rootParent.children) rootParent.children = [];
        rootParent.children.push(element);
      }
    });
    // create tree by filtering to only root elements
    const tree = data.filter((element) => !element[parentColumn]);
    return tree;
  },

  /**
   * Return root item of current item
   * @param {Object} dataMap data as object
   * @param {Object} item current item
   * @param {String} [parentColumn] name of property parentId
   * @returns {Object} root item
   */
  getRootItem(dataMap, item, parentColumn = 'parent_id') {
    const parentItem = dataMap[item[parentColumn]];
    if (parentItem) {
      return this.getRootItem(dataMap, parentItem);
    }
    return item;
  },

  /**
   * Flattening a nested array of objects
   * @param {Array} data nested array
   * @param {Array} result flattened array
   * @returns {Array}
   */
  flattenArray(data, result) {
    const that = this;
    _.each(data, (el) => {
      result.push(el);
      if (el.children) {
        that.flattenArray(el.children, result);
      }
    });
    return result;
  },

  /**
   * Flattening a nested array with one parent group
   * @param {Array} data nested array
   * @param {Array} result flattened array
   * @returns {Array}
   */
  flattenArrayToOneParentGroup(data, result) {
    const that = this;
    _.each(data, (el) => {
      result.push({
        id: el.id,
        text: el.text,
        parent_id: el.parent_id,
        children: [],
      });
      if (el.children) {
        result[result.length - 1].children = that.flattenArray(el.children, []);
      }
    });
    return result;
  },

  /**
   * Compare audiences by visitorcount variable
   * @param {Object} a First Audience
   * @param {Object} b Second Audience
   * @returns {integer} result
   */
  compareAudiencesByVisitorCount(a, b) {
    const audCountA = a.visitorCount;
    const audCountB = b.visitorCount;

    let comparison = 1;
    if (audCountA < audCountB) {
      comparison = 1;
    } else if (audCountA > audCountB) {
      comparison = -1;
    }
    return comparison;
  },

  /**
   * Generate zero values for timerseries data. Used for generating intial value for charts
   * @param {Date} from start date
   * @param {Date} to end date
   * @param {String} valueField Opional. Name of value field. Defaults to 'doc_count'.
   * @param {Number} interval Opional. Histogram timesteps. Defaults to 5 min.
   * @returns {Object[]} histogram data
   */
  defaultHistogram(
    from,
    to,
    valueField = 'doc_count',
    interval = 1000 * 60 * 5
  ) {
    const start = from.getTime();
    const end = to.getTime();
    const length = Math.floor((end - start) / interval);
    return Array.from({ length }, (item, index) => ({
      key: start + index * interval,
      [valueField]: 0,
    }));
  },

  /**
   * Transform an error object to an array of objects and extract the message
   * @param {object} errors error object
   * @returns {array}
   */
  transformErrorObject(errors) {
    return _.reduce(
      errors,
      (result, value, key) => {
        if (value.msg === 'Invalid value')
          result[key] = i18n.t('validations.errors.invalidValue');
        else result[key] = value.msg;
        return result;
      },
      {}
    );
  },

  /**
   *
   * @param {Object} fields Fieldlist: name -> { type, default }
   * @param {string} prefix localstorage key prefix
   * @param {Object} [intialObject={}] object on which to add properties
   * @returns {Object}
   */
  generateLocalStorageFields(fields, prefix, intialObject = {}) {
    const obj = intialObject;

    Object.entries(fields).forEach(([key, { type, default: defaultValue }]) => {
      switch (type.toLowerCase()) {
        case 'string':
          Object.defineProperty(
            obj,
            key,
            this.localstorageStringProperty(prefix + key, defaultValue)
          );
          break;
        case 'bool':
        case 'boolean':
          Object.defineProperty(
            obj,
            key,
            this.localstorageBooleanProperty(prefix + key, defaultValue)
          );
          break;
        default:
          Object.defineProperty(
            obj,
            key,
            this.localstorageProperty(prefix + key, defaultValue)
          );
          break;
      }
    });

    return obj;
  },

  /**
   * generates 3rd parameter for Object.defineProperty
   * used for creating props that are automatically stored in localstorage
   * @param {String} key localstorage key value
   * @param {*} defaultValue Define value that should be returned if no localStorage value is present of value is falsish
   * @returns {{get, set}}
   */
  localstorageProperty(key, defaultValue = null) {
    return {
      enumerable: true, // additonally prevents vue errors
      configurable: true, // vue needs this to watch the property
      get() {
        try {
          if (localStorage && localStorage[key])
            return JSON.parse(localStorage[key]) || defaultValue;
        } catch {
          return defaultValue;
        }
        return defaultValue;
      },
      set(value) {
        localStorage[key] = JSON.stringify(value);
      },
    };
  },

  /**
   * generates 3rd parameter for Object.defineProperty
   * used for creating props that are automatically stored in localstorage
   * @param {String} key localstorage key value
   * @param {*} defaultValue Define value that should be returned if no localStorage value is present of value is falsish
   * @returns {{get, set}}
   */
  localstorageStringProperty(key, defaultValue = null) {
    return {
      enumerable: true, // additonally prevents vue errors
      configurable: true, // vue needs this to watch the property
      get() {
        if (localStorage && localStorage[key])
          return localStorage[key] || defaultValue;
        return defaultValue;
      },
      set(value) {
        localStorage[key] = value;
      },
    };
  },

  /**
   * generates 3rd parameter for Object.defineProperty
   * used for creating props that are automatically stored in localstorage
   * @param {String} key localstorage key value
   * @param {*} defaultValue Define value that should be returned if no localStorage value is present of value is falsish
   * @returns {{get, set}}
   */
  localstorageBooleanProperty(key, defaultValue = null) {
    return {
      enumerable: true, // additonally prevents vue errors
      configurable: true, // vue needs this to watch the property
      get() {
        if (localStorage && localStorage[key])
          return localStorage[key] === 'true';
        return defaultValue;
      },
      set(value) {
        localStorage[key] = value;
      },
    };
  },

  /**
   * Set pagination for datatables in local storage
   *
   * @param {String} key key of local storage
   * @param {*} value value of local storage
   * @returns {empty}
   */
  setPagination(key, value) {
    if (typeof Storage !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem('datatableRowsPerPage'));
      if (obj) {
        obj[key] = value;
      } else {
        obj = {
          [key]: value,
        };
      }
      localStorage.datatableRowsPerPage = JSON.stringify(obj);
    }
  },

  /**
   * Retrieve local storage property pagination for
   *
   * @param {String} key key of local storage
   * @returns {empty}
   */
  getPagination(key) {
    const defaultPagination = 10;
    if (typeof Storage !== 'undefined') {
      const obj = JSON.parse(localStorage.getItem('datatableRowsPerPage'));
      if (obj && obj[key]) {
        return parseInt(obj[key], 10);
      }
    }
    return defaultPagination;
  },

  /**
   * Clean up prefix of given key
   * by params.metaTags and params.
   * @param {string} key - given key
   * @returns {string} cleaned up key
   */
  cleanUpPrefix(key) {
    return key.replace(/params.metaTags.|params./gi, '');
  },

  /**
   * Opens an DOM element in full screen
   * @param {HTMLHtmlElement} element DOM Elment
   * @returns {Promise}
   */
  openInFullScreen(element) {
    if (element.requestFullscreen) {
      return element.requestFullscreen();
    }

    if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
    return Promise.resolve();
  },

  /**
   * Closes Fullscreen
   * @returns {void}
   */
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  },

  /**
   * @returns {Boolean} whether browser is in fullscreen
   */
  isFullscreen() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    );
  },

  /**
   * @returns {String} The name of the 'fullscreenchange' Event
   */
  fullscreenEventName() {
    if (this.isSafari) return 'webkitfullscreenchange';
    return 'fullscreenchange';
  },

  /**
   * Returns placeholder image
   *
   * @returns {string} path to placeholder image
   */
  getPlaceholderImage() {
    return `${constants.CDN_STORAGE_ACCOUNT}/placeholder.png`;
  },
};
