var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-combobox',{attrs:{"label":_vm.$t('components.audienceOverview.search.audiences'),"chips":"","multiple":"","clearable":"","deletable-chips":"","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:search-input":_vm.searchAudiencesDebounced},model:{value:(_vm.searchAudience),callback:function ($$v) {_vm.searchAudience=$$v},expression:"searchAudience"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center justify-sm-end",attrs:{"cols":"12","sm":"8","md":"6"}},[_c('filter-button-menu',{attrs:{"menus":_vm.menus,"initial-filters":_vm.activeFilters},on:{"filters-updated":_vm.filtersUpdated}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"order-sm-first",attrs:{"cols":"12","sm":"7"}},[_c('v-breadcrumbs',{staticClass:"text-subtitle-1 pl-3 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{class:{ pointer: item.id !== _vm.rootAudienceId },attrs:{"disabled":item.id === _vm.rootAudienceId,"link":""},on:{"click":function($event){return _vm.loadChildren(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-end order-first",attrs:{"cols":"12","sm":"5"}},[(_vm.$acl.check('viewModuleAdmin'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingCache,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.refreshCache}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-refresh ")]),_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" "+_vm._s(_vm.$t('components.audienceOverview.buttons.refreshCache.label'))+" ")]),_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t( 'components.audienceOverview.buttons.refreshCache.labelXs' ))+" ")])],1)]}}],null,false,61840242)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audienceOverview.buttons.refreshCache.tooltip')))])]):_vm._e(),_c('v-btn',{staticClass:"ml-1",attrs:{"loading":_vm.loadingDownload,"disabled":_vm.loadingDownload,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadAudiences($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_c('span',[_vm._v(_vm._s(_vm.$t('components.audienceOverview.buttons.download')))])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},_vm._l((_vm.filteredUserAudiences),function(item){return _c('v-col',{key:item.id,staticClass:"pa-1",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-card',{staticClass:"elevation-10"},[_c('v-img',{staticClass:"audience-card",class:{ 'is-navigatable': _vm.hasChildren(item.id) },attrs:{"src":item.filename ? item.filename : _vm.helpers.getPlaceholderImage(),"aspect-ratio":"2.4","height":"100%"},on:{"click":function($event){return _vm.loadChildren(item)}}},[_c('v-container',{staticClass:"audience-container d-flex flex-column justify-space-between"},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"white--text text-uppercase audience-name",class:{
                        'max-font-size-big': _vm.$vuetify.breakpoint.mdAndDown,
                      }},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-btn',{class:{
                        'negative-top-margin-lg-and-down':
                          _vm.$vuetify.breakpoint.lgAndDown,
                      },attrs:{"small":"","text":"","icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetails(item.id)}}},[_c('v-icon',[_vm._v(" mdi-information ")])],1)],1)]),_c('div',[_c('div',[_c('div',{staticClass:"number count"},[(item.visitorsLoaded)?_c('span',{staticClass:"white--text"},[_c('animated-number',{attrs:{"data":item.visitorCount,"duration":2000}})],1):_c('span',[_c('v-progress-circular',{attrs:{"size":30,"indeterminate":"","color":"white"}})],1)]),(_vm.activeFilters.timeframe !== 'today')?_c('div',{class:{
                        'leads-font-size-bigger': _vm.$vuetify.breakpoint.lgAndUp,
                      }},[_c('span',{staticClass:"white--text"},[(item.leadsLoaded)?[_c('animated-number',{attrs:{"data":item.leadsCount,"duration":500}})]:[_c('v-progress-circular',{attrs:{"size":10,"width":2,"indeterminate":"","color":"white"}})]],2),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('components.audienceOverview.cards.leads'))+" ")]),_c('span',{staticClass:"white--text"},[_vm._v(" ("+_vm._s(_vm._f("formatPercentage")(item.visitorCount,item.leadsCount))+") ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.leadsCount),expression:"item.leadsCount"}],staticClass:"no-margin",attrs:{"small":"","text":"","icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadLeads(item.id, item.text)}}},[(
                            _vm.loadingLeads || _vm.loadingDownloadLeads === item.id
                          )?_c('span',[_c('v-progress-circular',{attrs:{"size":15,"width":3,"indeterminate":"","color":"white"}})],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audienceOverview.icons.leads')))])])],1)],1):_vm._e()])])])],1)],1)],1)}),1)],1)],1),_c('dialog-detail',{attrs:{"id":_vm.currentAudienceId,"show":_vm.showDetailsDialog,"type":_vm.activeFilters.type},on:{"close-dialog":function($event){return _vm.setDetailsDialog(false)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }