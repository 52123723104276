<template>
  <v-main>
    <trial-user-banner />
    <v-container fluid>
      <v-row
        v-if="!loading && authenticated && !hideContentHeader"
        class="mt-0 mb-3"
      >
        <v-col cols="12">
          <content-header class="pl-3" />
        </v-col>
      </v-row>
      <v-slide-y-transition mode="out-in">
        <v-row v-if="!loading" class="mb-1">
          <v-col cols="12">
            <router-view class="view" />
          </v-col>
        </v-row>
        <v-row v-else justify="center" align="center">
          <v-progress-circular :size="100" indeterminate color="productColor" />
        </v-row>
      </v-slide-y-transition>
      <snackbar />
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import productDetector from '@/mixins/productDetector';
import Snackbar from '@/components/Snackbar';
import ContentHeader from '@/components/ContentHeader';
import TrialUserBanner from '@/components/TrialUserBanner';

export default {
  components: { Snackbar, ContentHeader, TrialUserBanner },
  mixins: [productDetector],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('account', ['user']),
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    locale() {
      return this.$store.state.account && this.$store.state.account.user
        ? this.$store.state.account.user.language
        : localStorage.language;
    },
    /**
     * Indicator whether to hide content header
     *
     * @returns {boolean} hideContentHeader
     */
    hideContentHeader() {
      return this.$route.meta && this.$route.meta.hideContentHeader;
    },
  },
  watch: {
    authenticated() {
      this.getAudiences();
    },
    locale() {
      this.getAudiences();
    },
  },
  mounted() {
    this.checkAccountAndGetAudiences();
  },
  methods: {
    async checkAccountAndGetAudiences() {
      // If the user is authenticated,
      // fetch the data from the API
      if (this.authenticated) {
        try {
          await this.$store.dispatch('account/me');
          this.setIntercomUser();
          this.$acl.change(this.$store.state.account.access);
          this.$store.dispatch('account/loadDisplaySettings');
          // check if user has realtime permission
          if (this.$acl.check('viewRealtime')) {
            this.$store.dispatch('monitoring/loadSettings');
          }
          this.$bus.fire('user-authenticated');
          await this.getAudiences();
          this.forwardToDashboard();
        } catch (err) {
          console.error(err);
        }
      }
      this.loading = false;
    },
    async getAudiences() {
      if (this.authenticated && this.$acl.check('getAudiences')) {
        return this.$store.dispatch('audience/getAudiences');
      }
    },
    /**
     * Forward to correct dashboard if coming from login
     * @returns {void}
     */
    forwardToDashboard() {
      // check if coming from login
      if (this.$route.name === 'login') {
        // forward to current products dashboard
        this.$router.push({
          name: this.currentDashboardName,
          query: this.$route.query,
        });
      }
    },
    /**
     * Set user data for Intercom
     *
     * @returns {void}
     */
    setIntercomUser() {
      if (this.user) {
        const intercomData = {
          user_id: this.user.id,
          name: `${this.user.firstname || ''} ${this.user.lastname || ''}`,
          email: this.user.email,
          created_at: this.user.created_at,
          user_hash: this.user.userHash,
        };
        // add avatar image (if any)
        if (this.user.image) {
          intercomData.avatar = {
            type: 'avatar',
            image_url: this.user.image,
          };
        }
        this.$intercom.update(intercomData);
      }
    },
  },
};
</script>
