/* ============
 * Mutations for the pixel module
 * ============
 *
 * The mutations that are available on the
 * pixel module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';
import constants from '@/utils/constants';

export default {
  [types.SET_PIXELS](state, pixels) {
    state.pixels = pixels;
  },

  [types.SET_FILTERED_PIXELS](state, data) {
    // add right image link to all elements
    data.data.map((item) => {
      if (item.image && !item.image.includes('://')) {
        item.image = `${constants.CDN_STORAGE}/user/${item.image}`;
      }
      return item;
    });
    state.filteredPixels.items = data.data;
    state.filteredPixels.total = data.total;
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
};
