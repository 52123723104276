import _ from 'lodash';
import ResourceEndpoint, {
  REQUEST_ALREADY_RUNNING_ERROR,
} from '@/resources/ResourceEndpoint';
import Loadable from '@/utils/Loadable';
import LoadableCollection from '@/utils/LoadableCollection';
import Socket from '@/sockets/Socket';
import * as types from './mutation-types';
import { getResource, apiEndpoints, sockets } from './resources';
import helpers from '../../../utils/helpers';
import { settingsLocalStoragePrefix } from './state';
import { getInitialState } from './state';
import DashboardResource from '@/resources/DashboardResource';

const initialFilterState = getInitialState().filter;
const serverStoredSettings = {
  userId: null, // userId for which current serverStoredSettings are in localstorage
  list: ['nicknames'],
  clearLocalStorage() {
    for (const settingString of this.list) {
      delete localStorage[settingsLocalStoragePrefix + settingString];
    }
  },
};

Object.defineProperty(
  serverStoredSettings,
  'userId',
  helpers.localstorageProperty(settingsLocalStoragePrefix + 'userId')
);

/**
 * Normalize filter due to the standard structure & initial state fallback
 * @param {object} filter filter object to be normalized
 * @param {boolean} resetDates if the date values should be reset to current default values)
 * @returns {object} normalized filter
 */
const normalizeFilter = (filter, resetDates) => {
  // removing temporary mode
  filter.terms?.forEach((item) => delete item.isTemporary);

  if (resetDates) {
    filter = { ...filter, startDate: null, endDate: null };
  }
  return ['startDate', 'endDate', 'domain', 'page', 'terms'].reduce(
    (result, key) => {
      result[key] = filter[key] || initialFilterState[key];
      return result;
    },
    {}
  );
};

/**
 * Purge and refresh data + refresh sockets, after filter changed
 * @param {object} context vuex context
 * @returns {void}
 */
const applyFilter = (context) => {
  setTimeout(() => {
    // give components the chance to change to react to filter changes
    context.dispatch('purgeInsights');
    context.dispatch('purgeActiveResources');
    context.dispatch('refresh');
    if (context.state.activeView === 'realtime') context.dispatch('reopen');
  });
};

/**
 * List of active resources for frontend, not managed in vuex since there are problems with SocketIO Objects
 * @type {[s: string]: {name: string, resource: ResourceEndpoint|Socket, owner_id: string}}
 */
const activeResources = {};
window.activeResources = activeResources;

function activeResourceId(name, ownerId) {
  let id = name;
  if (ownerId) {
    id += `/${ownerId}`;
  }
  return id;
}

function deleteActiveResource(key) {
  if (activeResources[key]) {
    if (activeResources[key].resource instanceof Socket) {
      activeResources[key].resource.close();
    }
    delete activeResources[key];
  }
}

const load = (
  context,
  { name, resource, ownerId },
  { skipLoaded = true } = {}
) => {
  let state = context.state.get(name);
  if (state instanceof LoadableCollection) {
    if (!ownerId) {
      console.warn(`no ownerId defined for resource ${name}`);
    }
    state = state[ownerId];
  }
  if (state instanceof Loadable) {
    if (skipLoaded && state.loaded) return;
  }

  if (resource instanceof Socket) {
    // ResourceEndpoints will stay the same if they were loaded with same parameters
    if (
      state instanceof Loadable &&
      resource.isConnected() &&
      state.parameters &&
      _.isEqual(
        state.parameters,
        resource.getRequestParameters({ VuexContext: context })
      )
    )
      return;
    resource.reopen({ VuexContext: context, ownerId });
  } else {
    // ResourceEndpoints will stay the same if they were loaded with same parameters
    if (
      state instanceof Loadable &&
      state.loaded &&
      state.parameters &&
      _.isEqual(
        state.parameters,
        resource.getRequestParameters({ VuexContext: context })
      )
    )
      return;

    resource
      .fetch({ VuexContext: context })
      .then(({ data, parameters }) => {
        context.commit(types.UPDATE_DATA, {
          prop: name,
          value: data,
          parameters,
          ownerId,
        });
      })
      .catch((error) => {
        if (error.message !== REQUEST_ALREADY_RUNNING_ERROR) {
          console.error(`failed loading ${name}`);
          console.error(error);
        }
      });
  }
};
/* ==================
 * Monitoring Module
 * ==================
 */
export default {
  refresh(
    context,
    { resourceGroup = context.state.activeView, skipLoaded } = {}
  ) {
    if (resourceGroup && apiEndpoints[resourceGroup]) {
      Object.keys(apiEndpoints[resourceGroup]).forEach((key) => {
        if (
          skipLoaded &&
          context.state.get(key) instanceof Loadable &&
          context.state.get(key).loaded
        )
          return;

        /** @type {ResourceEndpoint} */
        let resource = apiEndpoints[resourceGroup][key];

        if (!(resource instanceof ResourceEndpoint)) {
          if (!resource.condition({ VuexContext: context })) return;
          resource = resource.endpoint;
        }
        resource
          .fetch({ VuexContext: context })
          .then(({ data }) => {
            context.commit(types.UPDATE_DATA, { prop: key, value: data });
          })
          .catch((error) => {
            if (error.message !== REQUEST_ALREADY_RUNNING_ERROR) {
              console.error(`failed loading ${key}`);
              console.error(error);
            }
          });
      });
    }

    Object.values(activeResources).forEach((resourceTriple) =>
      load(context, resourceTriple, { skipLoaded })
    );
  },
  updateFilter(context, payload) {
    context.commit(types.UPDATE_FILTER, payload);
    applyFilter(context);
  },
  addFilter(context, payload) {
    if (
      context.state.filter.terms.filter(
        (filter) =>
          filter.field === payload.filter.field &&
          filter.value === payload.filter.value
      ).length > 0
    ) {
      // filter already exists
      return;
    }
    context.commit(types.ADD_FILTER, payload);
    applyFilter(context);
  },
  /**
   * Set multiple filters at once and apply them
   * @param {VuexContext} context - current context
   * @param {Array<Object>} filters - filters to set
   * @returns {void}
   */
  setFilters(context, filters) {
    filters.forEach((singleFilter) => {
      // only add if filter does not already exist
      if (
        !context.state.filter.terms.filter(
          (filter) =>
            filter.field === singleFilter.filter.field &&
            filter.value === singleFilter.filter.value
        ).length
      ) {
        // different call if its domain or page filter
        if (singleFilter.filter.field === 'domain') {
          context.commit(types.UPDATE_FILTER, {
            prop: singleFilter.filter.field,
            value: singleFilter.filter.value,
          });
          context.commit(types.UPDATE_FILTER, {
            prop: 'domainIsTemporary',
            value: singleFilter.filter.isTemporary,
          });
        } else {
          context.commit(types.ADD_FILTER, singleFilter);
        }
      }
    });
    // apply filter
    applyFilter(context);
  },
  removeFilter(context, payload) {
    context.commit(types.REMOVE_FILTER, payload);
    applyFilter(context);
  },
  setTemporaryFilterMode(context, value) {
    context.commit(types.SET_TEMPORARY_FILTER_MODE, value);
  },

  updateSettings({ commit, dispatch }, payload) {
    commit(types.UPDATE_SETTING, payload);
    if (serverStoredSettings.list.includes(payload.prop)) {
      dispatch('account/updateSettings', payload, { root: true });
    }
  },

  loadSettings({ commit, dispatch, rootState }) {
    // its important to clear localstorage when logged in user changed
    // so we prevent override from settings from one user to another
    if (!rootState.account.user) {
      // should never be true
      console.warn(
        'clearing localstorage because user object does not exist in account controller'
      );
      serverStoredSettings.clearLocalStorage();
    } else if (rootState.account.user.id !== serverStoredSettings.userId) {
      // should be called when logged in as different user
      serverStoredSettings.clearLocalStorage();
      serverStoredSettings.userId = rootState.account.user.id;
    }
    const promises = [];
    serverStoredSettings.list.forEach((setting) =>
      promises.push(
        dispatch('account/loadSetting', setting, { root: true }).then(
          (data) => {
            try {
              commit(types.UPDATE_SETTING, {
                prop: setting,
                value: data && data.value ? JSON.parse(data.value) : null,
              });
            } catch {
              console.warn(`${setting} could not be comitted`);
            }
          }
        )
      )
    );
    return Promise.all(promises);
  },

  addNickname(context, payload) {
    context.commit(types.ADD_NICKNAME, payload);
    context.dispatch(
      'account/updateSettings',
      { prop: 'nicknames', value: context.state.settings.nicknames },
      { root: true }
    );
  },

  connect(context) {
    Object.values(sockets).forEach((socket) => {
      socket.open({ VuexContext: context });
    });
    Object.values(activeResources).forEach(({ resource }) => {
      if (resource instanceof Socket && !resource.isConnected()) {
        resource.open({ VuexContext: context });
      }
    });
  },

  disconnect(context) {
    context.commit(types.PURGE_LOADED, 'realtime');
    Object.values(sockets).forEach((socket) => {
      socket.close();
    });
    Object.values(activeResources).forEach(({ resource }) => {
      if (resource instanceof Socket) {
        resource.close();
      }
    });
  },

  reopen(context, options = {}) {
    if (options.sockets) {
      options.sockets.forEach((name) => {
        sockets[name].reopen({ VuexContext: context });
      });
    } else {
      context.commit(types.PURGE_LOADED, 'realtime');
      Object.values(sockets).forEach((socket) => {
        socket.reopen({ VuexContext: context });
      });
      Object.values(activeResources).forEach(({ resource }) => {
        if (resource instanceof Socket) {
          resource.reopen({ VuexContext: context });
        }
      });
    }
  },

  purge(context, { prop, ownerId }) {
    context.commit(types.PURGE_LOADED, { prop: prop.split('.'), ownerId });
  },

  purgeInsights(context) {
    context.commit(types.PURGE_LOADED, 'insights');
    context.commit(types.PURGE_LOADED, 'views');
    context.commit(types.PURGE_LOADED, 'users');
  },

  purgeActiveResources(context) {
    Object.values(activeResources).forEach(({ name, ownerId, resource }) => {
      if (
        !resource.loadingParams ||
        !_.isEqual(
          resource.loadingParams,
          resource.getRequestParameters({ VuexContext: context })
        )
      ) {
        context.commit(types.PURGE_LOADED, { prop: name.split('.'), ownerId });
      }
    });
  },

  purgeResourceGroup(context, resourceGroup) {
    Object.keys(apiEndpoints[resourceGroup]).forEach((key) => {
      /** @type {ResourceEndpoint} */
      let resource = apiEndpoints[resourceGroup][key];

      if (!(resource instanceof ResourceEndpoint)) {
        if (!resource.condition({ VuexContext: context })) {
          return;
        }
        resource = resource.endpoint;
      }

      context.commit(types.PURGE_LOADED, key.split('.'));
    });
  },

  updateActiveView(context, view) {
    context.commit(types.UPDATE_ACTIVE_VIEW, view);
  },

  addActiveResource(context, { name, parameters, ownerId, shared }) {
    const resource = getResource(name);

    if (parameters) resource.addRequestParameters(parameters);

    if (shared) {
      if (!ownerId) {
        console.warn('ownerId required for shared resource', name);
      }
      if (activeResources[name]) {
        activeResources[name].owners.push(ownerId);
      } else {
        activeResources[name] = {
          name,
          owners: [ownerId],
          resource,
          shared,
        };
        load(context, { name, resource }, { skipLoaded: true });
      }
    } else {
      activeResources[activeResourceId(name, ownerId)] = {
        name,
        ownerId,
        resource,
      };
      load(context, { name, ownerId, resource }, { skipLoaded: false });
    }
  },

  reload(context, { name, ownerId, purge = false }) {
    const activeResource = activeResources[activeResourceId(name, ownerId)];
    if (activeResource) {
      if (purge) {
        context.commit(types.PURGE_LOADED, { prop: name.split('.'), ownerId });
      }
      load(
        context,
        { name, ownerId, resource: activeResource.resource },
        { skipLoaded: false }
      );
    } else {
      console.warn(
        `trying to reload inactive resource ${activeResourceId(name, ownerId)}`
      );
    }
  },

  removeActiveResource(context, { name, ownerId }) {
    if (name) {
      if (activeResources[name] && activeResources[name].shared) {
        activeResources[name].owners = activeResources[name].owners.filter(
          (v) => v !== ownerId
        );
        if (activeResources[name].owners.length < 1) {
          deleteActiveResource(name);
        }
      } else {
        deleteActiveResource(activeResourceId(name, ownerId));
      }
    } else if (ownerId) {
      Object.keys(activeResources).forEach((key) => {
        if (key.endsWith(`/${ownerId}`)) {
          deleteActiveResource(key);
        }
      });
    }
  },

  clearActiveResources() {
    Object.keys(activeResources).forEach((key) => deleteActiveResource(key));
  },

  async loadDashboards(context) {
    let dashboards = await new DashboardResource().index();
    context.commit(types.SET_DASHBOARDS, dashboards);
  },

  async addLoadedDashboard(context, dashboard) {
    // Preventing mutating vuex store state outside mutation handlers errors
    let dashboards = _.cloneDeep(context.state.realtime.dashboards);
    dashboards[dashboard.id] = dashboard;
    context.commit(types.SET_DASHBOARDS, dashboards);
  },

  async removeLoadedDashboard(context, dashboardId) {
    let dashboards = context.state.realtime.dashboards;
    delete dashboards[dashboardId];
    context.commit(types.SET_DASHBOARDS, dashboards);
  },

  async selectDashboard(context, dashboardId) {
    context.commit(types.UPDATE_SETTING, {
      prop: 'currentDashboardId',
      value: dashboardId,
    });
  },

  async setFilter(context, filter) {
    filter = normalizeFilter(filter, true);
    context.commit(types.SET_FILTER, filter);
    // apply filter
    applyFilter(context);
  },

  async storeDashboard(context, dashboard) {
    dashboard.filter = normalizeFilter(dashboard.filter);
    if (dashboard.id) {
      return await new DashboardResource().updateDashboard(
        dashboard.id,
        dashboard
      );
    }
    return await new DashboardResource().storeDashboard(dashboard);
  },

  async deleteDashboard(context, dashboardId) {
    return await new DashboardResource().deleteDashboard(dashboardId);
  },
};
