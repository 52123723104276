/* ============
 * Actions for the audience module
 * ============
 *
 * The actions that are available on the
 * audience module.
 */

import AudienceResource from '@/resources/AudienceResource';
import * as types from './mutation-types';

export const getAudiences = ({ state, commit }) =>
  new Promise((resolve) => {
    // only query audiences if not already done
    if (!state.audiences.length) {
      const params = {
        locale: localStorage.language || 'en',
      };
      new AudienceResource()
        .getAudiences(params)
        .then(async (response) => {
          commit(types.SET_LOCALIZED_AUDIENCES, response);
          commit(types.SET_AUDIENCES, response);
          // needed for analytics toggle show pp-interests
          commit(types.SET_ALL_AUDIENCES, response);
          resolve();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      resolve();
    }
  });

export const getAllAudiences = ({ commit }) =>
  new Promise((resolve) => {
    const params = {
      locale: localStorage.language || 'en',
    };
    new AudienceResource()
      .getAllAudiences(params)
      .then((data) => {
        commit(types.SET_ALL_AUDIENCES, data);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export const getLeadsCount = ({ commit }, params) =>
  new Promise((resolve) => {
    new AudienceResource()
      .getLeadsCount(params)
      .then((audiences) => {
        commit(types.UPDATE_LEADS_COUNT, audiences);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export const getVisitorCount = ({ commit }, params) =>
  new Promise((resolve) => {
    new AudienceResource()
      .getVisitorCount(params)
      .then((audiences) => {
        commit(types.UPDATE_VISITOR_COUNT, audiences);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export const createAudience = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    const fields = {
      ...params,
      locale: localStorage.language || 'en',
    };
    new AudienceResource()
      .create(fields)
      .then((data) => {
        commit(types.CREATE_AUDIENCE, data);
        commit(types.SET_LOCALIZED_AUDIENCE, data);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateAudience = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    const fields = {
      ...params,
      locale: localStorage.language || 'en',
    };
    new AudienceResource()
      .update(params.id, fields)
      .then((data) => {
        commit(types.UPDATE_AUDIENCE, data);
        commit(types.SET_LOCALIZED_AUDIENCE, data);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteAudience = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .destroy(id)
      .then(() => {
        commit(types.DELETE_AUDIENCE, id);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAudience = (context, id) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .show(id)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAssignedAudiences = ({ commit }, params) =>
  new Promise((resolve) => {
    const paramsWithLocale = {
      ...params,
      locale: localStorage.language || 'en',
    };
    new AudienceResource()
      .getAssignedAudiences(paramsWithLocale)
      .then((data) => {
        commit(types.SET_ASSIGNED_AUDIENCES, data);
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
  });

export const assignNewAudience = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .assignNewAudience(params)
      .then((data) => {
        commit(types.CREATE_AUDIENCE, data.audience);
        commit(types.SET_LOCALIZED_AUDIENCE, data.audience);
        const audienceObject = {
          ...data.audience,
          affinity_score: params.affinityScore,
          form_data: params.modeParams.formData,
          mappedAudienceId: data.mappedAudienceId,
        };
        // update the assigned fields with the new created audience
        commit(types.ADD_ASSIGNED_AUDIENCE_TO_FIELD, audienceObject);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const assignAudience = ({ commit }, { postData, audience }) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .assignAudience(postData)
      .then((mappedAudienceId) => {
        audience.affinity_score = postData.affinityScore;
        audience.form_data = postData.modeParams.formData;
        audience.mappedAudienceId = mappedAudienceId;
        commit(types.ADD_ASSIGNED_AUDIENCE_TO_FIELD, audience);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const unassignAudience = ({ commit }, postData) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .unassignAudience(postData)
      .then(() => {
        commit(
          types.REMOVE_ASSIGNED_AUDIENCE_FROM_FIELD,
          postData.mappedAudienceId
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateAffinityScore = ({ commit }, postData) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .updateAffinityScore(postData)
      .then(() => {
        commit(types.UPDATE_ASSIGNED_AUDIENCES_AFFINITY);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const downloadLeadsOfAudience = (context, params) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .downloadLeadsOfAudience(params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAssignedRules = (context, params) =>
  new Promise((resolve, reject) => {
    const fields = {
      ...params,
      locale: localStorage.language || 'en',
    };
    new AudienceResource()
      .getAssignedRules(fields)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const removeRule = (context, postData) =>
  new Promise((resolve, reject) => {
    new AudienceResource()
      .unassignAudience({
        mode: postData.type,
        mappedAudienceId: postData.id,
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  getAudiences,
  getAllAudiences,
  getLeadsCount,
  getVisitorCount,
  createAudience,
  updateAudience,
  deleteAudience,
  getAudience,
  getAssignedAudiences,
  assignNewAudience,
  assignAudience,
  unassignAudience,
  updateAffinityScore,
  downloadLeadsOfAudience,
  getAssignedRules,
  removeRule,
};
