<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-card-text>
          <display-settings />
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import DisplaySettings from '@/components/Account/DisplaySettings';

export default {
  components: {
    DisplaySettings,
  },
};
</script>
