<template>
  <v-container class="main pl-3" fluid>
    <options-menu horizontal rounded close-on-content-click>
      <settings />
    </options-menu>
    <filter-bar />
    <v-row>
      <v-col>
        <date-range-filter :max-range="7" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="6" cols="12" class="pb-0">
        <v-card height="100%">
          <v-card-text class="textColor--text">
            <div class="text-h3">
              <span class="textColor--text">
                <number
                  :data="$store.state.monitoring.users.total.data.total"
                />
              </span>
            </div>
            <div class="subtitle-1">
              {{ $t('monitoring.data.users') }}
            </div>
          </v-card-text>
          <div class="card-progress-container">
            <v-progress-linear
              v-if="!$store.state.monitoring.users.total.loaded"
              :height="7"
              color="primary"
              indeterminate
            />
          </div>
        </v-card>
      </v-col>
      <v-col md="4" sm="6" cols="12" class="pb-0">
        <v-card height="100%">
          <v-card-text class="textColor--text">
            <div class="text-h3">
              <number :data="$store.state.monitoring.views.total.data" />
            </div>
            <div class="subtitle-1">
              {{ $t('monitoring.analytics.total') }}
            </div>
          </v-card-text>
          <div class="card-progress-container">
            <v-progress-linear
              v-if="!$store.state.monitoring.views.total.loaded"
              :height="7"
              color="primary"
              indeterminate
            />
          </div>
        </v-card>
      </v-col>
      <v-col md="4" sm="6" cols="12" class="pb-0">
        <v-card>
          <v-card-text>
            <devices-view
              :data="$store.state.monitoring.insights.devices.data"
              translate="monitoring.data"
            />
          </v-card-text>
          <div class="card-progress-container">
            <v-progress-linear
              v-if="!$store.state.monitoring.insights.devices.loaded"
              :height="7"
              color="primary"
              indeterminate
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="12" class="pb-0">
        <DomainPageView
          :domain-data="$store.state.monitoring.users.domains"
          :domain-data-views="$store.state.monitoring.views.domains"
          :page-data="$store.state.monitoring.users.pages"
          :page-data-views="$store.state.monitoring.views.pages"
          :referrer-data="$store.state.monitoring.insights.referrer"
          page-filter
        />
      </v-col>

      <v-col
        v-if="
          $store.state.monitoring.settings.useChannels &&
          !$store.state.monitoring.filter.page
        "
        md="4"
        class="pb-0"
      >
        <ChannelsView :data="$store.state.monitoring.insights.channels" />
      </v-col>
      <v-col
        v-if="$store.state.monitoring.settings.displayInterests"
        md="4"
        cols="12"
        class="pb-0"
      >
        <audiences-view
          :audiences="interests"
          :loaded="audienceLoadable.loaded"
        />
      </v-col>

      <v-col
        v-if="$store.state.monitoring.settings.displayVisitorProperties"
        md="4"
        cols="12"
        class="pb-0"
      >
        <visitor-properties-view
          :data="visitorProperties"
          :loaded="audienceLoadable.loaded"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Number from '@/components/Monitoring/Number';
import FilterBar from '@/components/Monitoring/FilterBar';
import DateRangeFilter from '@/components/Monitoring/DateRangeFilter';
import DomainPageView from '@/components/Monitoring/DomainPageView';
import AudiencesView from '@/components/Monitoring/AudiencesView';
import VisitorPropertiesView from '@/components/Monitoring/VisitorPropertiesView';
import DevicesView from '@/components/Monitoring/DevicesView';
import ChannelsView from '@/components/Monitoring/ChannelsView';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import Settings from '@/components/Monitoring/Settings';
import helpers from '@/utils/helpers';
import AudienceInterestProperties from '@/mixins/monitoring/AudienceInterestProperties';

export default {
  components: {
    Number,
    DateRangeFilter,
    FilterBar,
    DomainPageView,
    AudiencesView,
    VisitorPropertiesView,
    DevicesView,
    ChannelsView,
    OptionsMenu,
    Settings,
  },
  mixins: [AudienceInterestProperties],

  computed: {
    audienceLoadable() {
      return this.$store.state.monitoring.insights.audiences;
    },
    domainImage() {
      return helpers.firstImage(
        this.$store.state.monitoring.views.domains.data
      );
    },
  },

  mounted() {
    this.$store.dispatch('monitoring/updateActiveView', 'overview');
    this.$store.dispatch('monitoring/refresh');
  },

  destroyed() {
    this.$store.dispatch('monitoring/updateActiveView', null);
  },

  methods: {
    setDomainFilter(domain) {
      this.$store.dispatch('monitoring/updateFilter', {
        prop: 'domain',
        value: domain,
      });
    },
  },
};
</script>
