/* ============
 * Mutations for the cronjob module
 * ============
 *
 * The mutations that are available on the
 * cronjob module.
 */

import * as types from './mutation-types';
import { getInitialState } from './state';

export default {
  [types.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
};
