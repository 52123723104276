<template>
  <v-container ref="content" class="main" :class="{ fullscreen }" fluid>
    <div class="top-bar">
      <filter-bar @exit="exitTemporaryMode" @save="saveTemporaryFilters" />
      <dashboard-bar
        v-if="layout"
        class="tools-bar"
        :key="dashboardBarKey"
        @dashboard-selected="applyDashboard"
        @dashboard-saved="saveDashboard"
        @dashboard-deleted="deleteDashboard"
      />
      <span v-show="!isMobile" class="button-bar tools-bar">
        <fullscreen-button v-model="fullscreen" :element="fullscreenElement" />

        <v-switch
          v-if="fullscreen"
          v-model="showDarkMode"
          class="small"
          :label="
            $t('components.account.settings.displaySettings.showDarkMode.label')
          "
        />
      </span>
      <options-menu
        v-if="layout"
        horizontal
        rounded
        close-on-content-click
        static-position
      >
        <v-list>
          <v-list-item
            v-for="item in gridItemsArray"
            :key="item.i"
            :class="item.component"
          >
            <v-list-item-action @click.stop.prevent>
              <v-btn
                v-if="gridItemConfiguration[item.component].multipleAllowed"
                class="cardAddButton"
                @click="displayGridItemChanged(item.component, true)"
              >
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
              <v-switch
                v-else
                :input-value="
                  layout.findIndex(
                    (layoutItem) => layoutItem.component === item.component
                  ) >= 0
                "
                @change="
                  (state) => displayGridItemChanged(item.component, state)
                "
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('monitoring.options.' + item.component) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-btn color="warning" text @click="resetLayout">
              {{ $t('monitoring.actions.layoutReset') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </options-menu>
    </div>
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="mobile-grid">
      <v-col
        v-for="item in layout"
        :key="item.i"
        :style="{ order: item.y * 12 + item.x }"
        cols="12"
      >
        <component
          :is="$options.components[item.component]"
          :card-settings="item"
          @card-changed="layoutChanged"
          @close-card="removeCard(item.i)"
        />
      </v-col>
    </v-row>

    <grid-layout
      v-else-if="layout"
      :layout.sync="layout"
      :col-num="12"
      :row-height="39"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :use-css-transforms="true"
      @layout-updated="layoutChanged"
    >
      <grid-item
        v-for="item in layout"
        :key="item.i"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :min-h="item.minH || 1"
        :max-h="item.maxH || Infinity"
        :min-w="3"
        drag-ignore-from="a, button, .v-menu"
        @resized="(i, w, h) => itemResized(item, w, h)"
        @moved="(i, x, y) => itemMoved(item, x, y)"
      >
        <component
          :is="$options.components[item.component]"
          :size="item.size"
          :position="item.position"
          :card-settings="item"
          @card-changed="layoutChanged"
          @close-card="removeCard(item.i)"
        />
      </grid-item>
    </grid-layout>
    <v-spacer class="my-12" />
  </v-container>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import FullscreenButton from '@/components/Monitoring/FullscreenButton';
import FilterBar from '@/components/Monitoring/FilterBar';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import UsersCard from '@/components/Monitoring/UsersCard';
import DevicesCard from '@/components/Monitoring/DevicesCard';
import ReferrerTypeCard from '@/components/Monitoring/ReferrerTypeCard';
import DomainPageCard from '@/components/Monitoring/DomainPageCard';
import ChannelsCard from '@/components/Monitoring/ChannelsCard';
import AudienceGrowthCard from '@/components/Monitoring/AudienceGrowthCard';
import EventTrackingCard from '@/components/Monitoring/EventTrackingCard';
import InterestsCard from '@/components/Monitoring/InterestsCard';
import VisitorPropertiesCard from '@/components/Monitoring/VisitorPropertiesCard';
import ToolsStatsCard from '@/components/Monitoring/ToolsStatsCard';
import DashboardBar from '@/components/Monitoring/DashboardBar';
import {
  SET_BIGBOARD_STATE,
  UPDATE_DARK_MODE,
} from '@/store/modules/account/mutation-types';
import helpers from '@/utils/helpers';
import CardIdService from '../../utils/CardIdService';

const MINUTE = 1000 * 60;

const gridItems = {
  UsersCard: {
    component: 'UsersCard',
    x: 0,
    y: 0,
    w: 4,
    h: 3,
    minH: 3,
  },
  DevicesCard: {
    component: 'DevicesCard',
    x: 4,
    y: 0,
    w: 4,
    h: 3,
    minH: 3,
    maxH: 3,
  },
  ReferrerTypeCard: {
    component: 'ReferrerTypeCard',
    x: 8,
    y: 0,
    w: 4,
    h: 3,
    minH: 3,
    maxH: 3,
  },
  DomainPageCard: {
    component: 'DomainPageCard',
    x: 0,
    y: 3,
    w: 4,
    h: 9,
    minH: 3,
  },
  InterestsCard: {
    component: 'InterestsCard',
    x: 4,
    y: 3,
    w: 4,
    h: 9,
    minH: 3,
  },
  VisitorPropertiesCard: {
    component: 'VisitorPropertiesCard',
    x: 8,
    y: 3,
    w: 4,
    h: 9,
    minH: 3,
  },
  ChannelsCard: {
    component: 'ChannelsCard',
    x: 4,
    y: 3,
    w: 4,
    h: 9,
    minH: 3,
  },
  AudienceGrowthCard: {
    component: 'AudienceGrowthCard',
    x: 0,
    y: 3,
    w: 4,
    h: 3,
    minH: 3,
    audienceId: null,
    histogramCompare: 'yesterday',
  },
  EventTrackingCard: {
    component: 'EventTrackingCard',
    x: 4,
    y: 3,
    w: 4,
    h: 3,
    minH: 3,
    domain: '',
    event: '',
    histogramCompare: 'yesterday',
  },
  ToolsStatsCard: {
    permission: 'viewModuleAdmin',
    component: 'ToolsStatsCard',
    x: 4,
    y: 3,
    w: 4,
    h: 3,
    minH: 3,
    histogramCompare: 'yesterday',
    metric: 'views',
  },
};

const gridItemConfiguration = {
  UsersCard: {
    default: true,
  },
  DevicesCard: {
    default: true,
  },
  ReferrerTypeCard: {
    default: true,
  },
  DomainPageCard: {
    default: true,
  },
  InterestsCard: {
    default: true,
  },
  VisitorPropertiesCard: {
    default: true,
  },
  ChannelsCard: {},
  AudienceGrowthCard: {
    multipleAllowed: true,
  },
  EventTrackingCard: {
    multipleAllowed: true,
  },
  ToolsStatsCard: {
    multipleAllowed: true,
    permission: 'viewModuleAdmin',
  },
};
const gridItemsArray = Object.values(gridItems);
const getDefaultGrid = () =>
  gridItemsArray
    .filter((item) => gridItemConfiguration[item.component].default)
    .map((item) => ({ ...item }));

export default {
  components: {
    FilterBar,
    OptionsMenu,
    ChannelsCard,
    AudienceGrowthCard,
    EventTrackingCard,
    UsersCard,
    DevicesCard,
    ReferrerTypeCard,
    DomainPageCard,
    InterestsCard,
    VisitorPropertiesCard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    FullscreenButton,
    DashboardBar,
    ToolsStatsCard,
  },

  data() {
    return {
      /* {Boolean} Full screen mode flag */
      fullscreen: false,
      /* {Number} id of disconnect timeout if set */
      disconnectTimeout: null,
      /* {Array} Current layout (dashboard grid) */
      layout: null,
      /* {Array} Default dashboard grid */
      gridItemsArray: gridItemsArray.filter(
        (item) =>
          !gridItemConfiguration[item.component].permission ||
          this.$acl.check(gridItemConfiguration[item.component].permission)
      ),
      gridItemConfiguration,
      /* {Boolean} Mobile device flag */
      isMobile: helpers.isMobileDevice(),
      /* {String} stringified dump of the initial state of the current layout (to be compared if layout area changed by user) */
      currentLayoutDump: '',
      /* {Number} Numeric key to force re-rendering of the dashboardBar component */
      dashboardBarKey: 0,
      /* {Promise} Promise for dashboard data */
      loadDashboardsPromise: null,
    };
  },

  computed: {
    showDarkMode: {
      get() {
        return this.$store.state.account.displaySettings.showDarkModeBigboard;
      },
      set(status) {
        this.$store.commit(`account/${UPDATE_DARK_MODE}`, status);
        this.$store.dispatch('account/updateDisplaySettings');
      },
    },

    /**
     * Currently used filter
     * @returns {object}
     */
    currentFilter() {
      return { ...this.$store.state.monitoring.filter };
    },

    /**
     * If temporary filter mode is currently active
     * (filters should not be saved to the DB)
     * @returns {boolean}
     */
    isTemporaryFilterMode() {
      return this.$store.state.monitoring.isTemporaryFilterMode === true;
    },
  },

  watch: {
    fullscreen(fullscreen) {
      if (fullscreen) {
        this.$bus.fire('close-drawer');
      } else {
        this.$bus.fire('open-drawer');
      }
    },
    /**
     * Watch on dark mode setting and set vuetify theme accordingly
     *
     * @param {boolean} newValue - indicator whether it was switched to dark mode or not
     *
     * @returns {void}
     */
    showDarkMode(newValue) {
      this.$vuetify.theme.dark = newValue;
    },
    /**
     * Watch for changing of current filter
     * @returns {void}
     */
    async currentFilter() {
      if (!this.isTemporaryFilterMode) {
        await this.saveFilter();
      }
    },
  },

  created() {
    this.$store.dispatch('monitoring/connect');
  },

  async mounted() {
    this.$store.dispatch('monitoring/updateActiveView', 'realtime');
    this.$store.dispatch('monitoring/refresh');
    document.addEventListener('visibilitychange', this.visibilitychange);
    this.loadDashboardsPromise = this.$store.dispatch(
      'monitoring/loadDashboards'
    );
    this.checkForGetParams();
    await this.loadDashboardsPromise;
    this.$nextTick(async () => {
      // user has no saved dashboards
      if (
        Object.keys(this.$store.state.monitoring.realtime.dashboards).length ==
        0
      ) {
        this.layout = this.layoutSetup(getDefaultGrid());
        await this.saveDashboard({
          name: this.$t('monitoring.dashboard.defaultName'),
        });
      } else {
        // trying to get current dashboard id fron local storage and applying it or fallback value
        this.applyDashboard(
          parseInt(
            this.$store.state.monitoring.settings.currentDashboardId,
            10
          ) || 0
        );
      }
    });
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange);
  },

  destroyed() {
    this.$store.dispatch('monitoring/disconnect');
    this.$store.dispatch('monitoring/purge', {
      prop: 'audienceGrowth.histogram',
    });
    this.$store.dispatch('monitoring/purge', {
      prop: 'eventTracking.histogram',
    });
    this.$store.dispatch('monitoring/updateActiveView', null);
  },

  methods: {
    layoutSetup(layout) {
      // generate ids for cards
      layout.forEach((card) => {
        if (!card.component && card.i) {
          // migrate from old format
          // if you find this code somewhen in 2022 (or later) you can remove it
          card.component = card.i;
          card.i = CardIdService.getNewCardId();
        } else if (!card.i) {
          card.i = CardIdService.getNewCardId();
        }
      });

      // BEGIN migrated old custom nicknames from users
      // if you find this code after May 2020 you can safely remove it
      let layoutChanged = false;
      layout.forEach((card) => {
        if (card.component === 'AudienceGrowthCard' && card.customAudiences) {
          card.customAudiences.forEach(async (customAudience) => {
            await this.$store.dispatch('monitoring/addNickname', {
              key: customAudience.key,
              name: customAudience.custom,
              type: 'audiences',
            });
          });
          delete card.customAudiences;
          layoutChanged = true;
        }
        if (card.component === 'EventTrackingCard' && card.customEvents) {
          card.customEvents.forEach(async (customEvent) => {
            await this.$store.dispatch('monitoring/addNickname', {
              key: customEvent.key,
              name: customEvent.custom,
              type: 'events',
            });
          });
          delete card.customEvents;
          layoutChanged = true;
        }
      });
      if (layoutChanged) {
        setTimeout(this.layoutChanged);
      }
      // END migrated old custom nicknames

      // set size property
      layout.forEach((item) => this.itemResized(item, item.h, item.w));

      return layout;
    },
    displayGridItemChanged(name, display) {
      if (display) {
        const card = { ...gridItems[name] }; // clone card
        card.i = CardIdService.getNewCardId();
        this.itemResized(card, card.h, card.w); // set size property
        this.layout.push(card);
      } else {
        this.layout = this.layout.filter(
          (layoutItem) => layoutItem.component !== name
        );
      }
      this.layoutChanged();
    },
    removeCard(id) {
      this.layout = this.layout.filter((layoutItem) => layoutItem.i !== id);
      this.layoutChanged();
    },
    async layoutChanged(card) {
      if (card && card.component && card.params) {
        let item = this.layout.find((el) => el.component === card.component);
        if (item) {
          item.params = card.params;
        }
      }
      let newGrid = _.cloneDeep(this.layout);
      const currentDashboard =
        this.$store.state.monitoring.realtime.dashboards[
          this.$store.state.monitoring.settings.currentDashboardId
        ] || {};
      if (this.isTemporaryFilterMode) {
        // updating layout dump only if the temporary mode is not active
        // to avoid saving card changes caused by temporary filters
        let newGridCard = newGrid.find(
          (item) => item.component === 'DomainPageCard'
        );
        if (newGridCard) {
          let currentDashboardCard = currentDashboard.grid?.find(
            (item) => item.component === 'DomainPageCard'
          );
          if (currentDashboardCard?.params?.listMode) {
            newGridCard.params = currentDashboardCard.params;
          }
        }
      }
      const layoutsChanged =
        this.currentLayoutDump !== '' &&
        this.currentLayoutDump != JSON.stringify(newGrid);
      // available only if enabled and a dashboard is chosen
      if (layoutsChanged && currentDashboard.id) {
        await this.saveDashboard(currentDashboard, newGrid);
      }
    },
    resetLayout() {
      this.layout = this.layoutSetup(getDefaultGrid());
      this.layoutChanged();
    },
    itemResized(item, h, w) {
      Object.defineProperty(item, 'size', { value: { w, h }, writable: true }); // exclude property from JSON
    },
    itemMoved(item, x, y) {
      Object.defineProperty(item, 'position', {
        value: 'static',
        writable: true,
      }); // exclude property from JSON
      item.position = { x, y };
    },
    visibilitychange() {
      if (document.hidden) {
        this.disconnectTimeout = window.setTimeout(() => {
          this.$store.dispatch('monitoring/disconnect');
          this.$store.dispatch('monitoring/purge', {
            prop: 'audienceGrowth.histogram',
          });
          this.$store.dispatch('monitoring/purge', {
            prop: 'eventTracking.histogram',
          });
        }, MINUTE);
      } else {
        if (this.disconnectTimeout) {
          window.clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = null;
        }
        this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
        this.$store.dispatch('monitoring/connect');
      }
    },
    fullscreenElement() {
      return document.body;
    },

    /**
     * Check for get params and apply them as filters if there are any
     * @returns {void}
     */
    async checkForGetParams() {
      const allowedFilterGetParams = [
        'domain',
        'page',
        'audienceId',
        'channel',
      ];
      const filters = [];
      // parse all query params
      allowedFilterGetParams.forEach((getParam) => {
        const paramValue = this.$route.query[getParam];
        if (paramValue) {
          const filter = {
            field: getParam,
            value: paramValue,
            fieldLabel: null,
          };
          // if the filter is audience, load the localized audience name as well
          if (getParam === 'audienceId') {
            filter.field = 'audience';
            filter.value = parseInt(paramValue, 10);
            filter.valueLabel = this.$store.state.audience.localizedAudiences[
              paramValue
            ]
              ? this.$store.state.audience.localizedAudiences[paramValue].text
              : `AudienceID ${paramValue}`;
          }
          filter.isTemporary = true;
          // add filter
          filters.push({ filter });
        }
      });
      if (filters.length) {
        this.$store.dispatch('monitoring/setTemporaryFilterMode', true);
        this.$store.dispatch('monitoring/setFilters', filters);
      }

      // parse bigboard param
      if (this.$route.query.bigboard) {
        this.$store.commit(`account/${SET_BIGBOARD_STATE}`, true);
        this.fullscreen = true;
      }

      if (this.$route.query.dashboard) {
        if (this.loadDashboardsPromise) {
          await this.loadDashboardsPromise;
        }

        const dashboards = Object.values(
          this.$store.state.monitoring.realtime.dashboards
        );
        for (const dashboard of dashboards) {
          if (
            dashboard.name.toLocaleLowerCase() ===
            this.$route.query.dashboard.toLocaleLowerCase()
          ) {
            this.$store.dispatch('monitoring/selectDashboard', dashboard.id);
            break;
          }
        }
      }
    },
    /**
     * Set Dark Mode depending on bigboard is opened or closed
     *
     * @param {boolean} showBigboard - show big board
     *
     * @returns {void}
     */
    updateDarkMode(showBigboard) {
      let currentDarkMode = false;
      if (showBigboard) {
        // set dark mode for bigboard (stored in database currently)
        currentDarkMode = this.showDarkMode;
      } else if (localStorage && localStorage.getItem('dark-mode')) {
        // set dark mode for app (stored in local storage)
        try {
          currentDarkMode = JSON.parse(localStorage.getItem('dark-mode'));
        } catch (err) {
          console.info(
            'Could not parse localStorage item "dark-mode", please reload the page.',
            err
          );
        }
      }
      // set dark mode
      this.showDarkMode = currentDarkMode;
    },
    /**
     * Setting the active dashboard and applying its widgets on the page
     * @param {number} dashboardId id of the selected dashboard
     * @returns {void}
     */
    applyDashboard(dashboardId) {
      // Fallback for undefined or not existing dashboard id: selecting the first avauilable one
      let appliedId =
        dashboardId &&
        this.$store.state.monitoring.realtime.dashboards[dashboardId]
          ? dashboardId
          : Object.keys(this.$store.state.monitoring.realtime.dashboards)[0];
      if (appliedId) {
        this.$store.dispatch('monitoring/selectDashboard', appliedId);
        this.currentLayoutDump = JSON.stringify(
          this.$store.state.monitoring.realtime.dashboards[
            this.$store.state.monitoring.settings.currentDashboardId
          ]?.grid || []
        );
        if (!this.isTemporaryFilterMode) {
          this.$store.dispatch(
            'monitoring/setFilter',
            this.$store.state.monitoring.realtime.dashboards[
              this.$store.state.monitoring.settings.currentDashboardId
            ]?.filter
          );
        }
        // setting this.layout through json parcing
        // to clear the reference to state object (as this.layout can be changed indepedently by grid component
        this.layout = this.layoutSetup(JSON.parse(this.currentLayoutDump));
      }
      this.dashboardBarKey += 1;
    },
    /**
     * Handler for saving of edited or created dashboard
     * @param {object} dashboard data of the dashboard to be saved
     * @param {object} grid custom grid data of the dashboard to be saved
     * @returns {void}
     */
    async saveDashboard(dashboard, grid) {
      const currentDashboard =
        this.$store.state.monitoring.realtime.dashboards[
          this.$store.state.monitoring.settings.currentDashboardId
        ] || {};
      const dashboardSettings = {
        id: dashboard.id,
        name: dashboard.name,
        grid:
          grid ||
          (dashboard.id ? this.layout : this.layoutSetup(getDefaultGrid())),
        filter:
          dashboard.id && dashboard.filter
            ? dashboard.filter
            : currentDashboard.filter || {},
      };

      let response = await this.$store.dispatch(
        'monitoring/storeDashboard',
        dashboardSettings
      );
      if (response?.status == 'success') {
        // created new dashboard
        if (!dashboard.id && response.item?.id) {
          dashboardSettings.id = response.item.id;
        }
        this.$store.dispatch(
          'monitoring/addLoadedDashboard',
          dashboardSettings
        );
        this.applyDashboard(dashboardSettings.id);
      }
    },
    /**
     * Handler for deleting of a dashboard
     * @param {number} dashboardId data of the dashboard to be saved
     * @returns {void}
     */
    async deleteDashboard(dashboardId) {
      let response = await this.$store.dispatch(
        'monitoring/deleteDashboard',
        dashboardId
      );
      if (response.status == 'success') {
        this.$store.dispatch('monitoring/removeLoadedDashboard', dashboardId);
        this.applyDashboard();
      }
    },
    /**
     * Saves filters to DB if they were changed
     * @param {boolean} forced if the filters should be saved in any case
     * @returns {void}
     */
    async saveFilter(forced) {
      this.$bus.fire('filter-changed', this.currentFilter);
      let currentDashboard =
        this.$store.state.monitoring.realtime.dashboards[
          this.$store.state.monitoring.settings.currentDashboardId
        ] || {};
      let filterChanged =
        forced ||
        JSON.stringify(this.currentFilter) !==
          JSON.stringify(currentDashboard.filter || {});
      if (filterChanged) {
        await this.saveDashboard({
          ...currentDashboard,
          filter: this.currentFilter,
        });
      }
    },
    /**
     * Removing URL filters from query string
     *
     * @returns {void}
     */
    clearURLFilters() {
      let query = Object.assign({}, this.$route.query);
      ['domain', 'page', 'terms', 'startDate', 'endDate'].forEach((item) => {
        delete query[item];
      });
      this.$router.replace({ query });
    },
    /**
     * Exit temporary filter mode and applying the filters from current dashboard
     *
     * @returns {void}
     */
    exitTemporaryMode() {
      this.$store.dispatch('monitoring/setTemporaryFilterMode', false);
      this.clearURLFilters();
      this.applyDashboard(
        this.$store.state.monitoring.settings.currentDashboardId
      );
    },
    /**
     * Exit temporary filter mode and save the filters to the current dashboard
     *
     * @returns {void}
     */
    saveTemporaryFilters() {
      this.$store.dispatch('monitoring/setTemporaryFilterMode', false);
      this.clearURLFilters();
      this.saveFilter(true);
    },
  },
};
</script>

<style>
.top-bar {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}
.top-bar .filter-bar {
  flex: 1 1 auto;
}
.top-bar .tools-bar {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
}
.main.edit-mode .top-bar .dashboard-bar {
  display: inline-flex;
  flex: 1 1 auto;
}
.main.edit-mode .vue-grid-item {
  border: 1px dotted;
}
.edit-options-menu-bar .v-list-item {
  display: inline-flex;
  flex: 0 0 auto;
}
.button-bar .v-btn {
  margin: 0;
}
.card-separator {
  padding: 4px 0;
}
.v-card.card-bar {
  border-radius: unset;
}
.card-bar .v-card__text {
  padding: 2px 9px;
  font-size: 13px;
  font-weight: bold;
}
.v-input--switch.small {
  margin: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.v-input--switch.small .v-messages {
  display: none;
}
.v-input--switch.small .v-input__slot {
  margin: 0;
}
.v-input--switch.small .v-label {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-indent: 0px;
  text-shadow: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  text-decoration: none;
}
.v-input--switch.small .v-label.theme--dark {
  color: white;
}
.v-input--switch.small .v-label.theme--light {
  color: rgba(0, 0, 0, 0.87);
}
.v-input--switch.small .v-input--selection-controls__input {
  transform: scale(0.9);
  margin-right: 4px;
}
button.cardAddButton.v-btn {
  min-width: 40px;
}
</style>
