<template>
  <line-chart
    :data="chartData"
    :height="chartHeight"
    :padding="chartPadding"
    :axis="false"
    :grid-size="size"
    datetime-format="HH:mm"
    base-zero
    timeseries
    compare-timeseries
  />
</template>

<script>
import { area } from 'billboard.js';
import LineChart from './LineChart';
import moment from 'moment-timezone';

export default {
  components: { LineChart },
  props: {
    histogramData: {
      type: Array,
      required: true,
    },
    histogramDataCompare: {
      type: Array,
      required: true,
    },
    compareTimeframe: {
      type: String,
      required: true,
    },
    /* card size in dashboard */
    size: {
      type: Object,
      required: false,
      default: null,
    },
    keyField: {
      type: String,
      required: false,
      default: 'key',
    },
    valueField: {
      type: String,
      required: false,
      default: 'count',
    },
    dateIncrement: {
      type: Array,
      required: false,
      default: () => [1, 'hour'],
    },
    aggregate: {
      type: Boolean,
      required: false,
      default: false,
    },
    utc: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    /* Data points for the chart */
    chartData() {
      return [
        {
          data: this.fullDayHistogramDataPoints,
          title: this.$t(
            `monitoring.date.${this.compareTimeframe || 'last_week'}`
          ),
          valueField: 'count',
          type: area && area(),
          color: 'rgba(144, 202, 249, 0.6)',
        },
        {
          data: this.dayHistogramDataPoints,
          title: this.$t('monitoring.date.today'),
          valueField: 'count',
        },
      ];
    },
    /* Chart data points for the whole day (yesterday or last week comparison) */
    fullDayHistogramDataPoints() {
      let startDate = null;
      let endDate = null;
      if (this.compareTimeframe === 'yesterday') {
        startDate = this.newMoment().subtract(1, 'days').startOf('day');
        endDate = this.newMoment().subtract(1, 'days').endOf('day');
      } else {
        startDate = this.newMoment().subtract(7, 'days').startOf('day');
        endDate = this.newMoment().subtract(7, 'days').endOf('day');
      }

      return this.mergeDataPoints(
        this.getDayHistogramDataPoints(startDate, endDate),
        this.histogramDataCompare
      );
    },
    /* Chart data points for the current day */
    dayHistogramDataPoints() {
      const startDate = this.newMoment().startOf('day').utc();
      const endDate = this.newMoment().utc();

      return this.mergeDataPoints(
        this.getDayHistogramDataPoints(startDate, endDate),
        this.histogramData
      );
    },
    /* Calculate chart height */
    chartHeight() {
      if (this.size) {
        return this.size.h * 53 - 30;
      }
      return 130;
    },
    /* Calculate chart padding */
    chartPadding() {
      if (this.size) {
        // prevent number clipping into chart: padding if low height or low width
        return {
          left:
            110 -
            Math.min(2, this.size.h - 3 + Math.max(0, this.size.w - 4)) * 55,
          right: 0,
        };
      }
      return { left: 110, right: 0 };
    },
  },

  methods: {
    newMoment() {
      if (this.utc) return moment().utc();
      return moment();
    },

    /**
     * Merge one data point array into another and sum up count
     * @param {array} array1 base array
     * @param {array} array2 array to merge
     * @returns {array} new merged data points
     */
    mergeDataPoints(array1, array2) {
      let sum = 0;
      const array2Map = array2.reduce((map, item) => {
        let key = item[this.keyField];
        if (key instanceof String || typeof key === 'string') {
          key = moment(key).unix() * 1000;
        }
        map[key] = item[this.valueField];
        return map;
      }, {});

      return array1.map((point) => {
        const eventCount = array2Map[point.key];

        if (this.aggregate) {
          if (eventCount) {
            sum += eventCount;
          }
          point.count = sum;
        } else {
          point.count = eventCount || 0;
        }

        return point;
      });
    },
    /* Get data points for histogram*/
    getDayHistogramDataPoints(start, end) {
      let startDate = start;
      const compareData = [];
      while (startDate < end) {
        compareData.push({ count: 0, key: startDate.unix() * 1000 });
        startDate = startDate.add(...this.dateIncrement);
      }
      return compareData;
    },
  },
};
</script>
