/* ============
 * Mutation types for the audience module
 * ============
 *
 * The mutation types that are available
 * on the audience module.
 */

export const SET_AUDIENCES = 'SET_AUDIENCES';
export const SET_ALL_AUDIENCES = 'SET_ALL_AUDIENCES';
export const SET_LOCALIZED_AUDIENCES = 'SET_LOCALIZED_AUDIENCES';
export const SET_LOCALIZED_AUDIENCE = 'SET_LOCALIZED_AUDIENCE';
export const FILTER_AUDIENCES = 'FILTER_AUDIENCES';
export const UPDATE_VISITOR_COUNT = 'UPDATE_VISITOR_COUNT';
export const UPDATE_LEADS_COUNT = 'UPDATE_LEADS_COUNT';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
export const UPDATE_AUDIENCE = 'UPDATE_AUDIENCE';
export const UPDATE_AUDIENCE_IMAGE = 'UPDATE_AUDIENCE_IMAGE';
export const DELETE_AUDIENCE = 'DELETE_AUDIENCE';
export const SEARCH_AUDIENCE = 'SEARCH_AUDIENCE';
export const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD';
export const SET_ASSIGNED_AUDIENCES = 'SET_ASSIGNED_AUDIENCES';
export const ADD_ASSIGNED_AUDIENCE_TO_FIELD = 'ADD_ASSIGNED_AUDIENCE_TO_FIELD';
export const REMOVE_ASSIGNED_AUDIENCE_FROM_FIELD =
  'REMOVE_ASSIGNED_AUDIENCE_FROM_FIELD';
export const UPDATE_ASSIGNED_AUDIENCES_AFFINITY =
  'UPDATE_ASSIGNED_AUDIENCES_AFFINITY';
export const SET_AFFINITY_SCORE = 'SET_AFFINITY_SCORE';
export const UPDATE_AFFINITY_SCORE = 'UPDATE_AFFINITY_SCORE';
export const RESET_FIELDS = 'RESET_FIELDS';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_AUDIENCES,
  SET_ALL_AUDIENCES,
  SET_LOCALIZED_AUDIENCES,
  SET_LOCALIZED_AUDIENCE,
  FILTER_AUDIENCES,
  UPDATE_VISITOR_COUNT,
  UPDATE_LEADS_COUNT,
  SET_TOTAL_COUNT,
  CREATE_AUDIENCE,
  UPDATE_AUDIENCE,
  DELETE_AUDIENCE,
  SEARCH_AUDIENCE,
  SET_SELECTED_FIELD,
  SET_ASSIGNED_AUDIENCES,
  ADD_ASSIGNED_AUDIENCE_TO_FIELD,
  REMOVE_ASSIGNED_AUDIENCE_FROM_FIELD,
  UPDATE_ASSIGNED_AUDIENCES_AFFINITY,
  SET_AFFINITY_SCORE,
  UPDATE_AFFINITY_SCORE,
  RESET_FIELDS,
  RESET_STATE,
};
