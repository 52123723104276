import { AUDIENCE_TYPES } from '@/utils/constants';

/**
 * This is a mixin that creates computed properties for Audiences, Interests, VisitiorProperties
 * based on the full Audience Data supplied by the Analytics Monitoring API
 *
 * IMPORTANT: the importing component needs to have a property called "audienceLoadable"
 *
 * Used for Monitoring
 *
 * Created by Sebastian 23.01.2019
 */

/**
 * Helper function to convert Audience counts to tree structure
 * @param {Array} treeData Audience structure
 * @param {Object} audienceMap User counts for audiences
 * @param {Object} options Optional options
 * @param {boolean} options.aggregateFirstLevel Optional. Aggregate visitor count in first level of its direct children, defaults to false
 * @param {boolean} options.skipFirstLevel Optional. Skip top level, defaults to false
 * @param {number} options.audienceType Optional. AUDIENCE_TYPES constant, which type of audience should be used.
 * @param {string} options.countField Optional. Name of count field. Defaults to 'visitors'.
 * @returns {Array}
 */
function buildAudienceTree(
  treeData,
  audienceMap,
  {
    skipFirstLevel = false,
    aggregateFirstLevel = false,
    audienceType = AUDIENCE_TYPES.INTEREST,
    countField = 'visitors',
  } = {}
) {
  let audiences = []; // build audience tree

  const traverseTree = (node, parent = null) => {
    const audience = audienceMap[node.id];

    if (audience && node.type_id === audienceType) {
      // add new node to our audience tree
      const n = {
        key: node.id,
        [countField]: audience[countField],
        image: node.filename,
        children: [],
      };
      if (parent) {
        parent.children.push(n);
      } else {
        audiences.push(n);
      }

      if (node.children) {
        for (let i = 0; i < node.children.length; i += 1) {
          traverseTree(node.children[i], n);
        }
        n.children.sort((a, b) => b[countField] - a[countField]);
      }
    }
  };

  if (skipFirstLevel) {
    for (let i = 0; i < treeData.length; i += 1) {
      // skip first level because these are the interests
      const node = treeData[i];
      if (node.children) {
        for (let j = 0; j < node.children.length; j += 1) {
          traverseTree(node.children[j]);
        }
      }
    }
  } else {
    for (let i = 0; i < treeData.length; i += 1) {
      traverseTree(treeData[i]);
    }
  }

  // aggregate first level as the sum of its direct children
  if (aggregateFirstLevel) {
    audiences.forEach((item) => {
      item[countField] = item.children.reduce(
        (accumulator, currentItem) =>
          accumulator + currentItem[countField] || 0,
        0
      );
    });

    // filter out audiences with count 0
    audiences = audiences.filter((item) => item[countField] > 0);
  }

  return audiences.sort((a, b) => b[countField] - a[countField]);
}

const data = () => ({
  countField: 'visitors',
});
const computed = {
  audienceMap() {
    return this.audienceLoadable.data.reduce((obj, item) => {
      obj[item.key] = item;
      return obj;
    }, {});
  },
  interests() {
    const treeData = this.$store.getters['audience/treeDataAudiencesAnalytics'];
    return buildAudienceTree(treeData, this.audienceMap, {
      audienceType: AUDIENCE_TYPES.INTEREST,
      countField: this.countField,
    });
  },
  visitorProperties() {
    const treeData = this.$store.getters['audience/treeDataAudiencesAnalytics'];
    return buildAudienceTree(treeData, this.audienceMap, {
      aggregateFirstLevel: true,
      audienceType: AUDIENCE_TYPES.PROPERTY,
      countField: this.countField,
    });
  },
};

/**
 * Adds interests property, use default export if you need visitor properties as well
 */
export const Interests = {
  data,
  computed: {
    audienceMap: computed.audienceMap,
    interests: computed.interests,
  },
};
/**
 * Adds visitor properties property, use default export if you need interests as well
 */
export const VisitorProperties = {
  data,
  computed: {
    audienceMap: computed.audienceMap,
    visitorProperties: computed.visitorProperties,
  },
};

export default {
  data,
  computed,
};
