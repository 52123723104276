import Resource from './Resource';

class MetatagResource extends Resource {
  /**
   * The constructor for the MetatagResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/metatags');
  }

  /**
   * Method used to get all filtered metaTags
   *
   * @param {Array} params filters.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getMetaTags(params) {
    return this.submit('get', 'getMetaTags', params);
  }

  /**
   * Method used to get distinct visitors of a resource
   *
   * @param {String} params The params to search for.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getDistinctVisitors(params) {
    return this.submit('get', 'getDistinctVisitors', params);
  }

  /**
   * Method used to get all available fields
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAvailableFields() {
    return this.submit('get', 'getAvailableFields');
  }
}

export default MetatagResource;
