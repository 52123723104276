<template>
  <div>
    <pixel-table />
    <pixel-dialog />
  </div>
</template>

<script>
import PixelDialog from '@/components/Pixels/PixelDialog';
import PixelTable from '@/components/Pixels/PixelTable';

export default {
  components: {
    PixelTable,
    PixelDialog,
  },
  methods: {
    showAddPixelDialog() {
      this.$bus.fire('show-pixel-dialog');
    },
  },
};
</script>

<style></style>
