<template>
  <distribution-view
    :data="referrerData"
    :color-map="{
      Search: '#22AB32',
      Social: '#0074D9',
      Direct: '#F0C419',
      Other: '#F15A5A',
      Paid_Search: '#955BA5',
      None: '#C9ADA1',
      Self: '#4D6A6D',
    }"
    order-by="key"
    translate="monitoring.data"
  />
</template>

<script>
import DistributionView from '@/components/Monitoring/DistributionView';

export default {
  components: {
    DistributionView,
  },

  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    referrerData() {
      const sortMap = {
        Self: -1, // hide
        Search: 2,
        Social: 1,
        Direct: 5,
        Other: 6,
        Paid_Search: 7,
      };
      if (this.data.length < 1) {
        if (this.$store.state.monitoring.realtime.referrerTypes.loaded)
          return [];
        return [{ key: 'None', doc_count: 1 }];
      }
      const filtered = this.data
        .filter((item) => sortMap[item.key] >= 0)
        .sort((a, b) => sortMap[a.key] - sortMap[b.key]);
      if (filtered.length < 1) return this.data; // display self if it is only data
      return filtered;
    },
  },
};
</script>
