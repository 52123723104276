<template>
  <v-card class="grid-card" style="position: relative">
    <div class="card-background">
      <line-chart
        :data="chartData"
        :height="chartHeight"
        :padding="chartPadding"
        :axis="false"
        :grid-size="size"
        datetime-format="HH:mm"
        base-zero
        timeseries
        compare-timeseries
      />
    </div>
    <v-card-text :style="fontSize">
      <div class="number">
        <span class="textColor--text">
          <number
            :data="$store.state.monitoring.realtime.users.data.total"
            no-styling
          />
        </span>
      </div>
      <div class="label">
        <span class="textColor--text">
          {{ $t('monitoring.realtime.activeUsers') }}
        </span>
      </div>
    </v-card-text>
    <options-menu left small :min-width="240">
      <realtime-histogram-settings />
    </options-menu>
    <v-card color="infoBar" class="card-bar elevation-0" dark>
      <div class="card-progress-container">
        <v-progress-linear
          v-show="!$store.state.monitoring.realtime.users.loaded"
          :height="7"
          color="primary"
          indeterminate
        />
      </div>
      <v-card-text class="white--text">
        <v-row>
          <v-col cols="4" class="text-md-left">
            <span>Min:</span>
            <span class="ml-1">{{ min | formatNumber }}</span>
          </v-col>
          <v-col cols="4" class="text-md-left">
            <span>Avg:</span>
            <span class="ml-1">{{ avg | formatNumber }}</span>
          </v-col>
          <v-col cols="4" class="text-md-left">
            <span>Max:</span>
            <span class="ml-1">{{ max | formatNumber }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { areaSpline } from 'billboard.js';
import Number from '@/components/Monitoring/Number';
import LineChart from '@/components/Monitoring/LineChart';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import RealtimeHistogramSettings from '@/components/Monitoring/RealtimeHistogramSettings';
import ResizableCard from './ResizableCard';

export default {
  components: {
    Number,
    LineChart,
    OptionsMenu,
    RealtimeHistogramSettings,
  },
  extends: ResizableCard,

  computed: {
    chartData() {
      let takeEveryNth = 6;
      if (this.size) {
        // dercrease variable (increse data density) with card size
        // formula sandbox: https://docs.google.com/spreadsheets/d/1w-yhTvYYHp3A-Yj2JtmNptSe9oVz6elIhUbOIfJ1O9I/edit?usp=sharing
        takeEveryNth =
          8 - Math.floor(this.size.w / 2 + Math.log10(this.size.w));
      }
      return [
        {
          data: this.$store.state.monitoring.realtime.histogram_compare.data.filter(
            (ele, eleIndex) => eleIndex % takeEveryNth === 0
          ),
          title: this.$t(
            `monitoring.date.${this.$store.state.monitoring.settings.histogram_compare}`
          ),
          valueField: 'users',
          type: areaSpline && areaSpline(),
          color: 'rgba(144, 202, 249, 0.6)',
        },
        {
          data: this.$store.state.monitoring.realtime.histogram.data.filter(
            (ele, eleIndex) => eleIndex % takeEveryNth === 0
          ),
          title: this.$t('monitoring.date.today'),
          valueField: 'users',
        },
      ];
    },
    chartHeight() {
      if (this.size) {
        return this.size.h * 53 - 30;
      }
      return 118;
    },
    chartPadding() {
      if (this.size) {
        // prevent number clipping into chart: padding if low height or low width
        return {
          left:
            110 -
            Math.min(2, this.size.h - 3 + Math.max(0, this.size.w - 4)) * 55,
          right: 0,
        };
      }
      return { left: 110, right: 0 };
    },
    fontSize() {
      if (this.size) {
        return {
          fontSize: `${10 + this.size.h * 2}px`,
        };
      }
      return {
        fontSize: '16px',
      };
    },
    min() {
      if (!this.$store.state.monitoring.realtime.histogram.data.length)
        return 0;
      return this.$store.state.monitoring.realtime.histogram.data.reduce(
        (min, p) => (p.users < min ? p.users : min),
        this.$store.state.monitoring.realtime.histogram.data[0].users
      );
    },
    max() {
      if (!this.$store.state.monitoring.realtime.histogram.data.length)
        return 0;
      return this.$store.state.monitoring.realtime.histogram.data.reduce(
        (max, p) => (p.users > max ? p.users : max),
        this.$store.state.monitoring.realtime.histogram.data[0].users
      );
    },
    avg() {
      if (!this.$store.state.monitoring.realtime.histogram.data.length)
        return 0;
      return (
        this.$store.state.monitoring.realtime.histogram.data.reduce(
          (sum, p) => sum + p.users,
          0
        ) / this.$store.state.monitoring.realtime.histogram.data.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.number {
  font-size: 2.8em;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: normal;
}
.label {
  font-size: 1em;
  font-weight: 400;
}
.card-background {
  position: absolute;
  width: 100%;
}
</style>
