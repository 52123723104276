/* ============
 * Mutation types for the user module
 * ============
 *
 * The mutation types that are available
 * on the user module.
 */

export const SET_USERS = 'SET_USERS';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_USERS,
  RESET_STATE,
};
