<template>
  <v-dialog
    v-model="show"
    width="800px"
    persistent
    scrollable
    @keydown.esc="closeDialog"
  >
    <ValidationObserver v-slot="{ handleSubmit }" ref="validationObserver" slim>
      <v-card>
        <v-card-title class="pp-custom-background py-6 text-h6">
          {{
            mode === 'create'
              ? $t('components.pixel.pixelDialog.title.createNewPixel')
              : $t('components.pixel.pixelDialog.title.updatePixel')
          }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.pixel.pixelDialog.labels.title')"
                vid="title"
                rules="required|min:3|max:100"
              >
                <v-text-field
                  v-model="pixel.title"
                  :counter="100"
                  :label="$t('components.pixel.pixelDialog.labels.title')"
                  :loading="loading"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" class="pa-0">
              <v-col cols="12">
                <div class="text-h5">
                  {{ $t('components.pixel.pixelDialog.labels.general') }}
                </div>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.pixeltype')"
                  vid="pixeltypes"
                  rules="required|oneOf:image,google,facebook,javascript"
                >
                  <v-select
                    v-model="pixel.type"
                    :items="pixeltypes"
                    :label="$t('components.pixel.pixelDialog.labels.pixeltype')"
                    :loading="loading"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-col>
            <!-- Javascript -->
            <v-col
              v-if="pixel.type === 'javascript'"
              cols="12"
              sm="6"
              class="pa-0"
            >
              <v-col cols="12">
                <div class="text-h5">
                  {{ $t('components.pixel.pixelDialog.labels.javascript') }}
                </div>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.headercode')"
                  vid="headercode"
                  rules="url"
                >
                  <v-text-field
                    v-model="pixel.externalcode"
                    :label="
                      $t('components.pixel.pixelDialog.labels.headercode')
                    "
                    :loading="loading"
                    :error-messages="errors"
                    @change="addHttpsPrefix"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.bodycode')"
                  vid="bodycode"
                  rules=""
                >
                  <v-textarea
                    v-model="pixel.staticcode"
                    :label="$t('components.pixel.pixelDialog.labels.bodycode')"
                    :rows="4"
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.triggercode')"
                  vid="trigger_code"
                  rules="required"
                >
                  <v-text-field
                    v-model="pixel.trigger_code"
                    :label="
                      $t('components.pixel.pixelDialog.labels.triggercode')
                    "
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-col>
            <!-- Google -->
            <v-col v-if="pixel.type === 'google'" cols="12" sm="6" class="pa-0">
              <v-col cols="12">
                <div class="text-h5">
                  {{ $t('components.pixel.pixelDialog.labels.google') }}
                </div>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.conversionid')"
                  vid="conversionid"
                  rules="required"
                >
                  <v-text-field
                    v-model="pixel.conversion_id"
                    :label="
                      $t('components.pixel.pixelDialog.labels.conversionid')
                    "
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="
                    $t('components.pixel.pixelDialog.labels.conversionlabel')
                  "
                  vid="conversionlabel"
                  rules="required"
                >
                  <v-text-field
                    v-model="pixel.conversion_label"
                    :label="
                      $t('components.pixel.pixelDialog.labels.conversionlabel')
                    "
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="
                    $t('components.pixel.pixelDialog.labels.conversionvalue')
                  "
                  vid="conversionvalue"
                  rules=""
                >
                  <v-text-field
                    v-model="pixel.custom_value"
                    :label="
                      $t('components.pixel.pixelDialog.labels.conversionvalue')
                    "
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-col>
            <!-- Facebook -->
            <v-col
              v-if="pixel.type === 'facebook'"
              cols="12"
              sm="6"
              class="pa-0"
            >
              <v-col cols="12">
                <div class="text-h5">
                  {{ $t('components.pixel.pixelDialog.labels.facebook') }}
                </div>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.pixelid')"
                  vid="pixelid"
                  rules="required"
                >
                  <v-text-field
                    v-model="pixel.facebook_id"
                    :label="$t('components.pixel.pixelDialog.labels.pixelid')"
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.customvalue')"
                  vid="custom_value"
                  rules=""
                >
                  <v-text-field
                    v-model="pixel.custom_value"
                    :label="
                      $t('components.pixel.pixelDialog.labels.customvalue')
                    "
                    :loading="loading"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-col>
            <!-- Image -->
            <v-col v-if="pixel.type === 'image'" cols="12" sm="6" class="pa-0">
              <v-col cols="12">
                <div class="text-h5">
                  {{ $t('components.pixel.pixelDialog.labels.image') }}
                </div>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('components.pixel.pixelDialog.labels.imageurl')"
                  vid="imageurl"
                  rules="required|url"
                >
                  <v-text-field
                    v-model="pixel.source"
                    :label="$t('components.pixel.pixelDialog.labels.imageurl')"
                    :loading="loading"
                    :error-messages="errors"
                    @change="addHttpsPrefix"
                  />
                </ValidationProvider>
              </v-col>
            </v-col>
            <v-col v-if="pixel.type === 'javascript'" cols="12">
              <template v-if="pixel.approved">
                <v-alert outlined color="success" icon="mdi-check-circle">
                  <strong>{{
                    $t('components.pixel.pixelDialog.alert.approved')
                  }}</strong>
                </v-alert>
              </template>
              <template v-else>
                <v-alert outlined color="primary" icon="mdi-exclamation-thick">
                  <div>
                    {{ $t('components.pixel.pixelDialog.alert.warning') }}
                  </div>
                  <strong v-if="mode === 'edit'">
                    {{ $t('components.pixel.pixelDialog.alert.notApproved') }}
                  </strong>
                </v-alert>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn v-show="pixel.id" text color="error" @click="deletePixel">
            {{ $t('components.pixel.pixelDialog.labels.delete') }}
          </v-btn>
          <v-spacer />
          <v-btn text color="warning" @click="closeDialog">
            {{ $t('components.pixel.pixelDialog.labels.cancel') }}
          </v-btn>
          <v-btn
            :loading="savingProgress"
            color="success"
            @click="handleSubmit(savePixel)"
          >
            {{ $t('components.pixel.pixelDialog.labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import helpers from '@/utils/helpers';

export default {
  data() {
    return {
      helpers,
      loading: false,
      title: '',
      show: false,
      mode: 'create',
      pixel: {
        type: 'javascript',
      },
      pixeltypes: [
        {
          value: 'javascript',
          text: this.$t('components.pixel.pixelDialog.pixelTypes.javascript'),
        },
        {
          value: 'google',
          text: this.$t('components.pixel.pixelDialog.pixelTypes.google'),
        },
        {
          value: 'facebook',
          text: this.$t('components.pixel.pixelDialog.pixelTypes.facebook'),
        },
        {
          value: 'image',
          text: this.$t('components.pixel.pixelDialog.pixelTypes.image'),
        },
      ],
      savingProgress: false,
    };
  },
  mounted() {
    this.$bus.listen('show-pixel-dialog', this.initDialog);
  },
  destroyed() {
    this.$bus.remove('show-pixel-dialog');
  },
  methods: {
    addHttpsPrefix() {
      if (
        this.pixel.externalcode &&
        !this.pixel.externalcode.startsWith('http://') &&
        !this.pixel.externalcode.startsWith('https://')
      ) {
        this.pixel.externalcode = `https://${this.pixel.externalcode}`;
      }
      if (
        this.pixel.source &&
        !this.pixel.source.startsWith('http://') &&
        !this.pixel.source.startsWith('https://')
      ) {
        this.pixel.source = `https://${this.pixel.source}`;
      }
    },
    async initDialog(pixelId = null) {
      this.show = true;
      if (pixelId) {
        this.mode = 'edit';
        // wait until domains for user are loaded
        await this.getPixel(pixelId);
      }
    },
    resetFields() {
      this.$refs.validationObserver.reset();
      this.mode = 'create';
      this.pixel = {
        type: 'javascript',
      };
    },
    async getPixel(pixelId) {
      this.loading = true;
      try {
        this.pixel = await this.$store.dispatch('pixel/getPixel', pixelId);
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    async savePixel() {
      // save pixel
      this.savingProgress = true;
      try {
        if (this.mode === 'edit') {
          await this.$store.dispatch('pixel/updatePixel', {
            id: this.pixel.id,
            pixel: this.pixel,
          });
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.pixelUpdated'),
          });
        } else {
          await this.$store.dispatch('pixel/createPixel', this.pixel);
          this.$bus.fire('show-snackbar', {
            message: this.$t('components.snackbar.text.pixelCreated'),
          });
        }
        this.closeDialog();
        this.$bus.fire('pixels-updated');
      } catch (errors) {
        this.$refs.validationObserver.setErrors(
          helpers.transformErrorObject(errors)
        );
      }
      this.savingProgress = false;
    },
    async deletePixel() {
      try {
        await this.$store.dispatch('pixel/deletePixel', this.pixel.id);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.pixelDeleted'),
        });
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.closeDialog();
      this.$bus.fire('pixels-updated');
    },
    closeDialog() {
      this.resetFields();
      this.show = false;
    },
  },
};
</script>
