/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import account from './modules/account';
import ad from './modules/ad';
import audience from './modules/audience';
import auth from './modules/auth';
import channel from './modules/channel';
import cronjob from './modules/cronjob';
import form from './modules/form';
import metatag from './modules/metatag';
import monitoring from './modules/monitoring';
import image from './modules/image';
import pixel from './modules/pixel';
import question from './modules/question';
import tracking from './modules/tracking';
import transfer from './modules/transfer';
import user from './modules/user';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    account,
    ad,
    audience,
    auth,
    channel,
    cronjob,
    form,
    image,
    metatag,
    monitoring,
    pixel,
    question,
    tracking,
    transfer,
    user,
  },
  mutations: {
    RESET_STATE() {
      this.commit('account/RESET_STATE');
      this.commit('ad/RESET_STATE');
      this.commit('audience/RESET_STATE');
      this.commit('channel/RESET_STATE');
      this.commit('cronjob/RESET_STATE');
      this.commit('form/RESET_STATE');
      this.commit('image/RESET_STATE');
      this.commit('metatag/RESET_STATE');
      this.commit('monitoring/RESET_STATE');
      this.commit('pixel/RESET_STATE');
      this.commit('question/RESET_STATE');
      this.commit('user/RESET_STATE');
      this.commit('tracking/RESET_STATE');
      this.commit('transfer/RESET_STATE');
    },
  },
  strict: debug,
});
