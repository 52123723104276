var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.id),expression:"model.id"}],attrs:{"flat":"","hover":"","accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":!_vm.hasChildren}},[_c('span',[_c('span',{class:{ clickable: _vm.isNotPinpollAudience },on:{"click":function($event){$event.stopPropagation();return _vm.showAddImageDialog($event)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.model.filename),expression:"model.filename"}],attrs:{"size":"30px"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.model.filename,"alt":"avatar"}})],1),_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.model.filename),expression:"!model.filename"}],attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-edit ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.isNotPinpollAudience ? _vm.$t( 'components.audiences.manageTreeItem.tooltip.edit-image' ) : _vm.localizedAudiences[_vm.model.id].text))])])],1),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.localizedAudiences[_vm.model.id] && _vm.localizedAudiences[_vm.model.id].text)+" ")])]),_c('span',{staticClass:"align-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.openNameAudienceDialog(true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audiences.manageTreeItem.tooltip.edit', { model: _vm.modelName, })))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.openNameAudienceDialog(false)}}},'v-icon',attrs,false),on),[_vm._v(" fa-level-down ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audiences.manageTreeItem.tooltip.add-sub', { model: _vm.modelName, })))])]),(_vm.isNotPinpollAudience)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteAudienceDialog($event)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,false,3264424974)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.audiences.manageTreeItem.tooltip.delete', { model: _vm.modelName, })))])]):_vm._e()],1)]),(_vm.hasChildren)?_c('v-expansion-panel-content',_vm._l((_vm.model.children),function(child){return _c('item',{key:child.id,ref:"currentitem",refInFor:true,staticClass:"item",attrs:{"model":child,"depth":_vm.depth + 1,"model-name":_vm.modelName},on:{"show-delete-dialog":_vm.forwardShowDeleteAudienceDialog}})}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }