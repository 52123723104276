/* ============
 * Lodash
 * ============
 *
 * Lodash
 *
 * https://www.npmjs.com/package/lodash
 */

import lodash from 'lodash';

window._ = lodash;
