import Resource from './Resource';

class TrackingResource extends Resource {
  /**
   * The constructor for the TrackingResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/trackings');
  }

  /**
   * Method used to get all filtered trackings
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  datatable(params) {
    return this.submit('get', 'datatable', params);
  }

  /**
   * Method used to toggle the current state
   *
   * @param {Object} params options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  toggleState(params) {
    return this.submit('post', 'toggleState', params);
  }
}

export default TrackingResource;
