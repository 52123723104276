<template>
  <div>
    <v-expansion-panels v-show="model.id" flat hover accordion>
      <v-expansion-panel>
        <v-expansion-panel-header :hide-actions="!hasChildren">
          <span>
            <span
              :class="{ clickable: isNotPinpollAudience }"
              @click.stop="showAddImageDialog"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-avatar
                    v-show="model.filename"
                    v-bind="attrs"
                    size="30px"
                    v-on="on"
                  >
                    <v-img :src="model.filename" alt="avatar" />
                  </v-avatar>
                  <v-icon
                    v-show="!model.filename"
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                  >
                    mdi-image-edit
                  </v-icon>
                </template>
                <span>{{
                  isNotPinpollAudience
                    ? $t(
                        'components.audiences.manageTreeItem.tooltip.edit-image'
                      )
                    : localizedAudiences[model.id].text
                }}</span>
              </v-tooltip>
            </span>
            <span class="ml-1">
              {{
                localizedAudiences[model.id] &&
                localizedAudiences[model.id].text
              }}
            </span>
          </span>
          <span class="align-right">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="warning"
                  v-on="on"
                  @click.stop="openNameAudienceDialog(true)"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>{{
                $t('components.audiences.manageTreeItem.tooltip.edit', {
                  model: modelName,
                })
              }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="info"
                  v-on="on"
                  @click.stop="openNameAudienceDialog(false)"
                >
                  fa-level-down
                </v-icon>
              </template>
              <span>{{
                $t('components.audiences.manageTreeItem.tooltip.add-sub', {
                  model: modelName,
                })
              }}</span>
            </v-tooltip>
            <v-tooltip v-if="isNotPinpollAudience" bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="error"
                  v-on="on"
                  @click.stop="showDeleteAudienceDialog"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>{{
                $t('components.audiences.manageTreeItem.tooltip.delete', {
                  model: modelName,
                })
              }}</span>
            </v-tooltip>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="hasChildren">
          <item
            v-for="child in model.children"
            ref="currentitem"
            :key="child.id"
            :model="child"
            :depth="depth + 1"
            :model-name="modelName"
            class="item"
            @show-delete-dialog="forwardShowDeleteAudienceDialog"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Item',
  props: {
    model: {
      default: null,
      type: Object,
    },
    depth: {
      default: 0,
      type: Number,
    },
    modelName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    hasChildren() {
      return this.model.children && this.model.children.length;
    },
    isNotPinpollAudience() {
      return !this.model.is_pinpoll || this.$acl.check('viewModuleAdmin');
    },
    ...mapState('audience', ['localizedAudiences']),
  },
  methods: {
    showDeleteAudienceDialog() {
      if (this.isNotPinpollAudience) {
        this.$emit('show-delete-dialog', {
          id: this.model.id,
          name: this.model.text,
        });
      }
    },
    /**
     * Forwarding emits to parent component if it is a nested item
     * @param {Object} options - options to emit
     * @returns {void}
     */
    forwardShowDeleteAudienceDialog(options) {
      this.$emit('show-delete-dialog', options);
    },
    showAddImageDialog() {
      if (this.isNotPinpollAudience) {
        this.$bus.fire(`${this.modelName}:show-add-image-dialog`, this.model);
      }
    },
    showNameDialog(data) {
      // fire different bus depending on audience type
      this.$bus.fire(`${this.modelName}:show-name-dialog`, data);
    },
    openNameAudienceDialog(isEdit) {
      const data = {
        isEdit,
        parentId: this.model.id,
        isPinpollAudience: !this.isNotPinpollAudience,
      };
      this.showNameDialog(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon,
.clickable {
  cursor: pointer;
}

.align-right {
  text-align: right;
}
</style>
