/* ============
 * Mutation types for the audience module
 * ============
 *
 * The mutation types that are available
 * on the audience module.
 */

export const RESET_FIELDS = 'RESET_FIELDS';
export const SET_ITEMS = 'SET_ITEMS';
export const SELECT_ITEM = 'SELECT_ITEM';
export const RESET_STATE = 'RESET_STATE';

export default {
  RESET_FIELDS,
  SET_ITEMS,
  SELECT_ITEM,
  RESET_STATE,
};
