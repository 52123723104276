<template>
  <v-row>
    <transfer-detail />
    <transfer-dialog />
    <pixel-dialog />
  </v-row>
</template>

<script>
import TransferDetail from '@/components/Transfer/Detail';
import TransferDialog from '@/components/Transfer/Dialog';
import PixelDialog from '@/components/Pixels/PixelDialog';

export default {
  components: {
    TransferDetail,
    TransferDialog,
    PixelDialog,
  },
  data: () => ({}),
};
</script>

<style></style>
