import Resource from './Resource';

class QuestionResource extends Resource {
  /**
   * The constructor for the QuestionResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/questions');
  }

  /**
   * Method used to get all questions from current user
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getQuestions() {
    return this.submit('get', 'getQuestions');
  }

  /**
   * Method used to get all filtered questions
   *
   * @param {Object} params axios options.
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  datatable(params) {
    return this.submit('get', 'datatable', params);
  }
}

export default QuestionResource;
