/* ============
 * State of the form module
 * ============
 *
 * The initial state of the form module.
 */

export const getInitialState = () => ({
  form: {},
  forms: [],
  filteredForms: {
    items: [],
    total: 0,
  },
  formDataValue: '',
  key: '',
});

export default getInitialState;
