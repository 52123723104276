<template>
  <div>
    <ValidationObserver v-slot="{ invalid, touched, handleSubmit }" slim>
      <v-form @submit.prevent="handleSubmit(login)">
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('components.authLoginForm.textFields.email.name')"
          rules="required|email"
        >
          <v-text-field
            v-model="user.email"
            :label="$t('components.authLoginForm.textFields.email.label')"
            name="email"
            autocomplete="email"
            prepend-icon="mdi-account"
            type="email"
            required
            :error-messages="errors"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('components.authLoginForm.textFields.password.name')"
          rules="required"
        >
          <v-text-field
            v-model="user.password"
            :label="$t('components.authLoginForm.textFields.password.label')"
            :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="hidePassword ? 'password' : 'text'"
            name="password"
            autocomplete="current-password"
            prepend-icon="mdi-lock"
            required
            :error-messages="errors"
            @click:append="hidePassword = !hidePassword"
          />
        </ValidationProvider>
        <v-row justify="end">
          <v-btn
            :loading="loading"
            :disabled="touched && invalid"
            name="login"
            color="primary"
            block
            type="submit"
          >
            {{ $t('components.authLoginForm.buttons.login') }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hidePassword: true,
      loading: false,
      user: {
        email: null,
        password: null,
      },
      error: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/login', this.user);
        this.$bus.fire('user-logged-in');
        this.loading = false;
      } catch (err) {
        if (err) {
          if (err.status === 'invalid_credentials') {
            this.showSnackbar(err.data);
          } else {
            const first = err[Object.keys(err)[0]];
            if (first) this.showSnackbar(first);
          }
        }
        this.loading = false;
      }
    },
    showSnackbar(error) {
      this.$bus.fire('show-snackbar', { message: error });
    },
  },
};
</script>
