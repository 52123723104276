<template>
  <v-hover v-slot="hoverState">
    <v-card
      class="grid-card"
      :class="{ warningIcon, pagelist: cardSettings.enablePages }"
    >
      <!-- Event widget top part wrapper (full card except pagelist) -->
      <div class="fixed-card-wrapper">
        <!-- Background chart -->
        <div class="card-background">
          <histogram-compare-chart
            v-if="showHistogram"
            :histogram-data="
              $store.state.monitoring.eventTracking.histogram.getData(
                cardSettings.i,
                []
              )
            "
            :histogram-data-compare="
              $store.state.monitoring.eventTracking.histogramCompare.getData(
                cardSettings.i,
                []
              )
            "
            :compare-timeframe="cardSettings.histogramCompare || 'last_week'"
            :size="chartSize"
          />
        </div>

        <!-- Card Top Bar -->
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-slide-x-reverse-transition>
              <v-btn
                v-if="hoverState && hoverState.hover"
                small
                icon
                class="closeButton"
                v-bind="attrs"
                @click="closeCard"
                v-on="on"
              >
                <v-icon small> mdi-delete-forever </v-icon>
              </v-btn>
            </v-slide-x-reverse-transition>
          </template>
          <span>{{ $t('monitoring.components.close') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip
              v-show="cardSettings.label"
              label
              x-small
              class="filter ma-1 pl-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar>
                <v-icon x-small color="textColor"> mdi-filter </v-icon>
              </v-avatar>
              {{ cardSettings.label }}
            </v-chip>
          </template>
          <span>{{
            $t('monitoring.help.labelFilter', { label: cardSettings.label })
          }}</span>
        </v-tooltip>

        <!-- Main Content -->
        <div v-if="cardSettings.event">
          <v-card-text :style="fontSize" class="pb-0">
            <!-- Big Number -->
            <div class="number">
              <number :data="dataToday.amount" no-styling class="big-number" />
              <div v-if="dataToday.sum > 0">
                <span class="todaySum">
                  ({{ Math.round(dataToday.sum * 10000) / 10000 }})
                </span>
              </div>
            </div>
            <!-- Display name of event -->
            <div class="label">
              {{ $t('monitoring.data.eventTracking.new') }}
              <editable-label
                :text="eventName"
                @text-changed="updateCustomEvents"
              />
            </div>
            <!-- Display percentage progression -->
            <div class="progressionBar">
              <v-row
                v-show="
                  $store.state.monitoring.eventTracking.today.isLoaded(
                    cardSettings.i
                  )
                "
                class="text-no-wrap"
              >
                <v-col
                  cols="6"
                  class="text-md-left progression px-1"
                  :style="{
                    color: getHighlightColor(progressionTodayYesterday),
                  }"
                >
                  <v-icon
                    :color="getHighlightColor(progressionTodayYesterday)"
                    class="progressIcon"
                  >
                    {{ getProgressionIcon(progressionTodayYesterday) }}
                  </v-icon>
                  <span class="totalProgressionYesterday">{{
                    progressionTodayYesterday | percent
                  }}</span>
                  <span
                    v-if="cardSettings.showValuePercent && dataToday.sum > 0"
                    :style="{
                      color: getHighlightColor(progressionSumTodayYesterday),
                    }"
                  >
                    <span class="openBracket">(</span>
                    <v-icon
                      :color="getHighlightColor(progressionSumTodayYesterday)"
                      class="progressIcon"
                    >
                      {{ getProgressionIcon(progressionSumTodayYesterday) }}
                    </v-icon>
                    <span class="totalProgressionYesterday">{{
                      progressionSumTodayYesterday | percent
                    }}</span>
                    <span>)</span>
                  </span>
                </v-col>
                <v-col
                  cols="6"
                  class="text-md-left progression px-1"
                  :style="{
                    color: getHighlightColor(progressionTodayLastWeek),
                  }"
                >
                  <v-icon
                    :color="getHighlightColor(progressionTodayLastWeek)"
                    class="progressIcon"
                  >
                    {{ getProgressionIcon(progressionTodayLastWeek) }}
                  </v-icon>
                  <span class="totalProgressionLastWeek">{{
                    progressionTodayLastWeek | percent
                  }}</span>
                  <span
                    v-if="cardSettings.showValuePercent && dataToday.sum > 0"
                    :style="{
                      color: getHighlightColor(progressionSumTodayYesterday),
                    }"
                  >
                    <span class="openBracket">(</span>
                    <v-icon
                      :color="getHighlightColor(progressionSumTodayLastWeek)"
                      class="progressIcon"
                    >
                      {{ getProgressionIcon(progressionSumTodayLastWeek) }}
                    </v-icon>
                    <span class="totalProgressionLastWeek">{{
                      progressionSumTodayLastWeek | percent
                    }}</span>
                    <span>)</span>
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <!-- loading bar -->
          <div class="card-progress-container">
            <v-progress-linear
              v-show="
                !$store.state.monitoring.eventTracking.today.isLoaded(
                  cardSettings.i
                )
              "
              :height="7"
              color="primary"
              indeterminate
            />
          </div>
          <!-- Card Footer -->
          <v-card
            v-if="cardSettings.event !== ''"
            color="infoBar"
            class="card-bar elevation-0"
            dark
          >
            <v-card-text style="padding-left: 16px" class="white--text">
              <v-row>
                <v-col cols="6" class="text-md-left">
                  <span>
                    {{ $t('monitoring.data.eventTracking.yesterday') }}
                  </span>
                  <span class="totalCountYesterday">
                    {{ dataYesterday.amount | formatNumber }}
                  </span>
                  <span v-if="dataToday.sum > 0">
                    ({{ dataYesterday.sum | formatNumber }})
                  </span>
                </v-col>
                <v-col cols="6" class="text-md-left">
                  <span>
                    {{ $t('monitoring.data.eventTracking.lastWeek') }}
                  </span>
                  <span class="totalCountLastWeek">
                    {{ dataLastWeek.amount | formatNumber }}
                  </span>
                  <span v-if="dataToday.sum > 0">
                    ({{ dataLastWeek.sum | formatNumber }})
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <!-- No Data View -->
        <div v-else class="text-center">
          <div class="pt-4 pb-2 text-body-2">
            {{ $t('monitoring.no_data') }}
          </div>
          <v-alert
            class="mx-6 mb-5 text-body-2"
            color="info"
            icon="mdi-information"
            outlined
          >
            {{ $t('monitoring.data.eventTracking.hint') }}
          </v-alert>
        </div>

        <!-- Card Settings -->
        <options-menu right small :min-width="240" @opened="settingsOpened">
          <v-card>
            <v-card-text>
              <h3 class="text-h6 mb-2">
                {{ $t('monitoring.headers.settings') }}
              </h3>
              <v-autocomplete
                :value="cardSettings.domain"
                :items="analyticsDomains"
                :label="$t('components.eventTrackingSettings.domainLabel')"
                :no-data-text="$t('monitoring.no_data')"
                clearable
                class="selectBox"
                @change="changeDomain"
              />
              <v-autocomplete
                :value="cardSettings.event"
                :items="domainEvents"
                :label="$t('components.eventTrackingSettings.eventLabel')"
                :loading="loadingEvents"
                :no-data-text="$t('monitoring.no_data')"
                item-text="text"
                item-value="text"
                clearable
                :disabled="!cardSettings.domain"
                class="selectBox"
                @change="changeEvent"
              />
              <h3 class="text-h6 mb-2 mt-6">
                {{ $t('monitoring.headers.filter') }}
              </h3>
              <v-autocomplete
                :value="cardSettings.label"
                :items="labels"
                :label="$t('components.eventTrackingSettings.filterLabel')"
                :loading="loadingLabels"
                :no-data-text="$t('monitoring.no_data')"
                clearable
                :disabled="!cardSettings.event"
                class="selectBox"
                @change="changeLabel"
              />
              <h3 class="text-h6 mb-2 mt-6">
                {{ $t('monitoring.headers.displaySettings') }}
              </h3>
              <v-select
                :value="cardSettings.histogramCompare"
                :items="timeframeOptions"
                :label="$t('monitoring.date.today') + ' vs.'"
                :hide-details="true"
                class="selectBox"
                @change="changeCompareSetting"
              />
              <v-switch
                :input-value="cardSettings.enablePages"
                :label="$t('components.eventTrackingSettings.showTopPages')"
                @change="changeEnablePages"
              />
              <v-switch
                v-model="cardSettings.showValuePercent"
                class="mt-0"
                :label="
                  $t('components.eventTrackingSettings.showValuePercentage')
                "
                @change="$emit('card-changed')"
              />
            </v-card-text>
          </v-card>
        </options-menu>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-slide-x-reverse-transition>
              <v-btn
                v-if="warningIcon"
                small
                icon
                class="filterWarning"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning" small> mdi-exclamation-thick </v-icon>
              </v-btn>
            </v-slide-x-reverse-transition>
          </template>
          <span>{{ $t('monitoring.components.audienceFilterWarning') }}</span>
        </v-tooltip>
      </div>
      <!-- pagelist bottom -->
      <div v-if="cardSettings.enablePages">
        <number-list
          :data="
            $store.state.monitoring.eventTracking.pages.getData(
              cardSettings.i,
              []
            )
          "
          :data-loaded="
            $store.state.monitoring.eventTracking.pages.isLoaded(cardSettings.i)
          "
          :value-header="$t('monitoring.data.events')"
          :key-header="$t('monitoring.data.page')"
          :on-row-click="
            (page) =>
              $store.dispatch('monitoring/updateFilter', {
                prop: 'page',
                value: page.key,
              })
          "
          :key-url="(item) => item.href"
          :translations="
            modelDisplayPageTitle
              ? (item) => item.title || '-'
              : (item) => item.key
          "
          count-field="count"
          fixed-table
        >
          <v-card slot="header-options-1" class="numberlist-header-options">
            <v-card-text class="d-flex align-center justify-space-between">
              <span class="subtitle-1">
                {{ $t('monitoring.options.display') }}
              </span>
              <v-btn-toggle
                v-model="modelDisplayPageTitle"
                class="ml-1"
                mandatory
              >
                <v-btn text :value="false">
                  {{ $t('monitoring.data.url') }}
                </v-btn>
                <v-btn text :value="true">
                  {{ $t('monitoring.data.title') }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
        </number-list>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import EditableLabel from '@/components/EditableLabel';
import Number from '@/components/Monitoring/Number';
import OptionsMenu from '@/components/Monitoring/OptionsMenu';
import { mapGetters } from 'vuex';
import MonitoringResource from '@/resources/MonitoringResource';
import NumberList from './NumberList';
import HistogramCompareChart from './HistogramCompareChart';
import ResizableCard from './ResizableCard';
import { paramFunctions } from '../../store/modules/monitoring/resources';
import helpers from '../../utils/helpers';

export default {
  components: {
    EditableLabel,
    Number,
    OptionsMenu,
    HistogramCompareChart,
    NumberList,
  },
  filters: {
    /* Format number to percentage */
    percent(value) {
      if (value === null || value === 0) {
        return '-';
      }
      if (numeral.locale() === 'en') {
        return numeral(value).format('0.00%');
      }
      return numeral(String(value).replace('.', ',')).format('0.00%');
    },
  },
  extends: ResizableCard,
  inject: ['eventBus'],
  data() {
    const data = {
      loadingEvents: false,
      domainEvents: [],
      loadingLabels: false,
      labels: [],
      timeframeOptions: ['yesterday', 'last_week'].map((value) => ({
        value,
        text: this.$t(`monitoring.date.${value}`),
      })),
      resourceParameters: () => ({
        eventName: this.cardSettings.event,
        label: this.cardSettings.label,

        // only use card domain when no global domain filter applied
        ...(this.$store.state.monitoring.filter.domain
          ? {}
          : {
              domain: this.cardSettings.domain,
            }),
      }),
    };
    Object.defineProperty(
      data,
      'modelDisplayPageTitle',
      helpers.localstorageProperty(
        'monitoring.settings.displayPageTitle' + this.cardSettings.i,
        false
      )
    );

    return data;
  },
  computed: {
    ...mapGetters('account', ['analyticsDomains']),

    /* Calculate correct font size */
    fontSize() {
      if (this.size && !this.cardSettings.enablePages) {
        return {
          fontSize: `${10 + this.size.h * 2}px`,
        };
      }
      return {
        fontSize: '16px',
      };
    },
    /* Chart will have different size depending on pageList being active */
    chartSize() {
      if (this.cardSettings.enablePages) return { ...this.size, h: 3 };
      return this.size;
    },
    /* Get event growth of today */
    dataToday() {
      return this.$store.state.monitoring.eventTracking.today.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get event growth of yesterday */
    dataYesterday() {
      return this.$store.state.monitoring.eventTracking.yesterday.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get event growth of last week same day */
    dataLastWeek() {
      return this.$store.state.monitoring.eventTracking.lastWeek.getData(
        this.cardSettings.i,
        0
      );
    },
    /* Get the progression compared to yesterday */
    progressionTodayYesterday() {
      return this.calculateProgression(
        this.dataYesterday.amount,
        this.dataToday.amount
      );
    },
    /* Get the progression of sum compared to yesterday */
    progressionSumTodayYesterday() {
      return this.calculateProgression(
        this.dataYesterday.sum,
        this.dataToday.sum
      );
    },
    /* Get the progression compared to last week */
    progressionTodayLastWeek() {
      return this.calculateProgression(
        this.dataLastWeek.amount,
        this.dataToday.amount
      );
    },
    /* Get the progression of sum compared to last week */
    progressionSumTodayLastWeek() {
      return this.calculateProgression(
        this.dataLastWeek.sum,
        this.dataToday.sum
      );
    },
    /* Get either custom or normal event name */
    eventName() {
      const nicknames = this.$store.state.monitoring.settings.nicknames.events;
      if (nicknames) {
        // find custom event name in object where all events are stored
        const nickname = nicknames.find(
          (elem) => elem.key === this.cardSettings.event
        );
        if (nickname) {
          return nickname.name;
        }
      }
      return this.cardSettings.event;
    },
    /* Determine if histogram should be shown */
    showHistogram() {
      return (
        this.cardSettings.event &&
        (this.dataToday.amount > 0 ||
          (this.cardSettings.histogramCompare === 'yesterday' &&
            this.dataYesterday.amount > 0) ||
          (this.cardSettings.histogramCompare === 'last_week' &&
            this.dataLastWeek.amount > 0))
      );
    },
    /* Boolean, determines if warning icon is present in card */
    warningIcon() {
      return this.$store.state.monitoring.filter.terms.some(
        (filter) => filter.field === 'audience'
      );
    },
  },
  watch: {
    size: {
      handler(newValue, oldValue) {
        if (newValue.h !== oldValue.h) {
          // reopen socket so we load with new count
          this.$store.dispatch('monitoring/reload', {
            name: 'eventTracking.pages',
            ownerId: this.cardSettings.i,
            purge: newValue.h > oldValue.h,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.addActiveResources();
  },
  destroyed() {
    this.removeActiveResources();
  },
  methods: {
    /**
     * initialize all resource needed for this card
     * @returns {void}
     */
    addActiveResources() {
      if (this.cardSettings.event) {
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'eventTracking.today',
          parameters: this.resourceParameters,
          ownerId: this.cardSettings.i,
        });
        if (this.cardSettings.enablePages) {
          this.addActivePageResource();
        }
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'eventTracking.yesterday',
          parameters: this.resourceParameters,
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'eventTracking.lastWeek',
          parameters: this.resourceParameters,
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'eventTracking.histogram',
          parameters: this.resourceParameters,
          ownerId: this.cardSettings.i,
        });
        this.$store.dispatch('monitoring/addActiveResource', {
          name: 'eventTracking.histogramCompare',
          parameters: () => ({
            ...this.resourceParameters(),
            ...(this.cardSettings.histogramCompare === 'yesterday'
              ? paramFunctions.yesterday()
              : paramFunctions.lastWeek()),
          }),
          ownerId: this.cardSettings.i,
        });
      }
    },
    /**
     * activates the resource needed for the page list
     * @returns {void}
     */
    addActivePageResource() {
      this.$store.dispatch('monitoring/addActiveResource', {
        name: 'eventTracking.pages',
        parameters: () => ({
          ...this.resourceParameters(),
          count: Math.max(this.cardSettings.h - 4, 2),
        }),
        ownerId: this.cardSettings.i,
      });
    },
    /**
     * Clear all active resources of this card
     * @returns {void}
     */
    removeActiveResources() {
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.today',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.yesterday',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.lastWeek',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.histogram',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.histogramCompare',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/removeActiveResource', {
        name: 'eventTracking.pages',
        ownerId: this.cardSettings.i,
      });
    },
    /* update custom event name */
    updateCustomEvents(name) {
      this.$store.dispatch('monitoring/addNickname', {
        key: this.cardSettings.event,
        name,
        type: 'events',
      });
    },
    /* Get a progression between two values */
    calculateProgression(start, end) {
      if (start > 0) {
        return (end - start) / start;
      }
      if ((start === 0 && end === 0) || typeof start === 'undefined') {
        return 0;
      }
      return 1;
    },
    /* Get the highlight color for progression */
    getHighlightColor(percentage) {
      if (percentage >= 0) {
        return 'rgb(34, 171, 50)';
      }
      return 'rgb(241, 90, 90)';
    },
    /* Get the icon for progression */
    getProgressionIcon(percentage) {
      if (percentage >= 0) {
        return 'mdi-arrow-up';
      }
      return 'mdi-arrow-down';
    },
    closeCard() {
      this.$emit('close-card');
    },

    /* Update compare timeframe */
    changeCompareSetting(value) {
      this.cardSettings.histogramCompare = value;
      this.$emit('card-changed');
      this.$store.dispatch('monitoring/purge', {
        prop: 'eventTracking.histogramCompare',
        ownerId: this.cardSettings.i,
      });
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
    /* Update domain setting, but not yet save change (only when new event chosen) */
    changeDomain(value) {
      this.cardSettings.domain = value;
      this.getDomainEvents(value);
    },
    /**
     * Update eventlist given domain
     * @param {string} domain load events from this domain
     * @returns {void}
     */
    async getDomainEvents(domain) {
      if (!domain) return;
      this.loadingEvents = true;
      this.domainEvents = await new MonitoringResource().getEvents({ domain });
      this.loadingEvents = false;
    },

    /**
     * Update eventlist given event
     * @param {string} domain domain of event
     * @param {string} event load labels from this domain
     * @returns {void}
     */
    async getEventLabels(domain, event) {
      if (!event) return;
      this.loadingLables = true;
      this.labels = await new MonitoringResource().getLabels({ domain, event });
      this.loadingLabels = false;
    },
    /**
     * Update cardsetting
     * @param {string} value event name
     * @returns {void}
     */
    changeEvent(value) {
      const previousValue = this.cardSettings.event;
      this.cardSettings.event = value;
      delete this.cardSettings.label;

      if (!previousValue) {
        this.addActiveResources();
      }
      if (!value) {
        this.removeActiveResources();
      }

      this.getEventLabels(this.cardSettings.domain, value);
      this.$emit('card-changed');
      this.$store.dispatch('monitoring/purgeActiveResources');
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },
    /**
     * Update cardsetting
     * @param {string} value event label name
     * @returns {void}
     */
    changeLabel(value) {
      this.cardSettings.label = value;
      this.$emit('card-changed');
      this.$store.dispatch('monitoring/purgeActiveResources');
      this.$store.dispatch('monitoring/refresh', { skipLoaded: true });
    },

    /**
     * Change cardsetting to enabe pagelist
     * @param {boolean} value new value
     * @returns {void}
     */
    changeEnablePages(value) {
      this.cardSettings.enablePages = value;
      if (value) {
        this.cardSettings.minH = 6;
        this.cardSettings.h = 6;
        if (this.size) this.size.h = 6;
        this.addActivePageResource();
      } else {
        this.cardSettings.minH = 3;
        this.cardSettings.h = 3;
        if (this.size) this.size.h = 3;
        this.$store.dispatch('monitoring/removeActiveResource', {
          name: 'eventTracking.pages',
          ownerId: this.cardSettings.i,
        });
      }
      this.$emit('card-changed');

      // trigger update in the vue-grid-layout packages, that will update
      // all cards to be properly positioned after the card size changed
      // eslint-disable-next-line vue/custom-event-name-casing
      if (this.eventBus) this.eventBus.$emit('resizeEvent');
    },

    /* registered event to load eventlist on first open */
    settingsOpened() {
      if (this.cardSettings.domain && !this.domainEvents.length) {
        this.getDomainEvents(this.cardSettings.domain);
      }
      if (this.cardSettings.event && !this.labels.length) {
        this.getEventLabels(this.cardSettings.domain, this.cardSettings.event);
      }
    },
  },
};
</script>

<style scoped>
.number {
  font-size: 2.8em;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: 'Roboto', sans-serif;
  display: flex;
}
.label {
  font-size: 1em;
  font-weight: 400;
  display: flex;
}
.card-background {
  position: absolute;
  width: 100%;
}
.progressIcon {
  font-size: 18px;
}
.todaySum {
  margin-left: 5px;
  font-size: 0.5em;
}
.openBracket {
  margin-right: -5px;
  margin-left: 5px;
}
.closeButton.closeButton {
  position: absolute;
  margin: 0;
  right: 1.4em;
}
.grid-card.warningIcon .closeButton {
  right: 3.2em;
}
.progression {
  z-index: 1;
  pointer-events: none;
}
.progressionBar {
  font-size: 13px;
  height: 24px;
  font-weight: bold;
}
.filterWarning {
  position: absolute;
  cursor: help;
  top: 0;
  right: 1.4em;
  margin: 0;
}
.selectBox {
  height: 50px;
}
.v-chip.filter {
  position: absolute;
}
.big-number {
  margin-top: 6px;
}
.fixed-card-wrapper {
  position: relative;
  height: 100%;
}
.pagelist .fixed-card-wrapper {
  height: 137px;
}
</style>
