<!--
***************************************************************
Name:         Display Settings

Description:  Display Settings for Account Page.
Usage:        <display-settings/>

Author:       Tobias Madner
***************************************************************
-->
<template>
  <v-card flat>
    <v-card-title class="text-h5">
      {{ $t('components.account.settings.displaySettings.title') }}
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-switch
          v-model="showNavigationDrawer"
          :label="
            $t(
              'components.account.settings.displaySettings.showNavigationDrawer.label'
            )
          "
          :hint="
            $t(
              'components.account.settings.displaySettings.showNavigationDrawer.hint'
            )
          "
          persistent-hint
        />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('account', ['displaySettings']),
    showNavigationDrawer: {
      get() {
        return this.displaySettings.showNavigationDrawer;
      },
      set(status) {
        this.$store.commit('account/UPDATE_DISPLAY_SETTING', {
          prop: 'showNavigationDrawer',
          value: status,
        });
        this.$store.dispatch('account/updateDisplaySettings');
      },
    },
  },
};
</script>

<style></style>
