var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-width"},[_c('v-card',{staticStyle:{"min-height":"196px"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.pluralModelName)+" ")]),_c('v-card-text',[(_vm.flattenTreeDataOneLevel.length > 0 && _vm.assignedAudiencesLoaded)?_c('multiselect',{attrs:{"max-height":300,"disabled":!_vm.selectedField,"options":_vm.flattenTreeDataOneLevel,"multiple":true,"taggable":true,"tag-placeholder":_vm.$t('components.audiences.assignMultiSelect.tagPlaceholder', {
            model: _vm.modelName,
          }),"placeholder":_vm.$t('components.audiences.assignMultiSelect.placeholder', {
            model: _vm.modelName,
          }),"select-label":_vm.$t('components.audiences.assignMultiSelect.selectLabel'),"selected-label":_vm.$t('components.audiences.assignMultiSelect.selectedLabel'),"deselect-label":_vm.$t('components.audiences.assignMultiSelect.deselectLabel'),"label":"text","track-by":"id","group-values":"children","group-label":"text"},on:{"tag":_vm.addTag,"select":_vm.assignAudience,"remove":_vm.unassignAudience},model:{value:(_vm.selectedAudiences),callback:function ($$v) {_vm.selectedAudiences=$$v},expression:"selectedAudiences"}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeParentCategoryDialog($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveNewTag($event)}]},model:{value:(_vm.addParentCategoryDialog),callback:function ($$v) {_vm.addParentCategoryDialog=$$v},expression:"addParentCategoryDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t( 'components.audiences.assignMultiSelect.parentCategoryDialog.title', { parentName: _vm.currentTag } ))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('multiselect',{attrs:{"tabindex":10,"options":_vm.treeData,"allow-empty":false,"tag-placeholder":_vm.$t(
                    'components.audiences.assignMultiSelect.tagPlaceholder',
                    { model: _vm.modelName }
                  ),"placeholder":_vm.$t('components.audiences.assignMultiSelect.placeholder', {
                    model: _vm.modelName,
                  }),"select-label":_vm.$t('components.audiences.assignMultiSelect.selectLabel'),"selected-label":_vm.$t('components.audiences.assignMultiSelect.selectedLabel'),"deselect-label":_vm.$t('components.audiences.assignMultiSelect.deselectLabel'),"track-by":"id","label":"text"},model:{value:(_vm.tagParentCategory),callback:function ($$v) {_vm.tagParentCategory=$$v},expression:"tagParentCategory"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":"","tabindex":"11"},on:{"click":_vm.closeParentCategoryDialog}},[_vm._v(" "+_vm._s(_vm.$t( 'components.audiences.assignMultiSelect.parentCategoryDialog.cancel' ))+" ")]),_c('v-btn',{attrs:{"color":"success","tabindex":"12"},on:{"click":_vm.saveNewTag}},[_vm._v(" "+_vm._s(_vm.$t( 'components.audiences.assignMultiSelect.parentCategoryDialog.save' ))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }