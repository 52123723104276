<template>
  <div>
    <lead-form-table />
    <assign-detail-view />
  </div>
</template>

<script>
import LeadFormTable from '@/components/Audiences/LeadFormTable';
import AssignDetailView from '@/components/Audiences/AssignDetailView';

export default {
  components: {
    LeadFormTable,
    AssignDetailView,
  },
  data: () => ({}),
};
</script>

<style></style>
