<template>
  <dashboard-actions />
</template>

<script>
import DashboardActions from '@/components/DashboardActions';

export default {
  components: {
    DashboardActions,
  },
};
</script>
