/* ============
 * Mutation types for the form module
 * ============
 *
 * The mutation types that are available
 * on the form module.
 */

export const SET_FORM = 'SET_FORM';
export const SET_FORMS = 'SET_FORMS';
export const SET_FILTERED_FORMS = 'SET_FILTERED_FORMS';
export const UPDATE_FORM_DATA_VALUE = 'UPDATE_FORM_DATA_VALUE';
export const RESET_STATE = 'RESET_STATE';

export default {
  SET_FORM,
  SET_FORMS,
  SET_FILTERED_FORMS,
  UPDATE_FORM_DATA_VALUE,
  RESET_STATE,
};
