/* ============
 * Actions for the user module
 * ============
 *
 * The actions that are available on the
 * user module.
 */

import UserResource from '@/resources/UserResource';
import * as types from './mutation-types';

export const getUsers = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new UserResource()
      .getUsers(params)
      .then((data) => {
        commit(types.SET_USERS, data);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const refreshUsers = (context, params) =>
  new Promise((resolve, reject) => {
    new UserResource()
      .refreshUsers(params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const refreshRetargeting = (context, params) =>
  new Promise((resolve, reject) => {
    new UserResource()
      .refreshRetargetingCache(params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

export default {
  getUsers,
  refreshUsers,
  refreshRetargeting,
};
