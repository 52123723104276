<template>
  <div>
    <section v-if="isIE11">
      <v-row justify="center" align-content="center">
        <v-col cols="12">
          <v-alert
            v-model="infoMessage"
            outlined
            dismissible
            transition="scale-transition"
            color="warning"
            icon="mdi-information"
          >
            <strong>{{ $t('pages.auth.login.infoMessage.headline') }}</strong>
            <div>{{ $t('pages.auth.login.infoMessage.message') }}</div>
            <ul>
              <li>
                <a
                  href="https://www.google.com/chrome/index.html"
                  target="_blank"
                >
                  {{ $t('pages.auth.login.infoMessage.browsers.chrome') }}
                </a>
              </li>
              <li>
                <a href="https://www.mozilla.org/firefox" target="_blank">
                  {{ $t('pages.auth.login.infoMessage.browsers.firefox') }}
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/downloads/safari"
                  target="_blank"
                >
                  {{ $t('pages.auth.login.infoMessage.browsers.safari') }}
                </a>
              </li>
              <li>
                <a
                  href="https://www.microsoft.com/windows/microsoft-edge"
                  target="_blank"
                >
                  {{ $t('pages.auth.login.infoMessage.browsers.edge') }}
                </a>
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row justify="center" align="center">
        <v-card class="form">
          <v-card-text>
            <div class="pb-4">
              <v-img :src="currentPinpollLogoPath" alt="Pinpoll" />
            </div>
            <login-form />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-3"
              block
              text
              :href="`${constants.LANDING_URL}/forgot-password`"
            >
              {{ $t('pages.auth.login.links.forgot-pw') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </section>
  </div>
</template>

<script>
import constants from '@/utils/constants';
import LoginForm from '@/components/Auth/LoginForm';

export default {
  components: {
    'login-form': LoginForm,
  },
  data: () => ({
    constants,
    infoMessage: true,
  }),
  computed: {
    isIE11() {
      return !!navigator.userAgent.match(/Trident\/7\./);
    },
    /**
     * Return current path to logo depending on dark mode
     *
     * @returns {string} path to logo
     */
    currentPinpollLogoPath() {
      // change logo color depending on dark mode
      return this.$vuetify.theme?.dark
        ? `/logo/logo-all-dark-mode.svg`
        : `/logo/logo-all-light-mode.svg`;
    },
  },
  methods: {},
};
</script>

<style scoped>
.form {
  width: 380px;
  margin: 4em auto;
  padding: 3em 2em 2em 2em;
}
</style>
