/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */

import ImageProxy from '@/proxies/ImageProxy';
import * as types from './mutation-types';

export const search = ({ commit }, data) =>
  new Promise((resolve) => {
    new ImageProxy()
      .search(data)
      .then((response) => {
        commit(types.SET_ITEMS, { data, response });
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
      });
  });

export const save = ({ state, commit }, data) =>
  new Promise((resolve) => {
    // prepare data
    const storeData = {
      ...state.postData,
      id: data,
    };
    new ImageProxy()
      .store(storeData)
      .then((response) => {
        commit('audience/UPDATE_AUDIENCE_IMAGE', response, { root: true });
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
      });
  });

export default {
  search,
  save,
};
