/* ============
 * Vue Authentication
 * ============
 *
 * VueAuthentication to refresh authentication token
 */

import Vue from 'vue';
import Axios from 'axios';
import AuthProxy from '@/proxies/AuthProxy';
import store from '@/store';

const auth = new (class {
  constructor() {
    this.setup();
  }

  setup() {
    if (window.localStorage) {
      // set interceptor for token refreshing
      Axios.interceptors.response.use(undefined, (err) => {
        // save original request
        const originalRequest = err.config;

        // check if request is unauthorized and if its NOT the refresh route (since that one is already handled anyways in the catch block of refresh token)
        if (
          err.response.status === 401 &&
          !originalRequest.isRetry &&
          !originalRequest.url.endsWith('auth/refresh')
        ) {
          if (
            err.response.data?.error === 'token_expired' ||
            err.response.data?.message === 'Unauthenticated.'
          ) {
            // refresh token
            return this.refreshToken().then(() => {
              originalRequest.isRetry = true;
              // redo previous request
              return Axios(originalRequest);
            });
          }
          store.dispatch('auth/logout');
          Vue.router.push({
            name: 'login',
            query: Vue.router.currentRoute.query,
          });
        }
        // Check resource is not authorized
        if (err.response.status === 403) {
          // Not authorized for this api route
          Vue.router.push({
            name: '403',
          });
        }
        throw err;
      });
    }
  }

  refreshToken() {
    // only call refresh route if request is not already running
    if (!this.authTokenRequest) {
      this.authTokenRequest = new AuthProxy()
        .refresh()
        .catch(() => {
          store.dispatch('auth/logout');
          Vue.router.push({
            name: 'login',
            query: Vue.router.currentRoute.query,
          });
          return Promise.reject(
            new Error('Your token is expired, please login again!')
          );
        })
        .finally(() => {
          // reset auth token to null
          this.authTokenRequest = null;
        });
    }
    return this.authTokenRequest;
  }
})();

export default auth;
