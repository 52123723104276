<template>
  <div>
    <behaviour-table />
    <assign-detail-view />
  </div>
</template>

<script>
import BehaviourTable from '@/components/Audiences/BehaviourTable';
import AssignDetailView from '@/components/Audiences/AssignDetailView';

export default {
  components: {
    BehaviourTable,
    AssignDetailView,
  },
  data: () => ({}),
};
</script>

<style></style>
