<template>
  <div>
    <ad-dialog />
    <ad-table />
  </div>
</template>

<script>
import AdTable from '@/components/Campaigns/Ads/AdTable';
import AdDialog from '@/components/Campaigns/Ads/AdDialog';

export default {
  components: {
    AdTable,
    AdDialog,
  },
  data: () => ({}),
  methods: {
    showAddAdDialog() {
      this.$bus.fire('show-ad-dialog-create-mode');
    },
  },
};
</script>
