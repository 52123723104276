<template>
  <v-container fluid>
    <v-card class="px-2">
      <v-container fluid>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <div>
              <div class="text-h4">
                {{ tracking.name }}
              </div>
              <span class="grey--text text-body-2">
                <span>{{ tracking.startDate | formatDate }}</span>
                <span v-show="tracking.endDate">
                  - {{ tracking.endDate | formatDate }}
                </span>
              </span>
              <div class="mt-2 text-body-2">
                <div>
                  {{ $t('components.tracking.detail.domain.label') }}:
                  <strong>{{
                    tracking.domain
                      ? tracking.domain.title
                      : $t('components.tracking.detail.domain.all')
                  }}</strong>
                </div>
                <div>
                  {{ $t('components.tracking.detail.deviceType.label') }}:
                  <strong>{{
                    tracking.deviceType &&
                    $t(
                      'components.tracking.detail.deviceType.' +
                        tracking.deviceType
                    )
                  }}</strong>
                </div>
                <div>
                  {{ $t('components.tracking.detail.autoAssignment.label') }}:
                  <strong>{{
                    tracking.autoAssignment
                      ? $t('components.tracking.detail.autoAssignment.yes')
                      : $t('components.tracking.detail.autoAssignment.no')
                  }}</strong>
                </div>
              </div>
            </div>
            <v-switch
              v-model="currentState"
              :label="
                tracking.active
                  ? $t('components.tracking.detail.state.active')
                  : $t('components.tracking.detail.state.inactive')
              "
              :hint="$t('components.tracking.detail.state.hint')"
              color="success"
              @change="toggleTrackingState"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="tracking.assignedPixels && tracking.assignedPixels.length"
            cols="12"
            sm="6"
          >
            <assigned-pixels />
          </v-col>
          <v-col
            v-if="tracking.assignedEvents && tracking.assignedEvents.length"
            cols="12"
            sm="6"
          >
            <assigned-events />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-h5">
              {{ $t('components.tracking.detail.headline.inventory') }}
            </div>
          </v-col>
          <v-col
            v-if="
              tracking.assignedQuestions && tracking.assignedQuestions.length
            "
            cols="12"
            sm="6"
          >
            <assigned-questions />
          </v-col>
          <v-col
            v-if="tracking.assignedAnswers && tracking.assignedAnswers.length"
            cols="12"
            sm="6"
          >
            <assigned-answers />
          </v-col>
          <v-col
            v-if="tracking.assignedAds && tracking.assignedAds.length"
            cols="12"
            sm="6"
          >
            <assigned-ads />
          </v-col>
          <v-col
            v-if="tracking.assignedForms && tracking.assignedForms.length"
            cols="12"
            sm="6"
          >
            <assigned-forms />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row justify="end">
            <v-btn large text color="primary" @click="editTracking">
              {{ $t('components.tracking.detail.buttons.edit') }}
            </v-btn>
            <v-btn large text color="error" @click="setDeleteDialog(true)">
              {{ $t('components.tracking.detail.buttons.delete') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('models.single.tracking')"
      :name="tracking.name"
      :loading="deletingProgress"
      @confirmed="deleteTracking"
      @close-dialog="setDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import DialogDelete from '@/components/DialogDelete';
import AssignedPixels from './AssignedPixels';
import AssignedEvents from './AssignedEvents';
import AssignedQuestions from './AssignedQuestions';
import AssignedAnswers from './AssignedAnswers';
import AssignedAds from './AssignedAds';
import AssignedForms from './AssignedForms';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'tracking/getField',
  mutationType: 'tracking/updateField',
});

export default {
  components: {
    AssignedPixels,
    AssignedEvents,
    AssignedQuestions,
    AssignedAnswers,
    AssignedAds,
    AssignedForms,
    DialogDelete,
  },
  data() {
    return {
      savingProgress: false,
      loading: true,
      show: false,
      form: {},
      validationErrors: {},
      dialogConfirmDelete: {
        show: false,
        loading: false,
      },
      type: 'Tracking',
      showDeleteDialog: false,
      deletingProgress: false,
    };
  },
  computed: {
    ...mapState('tracking', ['tracking']),
    ...mapFields({
      currentState: 'tracking.active',
    }),
  },
  watch: {},
  mounted() {
    this.getTracking();
    this.$bus.listen('tracking-updated', this.getTracking);
    this.$bus.listen('pixels-updated', this.getTracking);
  },
  destroyed() {
    this.$bus.remove('tracking-updated');
    this.$bus.remove('pixels-updated');
  },
  methods: {
    async getTracking() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          'tracking/getTracking',
          this.$route.params.id
        );
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelNotFound', {
            model: this.$t('models.single.tracking'),
          }),
        });
        this.$router.push({ name: 'trackEvents' });
      }
      this.loading = false;
    },
    editTracking() {
      this.$bus.fire('edit-tracking', this.tracking.id);
    },
    async deleteTracking() {
      this.deletingProgress = true;
      // post to controller
      try {
        await this.$store.dispatch('tracking/deleteTracking', this.tracking.id);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.modelDeleted', {
            model: this.tracking.name,
          }),
        });
      } catch (err) {
        this.$bus.fire('show-snackbar', {
          message: err,
          options: { color: 'error' },
        });
      }

      // hide delete dialog
      this.setDeleteDialog(false);
      this.deletingProgress = false;

      // forward to transfer-overview
      this.$router.push({ name: 'trackEvents' });
    },
    setDeleteDialog(state = false) {
      this.showDeleteDialog = state;
    },
    async toggleTrackingState(newState) {
      const params = {
        id: this.tracking.id,
        newState,
      };
      try {
        await this.$store.dispatch('tracking/toggleState', params);
        this.$bus.fire('show-snackbar', {
          message: this.$t('components.snackbar.text.stateChanged'),
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style></style>
