var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main pl-3",attrs:{"fluid":""}},[_c('filter-bar'),_c('v-row',[_c('v-col',[_c('date-range-filter',{attrs:{"max-range":45}})],1)],1),_c('v-card',[_c('v-row',[_c('v-col',{staticClass:"pt-0 left-bar",attrs:{"cols":"12","md":_vm.showContent ? 3 : 12,"order":"2","order-md":"1"}},[_c('audience-chooser',{attrs:{"parallel":_vm.parallelAudienceChooser},on:{"input":_vm.checkSelectedInterests},model:{value:(_vm.selectedAudiences),callback:function ($$v) {_vm.selectedAudiences=$$v},expression:"selectedAudiences"}})],1),(_vm.showContent)?_c('v-col',{attrs:{"md":"9","order-md":"2","order":"1"}},[_c('v-row',{staticClass:"mb-4 mt-2",attrs:{"justify":"center"}},_vm._l((_vm.selectedAudiences),function(id){return _c('v-avatar',{key:id,staticClass:"overlapping avatar",attrs:{"size":64}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"avatar-image",attrs:{"width":64,"height":64,"src":_vm.audienceMap[id].filename || _vm.helpers.getPlaceholderImage(),"alt":_vm.audienceMap[id].text}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.audienceMap[id].text))])])],1)}),1),_c('div',[_c('div',{staticClass:"card-progress-container ma-0"},[(!_vm.$store.state.monitoring.users.histogram.loaded)?_c('v-progress-linear',{attrs:{"height":7,"color":"primary","indeterminate":""}}):_vm._e()],1),_c('line-chart',{attrs:{"data":_vm.chartData,"timeseries":"","interval":_vm.$store.getters['monitoring/insightsUseHourInterval'] ? 'h' : 'd'}})],1),_c('v-col',[_c('v-tabs',{staticClass:"transparent",attrs:{"value":_vm.tabsModel,"fixed-tabs":"","show-arrows":""},on:{"change":_vm.tabChanged}},[_c('v-tab',{attrs:{"href":"#info","to":"#info"}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.headers.info'))+" ")]),_c('v-tab',{attrs:{"href":"#devices","to":"#devices"}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.devices'))+" ")]),_c('v-tab',{attrs:{"href":"#referrer","to":"#referrer"}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.referrer'))+" ")]),_c('v-tab',{attrs:{"href":"#pages","to":"#pages"}},[_vm._v(" "+_vm._s(_vm.$t('monitoring.data.pages'))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tabsModel),callback:function ($$v) {_vm.tabsModel=$$v},expression:"tabsModel"}},[_c('v-tab-item',{attrs:{"value":"info"}},[_c('v-card',[_c('v-card-text',{staticClass:"textColor--text"},_vm._l((_vm.selectedAudiences),function(id){return _c('v-row',{key:id,staticStyle:{"align-items":"center"}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":64}},[_c('v-img',{staticClass:"avatar-image",attrs:{"width":64,"height":64,"src":_vm.audienceMap[id].filename ||
                          _vm.helpers.getPlaceholderImage(),"alt":_vm.audienceMap[id].text}})],1),_c('div',[_c('h2',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.audienceMap[id].text)+" ")]),_c('div',{staticClass:"subtitle-1"},[_c('animated-number',{attrs:{"data":_vm.audienceMap[id].visitorCount,"duration":2000}}),_vm._v(" "+_vm._s(_vm.$t('monitoring.data.visitors'))+" "+_vm._s(_vm.$t('monitoring.date.inLast30Days'))+" "),_c('br'),_c('animated-number',{attrs:{"data":_vm.audienceMap[id].leadCount,"duration":1000}}),_vm._v(" "+_vm._s(_vm.$t('components.audienceOverview.cards.leads'))+" ")],1)])],1)}),1)],1)],1),_c('v-tab-item',{attrs:{"value":"devices"}},[_c('v-card',[_c('v-card-text',[_c('devices-view',{attrs:{"data":_vm.$store.state.monitoring.insights.devices.data,"translate":"monitoring.data"}})],1),_c('div',{staticClass:"card-progress-container"},[(!_vm.$store.state.monitoring.insights.devices.loaded)?_c('v-progress-linear',{attrs:{"height":7,"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1),_c('v-tab-item',{attrs:{"value":"referrer"}},[_c('v-card',[_c('v-card-text',[_c('referrer-distribution',{attrs:{"data":_vm.$store.state.monitoring.insights.referrerTypes.data}})],1),_c('div',{staticClass:"card-progress-container"},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.$store.state.monitoring.insights.referrerTypes.loaded
                    ),expression:"\n                      !$store.state.monitoring.insights.referrerTypes.loaded\n                    "}],attrs:{"height":7,"color":"primary","indeterminate":""}})],1)],1),_c('v-card',[_c('expandable-number-list',{staticClass:"mt-2",attrs:{"data":_vm.$store.state.monitoring.insights.referrer.getData([]),"data-loaded":_vm.$store.state.monitoring.insights.referrer.loaded,"translations":function (item, level) { return level === 0
                        ? _vm.$t('monitoring.data.' + item.key)
                        : item.key; },"value-header":_vm.$t('monitoring.data.views'),"key-header":_vm.$t('monitoring.data.referrer'),"key-url":function (item, level) {
                      switch (level) {
                        case 0:
                          return false;
                        case 1:
                          return '//' + item.key;
                        default:
                          return item.key;
                      }
                    },"empty-key-value":_vm.$t('monitoring.components.no_referrer'),"count-field":"doc_count","fixed-table":""}})],1)],1),_c('v-tab-item',{attrs:{"value":"pages"}},[_c('DomainPageView',{attrs:{"domain-data":_vm.$store.state.monitoring.users.domains,"page-data":_vm.$store.state.monitoring.users.pages,"page-filter":"","flat":""}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }