import Resource from './Resource';

class AdResource extends Resource {
  /**
   * The constructor for the AdResource.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor() {
    super('/v1/ads');
  }

  /**
   * Method used to get all ads from current user
   *
   * @returns {Promise<Object>} The result in a promise.
   */
  getAds() {
    return this.submit('get', 'getAds');
  }
}

export default AdResource;
