<template>
  <div>
    <div class="text-h6">
      {{ $t('components.transfer.assignedProperties.headline') }}
    </div>
    <div class="body-2">
      <span>{{ $t('components.transfer.assignedProperties.info') }}</span>
      <v-list>
        <v-list-item
          v-for="audience in transfer.assignedProperties"
          :key="audience.id"
        >
          <v-list-item-action>
            <v-list-item-avatar>
              <img :src="audience.filename || helpers.getPlaceholderImage()" />
            </v-list-item-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ audience.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helpers from '@/utils/helpers';

export default {
  data() {
    return {
      helpers,
    };
  },
  computed: {
    ...mapState('transfer', ['transfer']),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
