<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    scrollable
    persistent
    @keydown.esc="closeDialog"
  >
    <ValidationObserver
      v-slot="{ invalid, touched, handleSubmit }"
      ref="validationObserver"
      slim
    >
      <v-card>
        <v-card-title class="pp-custom-background py-6 text-h6">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text class="px-4" style="padding-bottom: 0">
          <v-tabs v-model="activeTab" grow show-arrows>
            <v-tab active ripple>
              {{ $t('components.ads.dialog.tabs.settings') }}
            </v-tab>
            <v-tab-item>
              <ad-settings :loading="loading" />
            </v-tab-item>
            <v-tab ripple>
              {{ $t('components.ads.dialog.tabs.ad') }}
            </v-tab>
            <v-tab-item>
              <ad-detail :edit-mode="editMode" :loading="loading" />
            </v-tab-item>
            <v-tab ripple>
              {{ $t('components.ads.dialog.tabs.inventory') }}
            </v-tab>
            <v-tab-item>
              <ad-inventory :loading="loading" :ad-loaded="adLoaded" />
            </v-tab-item>
            <template v-if="showTargeting">
              <v-tab ripple>
                {{ $t('components.ads.dialog.tabs.targeting') }}
              </v-tab>
              <v-tab-item>
                <ad-targeting />
              </v-tab-item>
            </template>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-btn v-show="editMode" text color="error" @click="deleteAd">
            {{ $t('components.ads.dialog.btns.delete') }}
          </v-btn>
          <v-spacer />
          <v-btn text color="warning" @click="closeDialog">
            {{ $t('components.ads.dialog.btns.cancel') }}
          </v-btn>
          <v-btn
            :disabled="touched && invalid"
            :loading="savingProgress"
            color="success"
            @click="handleSubmit(saveAd)"
          >
            {{ $t('components.ads.dialog.btns.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import AdSettings from './AdSettings';
import AdDetail from './AdDetail';
import AdInventory from './AdInventory';
import AdTargeting from './AdTargeting';

export default {
  components: {
    AdSettings,
    AdDetail,
    AdInventory,
    AdTargeting,
  },
  data: () => ({
    show: false,
    activeTab: 0,
    savingProgress: false,
    dialogTitle: '',
    editMode: false,
    loading: false,
    adLoaded: false,
    deleteAdFunction: undefined,
  }),
  computed: {
    ...mapState('ad', ['ad']),
    /**
     * Show audiences tab based on AUDIENCES.VIEW permission
     *
     * @returns {boolean}
     */
    showTargeting() {
      return (
        this.$acl.check('viewAudiences') || this.$acl.check('useProductTools')
      );
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.clearModalDialog();
      }
      this.$bus.fire('dialog-open-state-updated');
    },
  },
  mounted() {
    this.$bus.listen('hide-ad-dialog', this.closeDialog);
    this.$bus.listen('show-ad-dialog-edit-mode', this.initData);
    this.$bus.listen('show-ad-dialog-create-mode', async (item) => {
      this.duplicatingMode = !!item;
      if (this.duplicatingMode) {
        this.loading = true;
        await this.$store.dispatch('ad/getAdById', item.id);
        this.$store.dispatch('ad/updateAdParams', {
          name:
            this.$t('components.ads.dialog.duplicate_prefix') + this.ad.name,
          element_id: null,
          adExperiences: [],
        });
        if (this.ad.image_data) {
          this.$store.commit('image/SELECT_ITEM', {
            imageUrl: this.ad.image_data,
          });
        }
        this.loading = false;
      }
      this.adLoaded = true;
      this.openDialog();
    });
  },
  destroyed() {
    this.$bus.remove('show-ad-dialog-edit-mode');
    this.$bus.remove('show-ad-dialog-create-mode');
    this.$bus.remove('hide-ad-dialog');
  },
  methods: {
    async initData(params) {
      const { id, deleteFunction } = params;
      this.editMode = true;
      this.openDialog();
      this.loading = true;
      try {
        await this.$store.dispatch('ad/getAdById', id);

        // update the ad experience active toggle after the ad was loaded
        if (
          this.$store.state.ad.ad.adExperiences &&
          this.$store.state.ad.ad.adExperiences.length > 0
        ) {
          this.$store.commit('ad/SET_AD_EXPERIENCES_ACTIVE', true);
        } else {
          this.$store.commit('ad/SET_AD_EXPERIENCES_ACTIVE', false);
        }

        this.$bus.fire('ad-loaded');
        this.deleteAdFunction = deleteFunction;
      } catch (err) {
        console.error(err);
      }
      this.adLoaded = true;
      this.loading = false;
    },
    clearModalDialog() {
      this.editMode = false;
      this.$store.commit('ad/RESET_QUESTIONS_AND_ANSWERS');
      this.$store.commit('ad/RESET_FIELDS');
      this.$bus.fire('clear-data');
      this.$refs.validationObserver.reset();
      this.activeTab = 0;
    },
    closeDialog() {
      this.show = false;
    },
    openDialog() {
      if (this.editMode) {
        this.dialogTitle = this.$t('components.ads.dialog.title.edit');
      } else {
        this.dialogTitle = this.$t(
          `components.ads.dialog.title.${
            this.duplicatingMode ? 'duplicate' : 'create'
          }`
        );
      }
      this.show = true;
    },
    closeWarning() {
      this.savingProgress = false;
    },
    async deleteAd() {
      try {
        await this.deleteAdFunction(this.ad);
      } catch (err) {
        console.error(err);
      }
    },
    async saveAd() {
      this.savingProgress = true;
      try {
        if (
          this.ad.custom_targeting &&
          typeof this.ad.custom_targeting === 'object'
        ) {
          const entries = Object.entries(this.ad.custom_targeting).filter(
            (entry) => entry[0]
          );

          this.ad.custom_targeting = entries.length
            ? JSON.stringify(
                entries.reduce((acc, [key, value]) => {
                  acc[key] = value;
                  return acc;
                }, {})
              )
            : null;
        }

        const params = {
          id: this.ad.element_id,
          ad: this.ad,
        };

        if (this.$store.state.ad.adAllExperienceActive) {
          this.$store.commit('ad/SET_AD_QUESTION_MAPPING', []);
          this.$store.commit('ad/SET_AD_ANSWER_MAPPING', []);
          this.$store.commit('ad/SET_AD_COVER_MAPPING', []);
          this.$store.commit('ad/SET_AD_RESULT_MAPPING', []);
        } else {
          this.$store.commit('ad/SET_AD_EXPERIENCES_ITEM_MAPPING', []);
        }

        if (this.ad.element_id) {
          await this.$store.dispatch('ad/update', params);
        } else {
          await this.$store.dispatch('ad/save', params.ad);
        }
        this.closeDialog();
        // fire event that an ad was assigned
        this.$bus.fire('data-updated');
      } catch (errors) {
        this.$refs.validationObserver.setErrors(errors);
      }
      this.savingProgress = false;
    },
  },
};
</script>
